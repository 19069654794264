import React from "react";

const TextField = ({
  name,
  id,
  type,
  placeholder,
  onChange,
  onFocus,
  rounded = null,
  focus = null,
  onBlur,
  border = null,
  value,
  imageUrl,
  marginTop = null,
  onClick = null,
  isPasswordVisible = null,
  passwordIcon = null,
  disabled = null,
  image = null,
  onClickInput = null,
  textSize,
}) => {
  return (
    <div
      className={`relative ${border && border} ${marginTop && marginTop} ${
        rounded && rounded
      }`}
      style={{ fontFamily: "Silka" }}
    >
      {imageUrl && (
        <img
          src={imageUrl}
          alt="Icon"
          className="absolute left-2 top-1/2 transform -translate-y-1/2 h-11 w-11"
        />
      )}
      <input
        name={name}
        className={`${textSize ? textSize : "text-base"} rounded-md ${
          focus && focus
        } ${imageUrl && "pl-[4rem]"} w-full py-4 px-3`}
        style={{ fontFamily: "Silka" }}
        id={id}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        onClick={onClickInput}
        onFocus={onFocus}
        autoComplete="off"
      />
      {passwordIcon && (
        <div
          className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600 cursor-pointer"
          onClick={onClick}
        >
          {isPasswordVisible ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          )}
        </div>
      )}
      {image && (
        <div className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600 cursor-pointer">
          <img src={image} className="w-6 h-6" />
        </div>
      )}
    </div>
  );
};

export default TextField;
