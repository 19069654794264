import http from "../../../../../http"

export const getCandidates = async (assessmentId, entries, page, search, value, status, hiring, soryBy, pageNumber, order) => {
    if (value.min === 0 && value.max === 100) {
        const response = await http.get(`/assessment/candidates/${assessmentId}?order=${order}&page=${pageNumber}&take=${entries}&search=${search}&status=${status}&hiringStage=${hiring}&sortBy=${soryBy}`)
        return response.data
    }
    else {
        const response = await http.get(`/assessment/candidates/${assessmentId}?order=${order}&page=${pageNumber}&take=${entries}&search=${search}&minScore=${value.min}&maxScore=${value.max}&status=${status}&hiringStage=${hiring}&sortBy=${soryBy}`)
        return response.data
    }
}