import http from "../../../../http";

export const fetchSections = async (
  industries,
  departments,
  entry_level,
  startTime,
  endTime,
  searchedValue,
  jobs,
  currentTake
) => {
  const combinedIndustriesAndDepartments = [...industries, ...departments];
  const response = await http.get(
    `/section?category_id=${industries}&sub_category_id=${departments}&experience=${entry_level}&timeRangeStart=${startTime}&timeRangeEnd=${endTime}&search=${searchedValue}&jobIds=${jobs}&page=${currentTake}&take=200&status=active,coming_soon&orderBy=name&order=ASC`
  );
  return response.data;
};
