import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';

const TablePagination = ({ totalCount, onPageChange }) => {
    const [pageNumbers, setPageNumbers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const numbers = [];
        for (let i = 1; i <= totalCount; i++) {
            numbers.push(i);
        }
        setPageNumbers(numbers);
    }, [totalCount]);

    const handleClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        onPageChange(pageNumber);
    };

    // Show skeleton loading when totalCount is not available yet
    if (!totalCount) {
        return (
            <div className="flex flex-row mb-10 gap-3 ">
                <p className="my-auto text-[13px]">
                    <Skeleton width={50} />
                </p>
                <div className="border border-[#D3D5D8] rounded px-5 py-1.5 flex flex-row gap-3 items-center">
                    <Skeleton width={30} height={30} count={1} />
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-row mb-10 gap-3 ">
            <p className="my-auto text-[13px]" style={{fontFamily : "Silka"}}>
                Pages
            </p>
            <div className="border border-[#D3D5D8] rounded px-5 py-1.5 flex flex-row gap-3 items-center">
                {pageNumbers.map((i) => (
                    <p
                        className={`w-6 h-6 text-[12px] rounded text-center flex justify-center items-center cursor-pointer ${currentPage === i ? "bg-coalColor text-white hover:bg-primaryGreen hover:text-coalColor" : "text-black"}`}
                        key={i}
                        onClick={() => handleClick(i)}
                    >
                        {i}
                    </p>
                ))}
            </div>
        </div>
    );
};
export default TablePagination;
