import React from "react";
import { Navigate } from "react-router-dom";
import { isRecruiterAuthenticated } from "../Helpers/Auth";

const NonAuthMiddleware = (props) => {
  const isAuthenticated = isRecruiterAuthenticated();
  if (isAuthenticated) {
    if (!localStorage.getItem("Registration")) {
      return <Navigate to={{ pathname: "/dashboard" }} />;
    }
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default NonAuthMiddleware;
