import React from "react"
import withRouter from "../Common/withRouter";
import NavbarCandidate from "../Navbar/NavbarCandidate";


const Layout = (props) => {
    return (
        <React.Fragment>
            <div id="preloader">
                <NavbarCandidate />
                <div className="main-content">{props.children}</div>
            </div>
        </React.Fragment >
    );
};

Layout.propTypes = {
};

export default withRouter(Layout);
