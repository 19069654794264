import React from "react";
import { Navigate } from "react-router-dom";

const AuthmiddlewareStudent = (props) => {
  if (!localStorage.getItem("CP-CANDIDATE-TOKEN")) {
    return (
      <Navigate to={{ pathname: "/candidate/login" }} />
    );
  }
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default AuthmiddlewareStudent;
