import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    setPackage: "",
};

export const packageDataSlice = createSlice({
    name: "packageDetails",
    initialState,
    reducers: {
        setPackageData: (state, action) => {
            state.setPackage = action.payload;
        },
    },
});

export const { setPackageData } =
    packageDataSlice.actions;
export default packageDataSlice.reducer;
