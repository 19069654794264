const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const isRecruiterAuthenticated = () => {
  if (localStorage.getItem("CP-USER-TOKEN")) {
    const userToken = localStorage.getItem("CP-USER-TOKEN");
    if (userToken === "undefined") {
      localStorage.removeItem("CP-USER-TOKEN");
      localStorage.removeItem("CP-USER-ID");
      return false;
    }
    const userObject = parseJwt(userToken);
    if (userObject.exp * 1000 > Date.now()) {
      return true;
    } else {
      localStorage.removeItem("CP-USER-TOKEN");
      localStorage.removeItem("CP-USER-ID");
      return false;
    }
  } else {
    return false;
  }
};
