import React from "react";
import { useNavigate } from "react-router-dom";

const SetupCompleted = (props) => {
  const navigate = useNavigate();
  return (
    <div className="sm:px-2 pb-20">
      <div className="bg-white rounded-lg">
        <div className="p-5 pt-5 text-coalColor">
          <h2
            className="text-coalColor mt-3"
            style={{ fontFamily: "Archia Semibold" }}
          >
            Thank you!
          </h2>
          <p className="mt-10" style={{ fontFamily: "Silka" }}>
            Your invites have been sent to your selected candidates.
          </p>
          <p
            className="underline mt-10 text-md cursor-pointer"
            onClick={() => {
              navigate("/dashboard");
              localStorage.removeItem("current_module");
            }}
          >
            My tests
          </p>
        </div>
      </div>
    </div>
  );
};
export default SetupCompleted;
