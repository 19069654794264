import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
const CustomerTerms = () => {
    const navigate = useNavigate();
    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    return (
        <div className="bg-gray-100 min-h-screen py-10 ">
            <div className="max-w-3xl mx-auto bg-white shadow-md rounded-lg ">
                <div className='bg-[#252E3A] p-8'>
                    <h2 className=" text-white text-center text-2xl" style={{ fontFamily: "Archia Bold" }}>Customer Terms of Use</h2>
                    <p className="text-white text-[11px] text-center" style={{ fontFamily: "Silka" }}>Version v1 -June 2024</p>
                </div>
                <div className=' overflow-y-auto h-screen p-8'>
                    <p className="" style={{ fontFamily: "Archia Bold" }}>Introduction</p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        These Dexta Terms of Service (hereafter the “Terms”) outline the terms regarding Your use of the Services and are an integral part of the agreement between you (“you,” “your,” or “Customer”) and Career Tech LTD (“Dexta,” “us,” “we,” or “our”). You or Dexta may also be referred to individually as a “Party” and together as “Parties” in these Terms. An “Affiliate” means any company or other entity, whether or not a legal person, which directly or indirectly controls, is controlled by, or is under joint control with that person. For the purpose of this definition, “control” means (a) any direct or indirect ownership of over 50% of the ownership or (b) in the absence of such ownership interest, the power to direct or cause the direction of the management and set the policies of such company or entity.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        These Terms are a legally binding contract between You and Dexta. If you do not agree with these Terms, do not register for a Dexta account or purchase or use Services. By using or accessing the Services or registering for a Dexta account, you agree to be bound by these Terms. If you use the Services on behalf of an entity, you agree to these Terms for that entity and represent and warrant to Dexta that you have the authority to bind that entity to these Terms unless that entity has a separate paid contract in effect with Dexta in which event the separate paid contract governs your use of the Services.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        In these Terms, we refer collectively to these Terms, the Data Processing Agreement (attached as a separate annex to these Terms), the Service Level Agreement (“SLA”), the Security Measures, and the Cookie Policy (jointly the “Documentation”) available on our website (“Website”) and applicable ordering documents (“Order Form” as defined below) as the “Agreement.”
                    </p>
                    <p className=" mt-6 " style={{ fontFamily: "Archia Bold" }}>1. Grant of access and use</p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}> 1.1</span> Subject to the terms and conditions of the Agreement (including our receipt of applicable fees), Dexta hereby grants to you, and you accept, a limited, personal, non-exclusive, non-sublicensable, non-transferable, non-assignable license to access and use the Services solely for your own internal assessment and (direct) recruitment purposes (unless explicitly agreed in writing otherwise).
                    </p>
                    <p className="mb-4 text-xs " style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}> 1.2</span> Access and use of the Platform and Services are provided to any number of individuals taking the assessments or tests as provided as part of the Services pursuant to the Agreement in accordance with the applicable subscription plan (“Candidates”).
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}> 1.3</span> Subject to prior written approval by Dexta, your Affiliates may use the Services without entering into a separate Order Form by providing such Affiliate(s) a login ID and password to access and use the Services. The Agreement shall apply to each Affiliate with access to your account, and you are directly and primarily responsible for all access to and use of the Services by your Affiliates. References in these Terms to you shall include a reference to your Affiliates.
                    </p>
                    <p className=" mt-6 " style={{ fontFamily: "Archia Bold" }}>2. The Services</p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}> 2.1</span> The “Services” include the Dexta online talent assessment platform (“Platform”) and any other products and services provided by Dexta that (a) have been applied for by the Customer under any applicable subscription plan (either via the Website or otherwise) that specifies pricing and other commercial terms (“Order Form”); or (b) are used by you. Our Services provided under this Agreement are not intended for personal or private individual use but designed and intended for commercial use only.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}> 2.2</span> The Services provided under this Agreement shall be strictly used for internal or direct recruiting and job application procedures only. Any use of the Services by search agencies, recruiting agencies, or sourcing platforms for third-party screening purposes or search assignments on behalf of third parties is strictly forbidden unless Parties have explicitly agreed otherwise in the Order Form. We reserve the right to immediately terminate your access to the Services and your account upon violation by you of this prohibited use of the Services under these Terms.
                    </p>
                    <p className="mb-4 text-xs " style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}> 2.3</span> Dexta offers three subscription plans:
                    </p>
                    <ul class='list-disc pl-5 pl-10 text-xs mb-2'>
                        <li style={{ fontFamily: "Silka" }}><span className="mb-4 text-xs" style={{ fontFamily: "Archia Bold" }}>Explore For Free:</span> This is a free plan allowing the user to see how the product functions with limited access to features.
                        </li>
                        <li style={{ fontFamily: "Silka" }}><span className="mb-4 text-xs" style={{ fontFamily: "Archia Bold" }}>Pro Plan:</span> This is the standard product offering with limited customization options
                        </li>
                        <li style={{ fontFamily: "Silka" }}><span className="mb-4 text-xs" style={{ fontFamily: "Archia Bold" }}>Enterprise Plan:</span> This plan includes a number of customization options such as the ability to customize emails to candidates, create custom questions, and more.
                        </li>
                    </ul>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}> 2.4</span> Dexta will provide the Services in accordance with (a) the terms of the Agreement; (b) applicable laws; and (c) the Information Security Measures.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}> 2.5</span> We may provide the Services or a portion thereof to you through our Affiliates in accordance with these Terms and any applicable Order Form(s). Dexta shall always remain responsible for any Services or part thereof provided by our Affiliates.
                    </p>
                    <p className="mt-2" style={{ fontFamily: "Archia Bold" }}>3. Your Account
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>3.1</span> To use our Services, it is required to create an account. To create an account, you (i) must select the applicable subscription plan (Explore For Free, Pro Plan, or Enterprise Plan); (ii) must be legally authorized to represent the company or business contracting our Services; and (iii) must review and accept these Terms on the Customer’s behalf. To create an account, you must provide your email address and create a password.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>3.2</span> You agree to provide us with information that is accurate, complete, and current during your use of the Services. Failure to do so constitutes a breach of the Terms, which may result in termination of your account on our Service.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>3.3</span> You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password. You agree not to disclose your password to any third party.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>3.4</span> You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account. You are responsible for preventing unauthorized access to or use of the Services through your account and will notify Dexta immediately of any such unauthorized access or use. Dexta is not liable for any loss or damage arising from unauthorized use of your account.
                    </p>
                    <p className="mt-2  " style={{ fontFamily: "Archia Bold" }}>4. User rights and responsibilities
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>4.1</span> In using the Services, you agree to use the Services only in accordance with these Terms and applicable laws.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>4.2</span> You will be solely responsible for all use of the Services under your account, including all applications, web domains, devices, and communication channels owned or controlled by you or owned or controlled by third parties and made available by you to the Candidates which access, use, interact with, or depend on the Services (each a “Customer Application”).
                    </p>
                    <p className="mb-4 text-xs " style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>4.3</span> You shall not: (a) duplicate any portion of the Services or any documentation (except for your internal use); (b) modify, translate, decompile, reverse engineer, disassemble, adapt the Services, or attempt to derive the source code of the software offered through the Services; (c) use the Services or allow the transfer, transmission, export, or re-export of the Services or portion thereof in violation of any applicable law, regulation, or rule; (d) develop any software or service that is derived from the Services and materially similar in function to or competes with the Services; (e) bypass, hack, or breach any security device or protection used by the Services or access or use the Services with or without automated means (such as scrape, crawl, or spider); (f) remove, modify, or obscure any identification or proprietary or restrictive rights markings or notices from the Services or any component thereto; (g) input, upload, transmit, or otherwise provide to or through the Services any information or materials that are unlawful or injurious, including the distribution or publication of information that is in violation of applicable law, contrary to public order or public morality, or contain, transmit, or activate any software, hardware, or other technology, device, or means, including any virus, worm, malware, or other malicious computer code; or (h) aid or assist any third parties in doing any of the above.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>4.4</span> You will not lease, (re)sell, (sub)license, assign, distribute, publish, transfer, or otherwise make available any Services to third parties (except Candidates) unless explicitly permitted under the Agreement.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>4.5</span> The Services can only be used by individuals that are at least sixteen (16) years old
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>4.6</span> If you or any of the Candidates or any other person authorized using the Services through your account violates the above restrictions or threatens to violate them, Dexta is entitled to intervene without prior consultation to terminate the violation or intervene to prevent any imminent danger, for example, by disabling the account of the relevant Candidates or your access to the Services. You will be liable for any damage caused by your use of the Services through your account in violation of these restrictions. Dexta is at all times entitled to report criminal offenses with the relevant authorities directly related to your violation of the restrictions it has discovered and shall have no liability for any damage to you that may result from such reports.
                    </p>
                    <p className="mt-2  " style={{ fontFamily: "Archia Bold" }}>5. Account suspension</p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>5.1</span> We shall have the right to remove any inappropriate content from the Services, limit, suspend your access to your account and the Services with immediate effect and without prior notice in the event that in our reasonable determination if: (a) your use or the use of Candidates of the Services is for any unlawful, unauthorized, or fraudulent purpose; (b) you or Candidates are in material breach of any provision of the Agreement; (c) your use of the Services is materially adversely threatening the security, availability, or integrity of the Services or our ability to provide service to other customers; (d) our provision or your use of the Services is not permitted by applicable laws or regulations; (e) the account information you have provided is incorrect or incomplete; or (f) you are in breach of your payment obligations under the Agreement.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>5.2</span> If your account or use of the Services is suspended by us as a result of your actions or omissions pursuant to this Section 5 or Section 8 (Fees and Payment Terms), Dexta does not have any liability for damages or losses or any other consequences that you may incur as a result. You will remain responsible for the Fees (as defined below) during any suspension.
                    </p>
                    <p className="mt-2  " style={{ fontFamily: "Archia Bold" }}>6. Maintenance and Downtime Modifications</p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>6.1</span> The Services may occasionally become unavailable due to (a) the performance of scheduled or unscheduled maintenance, modifications, or upgrades; (b) hardware failures or failures of third-party providers; (c) to mitigate or prevent the effects of any threat or attack to the Services or any other network or systems on which the Services rely; or (d) as necessary for legal or regulatory reasons. We will use reasonable efforts to communicate any scheduled service outages to you in advance.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>6.2</span> Unless specifically stipulated in the SLA, Dexta is not liable for any damages, losses, or any other consequences incurred as a result of unavailability of Services or the failure to provide notice of unavailability.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>6.3</span> We have the right to occasionally modify the features and operations of the Services and will use reasonable efforts to inform you. We agree such changes to the Service will not materially diminish the overall features or functionality of the Services. Your continued use of the Services following the posting or notice of the changes will constitute your acceptance of such changes. If you do not agree to such changes, you must stop using the Services immediately. We will notify you in line with Section 15 (Amendments) if applicable legislation requires Dexta to provide you with specific notice of any such change.
                    </p>
                    <p className="mt-2  " style={{ fontFamily: "Archia Bold" }}>7. Free Plan and Beta Products
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>7.1</span> We may invite you to test out new or additional products or features of our Services that are not generally available to all customers (“Beta Products”) or use our Services free of charge (“Free Plan Products”)
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>7.2</span> Beta Products and Free Plan Products may contain errors and are provided for limited evaluation only.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>7.3</span> If you have access to Free Plan Products, we will make the Services available to you on a trial basis free of charge until the earlier of (a) the end of the free trial period or (b) the start date of any purchased Service subscriptions ordered by you for such Services.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>7.4</span> Beta Products and Free Plan Products are provided “AS IS” without warranty of any kind, whether express, implied, statutory, or otherwise. Dexta specifically disclaims all implied warranties of merchantability, non-infringement, and fitness for a particular purpose in relation to Beta Products and Free Plan Products.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>7.5</span>  Dexta does not have any obligation to provide Beta Products and Free Plan Products to any customer or to our general customer base. Dexta is entitled to terminate or discontinue a Beta Product or Free Plan Product at any moment.
                    </p>
                    <p className="mt-2  " style={{ fontFamily: "Archia Bold" }}>8. Fees and Payment Terms </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>8.1</span> Unless agreed otherwise in writing by the Parties in the Order Form, you shall pay Dexta all fees in accordance with the applicable subscription plan as published on the Website or as agreed in the Order Form (the "Fees"). The subscription plans are Explore For Free, Pro Plan, and Enterprise Plan, each with different levels of access and customization options.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>8.2</span>  Subscription plans have a standard duration of either twelve (12) months or monthly, unless explicitly agreed otherwise, and are paid in advance annually or monthly, depending on your selection in the Order Form or the Website.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>8.3</span>  All payment obligations are non-cancelable, and Fees and taxes once paid are non-refundable. Except as otherwise set forth in the applicable Order Form(s) and subject to Section 8.9 (payment disputes), you will pay the Fees due under these Terms in accordance with the following applicable payment method: (a) if you elect to remit the Fees using a credit card or PayPal, you represent and warrant that you are authorized to use that credit card or PayPal account, that any and all Fees may be billed to that credit card or PayPal account, and that payment of such Fees will not be declined; or (b) if you elect to receive invoices and Dexta approves you for the same, invoices will be sent to you at the frequency set forth in the applicable Order Form and you will pay the Fees due within twenty (20) days of the date of the invoice.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>8.4</span> If you have selected payment by credit card with payment in monthly installments, your credit card will be charged once a month. If your credit card cannot be charged, Dexta will notify you, and you will need to update your payment information. In the event you do not update your payment information within twenty (20) days of Dexta’s notice, your access to the Service may be suspended, and you will need to update your card information to resume use of the Services. There will be no refunds or credits for partial months of service.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>8.5</span> You shall provide Dexta with accurate and complete billing information, including full name, address, state, zip code, country, telephone number, and a valid VAT or applicable tax registration number, and notify us of any changes to such information. By submitting the payment information, you automatically authorize Dexta to charge all Fees incurred through your account to any such selected payment instruments.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>8.6</span> Your use of the Services is based on the selected subscription plan (Explore For Free, Pro Plan, or Enterprise Plan). Dexta is entitled to verify at any time whether you have selected the correct subscription plan based on your usage and needs.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>8.7</span> All Fees and other amounts payable by you under the Agreement are exclusive of taxes, duties, levies, and similar assessments, as well as any other costs, including transaction costs or bank transfer fees. Without limiting the foregoing, you are responsible for all sales, use, and excise taxes and any other similar taxes, duties, and charges of any kind imposed by any governmental or regulatory authority on any amounts payable by you hereunder other than any corporate income taxes imposed on Dexta's income.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>8.8</span> Dexta is entitled to invoice you if, for any reason, the appropriate taxing authorities determine that you are not exempt from any taxes and Dexta is required to pay such taxes. Any applicable penalties or interest that might be imposed will be added to such invoices. The Fees shall in no event be decreased by any taxes and/or fees of any nature owed by you in connection with your purchase of the Services.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>8.9</span>  If you fail to make any payment when due, then in addition to all other remedies that may be available: (a) Dexta may charge interest on the past due amount at the rate of 1.5% per month of the value of the applicable Fees, calculated daily and compounded monthly, or, if higher, the maximum rate permitted under applicable law; (b) You shall reimburse us for all costs incurred by Dexta in collecting any late payments or interest, including attorneys' fees, court costs, and collection agency fees; and (c) If such failure continues for more than thirty (30) days following written notice thereof, we may suspend performance of the Services until all past due amounts and interest thereon have been paid, without incurring any obligation or liability to you or any other person by reason of such suspension.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>8.10</span> If you do not agree with any invoice for Fees, you must notify us in writing within ten (10) days of the date of the respective invoice, failure of which shall result in acceptance of the invoice and forfeiture of your right to dispute the invoice. All undisputed fees remain due according to schedule.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>8.11</span> All amounts payable to Dexta under the Agreement shall be paid by you in full without any setoff, recoupment, counterclaim, deduction, debit, or withholding for any reason.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>8.12</span> We are entitled to increase the Fees annually after the Initial Term for any Renewal Term (as defined below) with a notice period of thirty (30) days before taking effect. If you do not agree to any change in the Fees, you are entitled to terminate the Agreement before the start of the Renewal Term. Your continued use of the Service after the modification of the Fees comes into effect constitutes your acceptance and agreement to pay the updated Fees.
                    </p>
                    <p className="mt-2  " style={{ fontFamily: "Archia Bold" }}>9. Intellectual Property and Data</p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>9.1</span> You acknowledge and agree that Dexta and its licensors, as applicable, own and retain all title and rights, including all intellectual property rights, in and to (a) the Platform, the Services, Beta Products, the Documentation, the software, including any related software application and/or component thereof, or to any associated materials or intellectual property, or in or to any enhancements, modifications, updates, or improvements of any of the foregoing (including any feedback you may provide) and all other materials created by us or provided by us to you as part of the Services under the Agreement and (b) all operational and performance data related to your use of the Service and Beta Products, however, excluding Customer Data (as defined below) and subject to applicable law, any de-identified or anonymized aggregated data (“Dexta Data”).
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>9.2</span> The Agreement does not grant to Customer any title or right of ownership in or to the Platform, the Services, or any related software application or component thereof, or to any associated materials or intellectual property, including test content and questions, test algorithms, output generated by Candidates, or psychological profiling (with the exception of Customer Data as defined below).
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>9.3</span> You are prohibited from modifying, translating, decompiling, reverse engineering, disassembling, adapting the Services and the associated intellectual property rights, or attempting to or abstracting the source code from the software underlying the Platform, the Services, the software, or the applications in any other way. You shall not remove or alter any of Dexta’s proprietary or copyright notices, trademarks, or logos contained in the Services.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>9.4</span> Any data that originates with a Candidate or that is directly provided by a Candidate via the use of the Platform or by a Candidate having created an account with Dexta, including but not limited to the output of an individual Candidate generated by the Platform and communications with and video recordings of Candidates (“Candidate Data”), shall be the exclusive property of the Candidate. This includes any personal data provided by the Candidate via the use of the Platform or when creating an account with Dexta.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>9.5</span> Dexta hereby grants to you, and you accept, a limited-term, personal, non-exclusive, non-sublicensable, non-transferable, non-assignable license to access and use Dexta Data, including results derived from Candidate Data, solely for your own use of the Services in accordance with the Agreement.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>9.6</span> All data or input, including personal data of your employees, that you submit, share, store, or otherwise provide to Dexta as part of your use of the Services (which shall include Customer-developed tests uploaded by you as part of your use of the Services), as well as data and materials generated by you via your use of the Services, shall remain, as between Customer and Dexta, the exclusive property of Customer (collectively “Customer Data”), and you will retain ownership of your Customer Data (subject to the licenses below and any third-party rights therein). Candidate Data is explicitly excluded from Customer Data.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>9.7</span> You grant us and our Affiliates, as well as any sub-processors authorized by you to provide Services under these Terms, the right to collect, use, and process Customer Data only to the extent necessary to provide the Services and in each case to enable Dexta to operate or improve the Services in accordance with the Terms and the Data Processing Agreement. The terms of the Data Processing Agreement are incorporated into these Terms as an Annex. Your agreement to these Terms also constitutes your agreement to the Data Processing Agreement.
                    </p>
                    <p className="mt-2  " style={{ fontFamily: "Archia Bold" }}>10. Representations, Warranties, and Disclaimer
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>10.1</span> Each Party shall at all times comply with all applicable laws, rules, and regulations in the performance of the Agreement.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>10.2</span> Each Party represents and warrants that it has been duly authorized to enter into the Agreement and it is entitled to perform its obligations and provide the licenses hereunder.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>10.3</span> Customer represents and warrants to have obtained all the required permissions or consents to provide Customer Data to Dexta for use and disclosure in accordance with the Agreement.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>10.4</span> Customer represents and warrants (a) to use the Services only for lawful purposes and in a manner consistent with these Terms; (b) not to use the Services to assess candidates for any jobs which are unlawful, unsafe, offensive, discriminatory, or inappropriate; and (c) not to use the Services to solicit information from candidates that could be used to discriminate against them.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>10.5</span> Dexta warrants that the Services will substantially conform in all material respects in accordance with the specifications as described on the Website and that the Services shall not contain or transmit any (a) virus, trojan horse, worm, backdoor, or other software or hardware devices the effect of which is to permit unauthorized access to or to disable, erase, or otherwise harm any computer systems or software; or (b) time bomb, drop dead device, or other software or hardware device designed to disable a computer program automatically with the passage of time or under the positive control of any person. Dexta further warrants and undertakes that: (a) it has and will maintain all necessary licenses, consents, and permissions necessary for the performance of its obligations under the Agreement; and (b) it will cooperate with Customer in all matters relating to the Services. You will provide prompt written notice of any non-conformity. As Customer’s sole and exclusive remedy and Dexta’s entire liability for any breach of our warranties, Dexta will at our sole discretion either (a) use reasonable efforts to fix, provide a workaround, or otherwise correct the defect, or if Dexta is unable to do so (b) refund the Fees paid for such allegedly defective Services for the period commencing from the receipt of your default notice for the remainder of the time period during which the material failure affected the Services.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>10.6</span> Dexta warrants that it has obtained the consent of each Candidate to provide the Candidate Data (including Candidate personal data) to you (including to your Affiliates in countries outside the European Economic Area if applicable) for the purpose of your direct or internal recruitment activities. Dexta will request the Candidate on behalf of you (which request will explicitly state that the Candidates’ answer will not impact the application procedure) to consent that you may keep the Candidate personal data for a period of two (2) years after the application for which that Candidate has completed the assessment has been completed or withdrawn. In addition, the Candidate is informed that he or she may withdraw such consent at any time.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>10.7</span> Except for the warranties expressly provided by us in Section 10.1, 10.5, and 10.6, Customer expressly acknowledges and agrees that to the maximum extent permitted by applicable law, the Services are provided “as is” with all faults and without warranty of any kind, and Dexta hereby disclaims all warranties and conditions with respect to the Services, either express, implied, or statutory, including but not limited to the implied warranties and/or conditions of merchantability, of satisfactory quality, of fitness for a particular purpose, or of accuracy.
                    </p>
                    <p className="mt-2  " style={{ fontFamily: "Archia Bold" }}>11. Indemnification</p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>11.1</span> We will defend, indemnify, and hold Customer harmless against all damages, fines, penalties, settlement amounts pre-approved by us, costs, expenses, taxes, and other liabilities (including reasonable attorneys’ fees) incurred or awarded against you in connection with any unaffiliated third-party claim, action, demand, suit, or proceeding made or brought against you and your Affiliates, as well as their individual officers, directors, and employees, that your use of the Services in accordance with the Agreement infringes a copyright, registered trademark, issued patent, or other intellectual property right of such third party ("Infringement Claim") on written demand. We reserve the right to either (a) alter the Services to make them non-infringing or (b) terminate the infringing Services and refund you any Fees in the event of an Infringement Claim.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>11.2</span> Any Infringement Claim resulting from or arising out of (a) your use of the Services in violation of the Agreement; (b) the combination of the Services with other applications (including Customer Applications), products, or services where the Services would not by themselves be infringing; or (c) Beta Products and Free Plan Products do not give rise to any liability or obligation on our part under Section 11.1.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>11.3</span> Customer will defend, indemnify, and hold Dexta harmless against all damages, fines, penalties, costs, expenses, taxes, and other liabilities (including reasonable attorneys’ fees) incurred or awarded against Dexta, our Affiliates, officers, directors, and personnel in connection with any claim by an unaffiliated third party alleging or arising out of your (a) breach of your obligations under the Agreement; (b) failure to obtain any appropriate license or other permissions, regulatory certifications, or approvals associated with technology or data provided by Customer to us, including Customer Data; (c) failure to comply with your obligations in violation of any applicable law, including data privacy laws; (d) infringement or misappropriation of intellectual property rights of third parties; and (e) breach of confidentiality.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>11.4</span> The obligation to provide indemnification under the Agreement shall be contingent upon the party seeking indemnification (i) providing the indemnifying party with prompt written notice of any claim for which indemnification is sought; (ii) allowing the indemnifying party to control the defense and settlement of such claim, provided however that the indemnifying party agrees not to enter into any settlement or compromise of any claim or action in a manner that admits fault or imposes any restrictions or obligations on an indemnified party without that indemnified party’s prior written consent, which will not be unreasonably withheld; and (iii) cooperating fully with the indemnifying party in connection with such defense and settlement.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>11.5</span> This Section 11 (Indemnification) states the sole liability of the indemnifying Party to, and the exclusive remedy of, the indemnified Party against the other Party for any third-party claims.
                    </p>
                    <p className="mt-2  " style={{ fontFamily: "Archia Bold" }}>12. Limitation of Liability
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>12.1</span> To the greatest extent permitted by applicable law, each Party’s total accumulated liability to the other or to any third party for any direct loss, damages, costs, or expenses, whether in strict liability, negligence, contract, or otherwise relating to this Agreement shall be limited to the aggregate fees paid or payable by Customer to Dexta during the twelve-month period prior to the occurrence of the initial event giving rise to a claim.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>12.2</span> Neither Party shall be liable to the other for any consequential damages, including but not limited to loss, lost sales, or lost profits, consequential, incidental, special, punitive, and/or contingent damages whatsoever (whether in contract, tort (including negligence), strict liability, warranty, or otherwise), even if such party knew or should have known of the possibility of such damages.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>12.3</span> Dexta shall not be liable for any damages or losses resulting from or relating to misrepresentation or fraud by any Candidate performing or conducting tests or assessments via the Platform. In addition, Dexta shall not be liable for Beta Products and Free Plan Products, nor for damage, destruction, or loss of data or documents (including Customer Data) resulting from the use of the Services.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>12.4</span> The limitations of liability contained in this Section explicitly do not apply to: (a) Customer’s breach of Section 4 (User Rights and Responsibilities); (b) Customer’s breach of Section 8 (Fees and Payment Terms); (c) the indemnification obligations in Section 11 (Indemnification); or (d) in case of gross negligence or willful misconduct of a Party.
                    </p>
                    <p className="mt-2  " style={{ fontFamily: "Archia Bold" }}>13. Limitation of Liability
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>13.1</span> In connection with this Agreement, each Party may have access to or be exposed to information of the other Party that is not generally known to the public, such as software, performance, scoring, evaluation, and Customer ratings of Candidates and Customer employees, Candidate lists, product plans, pricing, marketing, and sales information, “know-how,” or trade secrets, which may be designated as confidential or which under the circumstances surrounding disclosure ought to be treated as confidential, including Customer Data (collectively “Confidential Information”).
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>13.2</span> Confidential Information may not be shared with third parties unless such disclosure is to the receiving Party’s personnel, including employees, agents, and authorized subcontractors, on a “need-to-know” basis in connection with this Agreement, so long as such personnel have agreed in writing to treat such Confidential Information under terms at least as restrictive as those herein. Each Party agrees to take the necessary precautions to maintain the confidentiality of the other Party’s Confidential Information by using at least the same degree of care as such Party employs with respect to its own Confidential Information of a similar nature, but in no case less than a reasonable standard of care to maintain confidentiality
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>13.3</span> The foregoing shall not apply to information that: (a) was known by the receiving party prior to its receipt from the disclosing party or is or becomes public knowledge through no fault of the receiving party; or (b) is rightfully received by the receiving party from a third party without a duty of confidentiality. If a receiving party is required by a court or government agency to disclose Confidential Information, the receiving party shall provide reasonable advance notice to the disclosing party before making such a disclosure to enable the disclosing party to seek an appropriate protective order or other such remedy.
                    </p>
                    <p className="mt-2  " style={{ fontFamily: "Archia Bold" }}>14. Term and termination
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>14.1</span> The Agreement, including these Terms, commence on the date when accepted or on the date as indicated in the Order Form and will continue until all Order Forms or Services used by the Customer on the Website have expired or have been terminated in accordance with these Terms.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>14.2</span> The initial subscription term (“Initial Term”) starts to run from the moment the Services are activated through the Website or as indicated in the applicable Order Form.
                    </p>
                    <p className="mb-4  text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>14.3</span> Unless otherwise provided on the Website or in the applicable Order Form, your subscription will automatically renew for additional successive periods of equal duration to the Initial Term (each a “Renewal Term” and together with the Initial Term, the “Term”) unless the renewal is canceled by you or Dexta. If you do not agree to the renewal, you are entitled to terminate the renewal of the Agreement either through the Website or by contacting the contact help center up to the last day of the Term. Your continued use of the Services constitutes your acceptance and agreement to the Renewal Term. Termination of the Agreement during the Term is not possible unless in accordance with this Section 14.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>14.4</span> The applicable fee for any Renewal Term will be determined using the then-current list price based on the applicable subscription plan on the Website for such renewed Services unless a different renewal pricing is specified in the Order Form.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>14.5</span> Either Party may terminate the Agreement and any Order Form (in whole or in part) by providing the other party with not less than ten (10) days' prior written notice in the event the other party materially breaches any provision of this Agreement. If the breaching Party fails to cure the material breach within the ten (10) day period following the notice of default, the non-breaching Party may terminate this Agreement effective at the end of the ten (10) day period notwithstanding any other provision in this Agreement. Regarding a material breach of Customer, Dexta may, in addition to termination, suspend the provision of certain Services, close your accounts, and/or prohibit Customer from creating any new accounts.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>14.6</span> In no event will termination of the Agreement or Order Form relieve Customer of any payment obligation of the Fees payable prior to the effective date of termination.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>14.7</span> Either Party may terminate this Agreement by written notice with immediate effect in the event the other Party becomes insolvent or generally unable to pay its debts as they become due or makes an assignment for the benefit of its creditors or seeks relief under any bankruptcy, insolvency, or liquidation proceedings.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>14.8</span> In the event of any expiration or termination of the Agreement: (a) Dexta will invoice Customer for any accrued but unbilled amounts and Customer shall promptly pay any then outstanding and unpaid amounts, including any accrued but unbilled amounts owed under the Agreement; (b) Customer shall immediately cease all use of the Services and return or purge any and all components thereof, including returning or destroying or causing to be destroyed any and all copies of the Documentation, notes, and other materials comprising or regarding the Services, any Candidate Data copied, stored, or otherwise transferred to Customers’ systems, as well as any Dexta Data and Confidential Information; (c) Dexta will suspend access to the Services and Customer shall no longer have access to Dexta’s platform, including its historical assessments; and (d) Dexta will delete all Customer Data, all Customer Confidential Information, and any other material, equipment, or information that is proprietary to Customer within sixty (60) days after the effective date of expiration or termination unless it is required to store such data longer as required by applicable law or as required to prosecute or defend a legal claim, in which case such information will only be retained for as long as required to resolve the claim.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>14.9</span> The following sections and paragraphs shall survive the expiration or termination of the Agreement under this Section 14 (Term and Termination): Section 8 (Fees and Payment Terms), Section 9 (Intellectual Property and Data), Section 13 (Confidentiality); Section 10.7 (Disclaimer), Section 11 (Indemnification), Section 12 (Limitation of Liability), Section 16 (Miscellaneous), as well as the Data Processing Agreement.
                    </p>
                    <p className="mt-2  " style={{ fontFamily: "Archia Bold" }}>15. Amendments</p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>15.1</span> Dexta is entitled to amend these Terms from time to time. We will use reasonable efforts to notify you of any material changes by posting an announcement on the Website or by email. To the greatest extent permitted by applicable law, the new Terms will take immediate effect, and your continued use of the Services following our posting or notice of the changes will constitute your acceptance of the updated Terms.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>15.2</span> If we are required by applicable law to give additional notice, changes will automatically take effect regarding your use of the relevant Services upon expiry of such notice period (unless you terminate during that period) or upon your earlier acceptance of such changes. If you have a right under applicable law to terminate this Agreement upon receipt of such notice, you will not be charged a fee for early termination where you exercise that right under applicable law, but any fees previously paid by you are non-refundable and any fees owing continue to remain due and payable.
                    </p>
                    <p className="mt-2  " style={{ fontFamily: "Archia Bold" }}>16. Miscellaneous
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>16.1 Compliance with Law Assurances.</span> Both Parties warrant to comply with all applicable anti-corruption, anti-money laundering, sanctions, export controls, and other international trade laws, regulations, and governmental orders of the European Union, the United Kingdom, the United States of America, the United Nations, or any other relevant governmental authority, including obtaining all necessary licenses and/or government approvals. Parties will promptly notify each other in writing of any actual or potential violation of such applicable laws and regulations in connection with the use of the Services, and Parties shall take all appropriate actions to remedy or resolve such violations, including any actions requested by the other Party.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>16.2 No Class Actions.</span> To the greatest extent permitted by applicable law, neither Customer nor Dexta shall be entitled to join or consolidate claims by or against other customers or pursue any claim as a representative of a class action or in a private attorney general capacity.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>16.3 UK Government Terms.</span> The Services, including any related software and technology, are provided solely in accordance with these Terms for the United Kingdom government end use. If you (or any users of your Customer Application) are an agency, department, or other entity of the United Kingdom government, the use, duplication, reproduction, release, modification, disclosure, or transfer of the Services or any related documentation is restricted by these Terms. All other use is prohibited, and no other rights other than those provided in these Terms are conferred.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>16.4 Independent Contractors.</span> The Parties are independent contractors. No provision of this Agreement will or shall be deemed to create an association, trust, partnership, joint venture, or other entity or similar legal relationship between Dexta and Customer or impose a trust, partnership, or fiduciary duty, obligation, or liability on or with respect to such entities. Neither Party will have any rights, power, or authority to act or create an obligation, express or implied, on behalf of another party except as specified in the Agreement.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>16.5 Force Majeure</span> Neither Party shall be liable to the other for any failure to perform any of its obligations (except payment obligations) under the Agreement during any period in which such performance is delayed by circumstances beyond its reasonable control, such as fire, flood, war, embargo, strike, riot, terrorism, epidemic or pandemic, or the intervention of any governmental authority (a “Force Majeure”). In such an event, however, the delayed Party must promptly provide the other Party with written notice of the Force Majeure. The delayed Party’s time for performance will be excused for the duration of the Force Majeure, but if the Force Majeure event lasts longer than ninety (90) days, then the other Party may immediately terminate without any liability, in whole or in part, the Agreement by giving written notice to the delayed Party.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>16.6 Transferability and subcontracting.</span> Neither all nor any part of Customer’s rights or obligations under this Agreement are assignable or transferable by Customer, whether directly or indirectly, without the prior written consent of Dexta, and any attempt to do so shall be void except in case of merger, acquisition, or sale of majority of assets. Dexta has the right to freely assign all or part of its rights and obligations under the Agreement or to make use of the services of third parties by subcontracting. Subject to the foregoing, the Agreement shall be binding upon and inure to the benefit of the parties and their respective successors and permitted assigns.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>16.7 Entire Agreement. </span> The Agreement constitutes the entire agreement between you and us with respect to its subject matter and supersedes all prior oral and written understandings, communications, or agreements. General terms and conditions of Customer are not applicable and expressly excluded.
                    </p>
                    <p className="mb-4  text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>16.8 Severability.  </span> If any provision of the Agreement should be found to be void or unenforceable, such provision will be stricken or modified, but only to the extent necessary to comply with the law, and the remainder of the Agreement will remain in full force and will not be terminated.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>16.9 Notices.</span> Any notice, request, demand, or other communication to be provided under this Agreement shall be in writing and shall be sent by the email addresses provided by each Party or at such other address as a Party may designate by written notice to the other Party.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>16.10 Headings. </span> The section headings in this Agreement are inserted for convenience of reference only and shall not affect the meaning or interpretation of the Agreement.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>16.11 Publicity. </span> You grant us the right to use your name, logo, and a description of your use case to refer to you on our website, customer lists, or marketing or promotional materials, subject to your standard trademark usage guidelines expressly provided to us.
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>16.12 Execution. </span> This Agreement may be executed in one or more counterparts, each of which shall be deemed an original, but all of which together shall constitute one and the same instrument. Each Party agrees that this Agreement and any other documents to be delivered in connection herewith may be electronically signed and that any electronic signatures appearing on this Agreement or such other documents are the same as handwritten signatures for the purposes of validity, enforceability, and admissibility.
                    </p>
                    <p className="mt-2  " style={{ fontFamily: "Archia Bold" }}>17. Governing law
                    </p>
                    <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>17.1 </span> The Agreement, including these Terms, shall be governed by the laws of England and Wales. The United Nations Convention on Contracts for the International Sale of Goods is explicitly excluded.
                    </p>
                    <p className="mb-4 text-xs " style={{ fontFamily: "Silka" }}>
                        <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>17.2 </span> The Parties agree that any dispute arising out or relating to the Agreement shall be brought exclusively in the courts in England and Wales.

                    </p>
                </div>
            </div>
        </div>
    );
};

export default CustomerTerms;