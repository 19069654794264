import PropTypes from "prop-types";
import React from "react";

import { Routes, Route } from "react-router-dom";

// Import Routes all
import {
  privateRoutes,
  publicRoutes,
  studentRoutes,
  studentpublic,
  candidateRoutes,
  privateWithoutNavbar,
  adminPublic,
} from "./routes";

// Import all middleware
import AuthMiddleware from "./routes/routes";

import VerticalLayout from "./Components/VerticalLayout";
// layouts Format
import StudentsLayout from "./Components/StudentsLayout";
import NonAuthLayout from "./Components/NonAuthLayout.js/NonAuthLayout";

import CandidateLayout from "./Components/CandidateLayout";
import NonAuthStudentLayout from "./Components/NonAuthStudentLayout/NonAuthStudentLayout";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./animation.css";
import AuthmiddlewareStudent from "./routes/studentroutes";
import { nonAuthRoutes } from "./routes";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import NonAuthMiddleware from "./routes/nonAuthMiddleware";
const App = () => {
  function getLayout(layoutType) {
    let layoutCls = VerticalLayout;
    switch (layoutType) {
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout("vertical");

  function getStudentsLayout(layoutType) {
    let layoutCls = StudentsLayout;
    switch (layoutType) {
      default:
        layoutCls = StudentsLayout;
        break;
    }
    return layoutCls;
  }

  const LayoutStudent = getStudentsLayout("vertical");

  function getCandidateLayout(layoutType) {
    let layoutCls = CandidateLayout;
    switch (layoutType) {
      default:
        layoutCls = CandidateLayout;
        break;
    }
    return layoutCls;
  }

  const LayoutCandidate = getCandidateLayout("vertical");
  return (
    <DndProvider backend={HTML5Backend}>
      <React.Fragment>
        <Routes>
          {nonAuthRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <TransitionGroup>
                  <CSSTransition key={idx} timeout={450} classNames="fade">
                    <NonAuthMiddleware>
                      <NonAuthLayout>{route.component}</NonAuthLayout>
                    </NonAuthMiddleware>
                  </CSSTransition>
                </TransitionGroup>
              }
              key={idx}
              exact={true}
            />
          ))}

          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <TransitionGroup>
                  <CSSTransition key={idx} timeout={450} classNames="fade">
                    <NonAuthLayout>{route.component}</NonAuthLayout>
                  </CSSTransition>
                </TransitionGroup>
              }
              key={idx}
              exact={true}
            />
          ))}

          {studentpublic.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <TransitionGroup>
                  <CSSTransition key={idx} timeout={450} classNames="fade">
                    <NonAuthStudentLayout>
                      {route.component}
                    </NonAuthStudentLayout>
                  </CSSTransition>
                </TransitionGroup>
              }
              key={idx}
              exact={true}
            />
          ))}

          {privateRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <TransitionGroup>
                  <CSSTransition key={idx} timeout={450} classNames="fade">
                    <AuthMiddleware>
                      <Layout>{route.component}</Layout>
                    </AuthMiddleware>
                  </CSSTransition>
                </TransitionGroup>
              }
              key={idx}
              exact={true}
            />
          ))}

          {privateWithoutNavbar.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <TransitionGroup>
                  <CSSTransition key={idx} timeout={450} classNames="fade">
                    <AuthMiddleware>
                      <LayoutStudent>{route.component}</LayoutStudent>
                    </AuthMiddleware>
                  </CSSTransition>
                </TransitionGroup>
              }
              key={idx}
              exact={true}
            />
          ))}

          {studentRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <TransitionGroup>
                  <CSSTransition key={idx} timeout={450} classNames="fade">
                    <AuthmiddlewareStudent>
                      <LayoutStudent>{route.component}</LayoutStudent>
                    </AuthmiddlewareStudent>
                  </CSSTransition>
                </TransitionGroup>
              }
              key={idx}
              exact={true}
            />
          ))}

          {candidateRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <TransitionGroup>
                  <CSSTransition key={idx} timeout={300} classNames="fD">
                    <AuthmiddlewareStudent>
                      <LayoutCandidate>{route.component}</LayoutCandidate>
                    </AuthmiddlewareStudent>
                  </CSSTransition>
                </TransitionGroup>
              }
              key={idx}
              exact={true}
            />
          ))}

          {adminPublic.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <TransitionGroup>
                  <CSSTransition key={idx} timeout={450} classNames="fade">
                    {route.component}
                  </CSSTransition>
                </TransitionGroup>
              }
              key={idx}
              exact={true}
            />
          ))}

          <Route
            path={"*"}
            element={
              <NonAuthLayout>{<div>Default Page Content</div>}</NonAuthLayout>
            }
          />
        </Routes>
      </React.Fragment>
    </DndProvider>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

export default App;
