import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    setJobs: [],
};

export const JobsSlice = createSlice({
    name: "jobs",
    initialState,
    reducers: {
        setJobsData: (state, action) => {
            state.setJobs = action.payload;
        },
    },
});

export const { setJobsData } =
    JobsSlice.actions;
export default JobsSlice.reducer;
