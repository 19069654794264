import React, { useState, useEffect, useRef } from "react";
import TextField from "../../../../Components/Dexta/TextField/TextField";
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import { updateUser } from "../../hooks/updateUser";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { updateCandidate } from "../../hooks/updateCandidate";
import {
  candidate_statuses,
  experience_years,
  highestEducation,
} from "../../../../data/mapData";
import useOutsideClick from "../../../../Components/OutsideClick/OutsideClick";
import { getAllJobs } from "../../../Profile/MyAssessments/Assessments-main/hooks/getAlljobs";
import Scrollbars from "react-custom-scrollbars";
import { IoAddCircle } from "react-icons/io5";
import { currency, location_preferences } from "./data";

const ExperienceInfo = ({
  educationLevel,
  setEducationLevel,
  yearexp,
  setyearexp,
  study,
  setStudy,
  selectedRole,
  setSelectedRole,
  selectedLocation,
  setSelectedLocation,
  candidate,
  setCandidate,
  selectedCurrency,
  setSelectedCurrency,
  amount,
  setAmount,
}) => {
  const queryClient = useQueryClient();
  const educationref = useRef(null);
  const [educationDrop, setEducationDrop] = useState(false);
  const [focusedField, setFocusedField] = useState(null);
  const [primaryError, setPrimaryError] = useState(false);
  const [roleModal, setRoleModal] = useState(false);
  const listInnerRef = useRef(null);
  const [paginationInfo, setPaginationInfo] = useState({
    currentTake: 1,
    hasNextPage: true,
    pageSize: 10,
  });
  const [allData, setAllData] = useState([]);
  const roleRef = useRef(null);
  const [expDropdown, setExpDropdown] = useState(false);
  const expRef = useRef(null);
  const locationRef = useRef(null);
  const [locationError, setLocationError] = useState(false);
  const [locationModal, setLocationModal] = useState(false);
  const [candidateDropdown, setCandidateDropdown] = useState(false);
  const [otherValue, setOtherValue] = useState("");
  const candidateRef = useRef(null);
  const [currencyModal, setCurrencyModal] = useState(false);
  const currencyRef = useRef(null);

  console.log(yearexp, candidate, "candy");
  //#region use Formik and yup to handle forms
  const HandleEducationSubmit = () => {
    let data = JSON.stringify({
      candidateMeta: {
        experience_in: selectedRole,
        experience_years:
          yearexp === "Years of experience in field" ? null : yearexp,
        desired_annual_salary: parseFloat(amount),
        desired_currency: selectedCurrency,
        employmentStatus:
          candidate !== "Other (Please specify)"
            ? candidate === "Candidate Status"
              ? ""
              : candidate
            : otherValue,
        location_preferences: selectedLocation?.toString(),
      },
    });
    try {
      updateMutate(data);
    } catch (error) {
      //onError will automatically detect
    }
  };
  //#endregion

  //#region mutate to update data
  const { mutate: updateMutate, isLoading: mutateLoadings } = useMutation(
    updateUser,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        handleOnBoard();
        toast.success("Information Saved", {
          toastId: "copy-success",
        });
      },
      onError: (error) => {
        setTimeout(() => {
          toast.error(error?.response?.data?.message[0], {
            toastId: "copy-success",
          });
        }, 500);
      },
    }
  );
  //#endregion

  //#region Updating Tour Completed
  const handleOnBoard = () => {
    let data = JSON.stringify({
      isUserOnboard: true,
    });
    try {
      updateCandidateUserMutate(data);
    } catch (err) {
      console.log(err.message);
    }
  };
  //#endregion

  //#region updating tour status
  const { mutate: updateCandidateUserMutate, isLoading: updateLoading } =
    useMutation(updateCandidate, {
      onSuccess: (response) => {
        queryClient.invalidateQueries("/users");
      },
    });
  //#endregion

  //#region Dropdown close on outside click -> Country
  useOutsideClick(
    [
      {
        ref: educationref,
        excludeClasses: [".educationClass"],
        excludeIds: ["education"],
      },
      {
        ref: expRef,
        excludeClasses: [".expClass"],
        excludeIds: ["expYear"],
      },
      {
        ref: locationRef,
        excludeClasses: [".locationClass"],
        excludeIds: ["location", "location2"],
      },
      {
        ref: candidateRef,
        excludeClasses: [".candidateClass"],
        excludeIds: ["candidate"],
      },
      {
        ref: currencyRef,
        excludeClasses: [".currencyClass"],
        excludeIds: ["currency"],
      },
    ],
    (ref) => {
      if (ref === educationref) setEducationDrop(false);
      if (ref === expRef) setExpDropdown(false);
      if (ref === locationRef) setLocationModal(false);
      if (ref === candidateRef) setCandidateDropdown(false);
      if (ref === currencyRef) setCurrencyModal(false);
    }
  );
  //#endregion

  //#region placeholder text
  useEffect(() => {
    if (educationLevel === "") {
      setEducationLevel("Highest education level");
    }
    if (yearexp === "" || yearexp === null) {
      setyearexp("Years of experience in field");
    }
    if (candidate === "") {
      setCandidate("Candidate Status");
    }
  }, [educationLevel, candidate, yearexp]);
  //#endregion

  //#region Handle Focus and Blur
  const handleFocus = (fieldName) => {
    setFocusedField(fieldName);
  };

  const handleBlur = () => {
    setFocusedField(null);
  };
  //#endregion

  //#region handling input change
  const handleInputChange = (e) => {
    setSelectedRole(e.target.value);
    setRoleModal(true);
    setPrimaryError(false);
  };
  //#endregion

  //#region handle pagination of jobs
  const { data: jobsData, isLoading: jobsLoading } = useQuery(
    ["jobs", paginationInfo.currentTake, selectedRole],
    () => getAllJobs(paginationInfo.currentTake, selectedRole),
    {
      keepPreviousData: true,
    }
  );

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight && !jobsLoading) {
        if (
          jobsData?.meta?.hasNextPage !== false &&
          allData.length >= paginationInfo.currentTake * paginationInfo.pageSize
        ) {
          setPaginationInfo((prevPaginationInfo) => ({
            ...prevPaginationInfo,
            currentTake: prevPaginationInfo.currentTake + 1,
          }));
        }
      }
    }
  };
  const handleScrollFrame = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      onScroll();
    }
  };

  useEffect(() => {
    if (!jobsLoading && jobsData && paginationInfo.currentTake === 1) {
      setAllData(jobsData?.data);
    } else if (!jobsLoading && jobsData && paginationInfo.currentTake > 1) {
      setAllData((prevData) => {
        const newData = jobsData?.data || [];
        const filteredData = newData.filter(
          (newItem) => !prevData.some((prevItem) => prevItem.id === newItem.id)
        );
        const updatedData = [...prevData, ...filteredData];

        // Update paginationInfo.hasNextPage based on whether all data is fetched
        if (filteredData.length < paginationInfo.pageSize) {
          setPaginationInfo((prevPaginationInfo) => ({
            ...prevPaginationInfo,
            hasNextPage: false,
          }));
        }

        return updatedData;
      });
    }
  }, [jobsData, jobsLoading, paginationInfo.currentTake]);
  //#endregion

  //#region Calculate height based on the number of items
  const calculateHeight = () => {
    const itemHeight = 40;
    const maxVisibleItems = 8;

    // Number of items in allData
    const itemsCount = allData.length > 0 ? allData.length : 1; // Ensure there's height for the "No results" message

    // Determine the height based on the items count
    const height =
      itemsCount > maxVisibleItems
        ? itemHeight * maxVisibleItems
        : itemHeight * itemsCount;

    return height;
  };
  //#endregion

  //#region clearing text errors
  useEffect(() => {
    if (selectedRole != "") {
      setPrimaryError(false);
    }
    if (selectedLocation?.length != 0) {
      setLocationError(false);
    }
  }, [selectedRole, selectedLocation]);
  //#endregion

  //#region handling locations
  const handleLocationClick = (selectedLocationItem) => {
    const isLocationSelected = selectedLocation?.includes(selectedLocationItem);
    setSelectedLocation((prevSelectedLocation) =>
      isLocationSelected
        ? prevSelectedLocation.filter(
            (location) => location !== selectedLocationItem
          )
        : [...prevSelectedLocation, selectedLocationItem]
    );
  };
  //#endregion
  return (
    <>
      <div className="p-3">
        <h1
          style={{
            fontFamily: "Archia Semibold",
          }}
        >
          Work experience experience
        </h1>
        <div className="grid md:grid-cols-2 gap-8 mt-5">
          <div>
            <p className="mb-2" style={{ fontFamily: "Silka" }}>
              Most relevant experience
            </p>
            <div className="h-10">
              <div className="relative">
                <div
                  className={`border px-3 flex border-[#D3D5D8] focus-within:border focus-within:border-coalColor rounded-md text-left w-full py-4`}
                >
                  <input
                    id="role"
                    className={`w-full bg-transparent border-none outline-none ${
                      selectedRole !== "" ? "text-black" : "text-gray-400"
                    }`}
                    placeholder="Primary Role"
                    style={{ fontFamily: "Silka" }}
                    value={selectedRole}
                    onFocus={() => handleFocus("role")}
                    onBlur={(event) => {
                      handleFocus("");
                      if (selectedRole === "") {
                        setPrimaryError(true);
                      }
                      // Delay closing to allow clicks on the dropdown
                      setTimeout(() => {
                        setRoleModal(false);
                      }, 200);
                    }}
                    onClick={() => setRoleModal(true)}
                    onChange={handleInputChange}
                  />
                  <div className="ml-auto flex flex-row py-1">
                    {selectedRole && (
                      <IoMdClose
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedRole("");
                          setRoleModal(false);
                        }}
                      />
                    )}
                    <IoIosArrowDown onClick={() => setRoleModal(!roleModal)} />
                  </div>
                </div>
                {roleModal && (
                  <div
                    className="roleClass absolute z-40 border enable-scrollbar2 border-coalColor right-0 top-full h-[15rem] overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                    id="experienceDropdown"
                    ref={listInnerRef}
                    style={{
                      fontFamily: "Silka",
                      height: calculateHeight(),
                    }}
                  >
                    <Scrollbars
                      style={{
                        width: "100%",
                        height: "100%",
                        overflowX: "hidden",
                      }}
                      onScrollFrame={handleScrollFrame}
                    >
                      {allData?.map((item, index) => (
                        <ul
                          key={index}
                          className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                          ref={roleRef}
                          onClick={() => {
                            setSelectedRole(item?.name);
                            setRoleModal(false);
                          }}
                        >
                          <li>
                            <a className="block px-5 py-2 text-sm font-medium">
                              {item.name}
                            </a>
                          </li>
                        </ul>
                      ))}
                      <>
                        {selectedRole !== "" &&
                          !allData.some(
                            (item) => item.name === selectedRole
                          ) && (
                            <ul
                              className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                              ref={roleRef}
                              onClick={() => {
                                setRoleModal(false);
                              }}
                            >
                              <li className="flex flex-row">
                                <a className="block px-5 py-2 text-sm font-medium">
                                  Add "{selectedRole}"
                                </a>
                                <IoAddCircle className="w-5 h-5 ml-auto my-auto" />
                              </li>
                            </ul>
                          )}
                      </>
                    </Scrollbars>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <p className="mb-2" style={{ fontFamily: "Silka" }}>
              Years of experience in field
            </p>
            <div className="h-10">
              <div className="relative">
                <input
                  id="expYear"
                  type="button"
                  className={`border border-[#D3D5D8] ${
                    yearexp === "Years of experience in field"
                      ? "text-[#999999]"
                      : "text-coalColor"
                  }  focus-within:border focus-within:border-coalColor rounded-md px-3 text-left w-full py-4`}
                  value={
                    typeof yearexp === "string" && yearexp.includes("years")
                      ? yearexp
                      : `${yearexp || ""} years`
                  }
                  style={{ fontFamily: "Silka" }}
                  onChange={(e) => {
                    setyearexp(e.target.value);
                    setExpDropdown(true);
                  }}
                  onClick={() => setExpDropdown(!expDropdown)}
                />
                <div
                  className={`absolute top-1/2 right-3 transform -translate-y-1/2 flex flex-row ${
                    yearexp === "Years of experience in field"
                      ? "text-[#999999]"
                      : "text-coalColor"
                  }`}
                >
                  <IoMdClose
                    onClick={() => {
                      setyearexp("");
                    }}
                    className="cursor-pointer"
                  />
                  <IoIosArrowDown />
                </div>

                {expDropdown && (
                  <div
                    id="education-drop"
                    className="expClass absolute z-40 enable-scrollbar2 border border-coalColor right-0 top-full h-80 overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                    style={{ fontFamily: "Silka" }}
                  >
                    <Scrollbars
                      autoHide
                      style={{ width: "100%", height: "100%" }}
                    >
                      {experience_years?.map((i) => (
                        <ul
                          key={i.title}
                          className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                          ref={expRef}
                          onClick={() => {
                            setyearexp(i?.title);
                            setExpDropdown(false);
                          }}
                        >
                          <li>
                            <a className="block px-5 py-2 text-sm font-medium">
                              {i.title}
                            </a>
                          </li>
                        </ul>
                      ))}
                    </Scrollbars>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-8 mt-2">
          <div>
            <p className="mt-10" style={{ fontFamily: "Silka" }}>
              Location preferences
            </p>
            <div className="h-10 mt-2">
              <div className="relative">
                <button
                  id="location"
                  className={`border px-3 border-[#D3D5D8] focus-within:border focus-within:border-coalColor rounded-md text-left w-full py-4`}
                  style={{ fontFamily: "Silka" }}
                  type="button"
                  onFocus={() => handleFocus("location")}
                  onBlur={(event) => {
                    handleFocus("");
                    if (selectedLocation?.length === 0) {
                      setLocationError(true);
                    }
                  }}
                  onClick={() => setLocationModal(!locationModal)}
                >
                  <div
                    id="location"
                    className={`flex justify-between ${
                      selectedLocation != "" ? "text-black" : "text-gray-400"
                    }`}
                  >
                    {selectedLocation?.length === 0 && (
                      <p className="text-[#999999]">Location preferences</p>
                    )}
                    {selectedLocation?.map((i, index) => (
                      <div className="flex flex-row my-auto gap-2 mr-2 bg-coalColor py-0.5 px-2 rounded-sm text-white">
                        <IoMdClose
                          className="my-auto hover:text-red-800 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            const updatedLocations = [...selectedLocation];
                            updatedLocations.splice(index, 1);
                            setSelectedLocation(updatedLocations);
                          }}
                        />
                        <p>{i}</p>
                      </div>
                    ))}
                    <div className="ml-auto flex flex-row py-1">
                      <IoMdClose
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedLocation([]);
                        }}
                      />
                      <IoIosArrowDown />
                    </div>
                  </div>
                </button>

                {locationModal && (
                  <div
                    id="education-drop"
                    className="locationClass absolute z-40 border border-coalColor right-0 top-full h-auto overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full"
                  >
                    {location_preferences.map((i, index) => (
                      <ul
                        key={index}
                        className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                        ref={locationRef}
                        onClick={() => {
                          handleLocationClick(i.title);
                          setLocationModal(false);
                        }}
                      >
                        <li>
                          <a className="block px-5 py-2 text-sm font-medium">
                            {i.title}
                          </a>
                        </li>
                      </ul>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <p className="mt-10" style={{ fontFamily: "Silka" }}>
              Candidate status
            </p>
            <div className="h-10 mt-2">
              <div className="relative">
                <button
                  id="candidate"
                  type="button"
                  className={`border border-[#D3D5D8] ${
                    candidate === "Candidate Status" || candidate === null
                      ? "text-[#999999]"
                      : "text-coalColor"
                  }  focus-within:border focus-within:border-coalColor rounded-md px-3 text-left w-full py-4`}
                  style={{ fontFamily: "Silka" }}
                  onClick={() => setCandidateDropdown(!candidateDropdown)}
                >
                  {candidate != "" && candidate != null ? (
                    candidate === "Other (Please specify)" ? (
                      <input
                        placeholder="Please specify"
                        autoFocus
                        value={otherValue}
                        className="w-full"
                        onChange={(e) => setOtherValue(e.target.value)}
                      />
                    ) : (
                      candidate
                    )
                  ) : (
                    "Candidate Status"
                  )}
                  <div
                    className={`absolute top-1/2 right-3 transform ${
                      candidate === "Candidate Status" || candidate === null
                        ? "text-[#999999]"
                        : "text-coalColor"
                    }  -translate-y-1/2 flex flex-row`}
                  >
                    <IoMdClose
                      onClick={() => {
                        setCandidate("");
                      }}
                      className="cursor-pointer"
                    />
                    <IoIosArrowDown />
                  </div>
                </button>
                {candidateDropdown && (
                  <div
                    id="education-drop"
                    className="candidateClass absolute z-40 border enable-scrollbar2 border-coalColor right-0 top-full h-[12rem] overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                    style={{ fontFamily: "Silka" }}
                  >
                    <Scrollbars
                      autoHide
                      style={{ width: "100%", height: "100%" }}
                    >
                      {candidate_statuses.map((i, index) => (
                        <ul
                          key={index}
                          className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                          ref={candidateRef}
                          onClick={() => {
                            setCandidate(i.title);
                            setCandidateDropdown(false);
                          }}
                        >
                          <li>
                            <a className="block px-5 py-2 text-sm font-medium">
                              {i.title}
                            </a>
                          </li>
                        </ul>
                      ))}
                    </Scrollbars>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <hr className="w-full mt-[4rem] bg-[#D3D5D8] border-1" />
        <h1
          className="mt-8"
          style={{
            fontFamily: "Archia Semibold",
          }}
        >
          Desired gross annual salary
        </h1>
        <div className="grid md:grid-cols-2 gap-5 mt-3 md:h-16">
          <div className="flex flex-row gap-3">
            <div className="sm:w-full md:w-1/3 relative">
              <button
                id="currency"
                className={`border px-3 border-[#D3D5D8] focus-within:border focus-within:border-coalColor rounded-md text-left w-full py-4`}
                type="button"
                onFocus={() => handleFocus("currency")}
                onBlur={(event) => {
                  handleFocus("");
                }}
                onClick={() => setCurrencyModal(!currencyModal)}
              >
                <div
                  id="currency"
                  className={`flex justify-between ${
                    selectedCurrency != "" && selectedCurrency != null
                      ? "text-black"
                      : "text-gray-400"
                  }`}
                >
                  {selectedCurrency != "" && selectedCurrency != null
                    ? selectedCurrency
                    : "Currency"}
                  <div className="ml-auto flex flex-row py-1">
                    <IoMdClose
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedCurrency("");
                      }}
                    />
                    <IoIosArrowDown />
                  </div>
                </div>
              </button>
              {currencyModal && (
                <div
                  id="education-drop"
                  className="currencyClass absolute enable-scrollbar2 z-40 border border-coalColor right-0 top-full h-[8rem] overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                >
                  <Scrollbars
                    autoHide
                    style={{ width: "100%", height: "100%" }}
                  >
                    {currency.map((i) => (
                      <ul
                        key={i.title}
                        className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                        ref={currencyRef}
                        onClick={() => {
                          setSelectedCurrency(i?.title);
                          setCurrencyModal(false);
                        }}
                      >
                        <li>
                          <a className="block px-5 py-2 text-sm font-medium">
                            {i.title}
                          </a>
                        </li>
                      </ul>
                    ))}
                  </Scrollbars>
                </div>
              )}
            </div>
            <div className="sm:w-full md:w-2/3 relative">
              <TextField
                type="number"
                name="amount"
                rounded="rounded-md"
                border={`border border-[#D3D5D8] focus-within:border focus-within:border-coalColor ]`}
                onChange={(e) => setAmount(e.target.value)}
                onFocus={() => handleFocus("amount")}
                placeholder="Amount/yr"
                value={amount || ""}
              />
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div className="mb-3 mt-6 items-center flex justify-end px-1">
        <button
          type="submit"
          name="Sign up"
          onClick={HandleEducationSubmit}
          className="bg-primaryGreen hover:bg-coalColor hover:text-white sm:w-full md:w-1/6 h-12 rounded-md text-coalColor border border-coalColor font-medium text-md relative"
          style={{ fontFamily: "Archia Semibold" }}
        >
          {mutateLoadings || updateLoading ? (
            <span className="flex items-center justify-center">
              <Loader type="Oval" color="black" height={20} width={20} />
              <span className="ml-2">Saving...</span>
            </span>
          ) : (
            "Save"
          )}
        </button>
      </div>
    </>
  );
};

export default ExperienceInfo;
