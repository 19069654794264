import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { ToastContainer, toast, Zoom } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import PhoneInput from "react-phone-number-input";
import Loader from "react-loader-spinner";
import Danger from "../../../Assets/danger.png";
import Default from "../../../Assets/default-img.png";
import King from "../../../Assets/preee.png";
import { useRef } from "react";
import "react-phone-number-input/style.css";
import { Sketch } from "@uiw/react-color";
import axios from "axios";
import "./settings.css";
import { me } from "./hooks/me";
import { updateMe } from "./hooks/updateMe";
import { getCompanyDetails } from "./hooks/getCompanyDetails";
import { updateCompany } from "./hooks/updateCompany";
import { getUserPackage } from "./hooks/getUserPackage";
import ReactHtmlParser from "react-html-parser";
import styles from "../../../Components/Modals/styling.module.css";
import styles2 from "../../../Components/Modals/styling2.module.css";
import styles3 from "../../../Components/Modals/styling3.module.css";
import { getPackages } from "./hooks/getPackages";
import { upgradePackage } from "./hooks/upgradePackage";
import { BiUpload } from "react-icons/bi";
import { getStripeLink } from "./hooks/getStripeLink";
import FileInput from "../../../Components/FileInput/FileInput";
import ImageCropper from "../../../Components/ImageCropper/ImageCropper";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import DpCropper from "../../../Components/ImageCropper/DpCropper";
import ImageInput from "../../../Components/FileInput/ImageInput";
import Plans from "../../../Components/Modals/Plans";
import Premium from "../../../Components/Modals/Premium";
import { setPreviewData } from "../../../redux/reducers/PreviewData/PreviewDataSlice";
import { useDispatch } from "react-redux";
import BillingHistory from "../../../Components/Modals/BillingHistory";
import BillingAddress from "../../../Components/Modals/BillingAddress";
import PaymentMethod from "../../../Components/Modals/PaymentMethod";
import { useSelector } from "react-redux";
import { GoKey } from "react-icons/go";
import { TfiEmail } from "react-icons/tfi";
import ChangePassword from "../../../Components/Modals/ChangePassword";
import ChangeEmail from "../../../Components/Modals/ChangeEmail";
import { Scrollbars } from "react-custom-scrollbars";
import { screen_selection, stepsCandidates, aspectRatios } from "./data";
import { Steps } from "intro.js-react";
import {
  setTourToCompleted,
  setTourToIncomplete,
} from "../../../redux/reducers/TourCompleted/TourCompletedSlice";
import { settourtofalse } from "../../../redux/reducers/toggleTour/ToggleSlice";
import { setTourStepData } from "../../../redux/reducers/TourSteps/TourStepsSlice";
import { CiSettings } from "react-icons/ci";
import TextField from "../../../Components/Dexta/TextField/TextField";
import TeamMembers from "../../../Components/TeamMembers/TeamMembers";
import star from "../../../Dexta_assets/star.png";
import confirm from "../../../Dexta_assets/confirm.png";
import ConfirmModal from "../../../Components/ConfirmationModals/ConfirmModal";
import { setPackageData } from "../../../redux/reducers/packageData/packageDataSlice";
import { LuExpand } from "react-icons/lu";
import useWindowSize from "../../../Helpers/useWindowSize";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
import FeedbackModal from "../../../Components/ConfirmationModals/FeedbackModal";
import * as moment from "moment";
import Highlighter from "../../../Dexta_assets/Highlighter.png";
import Highlighter2 from "../../../Dexta_assets/Highlighter2.png";
import http from "../../../http";

const Settings = () => {
  //#region useStates
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("My profile");
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countryDrop, setCountryDrop] = useState(false);
  const countryRef = useRef(null);
  const [file, setFile] = useState();
  const [phone, setPhone] = useState();
  const [hex, setHex] = useState("");
  const [hex2, setHex2] = useState("");
  const queryClient = useQueryClient();
  const [checkedComplete, setCheckedComplete] = useState(false);
  const [checkedNotify, setCheckedNotify] = useState(false);
  const [company, setCompany] = useState("");
  const userID = parseInt(localStorage.getItem("CP-USER-ID"));
  const [imageLoad, setImageLoad] = useState(false);
  const [src, setSrc] = useState(null);
  const [profileAllow, setProfileAllow] = useState(false);
  const [allow, setAllow] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const colorRef = useRef(null);
  const screenRef = useRef(null);
  const [blobdata, setblobdata] = useState(null);
  const [blobdataDp, setblobdataDp] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [profileSrc, setProfileSrc] = useState(null);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [image, setImage] = useState("");
  const [currentPage, setCurrentPage] = useState("choose-img");
  const [currentPage1, setCurrentPage1] = useState("choose-img");
  const [imgAfterCrop, setImgAfterCrop] = useState("");
  const [dpAfterCrop, setDpAfterCrop] = useState("");
  const [colorDrop, setColorDrop] = useState(false);
  const [screenDrop, setScreenDrop] = useState();
  const [selectedColor, setSelectedColor] = useState("Primary");
  const [screen, setScreen] = useState("Question screen");
  const chooseImgFunctionRef = useRef();
  const [plansModal, setPlansModal] = useState(false);
  const [billingOpen, setBillingOpen] = useState(false);
  const [billingAddressOpen, setBillingAddressOpen] = useState(false);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const user_package_check = useSelector(
    (state) => state.packageDetails.setPackage
  );
  const [premiumOpen, setPremiumOpen] = useState(false);
  const [passwordOpen, setPasswordOpen] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const tourStepCheck = useSelector((state) => state.tourStep.settourStep);
  const [show, setShow] = useState(false);
  const [tourExitedManually, setTourExitedManually] = useState(false);
  const [stepNumber, setStepNumber] = useState(1);
  const tourCompletedState = useSelector(
    (state) => state.tourCompleted.setCompleted
  );
  const [confirmModal, setConfirmModal] = useState(false);
  const [subscriptionID, setSubscriptionID] = useState(null);
  const [packageID, setPackageID] = useState(null);
  const [maindiv, setMainDiv] = useState(false);
  const [tabsVisible, setTabsVisible] = useState(true);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [feedback, setFeedback] = useState("");
  //#endregion

  //#region image cropper
  const onImageSelected = (selectedImg) => {
    setImage(selectedImg);
    setCurrentPage("crop-img");
    setModalOpen(true);
  };

  const onDpSelected = (selectedImg) => {
    setProfileImage(selectedImg);
    setCurrentPage1("crop-img");
    setProfileModalOpen(true);
  };

  const handleBiUploadClick = () => {
    // Call the chooseImg function when BiUpload is clicked
    if (chooseImgFunctionRef.current) {
      chooseImgFunctionRef.current();
    }
  };
  //#endregion

  //#region Generating Cropped Image When Done Button Clicked
  const onCropDone = (imgCroppedArea) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = imgCroppedArea.width;
    canvasEle.height = imgCroppedArea.height;
    const context = canvasEle.getContext("2d");

    // Set a transparent background
    context.clearRect(0, 0, canvasEle.width, canvasEle.height);

    let imageObj1 = new Image();
    imageObj1.src = image;
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height
      );

      // Convert the canvas data to a Blob
      canvasEle.toBlob((blob) => {
        // Set the blob data to a separate state variable
        setblobdata(blob);

        const dataURL = canvasEle.toDataURL("image/png");
        setImgAfterCrop(dataURL);
        setCurrentPage("img-cropped");
      }, "image/png");
    };
  };

  const onCropDoneForDp = (imgCropped) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = imgCropped.width;
    canvasEle.height = imgCropped.height;
    const context = canvasEle.getContext("2d");

    // Set a transparent background
    context.clearRect(0, 0, canvasEle.width, canvasEle.height);

    let imageObj1 = new Image();
    imageObj1.src = profileImage;
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCropped.x,
        imgCropped.y,
        imgCropped.width,
        imgCropped.height,
        0,
        0,
        imgCropped.width,
        imgCropped.height
      );

      // Convert the canvas data to a Blob
      canvasEle.toBlob((blob) => {
        // Set the blob data to a separate state variable
        setblobdataDp(blob);
        const dataURL = canvasEle.toDataURL("image/png");
        setDpAfterCrop(dataURL);
        setCurrentPage1("img-cropped");
      }, "image/png");
    };
  };

  // Handle Cancel Button Click
  const onCropCancel = () => {
    setCurrentPage("choose-img");
    setImage("");
  };

  const onCropCancelForDP = () => {
    setCurrentPage1("choose-img");
    setProfileImage("");
  };

  //#endregion

  //#region Handling Image Change
  const handleImgChange = (e) => {
    setSrc(URL.createObjectURL(e.target.files[0]));
    const file = e.target.files[0];
    setImage(file);
    setModalOpen(true);
  };
  //#endregion

  //#region slider tabs
  const data = [
    {
      title: "My profile",
    },
    {
      title: "Notifications",
    },
    {
      title: "My company",
    },
    {
      title: "Team management",
    },
    {
      title: "Plan & billing",
    },
  ];
  //#endregion

  //#region Handle item from tab
  const handleTabClick = (name) => {
    setSelectedTab(name);
  };
  //#endregion

  //#region fetching my data
  const { data: myData, isLoading } = useQuery(["me"], () => me());
  //#endregion

  //#region Saving data
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: !isLoading && myData.firstName != "" ? myData.firstName : "",
      lastName: !isLoading && myData.lastName != "" ? myData.lastName : "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .min(3, "Too Short!")
        .max(50, "Too Long!")
        .required("First Name Required"),
      lastName: Yup.string()
        .min(3, "Too Short!")
        .max(50, "Too Long!")
        .required("Last Name Required"),
      phone: Yup.number(),
    }),
    onSubmit: (values) => {
      try {
        let data = JSON.stringify({
          firstName: values?.firstName,
          lastName: values?.lastName,
          phoneNumber: phone,
        });
        try {
          mutate(data);
          if (!mutateLoading) {
            toast.success("Changes saved", {
              toastId: "copy-success",
            });
          }
        } catch (error) {
          //onError will automatically detect
        }
      } catch (err) {}
    },
  });
  //#endregion

  //#region Fetching Countries from API
  useEffect(() => {
    const getCountry = async () => {
      try {
        const response = await http.get("/countries?limit=249");
        const countryNames = Object.values(response.data.data)
          .map((country) => country.country)
          .sort();
        setCountries(countryNames);
      } catch (error) {
        console.error("Error fetching countries: ", error);
      }
    };
    getCountry();
  }, []);
  //#endregion

  //#region Handling outside clicks

  useEffect(() => {
    function handleOutsideClick(ref, setState, exceptId, exceptClass) {
      return function handleClick(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          event.target.id !== exceptId &&
          !event.target.closest(exceptClass)
        ) {
          setState(false);
        }
      };
    }

    const countryClickHandler = handleOutsideClick(
      countryRef,
      setCountryDrop,
      "country",
      ".countryClass"
    );
    const colorClickHandler = handleOutsideClick(
      colorRef,
      setColorDrop,
      "color",
      ".colorClass"
    );
    const screenClickHandler = handleOutsideClick(
      screenRef,
      setScreenDrop,
      "screen",
      ".screenType"
    );

    document.addEventListener("mousedown", countryClickHandler);
    document.addEventListener("mousedown", colorClickHandler);
    document.addEventListener("mousedown", screenClickHandler);

    return () => {
      document.removeEventListener("mousedown", countryClickHandler);
      document.removeEventListener("mousedown", colorClickHandler);
      document.removeEventListener("mousedown", screenClickHandler);
    };
  }, [countryRef, colorRef, screenRef]);

  //#endregion

  //#region Changing picture
  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  //#endregion

  //#region updating profile
  const { mutate, isLoading: mutateLoading } = useMutation(updateMe, {
    onSuccess: () => {
      queryClient.invalidateQueries("/users");
    },
  });
  //#endregion

  //#region handle checked state
  const handleClick = () => {
    if (user_package_check === "free") {
      setPremiumOpen(true);
    } else {
      setCheckedComplete(!checkedComplete);
    }
  };
  const handleClick2 = () => {
    if (user_package_check === "free") {
      setPremiumOpen(true);
    } else {
      setCheckedNotify(!checkedNotify);
    }
  };
  //#endregion

  //#region handling checkboxes
  const handleCheckboxes = () => {
    try {
      let data = JSON.stringify({
        notifyOnAssessmentComplete: checkedComplete,
        notifyEverydayOnAssessment: checkedNotify,
      });
      try {
        mutate(data);
        if (!mutateLoading) {
          toast.success("Changes saved", {
            toastId: "copy-success",
          });
        }
      } catch (error) {
        //onError will automatically detect
      }
    } catch (err) {}
  };
  //#endregion

  //#region fetching data from api and adding to useState
  useEffect(() => {
    setCheckedComplete(myData?.notifyOnAssessmentComplete);
    setCheckedNotify(myData?.notifyEverydayOnAssessment);
  }, [myData?.notifyOnAssessmentComplete, myData?.notifyEverydayOnAssessment]);
  //#endregion

  //#region getting Company Details
  const { data: companyData, isLoading: companyLoading } = useQuery(
    ["company", userID],
    () => getCompanyDetails(userID)
  );

  useEffect(() => {
    setCompany(companyData?.data[0]?.companyName);
    setSelectedCountry(companyData?.data[0]?.country);
    setHex(companyData?.data[0]?.color);
    setHex2(companyData?.data[0]?.sec_color);
    setImgAfterCrop(companyData?.data[0]?.companyAvatar);
  }, [
    companyData?.data[0]?.companyName,
    companyData?.data[0]?.country,
    companyData?.data[0]?.color,
    companyData?.data[0]?.companyAvatar,
    companyData?.data[0]?.sec_color,
  ]);
  //#endregion

  //#region Submit company details
  const companySubmit = () => {
    try {
      let data = {
        data1: JSON.stringify({
          user: userID,
          companyName: company,
          country: selectedCountry,
          color: hex,
          sec_color: hex2,
        }),
        data2: {
          companyID: companyData?.data[0]?.id,
        },
      };
      // let data = JSON.stringify({
      //     "notifyOnAssessmentComplete": checkedComplete,
      //     "notifyEverydayOnAssessment": checkedNotify,
      // });
      try {
        companyMutate(data);
        if (!companyLoad) {
          toast.success("Changes saved", {
            toastId: "copy-success",
          });
        }
      } catch (error) {
        //onError will automatically detect
      }
    } catch (err) {}
  };

  const { mutate: companyMutate, isLoading: companyLoad } = useMutation(
    updateCompany,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("/company");
      },
    }
  );
  //#endregion

  //#region Update User Image
  useEffect(() => {
    const sendImageToApi = async () => {
      if (imgAfterCrop && allow === true) {
        setImageLoad(true);
        const formData = new FormData();
        formData.append("file", blobdata);
        formData.append("fileType", "user");
        try {
          const response = await http.post(
            "/questions/uploadQuestionImage",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          let data = {
            data1: JSON.stringify({
              user: userID,
              companyAvatar: response.data.link,
            }),
            data2: {
              companyID: companyData?.data[0]?.id,
            },
          };
          try {
            companyMutate(data);
            if (!companyLoad) {
              toast.success("Company logo updated successfully.", {
                toastId: "copy-success",

                style: { width: "350px" },
              });
            }
          } catch (error) {
            //onError will automatically detect
          }
          setImageLoad(false);
          setAllow(false);
          //   localStorage.setItem('notification', JSON.stringify({ message: 'Image Updated Successfully' }));
        } catch (error) {
          console.error(error);
          setImageLoad(false);
          setAllow(false);
        }
      }
    };
    sendImageToApi();
  }, [imgAfterCrop, allow]);
  //#endregion

  //#region Fetching user package
  const { data: packageData, isLoading: packageLoading } = useQuery(
    ["packages", myData?.id],
    () => getUserPackage(myData?.id)
  );
  //#endregion

  //#region Fetching all packages
  const { data: packagesData, isLoading: packagesLoading } = useQuery(
    ["packages"],
    () => getPackages()
  );
  //#endregion

  //#region Upgrade Subscription Status
  const UpdgradePackage = () => {
    const postData = JSON.stringify({
      subscription: subscriptionID,
      package: 1,
      currency: packageData?.package?.prices[0]?.currency,
      interval: packageData?.package?.prices[0]?.interval,
      description: "Cancelling Subscription",
      feedback: feedback,
    });
    try {
      packageMutate(postData);
    } catch (err) {
      console.error(err.message);
    }
  };

  const { mutate: packageMutate, isLoading: upgradeLoading } = useMutation(
    upgradePackage,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("/subscriptions/upgrade");
        toast.success("Subscription cancelled successfully");
        // dispatch(setPackageData("free"));
        setFeedbackModal(false);
      },
    }
  );
  //#endregion

  //#region Handling Profile Image
  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  useEffect(() => {
    const sendImageToApi = async () => {
      if (dpAfterCrop && profileAllow === true) {
        setImageLoad(true);
        console.log(blobdataDp, "yeah");
        const formData = new FormData();
        formData.append("file", blobdataDp);
        formData.append("fileType", "user");
        try {
          const response = await http.post(
            "/questions/uploadQuestionImage",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          let data = {
            profilePhoto: response.data.link,
          };
          try {
            mutate(data);
            if (!mutateLoading) {
              toast.success("User image updated successfully.", {
                style: { width: "350px" },
              });
            }
          } catch (error) {}
          setImageLoad(false);
          setProfileAllow(false);
        } catch (error) {
          console.error(error);
          setImageLoad(false);
          setProfileAllow(false);
        }
      }
    };
    sendImageToApi();
  }, [dpAfterCrop, profileAllow]);
  //#endregion

  //#region Fetching stripe Link
  const { data: stripeData, isLoading: stripeLoading } = useQuery(
    ["stripe_login_link", userID],
    () => getStripeLink(userID)
  );
  //#endregion

  //#region array of colors
  const colors = [
    {
      title: "Primary",
    },
    {
      title: "Secondary",
    },
  ];
  //#endregion

  //#region Handling preview click
  const handlePreviewClick = () => {
    if (screen === "Welcome screen") {
      window.open("/preview-confirmation-screen", "_blank");
    } else if (screen === "Webcam screen") {
      window.open("/preview-setup-screen", "_blank");
    } else if (screen === "Question screen") {
      window.open("/preview-test-1", "_blank");
    } else if (screen === "Stop timer screen") {
      window.open("/preview-ready-screen", "_blank");
    } else if (screen === "Feedback screen") {
      window.open("/preview-feedback-screen", "_blank");
    }
  };

  const handlePreviewClick2 = () => {
    if (screen === "Welcome screen") {
      window.open("/preview-confirmation-screen", "_blank");
    } else if (screen === "Webcam screen") {
      window.open("/preview-setup-screen", "_blank");
    } else if (screen === "Question screen") {
      window.open("/preview-test-2", "_blank");
    } else if (screen === "Stop timer screen") {
      window.open("/preview-ready-screen", "_blank");
    } else if (screen === "Feedback screen") {
      window.open("/preview-feedback-screen", "_blank");
    }
  };
  //#endregion

  useEffect(() => {
    if (tourStepCheck === 5) {
      setTimeout(() => {
        setShow(true);
      }, 2000);
    }
  }, [tourStepCheck]);

  const onExit = () => {
    console.log("hehehe");
    setTourExitedManually(true);
  };

  const onExitSetttings = () => {
    console.log("setting exit");
    dispatch(setTourStepData(99));
  };

  function isLightColor(color) {
    // Convert hex color to RGB
    const hexToRgb = (hex) =>
      hex.match(/[A-Za-z0-9]{2}/g).map((v) => parseInt(v, 16));
    const [r, g, b] = hexToRgb(color);
    // Calculate the luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    // Return true if luminance is above a certain threshold (adjust threshold as needed)
    return luminance > 0.5; // You can adjust this threshold value
  }

  //#region mutation callback to check if cancel clicked or not
  useEffect(() => {
    // Function to handle button click
    const handleSkipButtonClick = () => {
      dispatch(setTourStepData(99));
    };
    const observerCallback = (mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === "childList") {
          const button = document.querySelector(".introjs-skipbutton");
          if (button) {
            button.addEventListener("click", handleSkipButtonClick);
            observer.disconnect();
          }
        }
      }
    };
    const observer = new MutationObserver(observerCallback);
    observer.observe(document.body, { childList: true, subtree: true });
    return () => {
      observer.disconnect();
      const button = document.querySelector(".introjs-skipbutton");
      if (button) {
        button.removeEventListener("click", handleSkipButtonClick);
      }
    };
  }, []);
  //#endregion

  const size = useWindowSize();
  const isMobile = size.width <= 640;

  console.log(packageData?.status, "hhehehe");

  const handleOpenEmailInboxButtonClick = () => {
    // Constructing the mailto link without any additional parameters
    const mailtoLink = "mailto:support@dexta.io";

    // Opening the default email client
    window.location.href = mailtoLink;
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        enableMultiContainer={false}
        limit={1}
      />
      <>
        <ChangePassword
          passwordOpen={passwordOpen}
          setPasswordOpen={setPasswordOpen}
        />
        <ChangeEmail emailOpen={emailOpen} setEmailOpen={setEmailOpen} />
        {tourStepCheck === 5 && (
          <Steps
            enabled={show}
            options={{
              hideNext: false,
              exitOnOverlayClick: false,
              exitOnFinish: false,
              doneLabel: "Next",
            }}
            steps={stepsCandidates}
            onComplete={() => {
              dispatch(setTourToCompleted(true));
              setShow(false);
              dispatch(setTourStepData(6));
              dispatch(settourtofalse(false));
            }}
            onExit={(tour) => {
              if (tourCompletedState === false) {
                if (!tourExitedManually) {
                  onExit();
                } else {
                  onExitSetttings();
                }
              }
            }}
            initialStep={0}
            onBeforeChange={(value) => {
              setStepNumber(value);
              switch (value) {
                case 1:
                  handleTabClick(data[1].title);
                  break;
                case 2:
                  handleTabClick(data[2].title);
                  break;
                case 3:
                  handleTabClick(data[3].title);
                  break;
                case 4:
                  handleTabClick(data[4].title);
                  break;
                default:
                  break;
              }
            }}
          />
        )}
        {confirmModal && (
          <ConfirmModal
            setConfirmModal={setConfirmModal}
            icon={confirm}
            onClick={() => {
              setConfirmModal(false);
              setFeedbackModal(true);
            }}
            header="Unsubscribe package"
            buttonText="Yes"
            description={
              <div style={{ fontFamily: "Silka" }}>
                <div className="flex flex-col">
                  <p className="mb-4">
                    Are you sure you want to unsubscribe from your package? By
                    doing so, you will lose access to the following features:
                  </p>
                  <ul className="list-disc space-y-1 list-outside pl-4">
                    <li>
                      {user_package_check === "pro"
                        ? "Full access to all Test Modules through test modules library"
                        : "Create custom Test Modules & Test Questions"}
                    </li>
                    <li>
                      {user_package_check === "pro"
                        ? "Brand our Tests with your company’s logo and colours"
                        : "Customise emails sent to candidate (invite, pass, rejection emails)"}
                    </li>
                    <li>
                      {user_package_check === "pro"
                        ? "Invite unlimited candidates"
                        : "Invite multiple candidates at once through a CSV or XLSX file upload"}
                    </li>
                  </ul>
                </div>
              </div>
            }
          />
        )}
        {feedbackModal && (
          <FeedbackModal
            setFeedbackModal={setFeedbackModal}
            icon={confirm}
            header="Share your feedback"
            buttonText="Yes"
            feedback={feedback}
            setFeedback={setFeedback}
            onClickButton={() => UpdgradePackage()}
          />
        )}
        <div className="bg-bodyColor">
          <div className="bg-bodyColor md:container mx-auto">
            <div className="pt-[7rem] sm:px-4 md:px-2">
              <div className="grid sm:grid-cols-1 md:grid-cols-12 overflow-hidden">
                <div className="col-span-2 pr-4">
                  <div className="flex flex-row gap-2">
                    <CiSettings className="w-6 h-6 my-auto" />
                    <h1
                      className="text-2xl my-auto font-bold select-text text-coalColor"
                      id="step3"
                      style={{ fontFamily: "Archia Semibold" }}
                    >
                      Settings
                    </h1>
                    <MdOutlineArrowDropDownCircle
                      className="w-5 h-5 my-auto md:hidden ml-auto"
                      onClick={() => setTabsVisible(!tabsVisible)}
                    />
                  </div>
                  {tabsVisible && (
                    <div
                      className={`flex flex-col mt-5 ${
                        isMobile && "fade-in-image"
                      }`}
                    >
                      {data?.map((item, index) => {
                        return (
                          <li
                            id={`setting${index + 1}`}
                            key={index}
                            className={`p-4 cursor-pointer list-none relative text-base flex justify-between rounded-md hover:font-bold ${
                              selectedTab === item.title
                                ? "bg-bgAuth font-bold"
                                : "bg-transparent"
                            }`}
                            onClick={() => {
                              // Handle the tab change and other logic
                              handleTabClick(item.title);
                            }}
                          >
                            <a
                              aria-current="page"
                              style={{ fontFamily: "Silka" }}
                            >
                              {item.title}
                            </a>
                            {item?.title === "Team management" &&
                              user_package_check === "free" && (
                                <span className="">
                                  <img
                                    src={King}
                                    alt="Premium Icon"
                                    className="w-5 h-5"
                                  />
                                </span>
                              )}
                          </li>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div className="p-4 bg-white col-span-10 rounded-md sm:h-auto md:h-[85vh]">
                  <TransitionGroup>
                    <CSSTransition
                      key={selectedTab}
                      timeout={450}
                      classNames="settingFade"
                    >
                      <div className="grid md:grid-cols-6 gap-3">
                        {selectedTab === "My profile" && (
                          <React.Fragment>
                            {isLoading ? (
                              <div class="loader-container-1 col-span-6">
                                <div class="loader-1"></div>
                              </div>
                            ) : (
                              <React.Fragment>
                                <div className="md:col-span-1 p-3 rounded-md bg-bodyColor sm:h-auto md:h-[80vh]">
                                  <DpCropper
                                    profileImage={profileImage}
                                    onCropDoneForDp={onCropDoneForDp}
                                    onCropCancelForDP={onCropCancelForDP}
                                    profileModalOpen={profileModalOpen}
                                    setProfileModalOpen={setProfileModalOpen}
                                    setProfileAllow={setProfileAllow}
                                  />
                                  <h1 style={{ fontFamily: "Archia Semibold" }}>
                                    Profile picture
                                  </h1>
                                  <p
                                    className="underline mt-3 cursor-pointer"
                                    style={{ fontFamily: "Silka Light" }}
                                    onClick={handleBiUploadClick}
                                  >
                                    Upload
                                  </p>
                                  <div className="py-8 flex justify-center">
                                    <div
                                      className={`relative w-[8rem] h-[8rem] flex items-center justify-center rounded-full overflow-hidden ${
                                        isHovered ? "bg-black/40" : "bg-white"
                                      }`}
                                      onMouseEnter={handleHover}
                                      onMouseLeave={handleHover}
                                      onClick={handleBiUploadClick}
                                    >
                                      {imageLoad ? (
                                        <div className="w-full h-full flex items-center justify-center">
                                          <div class="three-body">
                                            <div class="three-body__dot"></div>
                                            <div class="three-body__dot"></div>
                                            <div class="three-body__dot"></div>
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          <img
                                            src={
                                              companyData?.data[0]?.users[0]
                                                ?.profilePhoto
                                            }
                                            className="h-full w-full object-cover"
                                          />
                                          {!companyData?.data[0]?.users[0]
                                            ?.profilePhoto && (
                                            <div className="w-full h-full flex items-center justify-center">
                                              <BiUpload className="h-6 w-6 text-white" />
                                            </div>
                                          )}
                                          <label
                                            htmlFor="profile-picture-upload"
                                            className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full flex items-center justify-center cursor-pointer ${
                                              isHovered ||
                                              !companyData?.data[0]?.users[0]
                                                ?.profilePhoto
                                                ? "block"
                                                : "hidden"
                                            }`}
                                          >
                                            <div className="w-full h-full flex items-center justify-center bg-black/20 rounded-full transition-colors duration-300">
                                              <BiUpload className="h-6 w-6 text-white" />
                                            </div>
                                          </label>
                                        </>
                                      )}
                                      <ImageInput
                                        setProfileImage={setProfileImage}
                                        onDpSelected={onDpSelected}
                                        onChooseImg={(chooseImg) =>
                                          (chooseImgFunctionRef.current =
                                            chooseImg)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="md:px-4 md:col-span-5 overflow-auto pb-10 md:h-[85vh]">
                                  <form
                                    onSubmit={(e) => {
                                      e.preventDefault();
                                      validation.handleSubmit();
                                      return false;
                                    }}
                                  >
                                    <div className="p-3">
                                      <h1
                                        style={{
                                          fontFamily: "Archia Semibold",
                                        }}
                                      >
                                        Personal information
                                      </h1>
                                      <div className="grid md:grid-cols-2 gap-8 mt-5">
                                        <div>
                                          <p
                                            className="mb-2"
                                            style={{ fontFamily: "Silka" }}
                                          >
                                            First name
                                          </p>
                                          <div className="h-10">
                                            <TextField
                                              type="text"
                                              name="firstName"
                                              placeholder="Enter your first name"
                                              border="border border-[#D3D5D8] focus-within:border focus-within:border-coalColor"
                                              onChange={validation.handleChange}
                                              rounded="rounded-md"
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.firstName ||
                                                ""
                                              }
                                            />
                                            {validation.touched.firstName &&
                                            validation.errors.firstName ? (
                                              <p className="text-rose-500 text-sm ">
                                                {validation.errors.firstName}
                                              </p>
                                            ) : null}
                                          </div>
                                          <p
                                            className="mb-2 mt-10"
                                            style={{ fontFamily: "Silka" }}
                                          >
                                            Phone number
                                          </p>
                                          <div className="h-10">
                                            <div className="border border-[#D3D5D8] rounded-md focus-within:border-coalColor">
                                              <PhoneInput
                                                placeholder="Enter phone number"
                                                value={
                                                  myData?.phoneNumber != null
                                                    ? myData?.phoneNumber
                                                    : phone
                                                }
                                                className="w-full h-full py-4 outline-none px-3"
                                                onChange={setPhone}
                                                international
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <p
                                            className="mb-2"
                                            style={{ fontFamily: "Silka" }}
                                          >
                                            Last name
                                          </p>
                                          <div className="h-10">
                                            <TextField
                                              type="text"
                                              name="lastName"
                                              placeholder="Enter your last name"
                                              border="border border-[#D3D5D8] focus-within:border focus-within:border-coalColor"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              rounded="rounded-md"
                                              value={
                                                validation.values.lastName || ""
                                              }
                                            />
                                            {validation.touched.lastName &&
                                            validation.errors.lastName ? (
                                              <p className="text-rose-500 text-sm ">
                                                {validation.errors.lastName}
                                              </p>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                      <hr className="w-full mt-[4rem] bg-[#D3D5D8] border-1" />
                                      <h1
                                        className="mt-[4rem]"
                                        style={{
                                          fontFamily: "Archia Semibold",
                                        }}
                                      >
                                        Language selection (Coming Soon)
                                      </h1>
                                      <p
                                        className="text-[#252E3A] text-sm mt-4"
                                        style={{ fontFamily: "Silka Light" }}
                                      >
                                        Choose the language in which you’d like
                                        to use Dexta. You will also receive
                                        communications in this language.
                                      </p>
                                      <div className="grid md:grid-cols-2 gap-8">
                                        <TextField
                                          type="text"
                                          name="language"
                                          placeholder="Enter your first name"
                                          border="border border-[#999999] rounded-md w-full mt-5 text-[#999999]"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={"English (default)"}
                                          disabled
                                        />
                                        <div></div>
                                      </div>
                                      <hr className="w-full mt-[4rem] bg-[#D3D5D8] border-1" />
                                    </div>
                                    <div className="px-4">
                                      <div className="flex justify-between sm:flex-col md:flex-row md:items-end xl:mt-0 2xl:mt-20">
                                        <div
                                          className="flex sm:flex-col md:flex-row gap-5 mt-5 text-sm"
                                          style={{
                                            fontFamily: "Archia Semibold",
                                          }}
                                        >
                                          <div className="sm:w-full md:w-[200px]">
                                            <button
                                              className="inline-flex items-center w-full justify-center px-0 py-4 bg-[#252E3A] hover:bg-primaryGreen hover:text-black border border-coalColor text-white rounded-md"
                                              onClick={() =>
                                                setPasswordOpen(!passwordOpen)
                                              }
                                              type="button"
                                            >
                                              <GoKey className="w-5 h-5 mr-2" />
                                              Change password
                                            </button>
                                          </div>
                                          <div className="sm:w-full md:w-[200px] md:ml-5">
                                            <button
                                              className="inline-flex items-center w-full justify-center px-0 py-4 bg-[#252E3A] hover:bg-primaryGreen hover:text-black border border-coalColor text-white rounded-md"
                                              type="button"
                                              onClick={() =>
                                                setEmailOpen(!emailOpen)
                                              }
                                              style={{
                                                fontFamily: "Archia Semibold",
                                              }}
                                            >
                                              <TfiEmail className="w-5 h-5 mr-2" />
                                              Change email
                                            </button>
                                          </div>
                                        </div>
                                        <div
                                          className="sm:w-full md:w-[10rem]"
                                          style={{
                                            fontFamily: "Archia Semibold",
                                          }}
                                        >
                                          <button
                                            className="inline-flex items-center w-full justify-center px-4 mt-5 py-4 bg-primaryGreen border border-coalColor hover:bg-coalColor text-coalColor hover:text-white rounded-md"
                                            type="submit"
                                            disabled={
                                              validation.values.firstName ===
                                                "" ||
                                              validation.values.lastName === ""
                                            }
                                          >
                                            {mutateLoading ? (
                                              <span className="flex items-center justify-center">
                                                <Loader
                                                  type="Oval"
                                                  color="white"
                                                  height={20}
                                                  width={20}
                                                />
                                                <span className="ml-2">
                                                  Saving...
                                                </span>
                                              </span>
                                            ) : (
                                              "Save changes"
                                            )}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        )}
                        {selectedTab === "Notifications" && (
                          <React.Fragment>
                            <div className="md:p-3 col-span-6">
                              <div className="xl:h-[60vh] 2xl:h-[70vh]">
                                <div className="bg-bodyColor rounded-md">
                                  <div className="flex sm:flex-col md:flex-row p-5 gap-3">
                                    <img
                                      src={Danger}
                                      className="w-5 h-5 sm:mx-auto md:mx-0"
                                    />
                                    <p className="text-black">
                                      The notification settings below apply to
                                      you only and not to any other users in
                                      this account. You only receive these
                                      notification for every active assessment.
                                    </p>
                                  </div>
                                </div>

                                {user_package_check === "free" && (
                                  <div className="relative w-40">
                                    <h1
                                      className="mt-20 text-lg"
                                      style={{ fontFamily: "Archia Semibold" }}
                                    >
                                      Notify me....
                                    </h1>
                                    <img
                                      src={King}
                                      alt="Color Picker Image"
                                      className="absolute -top-2 right-3 z-20 w-6 h-6"
                                    />
                                  </div>
                                )}
                                <Premium
                                  premiumOpen={premiumOpen}
                                  setPremiumOpen={setPremiumOpen}
                                />
                                <div className="sm:grid sm:grid-cols-12 md:flex md:flex-row mt-10">
                                  <input
                                    type="checkbox"
                                    className="accent-primaryGreen sm:ml-auto md:ml-0 col-span-1 w-5 h-5"
                                    onChange={handleClick}
                                    checked={checkedComplete}
                                  />
                                  <p
                                    className="ml-2 text-base text-coalColor col-span-10"
                                    style={{ fontFamily: "Silka" }}
                                  >
                                    Email me every time a candidate completes a
                                    test
                                  </p>
                                </div>
                                <div className="sm:grid sm:grid-cols-12 md:flex md:flex-row mt-8">
                                  <input
                                    type="checkbox"
                                    className="accent-primaryGreen col-span-1 w-5 h-5 sm:ml-auto md:ml-0"
                                    onChange={handleClick2}
                                    checked={checkedNotify}
                                  />
                                  <div className="my-auto col-span-11">
                                    <p
                                      className="ml-2 text-base text-coalColor"
                                      style={{ fontFamily: "Silka" }}
                                    >
                                      Email me every day with a summary of
                                      candidates that completed a test
                                    </p>
                                    <p
                                      className="ml-2 text-base text-coalColor"
                                      style={{ fontFamily: "Silka" }}
                                    >
                                      You’ll receive the daily summary at
                                      12:00PM (GMT+0)
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="px-4">
                                <div
                                  className="sm:w-full md:w-[10rem] ml-auto"
                                  style={{ fontFamily: "Archia Semibold" }}
                                >
                                  <button
                                    className="inline-flex items-center w-full justify-center px-4 mt-5 py-4 hover:bg-coalColor hover:text-white bg-primaryGreen border border-coalColor text-coalColor rounded-md"
                                    type="submit"
                                    onClick={handleCheckboxes}
                                  >
                                    {mutateLoading ? (
                                      <span className="flex items-center justify-center">
                                        <Loader
                                          type="Oval"
                                          color="white"
                                          height={20}
                                          width={20}
                                        />
                                        <span className="ml-2">Saving...</span>
                                      </span>
                                    ) : (
                                      "Save changes"
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                        {selectedTab === "My company" && (
                          <React.Fragment>
                            <ImageCropper
                              image={image}
                              onCropDone={onCropDone}
                              onCropCancel={onCropCancel}
                              modalOpen={modalOpen}
                              setModalOpen={setModalOpen}
                              setAllow={setAllow}
                              aspectRatios={aspectRatios}
                              defaultAspectRatio={3 / 1}
                            />
                            <div className="md:p-3 col-span-6">
                              <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-8 sm:h-[200vh] md:h-[80vh]">
                                <div
                                  className={`bg-bodyColor rounded-md py-6 md:px-2 overflow-auto 
                               enable-scrollbar2
                                  } `}
                                >
                                  <Scrollbars
                                    autoHide
                                    style={{ width: "100%", height: "100%" }}
                                  >
                                    <div className="flex flex-col sm:px-2 md:px-4 pb-3">
                                      <div>
                                        <p
                                          className="mb-2 text-sm"
                                          style={{ fontFamily: "Silka" }}
                                        >
                                          Company Name
                                        </p>
                                        <TextField
                                          type="text"
                                          name="language"
                                          placeholder="Enter your company name"
                                          border="border border-[#D3D5D8] focus-within:border focus-within:border-coalColor"
                                          onChange={(e) =>
                                            setCompany(e.target.value)
                                          }
                                          rounded="rounded-md"
                                          value={company}
                                        />
                                      </div>
                                      <div className="">
                                        {/* <p
                                          className="mb-2 text-sm"
                                          style={{ fontFamily: "Silka" }}
                                        >
                                          Company Name
                                        </p>
                                        <div className="relative">
                                          <button
                                            id="country"
                                            className={`rounded-md border border-[#D3D5D8] text-sm py-4 px-3 w-full bg-white text-center flex items-center`}
                                            type="button"
                                            onClick={() =>
                                              setCountryDrop(!countryDrop)
                                            }
                                            style={{ fontFamily: "Silka" }}
                                          >
                                            {selectedCountry != ""
                                              ? selectedCountry
                                              : "Country/Region of Residence"}
                                            <svg
                                              className="w-2.5 h-2.5 ml-auto"
                                              aria-hidden="true"
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 10 6"
                                            >
                                              <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="m1 1 4 4 4-4"
                                              />
                                            </svg>
                                          </button>
                                          {countryDrop && (
                                            <div
                                              id="education-drop"
                                              className="absolute countryClass enable-scrollbar2 z-40 right-0 top-full h-80 mt-2 bg-white divide-y rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full "
                                            >
                                              <Scrollbars
                                                autoHide
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                }}
                                              >
                                                {countries.map((i, index) => (
                                                  <ul
                                                    key={index}
                                                    className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                                    style={{
                                                      fontFamily: "Silka",
                                                    }}
                                                    ref={countryRef}
                                                    onClick={() => {
                                                      setSelectedCountry(i);
                                                      setCountryDrop(false);
                                                    }}
                                                  >
                                                    <li>
                                                      <a className="block px-5 py-2 text-sm font-medium">
                                                        {i}
                                                      </a>
                                                    </li>
                                                  </ul>
                                                ))}
                                              </Scrollbars>
                                            </div>
                                          )}
                                        </div> */}
                                        <div>
                                          <p
                                            className="mb-2 text-sm mt-5"
                                            style={{ fontFamily: "Silka" }}
                                          >
                                            Select Screen to preview
                                          </p>
                                          <div className="relative">
                                            <button
                                              id="screen"
                                              className={`rounded-md border border-[#D3D5D8] text-sm py-4 px-3 w-full bg-white text-center flex items-center`}
                                              type="button"
                                              onClick={() =>
                                                setScreenDrop(!screenDrop)
                                              }
                                              style={{ fontFamily: "Silka" }}
                                            >
                                              {screen}
                                              <svg
                                                className="w-2.5 h-2.5 ml-auto"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 10 6"
                                              >
                                                <path
                                                  stroke="currentColor"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  strokeWidth="2"
                                                  d="m1 1 4 4 4-4"
                                                />
                                              </svg>
                                            </button>
                                            {screenDrop && (
                                              <div
                                                id="screen-drop"
                                                className=" absolute screenType z-40 right-0 top-full h-auto mt-2 bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full "
                                              >
                                                {screen_selection.map(
                                                  (i, index) => (
                                                    <ul
                                                      key={index}
                                                      className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                                      style={{
                                                        fontFamily: "Silka",
                                                      }}
                                                      ref={screenRef}
                                                      onClick={() => {
                                                        setScreen(i?.title);
                                                        setScreenDrop(false);
                                                      }}
                                                    >
                                                      <li>
                                                        <a className="block px-5 py-2 text-sm font-medium">
                                                          {i.title}
                                                        </a>
                                                      </li>
                                                    </ul>
                                                  )
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="grid grid-cols-2 gap-5">
                                          <div>
                                            <p
                                              className="mb-2 text-sm mt-5"
                                              style={{ fontFamily: "Silka" }}
                                            >
                                              Primary Color
                                            </p>
                                            <div className="relative">
                                              <TextField
                                                type="text"
                                                name="firstName"
                                                placeholder="Enter your color"
                                                border="border border-[#D3D5D8] w-full focus-within:border focus-within:border-coalColor"
                                                rounded="rounded-md"
                                                onChange={(e) => {
                                                  const newValue =
                                                    e.target.value;
                                                  if (
                                                    !newValue.startsWith("#")
                                                  ) {
                                                    setHex("#" + newValue);
                                                  } else {
                                                    setHex(newValue);
                                                  }
                                                }}
                                                value={hex}
                                                disabled={
                                                  user_package_check === "free"
                                                }
                                              />
                                              <div className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600 cursor-pointer">
                                                <img
                                                  src={
                                                    selectedColor === "Primary"
                                                      ? Highlighter2
                                                      : Highlighter
                                                  }
                                                  className={`my-auto ml-auto w-5 h-5`}
                                                  onClick={() => {
                                                    if (
                                                      selectedColor ===
                                                      "Primary"
                                                    ) {
                                                      setSelectedColor("");
                                                    } else {
                                                      setSelectedColor(
                                                        "Primary"
                                                      );
                                                    }
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div>
                                            <p
                                              className="mb-2 text-sm mt-5"
                                              style={{ fontFamily: "Silka" }}
                                            >
                                              Secondary Color{" "}
                                            </p>
                                            <div className="relative">
                                              <TextField
                                                type="text"
                                                name="firstName"
                                                placeholder="Enter your color"
                                                border="border border-[#D3D5D8] sm:w-full w-full focus-within:border focus-within:border-coalColor"
                                                rounded="rounded-md"
                                                onChange={(e) => {
                                                  const newValue =
                                                    e.target.value;
                                                  if (
                                                    !newValue.startsWith("#")
                                                  ) {
                                                    setHex2("#" + newValue);
                                                  } else {
                                                    setHex2(newValue);
                                                  }
                                                }}
                                                value={hex2}
                                                disabled={
                                                  user_package_check === "free"
                                                }
                                              />
                                              <div className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600 cursor-pointer">
                                                <img
                                                  src={
                                                    selectedColor ===
                                                    "Secondary"
                                                      ? Highlighter2
                                                      : Highlighter
                                                  }
                                                  className={`my-auto ml-auto w-5 h-5`}
                                                  onClick={() => {
                                                    if (
                                                      selectedColor ===
                                                      "Secondary"
                                                    ) {
                                                      setSelectedColor("");
                                                    } else {
                                                      setSelectedColor(
                                                        "Secondary"
                                                      );
                                                    }
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {selectedColor === "Primary" && (
                                          <div className="relative w-full mt-5 fade-in-text-validations">
                                            {user_package_check === "free" && (
                                              <div className="relative flex justify-end w-[70px] ml-auto">
                                                <img
                                                  src={King}
                                                  alt="Color Picker Image"
                                                  className="absolute top-3 right-3 z-20 w-6 h-6"
                                                />
                                              </div>
                                            )}
                                            <Premium
                                              premiumOpen={premiumOpen}
                                              setPremiumOpen={setPremiumOpen}
                                            />
                                            <Sketch
                                              color={hex}
                                              style={{ width: "100%" }}
                                              onChange={(color) => {
                                                user_package_check === "free"
                                                  ? setPremiumOpen(true)
                                                  : setHex(color.hex);
                                              }}
                                            />
                                          </div>
                                        )}
                                        {selectedColor === "Secondary" && (
                                          <div className="relative w-full mt-5 fade-in-text-validations">
                                            {user_package_check === "free" && (
                                              <div className="relative flex justify-end w-[70px] ml-auto">
                                                <img
                                                  src={King}
                                                  alt="Color Picker Image"
                                                  className="absolute top-3 right-3 z-20 w-6 h-6"
                                                />
                                              </div>
                                            )}
                                            <Premium
                                              premiumOpen={premiumOpen}
                                              setPremiumOpen={setPremiumOpen}
                                            />
                                            <Sketch
                                              color={hex2}
                                              style={{ width: "100%" }}
                                              onChange={(color) => {
                                                user_package_check === "free"
                                                  ? setPremiumOpen(true)
                                                  : setHex2(color.hex);
                                              }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </Scrollbars>
                                </div>
                                <div>
                                  <div className="2xl:h-[70vh] xl:h-auto overflow-y-auto">
                                    <div
                                      className="border border-[#D3D5D8] relative xl:h-[280px] 2xl:h-[320px] rounded-lg"
                                      onClick={() => {
                                        if (user_package_check === "free") {
                                          setPremiumOpen(true);
                                        } else {
                                          setMainDiv(true);
                                        }
                                      }}
                                    >
                                      <div className="p-2">
                                        {user_package_check === "free" && (
                                          <div className="absolute w-7 right-1">
                                            <img
                                              src={King}
                                              className="w-5 h-5"
                                            />
                                          </div>
                                        )}
                                      </div>
                                      <div className="mt-2 sm:pt-3 md:pt-[5rem]">
                                        {imgAfterCrop === null &&
                                        companyData?.data[0].companyAvatar ===
                                          "" ? (
                                          <img
                                            src={Default}
                                            className="w-[140px] h-[180px] mx-auto"
                                          />
                                        ) : (
                                          <img
                                            src={imgAfterCrop}
                                            className="mx-auto object-contain"
                                            style={{
                                              maxWidth: "320px",
                                              maxHeight: "90px",
                                              width: "auto",
                                              height: "auto",
                                            }}
                                          />
                                        )}
                                        <FileInput
                                          setImage={setImage}
                                          maindiv={maindiv}
                                          setMainDiv={setMainDiv}
                                          onImageSelected={onImageSelected}
                                          user_package_check={
                                            user_package_check
                                          }
                                        />
                                      </div>
                                    </div>
                                    <p
                                      className="text-sm text-[#252E3A] mt-5"
                                      style={{ fontFamily: "Silka Light" }}
                                    >
                                      See below how the button and text design
                                      elements change as you adjust the colour
                                      palette
                                    </p>
                                    <div className="flex flex-row mt-5">
                                      <div
                                        className={`px-4 py-3 rounded-md w-1/3 text-center font-bold`}
                                        style={{
                                          background: hex,
                                          borderColor: hex2,
                                          borderWidth: "1px",
                                          fontFamily: "Silka",
                                          color: hex2,
                                        }}
                                      >
                                        Button
                                      </div>
                                      <div
                                        className={`px-4 py-3 text-sm 2xl:text-base rounded-md w-1/3 text-center`}
                                        style={{
                                          background: "none",
                                          borderColor: "white",
                                          borderWidth: "1px",
                                          fontFamily: "Silka",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        <span
                                          style={{ color: hex2 }}
                                          className="font-bold"
                                        >
                                          Text colour
                                        </span>{" "}
                                        in comparison to black text
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="px-4 flex flex-row"
                                    style={{ height: "10vh" }}
                                  >
                                    <div className="flex flex-row ml-auto gap-4">
                                      <div
                                        className="sm:w-full md:w-[10rem] ml-auto"
                                        style={{
                                          fontFamily: "Archia Semibold",
                                        }}
                                      >
                                        <button
                                          className="inline-flex items-center w-full justify-center px-4 mt-5 py-4 hover:bg-primaryGreen hover:text-coalColor bg-coalColor border border-coalColor text-white rounded-md"
                                          type="submit"
                                          onClick={() => {
                                            dispatch(
                                              setPreviewData([
                                                companyData?.data[0]
                                                  .companyAvatar,
                                                hex,
                                                hex2,
                                              ])
                                            );
                                            handlePreviewClick2();
                                          }}
                                        >
                                          Preview
                                        </button>
                                      </div>
                                      <div
                                        className="sm:w-full md:w-[10rem] ml-auto"
                                        style={{
                                          fontFamily: "Archia Semibold",
                                        }}
                                      >
                                        <button
                                          className="inline-flex items-center w-full justify-center px-4 mt-5 py-4 hover:bg-coalColor hover:text-white bg-primaryGreen border border-coalColor text-coalColor rounded-md"
                                          type="submit"
                                          onClick={companySubmit}
                                        >
                                          {mutateLoading ? (
                                            <span className="flex items-center justify-center">
                                              <Loader
                                                type="Oval"
                                                color="white"
                                                height={20}
                                                width={20}
                                              />
                                              <span className="ml-2">
                                                Saving...
                                              </span>
                                            </span>
                                          ) : (
                                            "Save changes"
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                        {selectedTab === "Plan & billing" && (
                          <div className="md:p-5 col-span-6 md:overflow-auto sm:full md:h-[80vh]">
                            <div
                              className={`grid ${
                                packageData?.package?.code == "free"
                                  ? "md:grid-cols-3"
                                  : "md:grid-cols-2"
                              } md:gap-4`}
                            >
                              {/* First Grid */}
                              <div className="bg-white shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                                <div
                                  className={`grid grid-cols-12 px-5 py-3  ${
                                    packageData?.package?.code == "free" &&
                                    "bg-[#C0FF06]"
                                  } ${
                                    packageData?.package?.code == "pro" &&
                                    "bg-[#FFB500]"
                                  } ${
                                    packageData?.package?.code ==
                                      "Enterprise" && "bg-[#FF5812] text-white"
                                  }`}
                                >
                                  <div
                                    className={`${
                                      packageData?.package?.code === "free"
                                        ? "col-span-12"
                                        : "col-span-9 "
                                    }  flex flex-col `}
                                  >
                                    <div className={`flex flex-row gap-3`}>
                                      <h1
                                        className="lg:max-2xl:text-xl col-span-3 sm:text-base md:text-2xl font-bold"
                                        style={{ fontFamily: "Archia Bold" }}
                                      >
                                        {packageData?.package?.name ===
                                        "Free forever"
                                          ? "Explore for free"
                                          : packageData?.package?.name}
                                      </h1>
                                      <p
                                        className="bg-coalColor px-1 text-xs flex flex-row my-auto py-1 rounded-lg text-white"
                                        style={{ fontFamily: "Silka" }}
                                      >
                                        <img
                                          src={star}
                                          className="w-3 h-3 mr-1"
                                        />
                                        Current Plan
                                      </p>
                                    </div>
                                    <p
                                      className={`mt-2 ${
                                        packageData?.package?.code === "free" &&
                                        "text-xs"
                                      }  text-base`}
                                      style={{ fontFamily: "Silka" }}
                                    >
                                      {packageData?.package?.subHeading}
                                    </p>
                                  </div>
                                </div>
                                <div className="p-5">
                                  <div
                                    className="text-coalColor text-sm mt-5 space-y-1 h-24"
                                    style={{ fontFamily: "Silka Light" }}
                                  >
                                    {packageData?.package?.code == "free" && (
                                      <>
                                        <p>
                                          Run
                                          <b
                                            style={{ fontFamily: "Silka Bold" }}
                                          >
                                            {" "}
                                            1 Active Test
                                          </b>
                                        </p>
                                        <p>
                                          Send Test to
                                          <b
                                            style={{ fontFamily: "Silka Bold" }}
                                          >
                                            {" "}
                                            5 Candidates
                                          </b>
                                        </p>
                                        <p>
                                          Managed by
                                          <b
                                            style={{ fontFamily: "Silka Bold" }}
                                          >
                                            {" "}
                                            1 Account User
                                          </b>
                                        </p>
                                      </>
                                    )}
                                    {packageData?.package?.code == "pro" && (
                                      <>
                                        <p>
                                          Run
                                          <b
                                            style={{ fontFamily: "Silka Bold" }}
                                          >
                                            {" "}
                                            5 Active Tests
                                          </b>
                                        </p>
                                        <p>
                                          Send Test to
                                          <b
                                            style={{ fontFamily: "Silka Bold" }}
                                          >
                                            {" "}
                                            Unlimited Candidates
                                          </b>
                                        </p>
                                        <p>
                                          Managed by
                                          <b
                                            style={{ fontFamily: "Silka Bold" }}
                                          >
                                            {" "}
                                            5 Account Users
                                          </b>
                                        </p>
                                        <p>
                                          <b
                                            style={{ fontFamily: "Silka Bold" }}
                                          >
                                            {" "}
                                            Organizations with max 25 employees
                                          </b>
                                        </p>
                                      </>
                                    )}
                                    {packageData?.package?.code ==
                                      "Enterprise" && (
                                      <>
                                        <p>
                                          Run
                                          <b
                                            style={{ fontFamily: "Silka Bold" }}
                                          >
                                            {" "}
                                            Unlimited Active Tests
                                          </b>
                                        </p>
                                        <p>
                                          Send Test to
                                          <b
                                            style={{ fontFamily: "Silka Bold" }}
                                          >
                                            {" "}
                                            Unlimited Candidates
                                          </b>
                                        </p>
                                        <p>
                                          Managed by
                                          <b
                                            style={{ fontFamily: "Silka Bold" }}
                                          >
                                            {" "}
                                            Unlimited Account Users
                                          </b>
                                        </p>
                                        <p>
                                          <b
                                            style={{ fontFamily: "Silka Bold" }}
                                          >
                                            {" "}
                                            Organizations with 25-250 employees
                                          </b>
                                        </p>
                                      </>
                                    )}
                                  </div>
                                  <div
                                    className={`mt-6 ${
                                      packageData?.package?.code === "free"
                                        ? "px-0"
                                        : "px-2"
                                    }`}
                                    style={{ fontFamily: "Silka" }}
                                  >
                                    <div
                                      className={
                                        packageData?.package?.code == "free"
                                          ? styles["html-content"]
                                          : packageData?.package?.code == "pro"
                                          ? styles2["html-content"]
                                          : styles3["html-content"]
                                      }
                                    >
                                      {ReactHtmlParser(
                                        packageData?.package?.description
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <BillingHistory
                                  billingOpen={billingOpen}
                                  setBillingOpen={setBillingOpen}
                                />

                                <BillingAddress
                                  billingAddressOpen={billingAddressOpen}
                                  setBillingAddressOpen={setBillingAddressOpen}
                                />

                                <PaymentMethod
                                  paymentOpen={paymentOpen}
                                  setPaymentOpen={setPaymentOpen}
                                />

                                <div
                                  className={`flex sm:flex-col md:flex-row sm:mt-0 flex-wrap md:max-2xl:mt-20 md:mt-10 ${
                                    packageData?.package?.code === "free"
                                      ? "md:gap-x-3"
                                      : "md:gap-x-6"
                                  } py-4 px-5`}
                                  style={{ fontFamily: "Silka" }}
                                >
                                  <p
                                    className={` ${
                                      packageData?.package?.code === "free"
                                        ? "text-[12.5px]"
                                        : "text-base md:max-2xl:text-[11px]"
                                    } font-medium text-coalColor underline cursor-pointer hover:text-coalColor/90`}
                                    onClick={() => setBillingOpen(true)}
                                  >
                                    Billing history
                                  </p>
                                  <p
                                    className={` ${
                                      packageData?.package?.code === "free"
                                        ? "text-[12.5px]"
                                        : "text-base md:max-2xl:text-[11px]"
                                    } font-medium text-coalColor underline cursor-pointer hover:text-coalColor/90`}
                                    onClick={() => setBillingAddressOpen(true)}
                                  >
                                    Billing address
                                  </p>
                                  <p
                                    className={` ${
                                      packageData?.package?.code === "free"
                                        ? "text-[12.5px]"
                                        : "text-base md:max-2xl:text-[11px]"
                                    } font-medium text-coalColor underline cursor-pointer hover:text-coalColor/90`}
                                    onClick={() => setPaymentOpen(true)}
                                  >
                                    Payment method
                                  </p>
                                  <p
                                    className={` ${
                                      packageData?.package?.code === "free"
                                        ? "text-[12.5px]"
                                        : "text-base md:max-2xl:text-[11px]"
                                    } font-medium text-coalColor underline cursor-pointer hover:text-coalColor/90`}
                                    onClick={handleOpenEmailInboxButtonClick}
                                  >
                                    Contact us
                                  </p>
                                </div>
                              </div>
                              {/* Second Grid */}
                              <div
                                className={`bg-white md:mt-0 sm:mt-5 ${
                                  packageData?.package?.code !== "Enterprise" &&
                                  "shadow-[0_3px_10px_rgb(0,0,0,0.2)]"
                                }`}
                              >
                                <Plans
                                  plansModal={plansModal}
                                  setPlansModal={setPlansModal}
                                />
                                {packagesData?.data
                                  ?.filter((i) => {
                                    // Assuming packageData?.package?.name contains the name of the current package
                                    const currentPackageName =
                                      packageData?.package?.name;

                                    if (
                                      currentPackageName === "Free forever" &&
                                      i.name === "Starter"
                                    ) {
                                      return true;
                                    }
                                    if (
                                      currentPackageName === "Starter" &&
                                      i.name === "Pro"
                                    ) {
                                      return true;
                                    }
                                    return false;
                                  })
                                  .slice(0, 1)
                                  .map((j) => (
                                    <div className="mb-20" key={j.id}>
                                      <div
                                        className={`grid grid-cols-12 px-5 py-3 ${
                                          packageData?.package?.code ==
                                            "free" && "gap-3"
                                        } ${
                                          j.code == "free" && "bg-[#C0FF06]"
                                        } ${
                                          j.code == "pro" && "bg-[#FFB500]"
                                        } ${
                                          j.code == "Enterprise" &&
                                          "bg-[#FF5812] text-white"
                                        }`}
                                      >
                                        <div
                                          className={`col-span-9 flex flex-col `}
                                        >
                                          <div className="flex flex-row">
                                            <h1
                                              className="lg:max-2xl:text-xl text-2xl font-bold"
                                              style={{
                                                fontFamily: "Archia Bold",
                                              }}
                                            >
                                              <b>{j?.name}</b>{" "}
                                            </h1>
                                          </div>
                                          <p
                                            className={`mt-2 ${
                                              packageData?.package?.code ===
                                                "free" && "text-xs"
                                            } text-base`}
                                            style={{ fontFamily: "Silka" }}
                                          >
                                            {j?.subHeading}
                                          </p>
                                        </div>
                                        <div className="col-span-3 flex flex-col">
                                          <div className="flex flex-col ml-auto">
                                            <p
                                              className="flex lg:max-2xl:text-xl text-2xl items-center"
                                              style={{
                                                fontFamily: "Archia Bold",
                                              }}
                                            >
                                              £
                                              {Intl.NumberFormat("en-US", {
                                                style: "decimal",
                                                minimumFractionDigits: 0,
                                              }).format(
                                                j?.prices[0]?.price
                                              )}{" "}
                                            </p>
                                            <span
                                              className={`text-sm text-coalColor ${
                                                j?.code == "Enterprise" &&
                                                "text-white"
                                              }
                                              ${
                                                packageData?.package?.code ==
                                                  "free" && "text-[9.5px]"
                                              }
                                              `}
                                              style={{ fontFamily: "Silka" }}
                                            >
                                              per month
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="p-5">
                                        <div
                                          className="text-coalColor text-sm mt-5 space-y-1 h-24"
                                          style={{ fontFamily: "Silka Light" }}
                                        >
                                          {j?.code == "free" && (
                                            <>
                                              <p>
                                                Run
                                                <b
                                                  style={{
                                                    fontFamily: "Silka Bold",
                                                  }}
                                                >
                                                  {" "}
                                                  1 Active Test
                                                </b>
                                              </p>
                                              <p>
                                                Send Test to
                                                <b
                                                  style={{
                                                    fontFamily: "Silka Bold",
                                                  }}
                                                >
                                                  {" "}
                                                  5 Candidates
                                                </b>
                                              </p>
                                              <p>
                                                Managed by
                                                <b
                                                  style={{
                                                    fontFamily: "Silka Bold",
                                                  }}
                                                >
                                                  {" "}
                                                  1 Account User
                                                </b>
                                              </p>
                                            </>
                                          )}
                                          {j?.code == "pro" && (
                                            <>
                                              <p>
                                                Run
                                                <b
                                                  style={{
                                                    fontFamily: "Silka Bold",
                                                  }}
                                                >
                                                  {" "}
                                                  5 Active Tests
                                                </b>
                                              </p>
                                              <p>
                                                Send Test to
                                                <b
                                                  style={{
                                                    fontFamily: "Silka Bold",
                                                  }}
                                                >
                                                  {" "}
                                                  Unlimited Candidates
                                                </b>
                                              </p>
                                              <p>
                                                Managed by
                                                <b
                                                  style={{
                                                    fontFamily: "Silka Bold",
                                                  }}
                                                >
                                                  {" "}
                                                  5 Account Users
                                                </b>
                                              </p>
                                              <p>
                                                <b
                                                  style={{
                                                    fontFamily: "Silka Bold",
                                                  }}
                                                >
                                                  {" "}
                                                  Organizations with max 25
                                                  employees
                                                </b>
                                              </p>
                                            </>
                                          )}
                                          {j?.code == "Enterprise" && (
                                            <>
                                              <p>
                                                Run
                                                <b
                                                  style={{
                                                    fontFamily: "Silka Bold",
                                                  }}
                                                >
                                                  {" "}
                                                  Unlimited Active Tests
                                                </b>
                                              </p>
                                              <p>
                                                Send Test to
                                                <b
                                                  style={{
                                                    fontFamily: "Silka Bold",
                                                  }}
                                                >
                                                  {" "}
                                                  Unlimited Candidates
                                                </b>
                                              </p>
                                              <p>
                                                Managed by
                                                <b
                                                  style={{
                                                    fontFamily: "Silka Bold",
                                                  }}
                                                >
                                                  {" "}
                                                  Unlimited Account Users
                                                </b>
                                              </p>
                                              <p>
                                                <b
                                                  style={{
                                                    fontFamily: "Silka Bold",
                                                  }}
                                                >
                                                  {" "}
                                                  Organizations with 25-250
                                                  employees
                                                </b>
                                              </p>
                                            </>
                                          )}
                                        </div>
                                        <div
                                          className={`mt-6 ${
                                            packageData?.package?.code ===
                                            "free"
                                              ? "px-0"
                                              : "px-2"
                                          }`}
                                          style={{ fontFamily: "Silka" }}
                                        >
                                          <div
                                            className={
                                              j?.code == "free"
                                                ? styles["html-content"]
                                                : j?.code == "pro"
                                                ? styles2["html-content"]
                                                : styles3["html-content"]
                                            }
                                          >
                                            {ReactHtmlParser(j?.description)}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                              {packageData?.package?.code == "free" && (
                                <div
                                  className={`bg-white md:mt-0 sm:mt-5 ${
                                    packageData?.package?.code !==
                                      "Enterprise" &&
                                    "shadow-[0_3px_10px_rgb(0,0,0,0.2)]"
                                  }`}
                                >
                                  {packagesData?.data.slice(2, 3).map((j) => (
                                    <div className="mb-20" key={j.id}>
                                      <div
                                        className={`grid grid-cols-12 px-5 py-3  ${
                                          j.code == "free" && "bg-[#C0FF06]"
                                        } ${
                                          j.code == "pro" && "bg-[#FFB500]"
                                        } ${
                                          j.code == "Enterprise" &&
                                          "bg-[#FF5812] text-white"
                                        }`}
                                      >
                                        <div
                                          className={`col-span-9 flex flex-col `}
                                        >
                                          <div className="flex flex-row">
                                            <h1
                                              className="lg:max-2xl:text-xl text-2xl font-bold"
                                              style={{
                                                fontFamily: "Archia Bold",
                                              }}
                                            >
                                              <b>{j?.name}</b>{" "}
                                            </h1>
                                          </div>
                                          <p
                                            className={`mt-2 ${
                                              packageData?.package?.code ===
                                                "free" && "text-xs"
                                            } text-base`}
                                            style={{ fontFamily: "Silka" }}
                                          >
                                            {j?.subHeading}
                                          </p>
                                        </div>
                                        <div className="col-span-3 flex flex-col">
                                          <div className="flex flex-col ml-auto">
                                            <p
                                              className="flex text-2xl items-center"
                                              style={{
                                                fontFamily: "Archia Bold",
                                              }}
                                            >
                                              £
                                              {Intl.NumberFormat("en-US", {
                                                style: "decimal",
                                                minimumFractionDigits: 0,
                                              }).format(
                                                j?.prices[0]?.price
                                              )}{" "}
                                            </p>
                                            <span
                                              className={`text-sm text-coalColor 
                                                  ${
                                                    packageData?.package
                                                      ?.code == "free" &&
                                                    "text-[9.5px]"
                                                  }
                                                ${
                                                  j?.code == "Enterprise" &&
                                                  "text-white"
                                                }`}
                                              style={{ fontFamily: "Silka" }}
                                            >
                                              per month
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="p-5">
                                        <div
                                          className="text-coalColor text-sm mt-5 space-y-1 h-24"
                                          style={{
                                            fontFamily: "Silka Light",
                                          }}
                                        >
                                          {j?.code == "free" && (
                                            <>
                                              <p>
                                                Run
                                                <b
                                                  style={{
                                                    fontFamily: "Silka Bold",
                                                  }}
                                                >
                                                  {" "}
                                                  1 Active Test
                                                </b>
                                              </p>
                                              <p>
                                                Send Test to
                                                <b
                                                  style={{
                                                    fontFamily: "Silka Bold",
                                                  }}
                                                >
                                                  {" "}
                                                  5 Candidates
                                                </b>
                                              </p>
                                              <p>
                                                Managed by
                                                <b
                                                  style={{
                                                    fontFamily: "Silka Bold",
                                                  }}
                                                >
                                                  {" "}
                                                  1 Account User
                                                </b>
                                              </p>
                                            </>
                                          )}
                                          {j?.code == "pro" && (
                                            <>
                                              <p>
                                                Run
                                                <b
                                                  style={{
                                                    fontFamily: "Silka Bold",
                                                  }}
                                                >
                                                  {" "}
                                                  5 Active Tests
                                                </b>
                                              </p>
                                              <p>
                                                Send Test to
                                                <b
                                                  style={{
                                                    fontFamily: "Silka Bold",
                                                  }}
                                                >
                                                  {" "}
                                                  Unlimited Candidates
                                                </b>
                                              </p>
                                              <p>
                                                Managed by
                                                <b
                                                  style={{
                                                    fontFamily: "Silka Bold",
                                                  }}
                                                >
                                                  {" "}
                                                  5 Account Users
                                                </b>
                                              </p>
                                              <p>
                                                <b
                                                  style={{
                                                    fontFamily: "Silka Bold",
                                                  }}
                                                >
                                                  {" "}
                                                  Organizations with max 25
                                                  employees
                                                </b>
                                              </p>
                                            </>
                                          )}
                                          {j?.code == "Enterprise" && (
                                            <>
                                              <p>
                                                Run
                                                <b
                                                  style={{
                                                    fontFamily: "Silka Bold",
                                                  }}
                                                >
                                                  {" "}
                                                  Unlimited Active Tests
                                                </b>
                                              </p>
                                              <p>
                                                Send Test to
                                                <b
                                                  style={{
                                                    fontFamily: "Silka Bold",
                                                  }}
                                                >
                                                  {" "}
                                                  Unlimited Candidates
                                                </b>
                                              </p>
                                              <p>
                                                Managed by
                                                <b
                                                  style={{
                                                    fontFamily: "Silka Bold",
                                                  }}
                                                >
                                                  {" "}
                                                  Unlimited Account Users
                                                </b>
                                              </p>
                                              <p>
                                                <b
                                                  style={{
                                                    fontFamily: "Silka Bold",
                                                  }}
                                                >
                                                  {" "}
                                                  Organizations with 25-250
                                                  employees
                                                </b>
                                              </p>
                                            </>
                                          )}
                                        </div>
                                        <div
                                          className={`mt-6 ${
                                            packageData?.package?.code ===
                                            "free"
                                              ? "px-0"
                                              : "px-2"
                                          }`}
                                          style={{ fontFamily: "Silka" }}
                                        >
                                          <div
                                            className={
                                              j?.code == "free"
                                                ? styles["html-content"]
                                                : j?.code == "pro"
                                                ? styles2["html-content"]
                                                : styles3["html-content"]
                                            }
                                          >
                                            {ReactHtmlParser(j?.description)}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                            <div className="md:px-4 2xl:mt-[2rem] xl:mt-0">
                              <div className="flex justify-end items-end mt-auto">
                                <div
                                  className="w-full md:w-[15rem]"
                                  style={{ fontFamily: "Archia Semibold" }}
                                >
                                  <button
                                    className="inline-flex items-center w-full justify-center px-4 mt-2 py-4 bg-primaryGreen hover:bg-coalColor hover:text-white border border-coalColor  text-coalColor rounded-md"
                                    onClick={() => setPlansModal(true)}
                                  >
                                    {user_package_check === "Enterprise"
                                      ? "See all packages"
                                      : "See upgrade options"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {selectedTab === "Team management" && (
                          <div
                            className="md:p-5 col-span-6 overflow-auto"
                            style={{ height: "80vh" }}
                          >
                            <TeamMembers margin="ml-auto" settingsBtn={true} />
                          </div>
                        )}
                      </div>
                    </CSSTransition>
                  </TransitionGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Settings;
