import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  setDepartments: [],
};

export const DepartmentsSlice = createSlice({
  name: "departments",
  initialState,
  reducers: {
    setDepartmentData: (state, action) => {
      state.setDepartments = action.payload;
    },
  },
});

export const { setDepartmentData } = DepartmentsSlice.actions;
export default DepartmentsSlice.reducer;
