import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    setIndustries: [],
};

export const IndustriesSlice = createSlice({
    name: "industries",
    initialState,
    reducers: {
        setIndustryData: (state, action) => {
            state.setIndustries = action.payload;
        },
    },
});

export const { setIndustryData } =
    IndustriesSlice.actions;
export default IndustriesSlice.reducer;
