import React, { useState, useEffect, useRef } from "react";
import Help from "../../../../Dexta_assets/questionMark.png";
import { useQuery } from "@tanstack/react-query";
import { getCategories } from "./hooks/getCategories";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { intiateStep } from "./hooks/intiateStep";
import { updateStep } from "./hooks/updateStep";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { MultiSelect } from "react-multi-select-component";
import "./general.css";
import { getJobs } from "./hooks/getJobs";
import { setNextGeneralToFalse } from "../../../../redux/reducers/NextGeneral/NextGeneralSlice";
import { experience, work_arrangements } from "./data";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { ToastContainer, Zoom, toast } from "react-toastify";
import useWindowSize from "../../../../Helpers/useWindowSize";
import http from "../../../../http";
const General = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const parsed = queryString.parse(location.search);
  const assessment_id = localStorage.getItem("assessment_ID");
  const next = useSelector((state) => state.nextGeneral.setNextGeneral);
  const [selectedExperience, setSelectedExperience] = useState("");
  const [selectedExp, setSelectedExp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const queryClient = useQueryClient();
  const [selected, setSelected] = useState([]);
  const [selectedJob, setSelectedJob] = useState([]);
  const [selectedWork, setSelectedWork] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [allIndustries, setAllindustries] = useState(false);
  const [alljobs, setalljobs] = useState(false);
  const [paginationJobRole, setPaginationJobRole] = useState({
    currentTake: 1,
    hasNextPage: true,
    pageSize: 10,
  });
  const listJobRef = useRef(null);
  const [allJobData, setAllJobData] = useState([]);
  const size = useWindowSize();
  const isMobile = size.width <= 640;
  //#region fetch categories
  const {
    data,
    isLoading,
    error: CategoryError,
  } = useQuery(["categories"], getCategories);
  //#endregion

  //#region fetch jobs
  const {
    data: jobData,
    isLoading: jobLoading,
    error: jobError,
  } = useQuery({
    queryKey: [
      "jobs",
      allIndustries ? true : props.data.categoryID,
      paginationJobRole.currentTake,
    ],
    queryFn: () =>
      getJobs(
        allIndustries ? true : props.data.categoryID,
        paginationJobRole.currentTake
      ),
  });

  const onScrollJobRole = () => {
    if (listJobRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listJobRef.current;
      if (scrollTop + clientHeight === scrollHeight && !jobLoading) {
        if (
          jobData?.meta?.hasNextPage !== false &&
          allJobData.length >=
            paginationJobRole.currentTake * paginationJobRole.pageSize
        ) {
          setPaginationJobRole((prevPaginationInfo) => ({
            ...prevPaginationInfo,
            currentTake: prevPaginationInfo.currentTake + 1,
          }));
        }
      }
    }
  };

  const handleScrollFrame = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      onScrollJobRole();
    }
  };

  useEffect(() => {
    if (!jobLoading && jobData && paginationJobRole.currentTake === 1) {
      setAllJobData(jobData?.data);
    } else if (!jobLoading && jobData && paginationJobRole.currentTake > 1) {
      setAllJobData((prevData) => {
        const newData = jobData?.data || [];
        const filteredData = newData.filter(
          (newItem) => !prevData.some((prevItem) => prevItem.id === newItem.id)
        );
        const updatedData = [...prevData, ...filteredData];

        // Update paginationJobRole.hasNextPage based on whether all data is fetched
        if (filteredData.length < paginationJobRole.pageSize) {
          setPaginationJobRole((prevPaginationInfo) => ({
            ...prevPaginationInfo,
            hasNextPage: false,
          }));
        }

        return updatedData;
      });
    }
  }, [jobData, jobLoading, paginationJobRole.currentTake]);
  //#endregion

  //#region Populating industry, work arrangements, job location and job dropdowns when component renders
  useEffect(() => {
    const selectedOptions = props?.data?.jobLocation.map((label, index) => ({
      label,
      value: props?.data?.jobLocation[index],
    }));
    setSelectedCountry(selectedOptions);
  }, [props.data?.jobLocation]);

  useEffect(() => {
    const selectedOptions = props?.data?.category.map((label, index) => ({
      label,
      value: props?.data?.categoryID[index],
    }));

    const selectedDataFromApi = data
      ?.filter((j) => j?.isParent === true)
      .map((i) => i?.id);

    // Filter selectedOptions to include only those with matched IDs
    const filteredSelectedOptions = selectedOptions?.filter((option) =>
      selectedDataFromApi?.includes(option.value)
    );

    setSelected(filteredSelectedOptions);

    const selectedProps = filteredSelectedOptions?.map((i) => i?.value);
    const selectedData = data
      ?.filter((j) => j?.isParent === true)
      .map((i) => i?.id);
    const areAllIdsSelected = selectedData?.every((id) =>
      selectedProps.includes(id)
    );

    if (areAllIdsSelected) {
      setAllindustries(true);
    }
  }, [props.data?.categoryID, props.data?.category, data]);

  useEffect(() => {
    const selectedOptions = props?.data?.job_name?.map((label, index) => ({
      label,
      value: props?.data?.job[index],
    }));
    setSelectedJob(selectedOptions);
    console.log(selectedOptions?.length, jobData, "hehehe");
    if (
      selectedOptions?.length ===
      jobData?.data?.filter((i) => i?.status === "active")?.length
    ) {
      setalljobs(true);
    }
  }, [props.data?.job_name, props.data?.job, jobData]);

  useEffect(() => {
    const updateWorkArrangement = [...selectedWork];
    updateWorkArrangement[0] = {
      value: props?.data?.work_arrangement,
      label: props?.data?.work_arrangement,
    };
    setSelectedWork(updateWorkArrangement);
  }, [props?.data?.work_arrangement]);

  //#endregion

  //#region first step on the assessment post apis
  const { mutate, isLoading: mutateLoad } = useMutation(intiateStep, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("assessment");
      props.setselecteditem("modules");
      props.setData({ ...props.data, AssessmentID: response.data.id });
      localStorage.setItem("assessment_ID", response?.data?.id);
      dispatch(setNextGeneralToFalse(false));
      props.setGeneralLoading(false);
    },
    onError: (error) => {
      setError(true);
      setErrorMessage(error.response.data.message[0]);
      dispatch(setNextGeneralToFalse(false));
      props.setGeneralLoading(false);
      toast.error(error.response.data.message, {
        toastId: "copy-success",
      });
    },
  });

  const { mutate: updateMutate, isLoading: mutateLoading } = useMutation(
    updateStep,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("assessment");
        props.setselecteditem("modules");
        props.setData({ ...props.data, AssessmentID: response.data.id });
        dispatch(setNextGeneralToFalse(false));
        props.setGeneralLoading(false);
      },
      onError: (error) => {
        setError(true);
        setErrorMessage(error.response.data.message[0]);
        dispatch(setNextGeneralToFalse(false));
        props.setGeneralLoading(false);
        toast.error(error.response.data.message, {
          toastId: "copy-success",
        });
      },
    }
  );
  //#endregion

  //#region handling next function
  useEffect(() => {
    if (next) {
      handleNext();
    }
  }, [next]);

  const handleNext = () => {
    props.setData({ ...props.data, task1: "completed" });
    localStorage.setItem("current_module", "modules");
    let data = JSON.stringify({
      user: parseInt(localStorage.getItem("CP-USER-ID")),
      category: props.data.categoryID,
      name: props.data.name,
      jobIds: props.data.job,
      experience: props.data.experience,
      work_arrangement: props.data.work_arrangement,
      job_location: props.data?.jobLocation.join(", "),
      jobRole: "",
      setpOne: "completed",
      status: "draft",
      notes: "step 1 added",
    });
    props.setGeneralLoading(true);
    try {
      if (assessment_id !== "null" && assessment_id) {
        let update = {
          content: {
            user: parseInt(localStorage.getItem("CP-USER-ID")),
            category: props.data.categoryID,
            name: props.data.name,
            jobIds: props.data.job,
            experience: props.data.experience,
            work_arrangement: props.data.work_arrangement,
            job_location: props.data?.jobLocation.join(", "),
            jobRole: "",
            setpOne: "completed",
            status: "draft",
            notes: "step 1 added",
          },
          categoryID:
            props.data.AssessmentID != 0
              ? props.data.AssessmentID
              : parseInt(assessment_id),
        };
        updateMutate(update);
      } else if (parsed.assessment_id != null || props.data.AssessmentID != 0) {
        let update = {
          content: {
            user: parseInt(localStorage.getItem("CP-USER-ID")),
            category: props.data.categoryID,
            name: props.data.name,
            jobId: props.data.job,
            experience: props.data.experience,
            jobRole: props.data.job_title,
            setpOne: "completed",
            status: "draft",
            notes: "step 1 added",
          },
          categoryID:
            props.data.AssessmentID != 0
              ? props.data.AssessmentID
              : parsed.assessment_id,
        };
        updateMutate(update);
      } else {
        mutate(data);
      }
    } catch (err) {
      //
    }
  };
  //#endregion

  //#region Fetch country list

  useEffect(() => {
    const getCountry = async () => {
      try {
        const response = await http.get("/countries?limit=249");
        const countriesData = Object.values(response.data.data).map(
          (country) => ({
            name: { common: country.country.replace(/\s*\(.*?\)\s*/g, "") },
          })
        );

        // Manually add the desired countries at the top of the list
        const globalCountry = { name: { common: "Global" } };
        const saudiArabia = countriesData.find(
          (country) => country.name.common === "Saudi Arabia"
        );
        const unitedKingdom = { name: { common: "United Kingdom" } };
        const unitedStates = { name: { common: "United States of America" } };
        const Western = { name: { common: "Western Sahara" } };

        // Remove the added countries from the original list
        const modifiedCountries = countriesData.filter(
          (country) =>
            country.name.common !== "Saudi Arabia" &&
            country.name.common !==
              "United Kingdom of Great Britain and Northern Ireland" &&
            country.name.common !== "United States of America" &&
            country.name.common !== "Western Sahara*"
        );

        // Add the countries in the desired order
        const finalCountries = [
          globalCountry,
          saudiArabia,
          unitedKingdom,
          unitedStates,
          Western,
          ...modifiedCountries.sort((a, b) =>
            a.name.common.localeCompare(b.name.common)
          ),
        ];

        // Set the state with the modified list
        setCountries(finalCountries);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };
    getCountry();
  }, []);
  //#endregion

  useEffect(() => {
    props.setData({ ...props.data, fileBulk: null });
  }, []);

  return (
    <div className="sm:px-0 md:px-2 pb-20">
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        enableMultiContainer={false}
        limit={1}
      />
      <div className="bg-white rounded-lg">
        {isLoading ? (
          <div class="loader-container-1">
            <div class="loader-1"></div>
          </div>
        ) : (
          <div className="p-5 py-10 md:px-10">
            <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-5 w-full">
              <div className="flex flex-col w-full">
                <div className="flex flex-row gap-4">
                  <h1 className="font-medium" style={{ fontFamily: "Silka" }}>
                    Role name
                  </h1>
                  <div className="my-auto">
                    <div className="relative">
                      <img
                        src={Help}
                        className="w-5 h-5 my-auto cursor-pointer mt-0.5"
                      />
                      <div
                        className={`${
                          isMobile
                            ? "tooltip left-0 w-[14rem]"
                            : "tooltip left-0 w-[20rem]"
                        } z-40 text-center absolute top-full opacity-0 pointer-events-none`}
                      >
                        Enter a name for your Role. This name will be displayed
                        to the candidate when taking the Test.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative w-full border border-1 mt-2 rounded-lg border-[#c4c7cd] focus:border-[#808080] focus-within:border-[#808080]">
                  <input
                    id="name"
                    type="text"
                    maxLength="44"
                    className="w-full px-4 py-5 text-[14px] rounded-lg outline-none bg-[#F6F7F7] placeholder-[#999]"
                    placeholder="Name your role"
                    style={{ fontFamily: "Silka" }}
                    value={props.data.name}
                    onChange={(e) =>
                      props.setData({ ...props.data, name: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col w-full">
                <h1 className="font-medium" style={{ fontFamily: "Silka" }}>
                  Work arrangements
                </h1>
                <MultiSelect
                  options={work_arrangements?.map((j) => ({
                    value: j.name,
                    label: j.name,
                  }))}
                  value={selectedWork}
                  hasSelectAll={false}
                  onChange={(selectedOptions) => {
                    if (selectedOptions?.length === 0) {
                      setSelectedWork([]);
                      props.setData({ ...props.data, work_arrangement: "" });
                    } else {
                      const selectedWorkOption =
                        selectedOptions[selectedOptions.length - 1];
                      setSelectedWork([selectedWorkOption]);
                      console.log(selectedWorkOption, "selected work option");
                      props.setData({
                        ...props.data,
                        work_arrangement: selectedWorkOption.label,
                      });
                    }
                  }}
                  labelledBy={"Select"}
                  isCreatable={false}
                  disableSearch={true}
                  closeOnChangedValue={true}
                  className="mt-2 text-[14px] special-dropdown"
                  valueRenderer={(selectedJobs, _options) => {
                    return selectedJobs &&
                      selectedJobs?.length &&
                      selectedJobs[0]?.label != "" ? (
                      selectedJobs.map(({ label }) => (
                        <span
                          className="text-[14px] rounded"
                          style={{ fontFamily: "Silka" }}
                        >
                          {label}
                        </span>
                      ))
                    ) : (
                      <span
                        className="text-[#999] text-[14px] absolute left-[15px] inset-0 translate-y-1/3"
                        style={{
                          color: "#999",
                          fontWeight: "normal",
                          opacity: 1,
                          fontFamily: "Silka",
                        }}
                      >
                        Please select a work arrangement
                      </span>
                    );
                  }}
                />
              </div>
            </div>
            <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-5 w-full">
              <div className="flex flex-col w-full">
                <h1
                  className="font-medium sm:mt-4 lg:mt-12"
                  style={{ fontFamily: "Silka" }}
                >
                  Industry
                </h1>
                <div className="enable-scrollbar2">
                  <MultiSelect
                    options={
                      data &&
                      data?.length > 0 &&
                      data
                        ?.filter((i) => i.status === "active")
                        .map((i) => ({
                          value: i.id,
                          label: i.categoryName,
                        }))
                    }
                    value={selected}
                    closeOnChangedValue={true}
                    onChange={(selectedOptions) => {
                      const selectedLabels = selectedOptions.map(
                        (option) => option.label
                      );
                      const selectedValues = selectedOptions.map(
                        (option) => option.value
                      );
                      setSelected(selectedOptions);
                      const isAllSelected =
                        selectedOptions.length ===
                        data.filter((i) => i.status === "active").length;
                      setAllindustries(isAllSelected);
                      props.setData({
                        ...props.data,
                        category: selectedLabels,
                        categoryID: selectedValues,
                      });
                    }}
                    labelledBy={"Select"}
                    isCreatable={true}
                    className="mt-2 text-[14px]"
                    placeholder="Please select an idustry"
                    valueRenderer={(selected, _options) => {
                      return selected?.length ? (
                        allIndustries ? (
                          <span
                            className="px-4 text-[14px] pb-1 pt-[0.15rem] text-white bg-coalColor rounded mr-4"
                            style={{ fontFamily: "Silka" }}
                          >
                            All industries
                          </span>
                        ) : (
                          selected.map(({ label, value }, index) => (
                            <span
                              key={index}
                              className="px-4 text-[14px] pb-1 pt-[0.15rem] text-white bg-coalColor rounded mr-4"
                              style={{ fontFamily: "Silka" }}
                            >
                              {label}
                              <button
                                className="ml-2 text-white hover:text-red-600"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  const updatedSelected = selected.filter(
                                    (option) => option.value !== value
                                  );
                                  setSelected(updatedSelected);
                                  const updatedLabels = updatedSelected.map(
                                    (option) => option.label
                                  );
                                  const updatedValues = updatedSelected.map(
                                    (option) => option.value
                                  );
                                  setAllindustries(
                                    updatedSelected.length ===
                                      data.filter((i) => i.status === "active")
                                        .length
                                  );
                                  props.setData({
                                    ...props.data,
                                    category: updatedLabels,
                                    categoryID: updatedValues,
                                  });
                                }}
                              >
                                &times;
                              </button>
                            </span>
                          ))
                        )
                      ) : (
                        <span
                          className="text-[#999] text-[14px] absolute left-[15px] inset-0 translate-y-1/3"
                          style={{
                            color: "#999",
                            fontWeight: "normal",
                            opacity: 1,
                            fontFamily: "Silka",
                          }}
                        >
                          Please select an industry
                        </span>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col w-full">
                <h1
                  className="lg:mt-12 font-medium"
                  style={{ fontFamily: "Silka" }}
                >
                  Job role
                </h1>
                <div
                  className="enable-scrollbar2"
                  ref={listJobRef}
                  onScroll={onScrollJobRole}
                >
                  <MultiSelect
                    options={
                      !jobLoading &&
                      !isLoading &&
                      jobData &&
                      jobData?.data?.length > 0
                        ? jobData?.data.map((j) => ({
                            value: j.id,
                            label: j.name,
                          }))
                        : []
                    }
                    value={selectedJob}
                    closeOnChangedValue={true}
                    hasSelectAll={true}
                    onChange={(selectedOptions) => {
                      const selectedLabels = selectedOptions.map(
                        (option) => option.label
                      );
                      const selectedValues = selectedOptions.map(
                        (option) => option.value
                      );
                      setSelectedJob(selectedOptions);
                      const isAllSelected =
                        selectedOptions.length ===
                        jobData?.data.filter((i) => i.status === "active")
                          .length;
                      console.log(
                        jobData?.data.filter((i) => i.status === "active")
                          .length
                      );
                      setalljobs(isAllSelected);
                      props.setData({
                        ...props.data,
                        job_name: selectedLabels,
                        job: selectedValues,
                      });
                    }}
                    labelledBy={"Select"}
                    isCreatable={false}
                    className="mt-2 text-[14px]"
                    valueRenderer={(selectedJobs, _options) => {
                      return selectedJobs &&
                        selectedJobs?.length &&
                        selectedJobs[0]?.label != "" ? (
                        alljobs ? (
                          <span
                            className="px-4 text-[14px] pb-1 pt-[0.15rem] text-white bg-coalColor rounded mr-4"
                            style={{ fontFamily: "Silka" }}
                          >
                            All jobs
                          </span>
                        ) : (
                          selectedJobs.map(({ label, value }, index) => (
                            <span
                              key={index}
                              className="px-4 text-[14px] pb-1 pt-[0.15rem] text-white bg-coalColor rounded mr-4"
                              style={{ fontFamily: "Silka" }}
                            >
                              {label}
                              <button
                                className="ml-2 text-white hover:text-red-600"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  // Filter out the removed job
                                  const updatedSelectedJobs =
                                    selectedJobs.filter(
                                      (job) => job.value !== value
                                    );
                                  setSelectedJob(updatedSelectedJobs);

                                  const updatedLabels = updatedSelectedJobs.map(
                                    (job) => job.label
                                  );
                                  const updatedValues = updatedSelectedJobs.map(
                                    (job) => job.value
                                  );

                                  // Update if all jobs are selected
                                  setalljobs(
                                    updatedSelectedJobs.length ===
                                      jobData?.data.filter(
                                        (i) => i.status === "active"
                                      ).length
                                  );

                                  // Update parent data
                                  props.setData({
                                    ...props.data,
                                    job_name: updatedLabels,
                                    job: updatedValues,
                                  });
                                }}
                              >
                                &times;
                              </button>
                            </span>
                          ))
                        )
                      ) : (
                        <span
                          className="text-[#999] text-[14px] absolute left-[15px] inset-0 translate-y-1/3"
                          style={{
                            color: "#999",
                            fontWeight: "normal",
                            opacity: 1,
                            fontFamily: "Silka",
                          }}
                        >
                          Please select a job role
                        </span>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-5 w-full">
              <div className="flex flex-col w-full">
                <h1
                  className="sm:mt-4 lg:mt-12 font-medium"
                  style={{ fontFamily: "Silka" }}
                >
                  Job role location
                </h1>
                <div className="enable-scrollbar2">
                  <MultiSelect
                    options={countries.map((j) => ({
                      label: j?.name?.common,
                      value: j?.name?.common,
                    }))}
                    value={selectedCountry}
                    closeOnChangedValue={true}
                    hasSelectAll={false}
                    onChange={(selectedOptions) => {
                      const selectedLabels = selectedOptions.map(
                        (option) => option.label
                      );
                      const selectedValues = selectedOptions.map(
                        (option) => option.value
                      );

                      setSelectedCountry(selectedOptions);

                      props.setData({
                        ...props.data,
                        jobLocation: selectedLabels,
                      });
                    }}
                    labelledBy={"Select"}
                    isCreatable={false}
                    className="mt-2 text-[14px]"
                    valueRenderer={(selectedCountry, _options) => {
                      return selectedCountry?.length ? (
                        selectedCountry.map(({ label, value }, index) => (
                          <span
                            key={index}
                            className="px-4 text-[14px] pb-1 pt-[0.15rem] text-white bg-coalColor rounded mr-4"
                            style={{ fontFamily: "Silka" }}
                          >
                            {label}
                            <button
                              className="ml-2 text-white hover:text-red-600"
                              onClick={(e) => {
                                e.stopPropagation();
                                const updatedSelectedCountry =
                                  selectedCountry.filter(
                                    (country) => country.value !== value
                                  );
                                setSelectedCountry(updatedSelectedCountry);
                                const updatedLabels =
                                  updatedSelectedCountry.map(
                                    (country) => country.label
                                  );
                                props.setData({
                                  ...props.data,
                                  jobLocation: updatedLabels,
                                });
                              }}
                            >
                              &times;
                            </button>
                          </span>
                        ))
                      ) : (
                        <span
                          className="text-[#999] text-[14px] absolute left-[15px] inset-0 translate-y-1/3"
                          style={{
                            color: "#999",
                            fontWeight: "normal",
                            opacity: 1,
                            fontFamily: "Silka",
                          }}
                        >
                          Please select job role location
                        </span>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col w-full">
                <div className="flex flex-row gap-4">
                  <h1
                    className="sm:mt-4 lg:mt-12 font-medium"
                    style={{ fontFamily: "Silka" }}
                  >
                    Experience level
                  </h1>
                  <div className="my-auto sm:mt-4 lg:mt-[2.9rem]">
                    <div className="relative">
                      <img
                        src={Help}
                        className="w-5 h-5 my-auto cursor-pointer mt-0.5"
                      />
                      <div className="tooltip w-[20rem] z-40 text-center absolute top-full left-0 opacity-0 pointer-events-none">
                        Each one of our modules are designed for a specific
                        level of experience. Select a level of experience to see
                        the modules associated with it. Alternatively select
                        ‘All Levels’ to see modules across all experience
                        levels.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gap-5 mt-2 grid sm:grid-cols-2 lg:grid-cols-4">
                  {experience.map((i, index) => (
                    <div className="flex relative">
                      <div
                        key={index}
                        style={{ fontFamily: "Silka" }}
                        className={`w-full py-[19px] text-[14px] text-center ${
                          selectedExperience === i.name ||
                          props.data.experience === i.value
                            ? "bg-coalColor border border-coalColor text-white hover:bg-primaryGreen hover:text-coalColor"
                            : "bg-[#F6F7F7] border border-[#c4c7cd] hover:text-white"
                        } rounded-lg cursor-pointer justify-center hover:bg-coalColor`}
                        onClick={() => {
                          setSelectedExperience(i.name);
                          setSelectedExp(i.value);
                          props.setData({
                            ...props.data,
                            experience: i.value,
                            expName: i.name,
                          });
                        }}
                      >
                        {i.name}
                      </div>
                      <div className="tooltip w-[8rem] z-40 bg-coalColor text-[14px] font-medium text-center">
                        {i?.value === "Beginner" && "1-2 years experience"}
                        {i?.value === "Intermediate" && "3-5 years experience"}
                        {i?.value === "Advanced" && "6+ years experience"}
                        {i?.value === "all" && "All levels of experience"}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="flex flex-col w-full">
                <h1 className="mt-12 font-medium" style={{ fontFamily: "Silka" }}>Job role title</h1>
                <div className="relative w-full border border-1 mt-2 rounded-lg border-[#c4c7cd] focus:border-[#808080] focus-within:border-[#808080]">
                  <input
                    id="jobRole"
                    type="text"
                    maxLength="44"
                    className="w-full px-4 py-5 text-[14px] rounded-lg bg-[#F6F7F7] outline-none placeholder-[#999]"
                    placeholder="Enter job role title"
                    style={{ fontFamily: "Silka" }}
                    value={props.data.job_title}
                    onChange={(e) =>
                      props.setData({ ...props.data, job_title: e.target.value })
                    }
                  />
                </div>
              </div> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default General;
