import React from "react";
import { useSelector } from "react-redux";
import "../invite.css";
import BoxPlotChart2 from "../../../../../Components/chart/demo3";

const Graph2 = () => {
  const { xArray, myScore, firstName, lastName } = useSelector(
    (state) => state.graphData
  );

  return (
    <div className="mt-2 px-20 py-2 rotate-container bg-[#DBD9C0] rounded-lg">
      <BoxPlotChart2
        xArray={xArray?.xArray}
        myScore={myScore?.myScore}
        firstName={firstName?.firstName}
        lastName={lastName?.lastName}
      />
    </div>
  );
};

export default Graph2;
