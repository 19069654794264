import React from 'react'
import Logo from "../../Assets/mainLOGO.png"
import { useState } from 'react'
import { Link } from 'react-router-dom'
// import { Link } from 'react-router-dom'

const Navbar = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState("")

    //#region Select item from navbar
    const handleSelectItem = (item) => {
        setSelectedItem(item);
    }
    //#endregion
    
    //#region toggle navbar for mobile
    const toggleMobileMenu = () => {
        setMobileMenuOpen(true);
        if (isMobileMenuOpen) {
            setMobileMenuOpen(false);
        }
    };
    //#endregion
    return (
        // <nav className="bg-white fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600">
        //     <div className="max-w-screen-xl flex items-center justify-between mx-auto p-4">
        //         <Link to="/dashboard" className="flex items-center">
        //             <img src={Logo} className="h-8" alt="CP Logo" />
        //         </Link>
        //         <div className="flex md:order-2">
        //             <p className='text-black py-2 mr-4 text-lg sm:hidden md:block'>Demo</p>
        //             <button type="button" className="text-white sm:hidden md:block bg-[#4A9CB9] hover:bg-[#4A9CB9]/90 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0">Get started</button>
        //             <div className=''>
        //                 <button
        //                     type="button"
        //                     onClick={toggleMobileMenu}
        //                     className="flex items-center ml-20 rounded-md p-2 lg:hidden text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#4A9CB9]"
        //                     aria-controls="mobile-menu"
        //                     aria-expanded={isMobileMenuOpen}
        //                 >
        //                     <span className="sr-only">Open main menu</span>
        //                     <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
        //                         <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
        //                     </svg>
        //                 </button>
        //             </div>
        //         </div>
        //         <div className="flex flex-shrink-0 items-center">
        //             <div className={`sm:hidden lg:flex ${isMobileMenuOpen ? 'block' : 'hidden'}`}>
        //                 <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-5 md:mt-0 md:border-0 md:bg-white">
        //                     <li>
        //                         <Link to={process.env.REACT_APP_STATIC_SITE + "/Home"} onClick = {() => handleSelectItem("home")} className={`block py-2 pl-3 pr-4 text-[#000000] hover:text-[#4A9CB9] ${selectedItem === "home" ? "text-[#4A9CB9] underline" : null} rounded md:bg-transparent`} aria-current="page">Home</Link>
        //                     </li>
        //                     <li>
        //                         <Link to={process.env.REACT_APP_STATIC_SITE + "/product"} onClick = {() => handleSelectItem("product")} className={`block py-2 pl-3 pr-4 text-[#000000] hover:text-[#4A9CB9] ${selectedItem === "product" ? "text-[#4A9CB9] underline" : null}  rounded hover:bg-gray-100 md:hover:bg-transparent`}>Product tour</Link>
        //                     </li>
        //                     <li>
        //                         <Link to={process.env.REACT_APP_STATIC_SITE + "/Pricing"} onClick = {() => handleSelectItem("pricing")} className={`block py-2 pl-3 pr-4 text-[#000000] hover:text-[#4A9CB9] ${selectedItem === "pricing" ? "text-[#4A9CB9] underline" : null}  rounded hover:bg-gray-100 md:hover:bg-transparent`}>Pricing</Link>
        //                     </li>
        //                     <li>
        //                         <Link to={process.env.REACT_APP_STATIC_SITE + "/Home"} onClick = {() => handleSelectItem("learn")} className={`block py-2 pl-3 pr-4 text-[#000000] hover:text-[#4A9CB9] ${selectedItem === "learn" ? "text-[#4A9CB9] underline" : null} rounded hover:bg-gray-100 md:hover:bg-transparent`}>Learn</Link>
        //                     </li>
        //                 </ul>

        //             </div>
        //         </div>

        //     </div>
        //     <div className={`lg:hidden ${isMobileMenuOpen ? 'block' : 'hidden'}`} id="mobile-menu">
        //         <div className="space-y-1 px-2 pb-3 pt-2">
        //             <Link to={process.env.REACT_APP_STATIC_SITE + "/Home"} onClick = {() => handleSelectItem("home")} className={`text-[#000000]  block rounded-md px-3 py-2 hover:text-[#4A9CB9] text-base font-medium`} aria-current="page">Home</Link>
        //             <Link to={process.env.REACT_APP_STATIC_SITE + "/product"} onClick = {() => handleSelectItem("product")} className={`text-[#000000]  block hover:text-[#4A9CB9] rounded-md px-3 py-2 text-base font-medium`}>Product tour</Link>
        //             <Link to={process.env.REACT_APP_STATIC_SITE + "/Pricing"} onClick = {() => handleSelectItem("pricing")} className={`text-[#000000]  block rounded-md px-3 hover:text-[#4A9CB9] py-2 text-base font-medium`}>Pricing</Link>
        //             <Link to={process.env.REACT_APP_STATIC_SITE + "/Home"} onClick = {() => handleSelectItem("learn")} className={`text-[#000000]  block rounded-md px-3 py-2 hover:text-[#4A9CB9] text-base font-medium`}>Learn</Link>
        //         </div>
        //     </div>
        // </nav>
        <></>
    )
}

export default Navbar