import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    setNextCandidate: false,
}

export const NextCandidateSlice = createSlice({
    name:"nextCandidate",
    initialState,
    reducers:{
        setNextCandidateToTrue :(state, action)=>{
            state.setNextCandidate = true
        },
        setNextCandidateToFalse :(state, action)=>{
            state.setNextCandidate = false
        },
    },
})

export const { setNextCandidateToTrue, setNextCandidateToFalse } = NextCandidateSlice.actions;
export default NextCandidateSlice.reducer;