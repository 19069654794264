import http from "../../../http";

export const getJobsForSearch = async (
  search,
  take,
  industries,
  departments
) => {
  const response = await http.get(
    `/jobs?order=ASC&page=${take}&take=25&orderBy=name&search=${search}&categoryIds=${industries}&sub_category_id=${departments}&status=active&hasSections=true`
  );
  return response.data;
};
