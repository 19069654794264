import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import { getInvoiceList } from "../hooks/getInvoiceList";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import moment from "moment";
import closeIcon from "../../Dexta_assets/closeModal.png";
export default function BillingHistory({ billingOpen, setBillingOpen }) {
  const cancelButtonRef = useRef(null);
  const userID = localStorage.getItem("CP-USER-ID");
  const [error_, setError] = useState(false);
  const [staticLoading, setStaticLoading] = useState(true);
  const { data, isLoading, error } = useQuery({
    queryKey: ["getInvoiceList", userID],
    queryFn: () => getInvoiceList(userID),
    retry: false,
  });

  useEffect(() => {
    setStaticLoading(true);
    setTimeout(() => {
      setStaticLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    setError(error?.response?.data?.message[0] ? true : false);
  });

  return (
    <Transition.Root show={billingOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={setBillingOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white text-left transition-all sm:my-8 sm:w-11/12 md:max-w-md"
                style={{ height: "500px" }}
              >
                <div className="bg-white">
                  <p
                    className="text-lg font-medium text-coalColor pl-5 cursor-pointer hover:text-coalColor text-left pt-5"
                    style={{ fontFamily: "Archia Bold" }}
                  >
                    Billing history
                  </p>
                  <img
                    src={closeIcon}
                    className="absolute top-3 right-5 z-20 w-6 h-6 cursor-pointer"
                    onClick={() => setBillingOpen(false)}
                  />
                </div>
                <div
                  className="py-8 px-5 overflow-y-scroll"
                  style={{ height: "400px" }}
                >
                  {isLoading || staticLoading ? (
                    <>
                      <SkeletonTheme
                        baseColor="#e2e2e2"
                        highlightColor="#bdbdbd"
                      >
                        <p className="w-1/3 text-2xl">
                          <Skeleton count={1} />
                        </p>
                        <div className="w-1/2">
                          <Skeleton count={1} height={35} />
                        </div>{" "}
                        <p className="w-1/3 mt-1 text-xs">
                          <Skeleton count={1} />
                        </p>{" "}
                        <p className="w-1/3 mt-1 text-xs">
                          <Skeleton count={1} />
                        </p>
                      </SkeletonTheme>
                    </>
                  ) : (
                    <>
                      {error_ && (
                        <p className="text-center text-gray-500">
                          No invoices to display
                        </p>
                      )}
                      {data?.data?.map((i) => (
                        <div className="mb-8 grid grid-cols-12 rounded-md bg-[#F6F7F7] py-3 px-3">
                          <div
                            className="col-span-9"
                            style={{ fontFamily: "Silka" }}
                          >
                            <p className="font-medium text-sm">
                              {i?.package?.name},{" "}
                              {i?.lines?.data[0]?.plan?.interval}ly, company
                              size {i?.package?.companySize},
                            </p>
                            <div className="flex flex-row">
                              <p className="font-medium text-sm ">
                                <span className="font-normal text-sm">
                                  {moment
                                    .unix(i?.created)
                                    .format("MMMM DD, YYYY")}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="ml-0 flex flex-col col-span-3">
                            <p
                              className="font-medium text-sm"
                              style={{ fontFamily: "Archia Bold" }}
                            >
                              {Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 0,
                              }).format((i?.total / 100).toFixed(2))}{" "}
                              {i?.currency?.toUpperCase()}
                            </p>
                            <a
                              style={{ fontFamily: "Silka" }}
                              className="text-coalColor text-sm italic underline"
                              href={i?.invoice_pdf}
                            >
                              Download
                            </a>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
