import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import { getInvoiceList } from "../hooks/getInvoiceList";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { loadStripe } from "@stripe/stripe-js";
import { FiAlertCircle } from "react-icons/fi";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { getPaymentMethod } from "../hooks/getPaymentMethod";
import { FaCcMastercard, FaInfoCircle } from "react-icons/fa";
import closeIcon from "../../Dexta_assets/closeModal.png";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const options = {
  mode: "payment",
  amount: 1099,
  currency: "usd",
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
};

export default function PaymentMethod({ paymentOpen, setPaymentOpen }) {
  const cancelButtonRef = useRef(null);
  const userID = localStorage.getItem("CP-USER-ID");
  const [error_, setError] = useState(false);
  const [staticLoading, setStaticLoading] = useState(true);
  const { data, isLoading, error } = useQuery({
    queryKey: ["getUserPaymentMethodList", userID],
    queryFn: () => getPaymentMethod(userID),
    retry: false,
  });
  const [home, setHome] = useState("home");
  useEffect(() => {
    setStaticLoading(true);
    setTimeout(() => {
      setStaticLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    setError(error?.response?.data?.message[0] ? true : false);
  });

  return (
    <Transition.Root show={paymentOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={() => {
          setPaymentOpen(false);
          setHome("home");
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8  sm:w-11/12 md:max-w-md"
                style={{ height: "500px" }}
              >
                {/* <img src={Logo} className='w-[200px] h-[50px] object-contain mx-auto mt-5'/> */}
                <div className="bg-white">
                  <p
                    className="text-lg font-medium text-coalColor pl-5 cursor-pointer hover:text-coalColor text-left pt-5"
                    style={{ fontFamily: "Archia Bold" }}
                  >
                    Payment Method
                  </p>
                  <img
                    src={closeIcon}
                    className="absolute top-3 right-5 z-20 w-6 h-6 cursor-pointer"
                    onClick={() => setPaymentOpen(false)}
                  />
                </div>

                <div
                  className={`${
                    home && !isLoading && !staticLoading && !home === "homie"
                      ? ""
                      : "pt-6 px-5"
                  } overflow-y-scroll`}
                  style={{ height: "400px" }}
                >
                  {staticLoading || isLoading ? (
                    <>
                      <SkeletonTheme
                        baseColor="#e2e2e2"
                        highlightColor="#bdbdbd"
                      >
                        <p className="w-1/3 text-2xl">
                          <Skeleton count={1} />
                        </p>
                        <div className="w-1/2">
                          <Skeleton count={1} height={35} />
                        </div>{" "}
                        <p className="w-1/3 mt-1 text-xs">
                          <Skeleton count={1} />
                        </p>{" "}
                        <p className="w-1/3 mt-1 text-xs">
                          <Skeleton count={1} />
                        </p>
                      </SkeletonTheme>
                    </>
                  ) : (
                    <>
                      {error_ ? (
                        <p className="text-center text-gray-500">
                          {(() => {
                            const errorMessage = error?.response?.data?.message;

                            if (Array.isArray(errorMessage)) {
                              return errorMessage[0];
                            } else if (typeof errorMessage === "string") {
                              return errorMessage;
                            } else {
                              return "An error occurred.";
                            }
                          })()}
                        </p>
                      ) : (
                        <>
                          {home === "home" ? (
                            <>
                              <div className="bg-[#DBD9C0] w-full">
                                <div
                                  className="px-8 py-2 flex flex-row"
                                  style={{ fontFamily: "Silka" }}
                                >
                                  <p className="text-coalColor flex flex-row gap-3">
                                    <FaInfoCircle className="my-auto w-5 h-5" />
                                    Backups come handy when your primary payment
                                    fails. Set a backup.
                                  </p>
                                </div>
                              </div>
                              <div className="pt-6 px-5">
                                <>
                                  {data?.data?.map((i, index) => (
                                    <div className="bg-[#F6F7F7] px-8 py-4 flex flex-row justify-between gap-3 mb-3">
                                      <div className="flex flex-row gap-3">
                                        <FaCcMastercard className="w-6 h-6" />
                                        <p>ending {i?.card?.last4}</p>
                                      </div>
                                      {index === 0 && (
                                        <div
                                          className="h-[26px] w-20 text-white border border-coalColor text-center rounded bg-coalColor"
                                          style={{ fontFamily: "Silka" }}
                                        >
                                          Primary
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                  <div
                                    className="w-1/4 bg-primaryGreen hover:bg-coalColor hover:text-white rounded mt-5 border border-coalColor text-coalColor text-sm font-medium py-3 mx-auto justify-center flex cursor-pointer"
                                    onClick={() => setHome("homie")}
                                    style={{ fontFamily: "Silka" }}
                                  >
                                    Add New
                                  </div>
                                </>
                              </div>
                            </>
                          ) : (
                            <Elements stripe={stripePromise} options={options}>
                              <CheckoutForm setHome={setHome} />
                            </Elements>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
