import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  setCopy: false,
};

export const CopySlice = createSlice({
  name: "copy",
  initialState,
  reducers: {
    setCopytoTrue: (state, action) => {
      state.setCopy = true;
    },
    setCopytoFalse: (state, action) => {
      state.setCopy = false;
    },
  },
});

export const { setCopytoTrue, setCopytoFalse } =
  CopySlice.actions;
export default CopySlice.reducer;
