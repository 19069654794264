import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import "./index.css";
import http from "../../http";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import MainLogo from "../../Dexta_assets/SignupLogov2.png";
import { ToastContainer, Zoom } from "react-toastify";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PlaceOrder = (props) => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);

  const [packageDetail, setPackageDetail] = useState({});
  const [notfound, setnotfound] = useState(false);
  const [pkgError, setpkgError] = useState(false);

  const getPackageDetail = (package_id) => {
    http
      .request({
        method: "get",
        maxBodyLength: Infinity,
        url: `/packages/${package_id}?currency=${parsed?.currency}&interval=${parsed?.interval}`,
      })
      .then((response) => {
        setPackageDetail(response.data);
        const exist = Object.keys(response.data).length;
        if (exist === 0) {
          setnotfound(true);
          setpkgError(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!localStorage.getItem("CP-USER-TOKEN")) {
      navigate(`/login`);
    }
    if (parsed && parsed.package_id) {
      getPackageDetail(Number(parsed.package_id));
    }

    if (!localStorage.getItem("Registration")) {
      navigate(`/dashboard`);
    }

    if (parsed.token) {
      localStorage.clear();
    }
  }, []);

  return (
    <React.Fragment>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        enableMultiContainer={false}
        limit={1}
      />
      <div className="sm:grid-cols-1 lg:grid-cols-2 grid bg-bgAuth h-screen">
        <div className="bg-black sm:hidden lg:block">
          <img
            src={MainLogo}
            className="w-full lg:h-full xl:h-fit lg:object-cover"
          />
        </div>
        <div className="bg-bgAuth flex justify-center flex-col pb-40">
          <div className="sm:w-3/4 lg:w-1/2 mx-auto">
            <h1
              className="text-2xl text-left"
              style={{ fontFamily: "Archia Semibold" }}
            >
              {" "}
              Payment Details{" "}
            </h1>
            <div style={{ fontFamily: "Silka" }} className="text-sm mt-6">
              <span>
                Complete your purchased items by providing the payment details
                blow.
              </span>
            </div>
            <Elements stripe={stripePromise}>
              <CheckoutForm packageDetail={packageDetail} pkg={pkgError} />
            </Elements>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PlaceOrder;

PlaceOrder.propTypes = {
  history: PropTypes.object,
};
