import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  settour: false,
};

export const ToggleSlice = createSlice({
  name: "tour",
  initialState,
  reducers: {
    settourtotrue: (state, action) => {
      state.settour = true;
    },
    settourtofalse: (state, action) => {
      state.settour = false;
    },
  },
});

export const { settourtotrue, settourtofalse } =
  ToggleSlice.actions;
export default ToggleSlice.reducer;
