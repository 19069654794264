//#region slider tabs
export const invite_tabs = [
  {
    title: "Invite by email",
  },
  {
    title: "Share link",
  },
  {
    title: "Invite in bulk",
  },
];
//#endregion
