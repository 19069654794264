import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getConfirmation } from "./hooks/confirmMail";
import MainLogo from "../../Dexta_assets/LoginLogov4.png";
import withRouter from "../../Components/Common/withRouter";
import verified from "../../Dexta_assets/verified.png";
import "./Auth.css";
import { FaArrowRightLong } from "react-icons/fa6";
import { ToastContainer, Zoom, toast } from "react-toastify";

import CustomButton from "../../Components/CustomButton/CustomButton";
const ConfirmEmail = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const { data, isLoading, error } = useQuery(["confirmation", token], () =>
    getConfirmation(token)
  );

  document.title = "Confirmation | Dexta";

  return (
    <React.Fragment>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="sm:grid-cols-1 lg:grid-cols-2 grid bg-bgAuth h-screen">
        <div className="bg-black sm:hidden lg:block">
          <img
            src={MainLogo}
            className="w-full lg:h-full xl:h-fit lg:object-cover"
          />
        </div>
        <div className="bg-bgAuth flex justify-center flex-col pb-40">
          <div className="sm:w-3/4 lg:w-1/2 mx-auto">
            <img src={verified} className="w-80 h-80 mx-auto" />
            <h1
              className="text-2xl text-center mt-8"
              style={{ fontFamily: "Archia Semibold" }}
            >
              Email Verified
            </h1>
            <div className="w-4/6 mx-auto">
              <CustomButton
                label="Continue"
                borderCustom="border border-black text-white"
                hoverBgColor="#C0FF06"
                hoverTextColor="#252E3A"
                paddingY="0.7rem"
                marginTop="mt-8"
                bgColor="#252E3A"
                iconR={FaArrowRightLong}
                onClickButton={() => {
                  toast.success("Email Verified", {
                    toastId: "copy-success",
                  });
                  setTimeout(() => {
                    navigate("/dashboard");
                  }, 500);
                }}
                noMarginIcon={false}
                autoLeftMargin="ml-auto"
                textMarginBotton="ml-auto"
                loadingText="LOGGING IN"
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ConfirmEmail);

ConfirmEmail.propTypes = {
  history: PropTypes.object,
};
