import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    setPreview: [],
};

export const PreviewDataSlice = createSlice({
    name: "preview",
    initialState,
    reducers: {
        setPreviewData: (state, action) => {
            state.setPreview = action.payload;
        },
    },
});

export const { setPreviewData } =
    PreviewDataSlice.actions;
export default PreviewDataSlice.reducer;
