import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import "react-loading-skeleton/dist/skeleton.css";
import { updatePassword } from "../hooks/updatePassword";
import TextField from "../TextField/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./modals.css";
import Loader from "react-loader-spinner";
import { toast, ToastContainer, Zoom } from "react-toastify";
import CloseModal from "../../Dexta_assets/closeModal.png";
import { updateCandidatePassword } from "../../Pages/Candidates/hooks/updateCandidatePassword";
export default function ChangePassword({
  passwordOpen,
  setPasswordOpen,
  candidateChange,
}) {
  const cancelButtonRef = useRef(null);
  const userID = localStorage.getItem("CP-USER-ID");
  const [error_, setError] = useState(false);
  const [isPasswordVisible1, setIsPasswordVisible1] = useState(false);
  const [isPasswordVisible2, setIsPasswordVisible2] = useState(false);
  const [isPasswordVisible3, setIsPasswordVisible3] = useState(false);
  const [passwordIcon, setPasswordIcon] = useState(true);
  const queryClient = useQueryClient();

  //#region Formik validations
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Old Password Required"),
      newPassword: Yup.string().required("New Password Required"),
      confirmPassword: Yup.string().required("Confirm Password Required"),
    }),
    onSubmit: (values) => {
      let data = JSON.stringify({
        password: values?.oldPassword,
        password1: values?.newPassword,
        password2: values?.confirmPassword,
      });
      try {
        mutate(data);
      } catch (error) {
        //onError will automatically detect
      }
    },
  });
  //#endregion

  //#region Updating Password
  const { mutate, isLoading } = useMutation(
    candidateChange ? updateCandidatePassword : updatePassword,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("/auth/update-password");
        toast.success("Password Changed", {
          toastId: "copy-success",
        });
        setPasswordOpen(!passwordOpen);
      },
      onError: (error) => {
        // Handle error here, such as displaying it in an alert
        toast.error(error?.response?.data?.message[0], {
          toastId: "copy-success",
        });
      },
    }
  );
  //#endregion

  // useEffect(() => {
  //     setError(error?.response?.data?.message[0] ? true : false)
  // })
  return (
    <Transition.Root show={passwordOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={setPasswordOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <ToastContainer
          position="top-center"
          transition={Zoom}
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8  first-letter sm:w-5/6 md:max-w-lg"
                style={{ height: "550px" }}
              >
                <div className="bg-white">
                  <p
                    className="text-lg font-medium text-coalColor cursor-pointer text-left pl-5 pt-5"
                    style={{ fontFamily: "Archia Bold" }}
                  >
                    Change Password
                  </p>
                  <img
                    src={CloseModal}
                    className="absolute top-4 right-5 z-20 w-6 h-6 cursor-pointer"
                    onClick={() => setPasswordOpen(false)}
                  />
                </div>
                <div className="px-8 pt-8">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="mt-4 h-20">
                      <TextField
                        id="password"
                        type={isPasswordVisible1 ? "text" : "password"}
                        name="oldPassword"
                        value={validation.values.oldPassword || ""}
                        label="Old Password"
                        onChangeValue={validation.handleChange}
                        onBlur={validation.handleBlur}
                        isPasswordVisible={isPasswordVisible1}
                        setIsPasswordVisible={setIsPasswordVisible1}
                        passwordIcon={passwordIcon}
                        bgTextColor="bg-white"
                      />
                      {validation.touched.oldPassword &&
                      validation.errors.oldPassword ? (
                        <div className="ml-1 fade-in-text-validations">
                          <p className="text-rose-500 sm:text-xs md:text-sm">
                            {validation.errors.oldPassword}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-4 h-20">
                      <TextField
                        id="newPassword"
                        type={isPasswordVisible2 ? "text" : "password"}
                        name="newPassword"
                        value={validation.values.newPassword || ""}
                        label="New Password"
                        onChangeValue={validation.handleChange}
                        onBlur={validation.handleBlur}
                        isPasswordVisible={isPasswordVisible2}
                        setIsPasswordVisible={setIsPasswordVisible2}
                        passwordIcon={passwordIcon}
                        bgTextColor="bg-white"
                      />
                      {validation.touched.newPassword &&
                      validation.errors.newPassword ? (
                        <div className="ml-1 fade-in-text-validations">
                          <p className="text-rose-500 sm:text-xs md:text-sm">
                            {validation.errors.newPassword}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-4 h-20">
                      <TextField
                        id="confirmPassword"
                        type={isPasswordVisible3 ? "text" : "password"}
                        name="confirmPassword"
                        value={validation.values.confirmPassword || ""}
                        label="Confirm New Password"
                        onChangeValue={validation.handleChange}
                        onBlur={validation.handleBlur}
                        isPasswordVisible={isPasswordVisible3}
                        setIsPasswordVisible={setIsPasswordVisible3}
                        passwordIcon={passwordIcon}
                        bgTextColor="bg-white"
                      />
                      {validation.touched.confirmPassword &&
                      validation.errors.confirmPassword ? (
                        <div className="ml-1 fade-in-text-validations">
                          <p className="text-rose-500 sm:text-xs md:text-sm">
                            {validation.errors.confirmPassword}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div
                      className="mt-14 gap-3 justify-center flex flex-row"
                      style={{ fontFamily: "Silka" }}
                    >
                      <button
                        className="inline-flex items-center w-[7rem] justify-center px-6 mt-5 py-3 bg-coalColor hover:bg-primaryGreen hover:text-coalColor border border-coalColor text-white text-base font-medium rounded-md"
                        type="button"
                        onClick={() => setPasswordOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className={`inline-flex items-center w-[7rem] justify-center px-6 mt-5 py-3 hover:bg-coalColor bg-primaryGreen hover:text-white text-coalColor border border-coalColor
                                            ${
                                              validation?.values
                                                ?.oldPassword === "" ||
                                              validation?.values
                                                ?.newPassword === "" ||
                                              validation?.values
                                                ?.confirmPassword === ""
                                                ? "bg-primaryGreen cursor-not-allowed"
                                                : "bg-primaryGreen"
                                            }  text-coalColor text-base font-medium rounded-md`}
                        type="submit"
                        disabled={
                          validation?.values?.oldPassword === "" ||
                          validation?.values?.newPassword === "" ||
                          validation?.values?.confirmPassword === ""
                        }
                      >
                        {isLoading ? (
                          <span className="flex items-center justify-center">
                            <Loader
                              type="Oval"
                              color="black"
                              height={20}
                              width={20}
                            />
                            <span className="ml-2">Saving...</span>
                          </span>
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
