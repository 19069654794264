import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { IoMdClose } from "react-icons/io";
import "./modal.css";
import React from "react";
import { toast, ToastContainer, Zoom } from "react-toastify";
import { MdArrowBackIos } from "react-icons/md";
import CustomButton from "../../../../../Components/CustomButton/CustomButton";
import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";
import http from "../../../../../http";
import Loader from "react-loader-spinner";
import DraggableElement from "./draggableElement";
import { FaPlus } from "react-icons/fa6";
import { IoMdWarning } from "react-icons/io";
import CloseModal from "../../../../../Dexta_assets/closeModal.png";
import ImageInput from "../../../../../Components/FileInput/ImageInput";
import CustomCropper from "../../../../../Components/ImageCropper/CustomCropper";
import { aspectRatios } from "./data";
import { onCropDone, onCropCancel } from "../../../../../Helpers/CropFunctions";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DeletingOption } from "../hooks/DeletingOption";
import DeleteModal from "../../../../../Components/ConfirmationModals/DeleteModal";
import DeleteIcon from "../../../../../Dexta_assets/deleteIcon.png";

export default function QuestionsModal({
  questionModal,
  setQuestionModal,
  questions,
  setQuestions,
  setData,
  data,
  showQuestion = false,
  questionIndex = null,
  setQuestionShow,
  setQuestionIndex,
}) {
  const chooseImgFunctionRef = useRef();
  const chooseImg2FunctionRef = useRef();
  const queryClient = useQueryClient();
  const cancelButtonRef = useRef(null);
  const [description, setDescription] = useState(null);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [DeleteLoading, setDeleteLoading] = useState(false);
  const [elements, setElements] = useState(
    showQuestion
      ? questions[questionIndex]?.options
      : [
          {
            id: 0,
            position: { x: 0, y: 0 },
            title: "",
            isCorrectOption: false,
            imageUrl: null,
          },
          {
            id: 1,
            position: { x: 0, y: 0 },
            title: "",
            isCorrectOption: false,
            imageUrl: null,
          },
        ]
  );
  const [createQuestionLoading, setCreateQuestionLoading] = useState(false);
  const [image, setImage] = useState("");
  const [image2, setImage2] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState("choose-img");
  const [allow, setAllow] = useState(false);
  const [imgAfterCrop, setImgAfterCrop] = useState("");
  const [blobdata, setblobdata] = useState(null);
  const [dragOver, setDragOver] = useState(false);
  const [imageLoad, setImageLoad] = useState(false);
  const [renderLoading, setRenderLoading] = useState(false);
  const [DeletedID, setDeletedID] = useState(null);
  const [questionImage, setQuestionImage] = useState("");
  const [questionImageTwo, setQuestionImageTwo] = useState("");
  const [addAnotherImage, setAddAnotherImage] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [toggleOn, setToggleOn] = useState(false);
  const handleCropDone = (croppedArea) => {
    onCropDone(
      croppedArea,
      image2 ? image2 : image,
      setblobdata,
      setImgAfterCrop,
      setCurrentPage
    );
  };

  //#region Saving image to bucket and updating states
  useEffect(() => {
    if (blobdata) {
      handlePostImage(blobdata);
    }
  }, [blobdata]);

  const handlePostImage = async (blobx) => {
    setImageLoad(true);
    if (image2) {
      setQuestionImageTwo("");
    } else {
      setQuestionImage("");
    }
    const formData = new FormData();
    formData.append("file", blobx);
    formData.append("fileType", "user");
    try {
      const response = await http.post(
        "/questions/uploadQuestionImage",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (image2) {
        setQuestionImageTwo(response.data.link);
      } else {
        setQuestionImage(response.data.link);
      }
      setImageLoad(false);
    } catch (error) {
      console.error(error);
    }
  };
  //#endregion

  useEffect(() => {
    if (questionImageTwo) {
      setAddAnotherImage(true);
    } else {
      setAddAnotherImage(false);
    }
  }, [questionImageTwo]);

  //#region Deleting Option
  useEffect(() => {
    if (DeletedID !== null) {
      mutate(DeletedID);
    }
  }, [DeletedID]);

  const { mutate, isLoading: mutateLoad } = useMutation(DeletingOption, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("options");
      setDeletedID(null);
    },
    onError: (error) => {},
  });
  //#endregion

  //#region canceling crop
  const handleCropCancel = () => {
    onCropCancel(setCurrentPage, image2 ? setImage2 : setImage);
  };
  //#endregion

  //#region Editor options
  const Editor = {};
  Editor.modules = {
    toolbar: false,
    // toolbar: [
    //   [{ header: [1, 2, false] }, { font: [] }],
    //   ["bold", "italic", "underline", "strike", "blockquote"],
    //   [
    //     { list: "ordered" },
    //     { list: "bullet" },
    //     { indent: "-1" },
    //     { indent: "+1" },
    //   ],
    //   ["link", "image", "video"],
    //   ["clean"],
    // ],
  };
  const pickerOpts = [".png", ".gif", ".jpeg", ".jpg"];
  //#endregion

  //#region Move element drag n drop
  const moveElement = (fromIndex, toIndex) => {
    const newElements = [...elements];
    const [removedElement] = newElements.splice(fromIndex, 1);
    newElements.splice(toIndex, 0, removedElement);
    setElements(newElements);
  };
  //#endregion

  //#region Creating question
  const createQuestion = async () => {
    setCreateQuestionLoading(true);
    const correctCount = elements
      ?.map((count, option) => count?.isCorrectOption)
      .filter(Boolean).length;
    const questionData = {
      type: correctCount > 1 ? "Multiple" : "Single",
      image: questionImage,
      figImage: questionImageTwo,
      status: "active",
      description: description,
      section: data?.customQuestion,
      is_sample: false,
    };
    http
      .post(`/questions`, questionData)
      .then(async (response) => {
        const { data } = response;
        const options = elements?.map((ele, index) => {
          return {
            question: data?.id,
            title: ele?.title,
            isCorrectOption: ele?.isCorrectOption,
            optionPosition: index,
            imageUrl: ele?.imageUrl,
            status: "active",
          };
        });
        const optionSavedList = await options?.map((opt, index) => {
          return createOption(opt);
        });

        const questionSaveItem = {
          ...data,
          options: await Promise.all(optionSavedList),
        };
        setQuestions([{ ...questionSaveItem }, ...questions]);
        handleModulesIDS([{ ...questionSaveItem }, ...questions]);
        setCreateQuestionLoading(false);
        setQuestionModal(false);
        setQuestionIndex(null);
        setDescription(null);
        setselectedFiles([]);
        setElements([]);
        setElements([
          {
            id: 0,
            position: { x: 0, y: 0 },
            title: "",
            isCorrectOption: false,
            imageUrl: null,
          },
          {
            id: 1,
            position: { x: 0, y: 0 },
            title: "",
            isCorrectOption: false,
            imageUrl: null,
          },
        ]);
      })
      .catch((error) => {
        // logAxiosError(error)
        setCreateQuestionLoading(false);
        alert("Error: While creating question. Please try again!");
        // setSaveBtnLoading(false)
      });
  };
  //#endregion

  //#region Updating question
  const updatingQuestion = async () => {
    const correctCount = elements
      ?.map((element) => element?.isCorrectOption)
      .filter(Boolean).length;

    const questionData = {
      type: correctCount > 1 ? "Multiple" : "Single",
      image: questionImage,
      figImage: questionImageTwo,
      status: "active",
      description: description,
      is_sample: false,
    };

    try {
      const response = await http.patch(
        `/questions/${questions[questionIndex]?.id}`,
        questionData
      );
      const { data } = response;

      const options = elements?.map((ele, index) => {
        return {
          question: questions[questionIndex]?.id,
          id: ele?.id,
          title: ele?.title,
          isCorrectOption: ele?.isCorrectOption,
          optionPosition: index,
          imageUrl: ele?.imageUrl,
          status: ele?.status ? "active" : "inactive",
        };
      });

      const optionSavedList = await Promise.all(
        options.map((opt) =>
          opt.status === "active" ? updateOption(opt) : createOption(opt)
        )
      );

      const questionSaveItem = {
        ...data,
        options: optionSavedList,
      };

      setQuestions([{ ...questionSaveItem }, ...questions]);
      handleModulesIDS([{ ...questionSaveItem }, ...questions]);
      setCreateQuestionLoading(false);
      setElements([
        {
          id: 0,
          position: { x: 0, y: 0 },
          title: "",
          isCorrectOption: false,
          imageUrl: null,
        },
        {
          id: 1,
          position: { x: 0, y: 0 },
          title: "",
          isCorrectOption: false,
          imageUrl: null,
        },
      ]);
      setQuestionModal(false);
      setQuestionIndex(null);
      setselectedFiles([]);
    } catch (error) {
      setCreateQuestionLoading(false);
      alert("Error: While creating question. Please try again!");
    }
  };

  //#endregion

  //#region Deleting question
  const deleteQuestion = () => {
    setDeleteLoading(true);
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `/questions/${questions[questionIndex]?.id}/sections/${data?.customQuestion}`,
      headers: {
        accept: "*/*",
      },
    };

    http
      .request(config)
      .then((response) => {
        const newElements = [...questions];
        newElements.splice(questionIndex, 1);
        setQuestions(newElements);
        setDeleteLoading(false);
        setQuestionModal(false);
        setDescription(null);
        setselectedFiles([]);
        setElements([]);
        setQuestionShow(false);
        setDeleteModal(false);
      })
      .catch((error) => {
        setDeleteLoading(false);
        console.log(error);
      });
  };
  //#endregion

  //#region handling ids of modules
  const handleModulesIDS = (obj) => {
    const newProps = { ...data };
    let newModuleID = newProps.customQuestionDetail;
    newModuleID = obj;
    newProps["customQuestionDetail"] = newModuleID;
    setData(newProps);
  };
  //#endregion

  //#region creating options for question
  const createOption = async (option) => {
    try {
      let cleaObj = option;
      cleaObj = removeEmpty(cleaObj);
      return await http.post(`/options`, cleaObj).then((response) => {
        return response.data;
      });
    } catch (error) {
      console.log("🚀 ~ createOption ~ error:", error);
    }
  };

  const updateOption = async (option, id) => {
    try {
      let cleaObj = option;
      cleaObj = removeEmpty(cleaObj);
      return await http
        .patch(`/options/${option?.id}`, cleaObj)
        .then((response) => {
          return response.data;
        });
    } catch (error) {
      console.log("🚀 ~ createOption ~ error:", error);
    }
  };
  //#endregion

  //#region Handling options
  function removeEmpty(obj) {
    return Object.entries(obj)
      .filter(([_, v]) => v != null && v !== "")
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }

  const handleTextEdit = (index, newText) => {
    setElements((prevElements) => {
      const updatedElements = [...prevElements];
      updatedElements[index] = {
        ...updatedElements[index],
        title: newText,
      };
      return updatedElements;
    });
  };

  const handleCorrectOption = (index) => {
    setElements((prevElements) => {
      const updatedElements = [...prevElements];
      updatedElements[index] = {
        ...updatedElements[index],
        isCorrectOption: !updatedElements[index]?.isCorrectOption,
      };
      return updatedElements;
    });
  };
  //#endregion

  //#region set question description & options when component renders
  useEffect(() => {
    if (showQuestion && questionIndex !== null) {
      const sorted = [...questions[questionIndex]?.options].sort(
        (a, b) => a.optionPosition - b.optionPosition
      );
      setElements(sorted);
    }
  }, [showQuestion, questions, questionModal, questionIndex]);

  useEffect(() => {
    if (showQuestion) {
      setDescription(questions[questionIndex]?.description);
    }
  }, [
    showQuestion,
    questions[questionIndex]?.description,
    questions[questionIndex]?.image,
  ]);
  //#endregion

  //#region disable modal close with escape button
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        // Prevent modal from closing on "Esc"
        event.preventDefault();
        event.stopPropagation();
      }
    };

    if (questionModal) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [questionModal]);
  //#endregion

  //#region Opening cropper
  const onDpSelected = (selectedImg) => {
    setImage2("");
    setImage(selectedImg);
    setCurrentPage("crop-img");
    setModalOpen(true);
  };

  const onDpSelectedTwo = (selectedImg) => {
    setImage2(selectedImg);
    setCurrentPage("crop-img");
    setModalOpen(true);
  };
  //#endregion

  //#region Drag and drop functionality
  const handleDrop = (event) => {
    event.preventDefault();
    setDragOver(false);
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        onDpSelected(reader.result);
      };
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };
  //#endregion

  //#region Upload image to bucket
  // useEffect(() => {
  //   const sendImageToApi = async () => {
  //     if (imgAfterCrop && allow === true) {
  //       setImageLoad(true);
  //       if (image2) {
  //         setQuestionImageTwo("");
  //       } else {
  //         setQuestionImage("");
  //       }
  //       const formData = new FormData();
  //       formData.append("file", blobdata);
  //       formData.append("fileType", "user");
  //       try {
  //         const response = await http.post(
  //           "/questions/uploadQuestionImage",
  //           formData,
  //           {
  //             headers: {
  //               "Content-Type": "multipart/form-data",
  //             },
  //           }
  //         );
  //         if (image2) {
  //           setQuestionImageTwo(response.data.link);
  //         } else {
  //           setQuestionImage(response.data.link);
  //         }
  //         setImageLoad(false);
  //         setAllow(false);
  //       } catch (error) {
  //         console.error(error);
  //         setImageLoad(false);
  //         setAllow(false);
  //       }
  //     }
  //   };
  //   sendImageToApi();
  // }, [imgAfterCrop, allow]);
  //#endregion

  //#region setting question image when component mounts
  useEffect(() => {
    if (
      questions[questionIndex]?.image != null ||
      questions[questionIndex]?.image != ""
    ) {
      setQuestionImage(questions[questionIndex]?.image);
    }
  }, [questions[questionIndex]?.image, questionModal]);
  //#endregion

  //#region setting question image when component mounts
  useEffect(() => {
    if (
      questions[questionIndex]?.figImage != null ||
      questions[questionIndex]?.figImage != ""
    ) {
      setQuestionImageTwo(questions[questionIndex]?.figImage);
    }
  }, [questions[questionIndex]?.figImage, questionModal]);
  //#endregion

  useEffect(() => {
    if (!showQuestion) {
      setQuestionImage("");
      setQuestionImageTwo("");
    }
  }, []);

  const handleToggle = () => {
    setToggleOn(!toggleOn);
  };

  return (
    <Transition.Root show={questionModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={setQuestionModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <ToastContainer
          position="top-center"
          transition={Zoom}
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          enableMultiContainer={false}
          limit={1}
        />
        <CustomCropper
          image={image2 ? image2 : image}
          aspectRatios={aspectRatios}
          onCropDone={handleCropDone}
          onCropCancel={handleCropCancel}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setAllow={setAllow}
        />

        <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center  text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform h-screen rounded-lg bg-bodyColor text-left shadow-xl transition-all w-full">
                {deleteModal && (
                  <DeleteModal
                    setDeleteModal={setDeleteModal}
                    onClick={() => deleteQuestion()}
                    header="Delete your question"
                    icon={DeleteIcon}
                    loading={DeleteLoading}
                    description="Are you sure you want to remove this question?"
                    buttonText="Delete question"
                  />
                )}
                <div className="bg-bodyColor">
                  <div className="sm:flex sm:items-start md:pr-6 sm:px-2 md:px-0 justify-between py-6 bg-coalColor">
                    <div className="my-auto flex flex-row">
                      <MdArrowBackIos
                        className="md:w-6 md:h-6 sm:h-4 sm:w-4 md:ml-10 md:mr-10 cursor-pointer text-white my-auto"
                        onClick={() => {
                          setQuestionModal(false);
                          setQuestionShow(false);
                          setQuestionIndex(null);
                          setDescription(null);
                          setElements([
                            {
                              id: 0,
                              position: { x: 0, y: 0 },
                              title: "",
                              isCorrectOption: false,
                            },
                            {
                              id: 1,
                              position: { x: 0, y: 0 },
                              title: "",
                              isCorrectOption: false,
                            },
                          ]);
                        }}
                      />
                      <h1
                        className="text-base md:text-2xl font-medium text-white select-text"
                        style={{ fontFamily: "Archia Semibold" }}
                      >
                        {showQuestion ? "Edit Question" : "New question"}
                      </h1>
                    </div>
                    <div className="flex flex-row my-auto gap-3">
                      <div className="flex flex-row gap-3">
                        <CustomButton
                          label="Cancel"
                          bgColor="#FFFFFF"
                          textColor="#000000"
                          paddingY="0.20rem"
                          textSize="text-lg"
                          textMarginBotton="mb-1"
                          onClickButton={() => {
                            setQuestionModal(false);
                            setQuestionShow(false);
                            setQuestionIndex(null);
                            setDescription(null);
                            setElements([
                              {
                                id: 0,
                                position: { x: 0, y: 0 },
                                title: "",
                                isCorrectOption: false,
                              },
                              {
                                id: 1,
                                position: { x: 0, y: 0 },
                                title: "",
                                isCorrectOption: false,
                              },
                            ]);
                          }}
                        />
                        {showQuestion ? (
                          <div className="relative group">
                            <CustomButton
                              label="Update"
                              bgColor="#C0FF06"
                              borderCustom="border border-coalColor hover:border-white"
                              textColor="#FFFFFF"
                              hoverBgColor="#252E3A"
                              hoverTextColor="#C0FF06"
                              paddingY="0.20rem"
                              textSize="text-lg"
                              textMarginBotton="mb-1"
                              onClickButton={() => updatingQuestion()}
                              disableField={
                                createQuestionLoading ||
                                description === null ||
                                description === "" ||
                                description === "<p><br></p>" ||
                                elements?.every(
                                  (item) => item?.title?.trim() === ""
                                ) ||
                                elements?.some(
                                  (item) => item?.isCorrectOption
                                ) === false
                              }
                              loadingText="Saving"
                              LoadingBtn={createQuestionLoading}
                              loadingColor="black"
                              disabledColor="#D3D5D8"
                              disabledTextColor="#7C8289"
                              disabledCheck={
                                description === null ||
                                description === "" ||
                                description === "<p><br></p>" ||
                                elements.every(
                                  (item) => item?.title?.trim() === ""
                                ) ||
                                elements?.some(
                                  (item) => item?.isCorrectOption
                                ) === false
                              }
                            />
                            {elements?.some((item) => item?.isCorrectOption) ===
                              false && (
                              <div
                                className="tooltipxD right-0 group-hover:block hidden sm:w-[200px] md:w-[160px] text-center absolute top-full opacity-0 pointer-events-none text-sm"
                                style={{ fontFamily: "Silka" }}
                              >
                                You haven't selected the correct option yet
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="relative group">
                            <CustomButton
                              label="Save"
                              bgColor="#C0FF06"
                              borderCustom="border border-coalColor hover:border-white"
                              textColor="#FFFFFF"
                              hoverBgColor="#252E3A"
                              hoverTextColor="#C0FF06"
                              paddingY="0.20rem"
                              textSize="text-lg"
                              textMarginBotton="mb-1"
                              onClickButton={() => createQuestion()}
                              disableField={
                                createQuestionLoading ||
                                description === null ||
                                description === "" ||
                                description === "<p><br></p>" ||
                                elements.every(
                                  (item) => item?.title?.trim() === ""
                                ) ||
                                elements?.some(
                                  (item) => item?.isCorrectOption
                                ) === false
                              }
                              loadingText="Saving"
                              LoadingBtn={createQuestionLoading}
                              loadingColor="black"
                              disabledColor="#D3D5D8"
                              disabledTextColor="#7C8289"
                              disabledCheck={
                                description === null ||
                                description === "" ||
                                description === "<p><br></p>" ||
                                elements.every(
                                  (item) => item?.title.trim() === ""
                                ) ||
                                elements?.some(
                                  (item) => item?.isCorrectOption
                                ) === false
                              }
                            />
                            {elements?.some((item) => item?.isCorrectOption) ===
                              false && (
                              <div
                                className="tooltipxD right-0 group-hover:block hidden sm:w-[200px] md:w-[160px] text-center absolute top-full opacity-0 pointer-events-none text-sm"
                                style={{ fontFamily: "Silka" }}
                              >
                                You haven't selected the correct option yet
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <IoMdClose
                        className="w-[1rem] md:ml-4 h-[1rem] my-auto text-white cursor-pointer"
                        onClick={() => {
                          setQuestionModal(false);
                          setQuestionShow(false);
                          setDescription(null);
                          setQuestionIndex(null);
                          setElements([
                            {
                              id: 0,
                              position: { x: 0, y: 0 },
                              title: "",
                              isCorrectOption: false,
                            },
                            {
                              id: 1,
                              position: { x: 0, y: 0 },
                              title: "",
                              isCorrectOption: false,
                            },
                          ]);
                        }}
                      />
                    </div>
                  </div>
                  <div className="p-4 md:p-8 grid sm:grid-cols-1 md:grid-cols-2 gap-8">
                    <div>
                      <h1
                        className="text-lg font-bold"
                        style={{ fontFamily: "Archia Semibold" }}
                      >
                        Enter multiple choice question
                      </h1>
                      <div className="bg-white rounded-lg mt-5">
                        <div className="flex sm:flex-col md:flex-row px-5 py-4 gap-3">
                          <IoMdWarning
                            color="red"
                            className="w-5 h-5 mx-auto md:mx-0"
                          />
                          <p
                            className="text-black ml-2"
                            style={{ fontFamily: "Silka" }}
                          >
                            The language of your test is English. Make sure to
                            phrase the question using the same language.
                          </p>
                        </div>
                      </div>
                      <div className="mt-5 bg-white">
                        <ReactQuill
                          theme="snow"
                          value={description}
                          onChange={setDescription}
                          className=" bg-white mt-4"
                          modules={Editor?.modules}
                          placeholder="For example: What features of a process make it more suitable for Robotics Process Automation?"
                        />
                      </div>
                      <div className="flex flex-row justify-between mt-5">
                        <h1
                          className="text-lg font-bold"
                          style={{ fontFamily: "Archia Semibold" }}
                        >
                          Upload image here if your question requires supporting
                          visuals
                        </h1>
                        <div className="relative group">
                          <p
                            className="my-auto font-bold underline cursor-pointer"
                            style={{ fontFamily: "Silka" }}
                            onClick={() => setAddAnotherImage(true)}
                          >
                            Add another image
                          </p>
                          {questionImageTwo && (
                            <div className="tooltipxD right-0 group-hover:block hidden sm:w-[300px] md:w-[300px] text-center absolute top-full opacity-0 pointer-events-none text-sm">
                              You can only add a maximum of two images in a
                              question.
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="mt-3">
                        {questionImage ? (
                          <div
                            className="p-10 bg-white border border-dashed border-gray-500 
               cursor-pointer relative h-[400px] overflow-hidden"
                          >
                            <img
                              src={questionImage}
                              className="max-w-full max-h-full object-contain mx-auto"
                              style={{ width: "100%", height: "auto" }}
                            />
                            <div className="absolute top-1 right-2 m-auto">
                              <img
                                src={CloseModal}
                                className="w-5 h-5 cursor-pointer"
                                onClick={() => {
                                  setQuestionImage("");
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            className="bg-white border border-dashed border-gray-500 
               cursor-pointer relative h-[400px] pb-80"
                            onClick={() => chooseImgFunctionRef.current()}
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                          >
                            <div
                              className="text-center mt-[10rem] absolute top-0 right-0 left-0 m-auto"
                              style={{ fontFamily: "Silka" }}
                            >
                              {imageLoad && !image2 ? (
                                <span className="flex items-center justify-center">
                                  <Loader
                                    type="Oval"
                                    color="black"
                                    height={40}
                                    width={40}
                                  />
                                </span>
                              ) : (
                                <>
                                  <h4 className="font-medium">
                                    Drop files anywhere on this box to upload
                                    <br />
                                    <span className="mt-1">or</span>
                                  </h4>
                                  <p className="font-medium">Select Files</p>
                                </>
                              )}
                            </div>
                            <ImageInput
                              setImage={setImage}
                              onDpSelected={onDpSelected}
                              onChooseImg={(chooseImg) =>
                                (chooseImgFunctionRef.current = chooseImg)
                              }
                            />
                          </div>
                        )}
                      </div>
                      {addAnotherImage && (
                        <div className="mt-3">
                          {questionImageTwo ? (
                            <div
                              className="p-10 bg-white border border-dashed border-gray-500 
                   cursor-pointer relative h-[400px] overflow-hidden"
                            >
                              <img
                                src={questionImageTwo}
                                className="max-w-full max-h-full object-contain mx-auto"
                                style={{ width: "100%", height: "auto" }}
                              />
                              <div className="absolute top-1 right-2 m-auto">
                                <img
                                  src={CloseModal}
                                  className="w-5 h-5 cursor-pointer"
                                  onClick={() => {
                                    setQuestionImageTwo("");
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            <div
                              className="bg-white border border-dashed border-gray-500 
                   cursor-pointer relative h-[400px] pb-80" // Increased height to match when no image is present
                              onClick={() => chooseImg2FunctionRef.current()}
                              onDrop={handleDrop}
                              onDragOver={handleDragOver}
                              onDragLeave={handleDragLeave}
                            >
                              <div
                                className="text-center mt-[10rem] absolute top-0 right-0 left-0 m-auto"
                                style={{ fontFamily: "Silka" }}
                              >
                                {imageLoad ? (
                                  <span className="flex items-center justify-center">
                                    <Loader
                                      type="Oval"
                                      color="black"
                                      height={40}
                                      width={40}
                                    />
                                  </span>
                                ) : (
                                  <>
                                    <h4 className="font-medium">
                                      Drop files anywhere on this box to upload
                                      <br />
                                      <span className="mt-1">or</span>
                                    </h4>
                                    <p className="font-medium">Select Files</p>
                                  </>
                                )}
                              </div>
                              <ImageInput
                                setImage={setImage2}
                                onDpSelected={onDpSelectedTwo}
                                onChooseImg={(chooseImg) =>
                                  (chooseImg2FunctionRef.current = chooseImg)
                                }
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div>
                      <div className="flex justify-between">
                        <h1
                          className="text-lg font-bold"
                          style={{ fontFamily: "Archia Semibold" }}
                        >
                          Select the right answer
                        </h1>
                        {/* <label class="inline-flex items-center me-5 cursor-pointer">
                          <input
                            type="checkbox"
                            value=""
                            class="sr-only peer"
                            checked={toggleOn}
                            onChange={handleToggle}
                          />
                          <div class="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-coalColor  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-coalColor"></div>
                          <span
                            class="ms-3 text-sm font-medium text-coalColor"
                            style={{ fontFamily: "Silka" }}
                          >
                            Show images
                          </span>
                        </label> */}
                      </div>
                      <div
                        className="draggable-container mt-5"
                        style={{ position: "relative" }}
                      >
                        {!renderLoading && (
                          <>
                            {elements?.map((element, index) => (
                              <DraggableElement
                                key={element?.id}
                                element={element}
                                index={index}
                                moveElement={moveElement}
                                elements={elements}
                                setElements={setElements}
                                handleCorrectOption={handleCorrectOption}
                                handleTextEdit={handleTextEdit}
                                setDeletedID={setDeletedID}
                                toggleOn={toggleOn}
                              />
                            ))}
                          </>
                        )}
                      </div>

                      <div className="flex sm:flex-col md:flex-row justify-start mt-5 items-end">
                        <div className="relative ">
                          <button
                            className={`inline-flex items-center px-5 sm:mt-4 md:mt-0 h-12 w-full border border-coalColor bg-primaryGreen text-coalColor hover:bg-coalColor hover:text-white text-base font-medium rounded-md`}
                            onClick={() => {
                              const eleList = [...elements];
                              eleList.push({
                                id: eleList?.length,
                                position: { x: 0, y: 0 },
                                title: "",
                                isCorrectOption: false,
                              });
                              setElements(eleList);
                            }}
                          >
                            <FaPlus className="mr-2" />
                            Add another answer
                          </button>
                        </div>
                      </div>
                      {/*Button for deleting question*/}
                      <div className="flex sm:flex-col md:flex-row justify-evenly mt-8 items-end">
                        {showQuestion ? (
                          <div className="relative ">
                            <button
                              className={`inline-flex items-center px-12  sm:mt-4 md:mt-0 h-12 w-full hover:text-white text-white text-sm font-medium rounded-md bg-[#f44336] hover:bg-[#f44336]/90`}
                              onClick={() => setDeleteModal(true)}
                              style={{ fontFamily: "Silka" }}
                            >
                              {/* <img src={Edit} alt="Add Transaction Icon" className="w-5 h-5 mr-1 icon-image" /> */}
                              {createQuestionLoading ? (
                                <span className="flex items-center justify-center">
                                  <Loader
                                    type="Oval"
                                    color="white"
                                    height={20}
                                    width={20}
                                  />
                                  <span className="ml-2">deleting...</span>
                                </span>
                              ) : (
                                "Delete Question"
                              )}
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
