import React, { useState, useEffect, useRef } from "react";
import TextField from "../../../../Components/Dexta/TextField/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-number-input";
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import https from "../../../../https";
import Scrollbars from "react-custom-scrollbars";
import { IoAddCircle } from "react-icons/io5";
import Loader from "react-loader-spinner";
import ChangePassword from "../../../../Components/Modals/ChangePassword";
import ChangeEmail from "../../../../Components/Modals/ChangeEmail";
import { ToastContainer, toast, Zoom } from "react-toastify";
import { updateUser } from "../../hooks/updateUser";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateCandidate } from "../../hooks/updateCandidate";
import { useNavigate } from "react-router-dom";
import { gender } from "../../../../data/mapData";
import useOutsideClick from "../../../../Components/OutsideClick/OutsideClick";

const GeneralInfo = ({
  metaError,
  metaData,
  fname,
  lname,
  data,
  selectedCountry,
  setSelectedCountry,
  gen,
  setGender,
}) => {
  const [phone, setPhone] = useState();
  const [searchCountry, setsearchCountry] = useState("");
  const [countryModal, setCountryModal] = useState(false);
  const [passwordOpen, setPasswordOpen] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const [countries, setCountries] = useState([]);
  const [gendrop, setGendrop] = useState(false);
  const genderRef = useRef(null);
  const [otherGender, setOtherGender] = useState("");
  const countryRef = useRef(null);
  const queryClient = useQueryClient();

  console.log(gen, "genddd");
  //#region use Formik and yup to handle forms
  const validation = useFormik({
    enableReinitialize: true,
    initialValues:
      metaError || metaData === undefined
        ? {
            firstName: fname ? fname : "",
            lastName: lname ? lname : "",
          }
        : {
            firstName:
              data?.firstName !== "" && data?.firstName !== " "
                ? data?.firstName
                : "",
            lastName: data?.lastName || "",
          },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .min(3, "Too Short!")
        .max(50, "Too Long!")
        .required("First Name Required"),
      lastName: Yup.string()
        .min(3, "Too Short!")
        .max(50, "Too Long!")
        .required("Last Name Required"),
    }),
    onSubmit: (values) => {
      let data = JSON.stringify({
        firstName: values?.firstName,
        lastName: values?.lastName,
        phoneNumber: phone,
        candidateMeta: {
          country: selectedCountry,
          gender: gen !== "Other (Please specify)" ? gen : otherGender,
        },
      });
      try {
        if (gen === "Other (Please specify)" && otherGender === "") {
          toast.error("Please specify your gender in the text box provided.", {
            toastId: "copy-success",
          });
        } else {
          updateMutate(data);
        }
      } catch (error) {
        //onError will automatically detect
      }
    },
  });
  //#endregion

  //#region mutate to update data
  const { mutate: updateMutate, isLoading: mutateLoadings } = useMutation(
    updateUser,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        handleOnBoard();
        toast.success("Information Saved", {
          toastId: "copy-success",
        });
      },
      onError: (error) => {
        setTimeout(() => {
          toast.error(error?.response?.data?.message[0], {
            toastId: "copy-success",
          });
        }, 500);
      },
    }
  );
  //#endregion

  //#region Updating Tour Completed
  const handleOnBoard = () => {
    let data = JSON.stringify({
      isUserOnboard: true,
    });
    try {
      updateCandidateUserMutate(data);
    } catch (err) {
      console.log(err.message);
    }
  };
  //#endregion

  //#region updating tour status
  const { mutate: updateCandidateUserMutate, isLoading: updateLoading } =
    useMutation(updateCandidate, {
      onSuccess: (response) => {
        queryClient.invalidateQueries("/users");
      },
    });
  //#endregion

  //#region Fetching countries from api
  useEffect(() => {
    const getCountry = async () => {
      try {
        const response = await https.get("/countries?limit=249");

        let countriesData = Object.values(response.data.data).map((country) => {
          // Keep "Western Sahara" as is, otherwise remove parenthetical content
          if (country.country === "Western Sahara*") {
            return "Western Sahara";
          } else {
            return country.country.replace(/\s*\(.*?\)\s*/g, "");
          }
        });

        // Manually add the desired countries at the top of the list
        const specialCountries = [
          "Global",
          "Saudi Arabia",
          "United Kingdom",
          "United States of America",
        ];

        // Remove the added countries from the original list
        countriesData = countriesData.filter(
          (country) => !specialCountries.includes(country)
        );

        // Add the countries in the desired order
        const finalCountries = [
          ...specialCountries,
          ...countriesData.sort((a, b) => a.localeCompare(b)),
        ];

        // Set the state with the modified list
        setCountries(finalCountries);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    getCountry();
  }, []);
  //#endregion

  //#region Filtering and calculating dropdown items height
  const filteredCountries = countries.filter((item) =>
    item.toLowerCase().includes(searchCountry.toLowerCase())
  );

  const calculateHeightForFeild = () => {
    const itemHeight = 40;
    const maxVisibleItems = 8;

    // Number of items in allData
    const itemsCount =
      filteredCountries.length > 0 ? filteredCountries.length : 1; // Ensure there's height for the "No results" message

    // Determine the height based on the items count
    const height =
      itemsCount > maxVisibleItems
        ? itemHeight * maxVisibleItems
        : itemHeight * itemsCount;

    return height;
  };
  //#endregion

  //#region updating phone number on component render
  useEffect(() => {
    setPhone(data?.phoneNumber);
  }, []);
  //#endregion

  //#region Dropdown close on outside click -> Country
  useOutsideClick(
    [
      {
        ref: genderRef,
        excludeClasses: [".genderClass"],
        excludeIds: ["gender"],
      },
      {
        ref: countryRef,
        excludeClasses: [".countryClass"],
        excludeIds: ["country", "country2"],
      },
    ],
    (ref) => {
      if (ref === genderRef) setGendrop(false);
      if (ref === countryRef) setCountryModal(false);
    }
  );
  //#endregion
  return (
    <>
      <ChangePassword
        passwordOpen={passwordOpen}
        setPasswordOpen={setPasswordOpen}
        candidateChange={true}
      />
      <ChangeEmail
        emailOpen={emailOpen}
        setEmailOpen={setEmailOpen}
        candidateChange={true}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <div className="p-3">
          <h1
            style={{
              fontFamily: "Archia Semibold",
            }}
          >
            Personal information
          </h1>
          <div className="grid md:grid-cols-2 gap-8 mt-5">
            <div>
              <p className="mb-2" style={{ fontFamily: "Silka" }}>
                First name
              </p>
              <div className="h-10">
                <TextField
                  type="text"
                  name="firstName"
                  placeholder="Enter your first name"
                  border="border border-[#D3D5D8] focus-within:border focus-within:border-coalColor"
                  onChange={validation.handleChange}
                  rounded="rounded-md"
                  onBlur={validation.handleBlur}
                  value={validation.values.firstName || ""}
                />
                {validation.touched.firstName && validation.errors.firstName ? (
                  <p className="text-rose-500 text-sm ">
                    {validation.errors.firstName}
                  </p>
                ) : null}
              </div>
              <p className="mb-2 mt-10" style={{ fontFamily: "Silka" }}>
                Phone number
              </p>
              <div className="h-10">
                <div className="border border-[#D3D5D8] rounded-md focus-within:border-coalColor">
                  <PhoneInput
                    placeholder="Enter phone number"
                    style={{ fontFamily: "Silka" }}
                    value={phone}
                    className="w-full h-full py-4 outline-none px-3"
                    onChange={setPhone}
                    international
                  />
                </div>
              </div>
            </div>
            <div>
              <p className="mb-2" style={{ fontFamily: "Silka" }}>
                Last name
              </p>
              <div className="h-10">
                <TextField
                  type="text"
                  name="lastName"
                  placeholder="Enter your last name"
                  border="border border-[#D3D5D8] focus-within:border focus-within:border-coalColor"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  rounded="rounded-md"
                  value={validation.values.lastName || ""}
                />
                {validation.touched.lastName && validation.errors.lastName ? (
                  <p className="text-rose-500 text-sm ">
                    {validation.errors.lastName}
                  </p>
                ) : null}
              </div>
              <p className="mb-2 mt-10" style={{ fontFamily: "Silka" }}>
                Gender
              </p>
              <div className="relative">
                <button
                  id="gender"
                  className={`border border-[#D3D5D8] focus-within:border focus-within:border-coalColor rounded-md px-3 text-left w-full py-4 justify-between flex ${
                    gen === "" || gen === null
                      ? "text-[#999999]"
                      : "text-coalColor"
                  }`}
                  style={{
                    fontFamily: "Silka",
                  }}
                  type="button"
                  onClick={() => setGendrop(!gendrop)}
                >
                  {gen != "" && gen != null ? (
                    gen === "Other (Please specify)" ? (
                      <input
                        placeholder="Please specify"
                        autoFocus
                        value={otherGender}
                        onChange={(e) => setOtherGender(e.target.value)}
                      />
                    ) : (
                      gen
                    )
                  ) : (
                    "Gender"
                  )}
                  <IoIosArrowDown className="mr-1 my-auto" />
                </button>
                {gendrop && (
                  <div
                    id="education-drop"
                    className="genderClass absolute z-40 border border-coalColor right-0 top-full h-auto overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full"
                    style={{ fontFamily: "Silka" }}
                  >
                    {gender?.map((i) => (
                      <ul
                        key={i.title}
                        className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                        ref={genderRef}
                        onClick={() => {
                          setGender(i?.title);
                          setGendrop(false);
                        }}
                      >
                        <li>
                          <a className="block px-5 py-2 text-sm font-medium">
                            {i.title}
                          </a>
                        </li>
                      </ul>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <h1
            className="mt-10"
            style={{
              fontFamily: "Archia Semibold",
            }}
          >
            Country
          </h1>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="relative">
              <input
                id="country"
                className={`border border-[#D3D5D8] text-coalColor focus-within:border focus-within:border-coalColor rounded-md px-3 text-left w-full py-4`}
                value={selectedCountry}
                onChange={(e) => {
                  setSelectedCountry(e.target.value);
                  setsearchCountry(e.target.value);
                  setCountryModal(true);
                }}
                onClick={() => setCountryModal(!countryModal)}
                placeholder="Country"
                style={{ fontFamily: "Silka" }}
              />
              <div className="absolute top-1/2 right-3 transform -translate-y-1/2 flex flex-row ">
                <IoMdClose
                  onClick={() => {
                    setSelectedCountry("");
                  }}
                  className="cursor-pointer"
                />
                <IoIosArrowDown />
              </div>

              {countryModal && (
                <div
                  id="education-drop"
                  className="countryClass absolute z-40 border enable-scrollbar2  border-coalColor right-0 top-full h-[15rem] overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full"
                  style={{
                    fontFamily: "Silka",
                    height: calculateHeightForFeild(),
                  }}
                >
                  <Scrollbars
                    autoHide
                    style={{ width: "100%", height: "100%" }}
                  >
                    {filteredCountries.map((i, index) => (
                      <ul
                        key={index}
                        className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                        ref={countryRef}
                        onClick={() => {
                          setSelectedCountry(i);
                          setCountryModal(false);
                        }}
                      >
                        <li>
                          <a className="block px-5 py-2 text-sm font-medium">
                            {i}
                          </a>
                        </li>
                      </ul>
                    ))}
                    <>
                      {selectedCountry !== "" &&
                        !filteredCountries.some(
                          (item) => item === selectedCountry
                        ) && (
                          <ul
                            className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                            ref={countryRef}
                            onClick={() => {
                              setCountryModal(false);
                            }}
                          >
                            <li className="flex flex-row">
                              <a className="block px-5 py-2 text-sm font-medium">
                                Add "{selectedCountry}"
                              </a>
                              <IoAddCircle className="w-5 h-5 ml-auto my-auto" />
                            </li>
                          </ul>
                        )}
                    </>
                  </Scrollbars>
                </div>
              )}
            </div>
            <div></div>
          </div>
          <hr className="w-full mt-[4rem] bg-[#D3D5D8] border-1" />
        </div>
        <div className="mb-3 mt-6 items-center flex justify-between px-1">
          <div className="flex flex-row w-1/2 gap-5">
            <div
              name="Sign up"
              className="bg-coalColor hover:bg-primaryGreen cursor-pointer align-center hover:text-coalColor sm:w-full py-3 text-center rounded-md text-white border border-coalColor font-medium text-md relative"
              style={{ fontFamily: "Archia Semibold" }}
              onClick={() => setPasswordOpen(!passwordOpen)}
            >
              Change password
            </div>
            <div
              name="Sign up"
              className="bg-coalColor hover:bg-primaryGreen cursor-pointer hover:text-coalColor sm:w-full py-3 text-center rounded-md text-white border border-coalColor font-medium text-md relative"
              style={{ fontFamily: "Archia Semibold" }}
              onClick={() => setEmailOpen(!emailOpen)}
            >
              Change email
            </div>
          </div>
          <button
            type="submit"
            name="Sign up"
            className="bg-primaryGreen hover:bg-coalColor hover:text-white sm:w-full md:w-1/6 h-12 rounded-md text-coalColor border border-coalColor font-medium text-md relative"
            style={{ fontFamily: "Archia Semibold" }}
          >
            {mutateLoadings || updateLoading ? (
              <span className="flex items-center justify-center">
                <Loader type="Oval" color="black" height={20} width={20} />
                <span className="ml-2">Saving...</span>
              </span>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default GeneralInfo;
