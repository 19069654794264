export const dummy_sections = [
  {
    name: "Agile Ways of Working L1",
    experience: "Beginner",
    time: "59s / 10m",
  },
  {
    name: "Process Reengineering L1",
    experience: "Intermediate",
    time: "30s / 10m",
  },
  {
    name: "Robotics Process Automation L1",
    experience: "Advanced",
    time: "6m / 10m",
  },
  {
    name: "Agile Ways of Working L2",
    experience: "Advanced",
    time: "1m / 10m",
  },
  {
    name: "Agile Ways of Working L3",
    experience: "Beginner",
    time: "5m / 10m",
  },
];

export const experience = [
  {
    name: "Entry level",
    value: "Beginner",
  },
  {
    name: "Mid level",
    value: "Intermediate",
  },
  {
    name: "Senior level",
    value: "Advanced",
  },
  {
    name: "All levels",
    value: "all",
  },
];

export const work_arrangements = [
  {
    name: "Remote",
    value: "remote",
  },
  {
    name: "On/Site",
    value: "onsite",
  },
  {
    name: "Hybrid",
    value: "hybrid",
  },
];

export const assessments = [
  {
    name: "All tests",
    title: "",
  },
  {
    name: "Active tests",
    title: "active",
  },
  {
    name: "Draft tests",
    title: "draft",
  },
  {
    name: "Archived tests",
    title: "archived",
  },
];

export const steps = [
  {
    title: "Create New Tests",
    element: "#assessment1",
    intro: (
      <div style={{ fontFamily: "Silka" }}>
        <p>
          To get started click ‘Create New Test' to build your test. From here
          you will also be able to
        </p>
        <ul className="list-disc px-4">
          <li>Name your test</li>
          <li>Document which job role you’re hiring for</li>
          <li>
            Select modules from our module library which best fit your hiring
            needs
          </li>
          <li>
            {" "}
            Click on modules to get an in-depth description and live preview
          </li>
          <li>
            {" "}
            And finally once your test is complete, send the tests to a
            pre-defined list of candidates
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: "Tests",
    element: "#assessment2",
    intro: (
      <div>
        <p>
          Use this dashboard to see every single test status at a glance, for
          example:
        </p>
        <ul className="list-disc px-4">
          <li>
            See the test name and how many candidates each test has been sent to
          </li>
          <li>
            See stats for each test, including how many candidates have
            completed, not completed, or are in progress of a test
          </li>
          <li>
            See when the test was created and the last time there was activity
            on the test
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: "Filter Tests",
    element: "#assessment3",
    intro: (
      <div>
        <p>
          Use these filters to toggle between tests which are Draft, Active and
          Archived
        </p>
        <ul className="list-disc px-4">
          <li>
            <b>Draft:</b> tests which are still under construction and have not
            been sent to candidates
          </li>
          <li>
            <b>Active:</b> tests which have been sent to candidates and are
            awaiting candidates responses
          </li>
          <li>
            <b>Archived:</b> tests which have been archived after all candidates
            responses have been received
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: "Search Tests",
    element: "#assessment4",
    intro: (
      <div>
        <p>
          Utilize the search functionality to quickly find specific tests by
          their names
        </p>
        <p>
          Simply enter the test name in the search bar and the dashboard will
          filter tests matching your criteria
        </p>
      </div>
    ),
  },
];

export const roles = [
  {
    title: "All Roles",
    value: "",
  },
  {
    title: "Owner",
    value: "owner",
  },
  {
    title: "Admin",
    value: "admin",
  },
  {
    title: "Editor",
    value: "recruiter",
  },
  {
    title: "Viewer",
    value: "hiring-manager",
  },
];

export const graph_filters = [
  {
    title: "Your best performing candidate",
  },
  {
    title: "Your candidate pool average",
  },
  {
    title: "Dexta candidate pool average",
  },
  {
    title: "Dexta best performing candidate",
  },
];

export const type_mail = [
  {
    title: "Accept",
    value: "Send offer to candidate",
  },
  {
    title: "Reject",
    value: "Reject candidate",
  },
];
