import React, { useState, useEffect, useLayoutEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, Zoom } from "react-toastify";
import { toast } from "react-toastify";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";
import { resetEmail } from "./hooks/resetEmail";
import MainLogo from "../../Dexta_assets/LoginLogov4.png";
import Password from "../../Dexta_assets/password-image.png";
import CustomButton from "../../Components/CustomButton/CustomButton";
import TextField from "../../Components/Dexta/TextField/TextField";
import { FaArrowRightLong } from "react-icons/fa6";
import { jwtDecode } from "jwt-decode";

const Reset = () => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const queryClient = useQueryClient();
  const [toastSuccess, setToastSuccess] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  useEffect(() => {
    if (parsed.resetToken) {
      const decodedToken = jwtDecode(parsed.resetToken);
      if (decodedToken?.type === "user") {
        navigate(`/candidate/reset-password/?token=${parsed.resetToken}`);
      }
    }
  }, []);
  //#region Setting up new password
  const { mutate, isLoading } = useMutation(resetEmail, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("/auth/forgot-password");
      if (response.message) {
        setToastSuccess("Reset Successful");
      }
      setTimeout(() => {
        navigate("/reset-password-complete");
      }, 1000);
    },
    onError: (error) => {
      setError(true);
      let errorMessage;
      if (Array.isArray(error.response.data.message)) {
        errorMessage = error.response.data.message.join(", ");
      } else {
        errorMessage = error.response.data.message;
      }
      setErrorMessage(errorMessage);
      toast.success(errorMessage, {
        toastId: "copy-success",
        style: { width: "400px" },
      });
    },
  });
  //#endregion

  //#region toast for success
  useEffect(() => {
    if (toastSuccess != "") {
      toast.success(toastSuccess, {
        toastId: "copy-success",
      });
    }
  }, [toastSuccess]);
  //#endregion

  //#region Validation on reset password form
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password1: "",
      password2: "",
    },
    validationSchema: Yup.object({
      password1: Yup.string()
        .required("Password 1 Required")
        .min(8, "Password 1 should be at least 8 characters"),
      password2: Yup.string()
        .required("Password 2 Required")
        .min(8, "Password 2 should be at least 8 characters"),
    }),

    onSubmit: (values) => {
      let data = JSON.stringify({
        password1: values?.password1,
        password2: values?.password2,
        resetToken: parsed.resetToken,
      });
      try {
        mutate(data);
      } catch (error) {
        //onError will automatically detect
      }
    },
  });
  //#endregion

  //#region toggle password visible / invisible
  function togglePasswordVisibility() {
    setIsPasswordVisible((prevState) => !prevState);
  }
  //#endregion

  document.title = "Reset Password | Dexta";

  return (
    <React.Fragment>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="sm:grid-cols-1 lg:grid-cols-2 grid bg-bgAuth h-screen">
        <div className="bg-black sm:hidden lg:block">
          <img
            src={MainLogo}
            className="w-full lg:h-full xl:h-fit lg:object-cover"
          />
        </div>
        <div className="bg-bgAuth flex justify-center flex-col pb-40">
          <div className="sm:w-3/4 lg:w-1/2 mx-auto">
            <h1
              className="text-2xl text-left"
              style={{ fontFamily: "Archia Semibold" }}
            >
              {" "}
              Set new password{" "}
            </h1>
            <div
              style={{ fontFamily: "Silka" }}
              className="text-sm mt-6 flex flex-col"
            >
              <span>Your new password must be different</span>
              <span>to previously used passwords.</span>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              id="login"
            >
              <div className="mt-6">
                <div className="h-[4rem]">
                  <TextField
                    name="password1"
                    id="password1"
                    type={isPasswordVisible ? "text" : "password"}
                    placeholder="Enter Password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password1 || ""}
                    imageUrl={Password}
                    passwordIcon={true}
                    onClick={togglePasswordVisibility}
                    isPasswordVisible={isPasswordVisible}
                  />
                  {validation.touched.password1 &&
                  validation.errors.password1 ? (
                    <div className="ml-1">
                      <p className="text-rose-500 fade-in-text-validations sm:text-xs md:text-sm">
                        {validation.errors.password1}
                      </p>
                    </div>
                  ) : null}
                </div>
                <div
                  style={{ fontFamily: "Silka" }}
                  className="text-xs underline mt-4"
                >
                  Must be at least 8 characters.
                </div>
                <div className="h-[4rem]">
                  <TextField
                    name="password2"
                    id="password2"
                    type={isPasswordVisible ? "text" : "password"}
                    placeholder="Confirm Password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password2 || ""}
                    marginTop="mt-6"
                    onClick={togglePasswordVisibility}
                    imageUrl={Password}
                    passwordIcon={true}
                    isPasswordVisible={isPasswordVisible}
                  />
                  {validation.touched.password2 &&
                  validation.errors.password2 ? (
                    <div className="ml-1">
                      <p className="text-rose-500 fade-in-text-validations sm:text-xs md:text-sm">
                        {validation.errors.password2}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>

              <CustomButton
                label="SET NEW PASSWORD"
                borderCustom="border border-black text-white"
                paddingY="0.7rem"
                marginTop="mt-8"
                bgColor="#252E3A"
                hoverBgColor="#C0FF06"
                hoverTextColor="#252E3A"
                iconR={FaArrowRightLong}
                noMarginIcon={false}
                autoLeftMargin="ml-auto"
                textMarginBotton="ml-auto"
                LoadingBtn={isLoading}
                loadingText="SETTING UP"
              />
            </form>
            <p
              className="underline text-sm my-auto mt-8 cursor-pointer"
              style={{ fontFamily: "Silka" }}
              onClick={() => navigate("/login")}
            >
              Return to login
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Reset;
