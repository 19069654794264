export const roles = [
  {
    title: "Viewer",
    value: "hiring-manager",
    access: "Read only access",
  },
  {
    title: "Editor",
    value: "recruiter",
    access: "Full access rights to test and candidates.",
  },
  {
    title: "Admin",
    value: "admin",
    access: "Control access to editors and viewers.",
  },
];
