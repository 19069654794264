import React from "react"
import withRouter from "../Common/withRouter";
import StudentsNavbar from "../Navbar/StudentsNavbar";


const Layout = (props) => {
    const examscreen = localStorage.getItem("exam")
    return (
        <React.Fragment>
            <div id="preloader">
                <div className="main-content">{props.children}</div>
            </div>
        </React.Fragment >
    );
};

Layout.propTypes = {
};

export default withRouter(Layout);
