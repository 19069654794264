import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    setButton: false,
}

export const UpdateSlice = createSlice({
    name:"buttonsupdate",
    initialState,
    reducers:{
        setButtonToTrue :(state, action)=>{
            state.setButton = true
        },
        setButtonToFalse :(state, action)=>{
            state.setButton = false
        },
    },
})

export const { setButtonToTrue, setButtonToFalse } = UpdateSlice.actions;
export default UpdateSlice.reducer;