import React, { useState } from "react";
import closem from "../../../Dexta_assets/closeModal.png";
import { Currencies, type } from "../../../Components/Modals/data";
import { useNavigate } from "react-router-dom";
import { getAdminPackages } from "../../PublicPages/Hooks/getPackages";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import { FaArrowRightLong } from "react-icons/fa6";

const PlanModal = ({ dropdownOpen, setDropdownOpen }) => {
  const [selecteditem, setSelectedItem] = useState("gbp");
  const [selectedType, setSelectedType] = useState("month");
  const [selectedSign, setSelectedSign] = useState("£");
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  //#region Fetching packages data from api
  const { data, isLoading, error } = useQuery({
    queryKey: ["packages", selecteditem, selectedType],
    queryFn: () => getAdminPackages(selecteditem, selectedType),
  });
  //#endregion
  return (
    <div>
      <div
        className="fixed inset-0 z-10 flex items-center justify-center bg-gray-500 bg-opacity-75"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        onClick={() => setDropdownOpen(false)}
      >
        <div
          className="relative z-20 bg-[#F8F8F8] shadow-xl transform transition-all overflow-x-scroll no-scrollbar sm:w-full md:w-1/2"
          onClick={(e) => e.stopPropagation()} // Prevent click from closing the modal
        >
          <div className="flex justify-end sticky top-0">
            <img
              src={closem}
              className="w-6 h-6 mr-3 mt-3 cursor-pointer"
              onClick={() => setDropdownOpen(false)}
            />
          </div>
          <div className="bg-bodyColor sm:px-2 md:px-4 pb-4 pt-10 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start md:items-center md:justify-center sm:justify-between py-4">
              <div></div>
              <h1
                className="text-2xl font-bold select-text"
                style={{ fontFamily: "Archia Bold" }}
              >
                Pick a plan that works for you (This is for dev and staging
                environment)
              </h1>
            </div>
          </div>
          <div className="flex items-center px-4 w-1/3 mx-auto justify-center">
            <CustomButton
              label="Request a Demo"
              borderCustom="border border-black text-white text-center"
              hoverBgColor="#C0FF06"
              hoverTextColor="#252E3A"
              paddingY="0.7rem"
              bgColor="#252E3A"
              iconR={FaArrowRightLong}
              noMarginIcon={false}
              textSize="sm:text-xs md:text-lg"
              autoLeftMargin="ml-4"
              onClickButton={() => navigate("/request-demo")}
            />
          </div>
          <div className="md:px-4 sm:px-2 py-3 flex sm:w-full md:w-5/6 mt-4 mx-auto flex-col">
            <div className="flex md:flex-row sm:flex-col justify-between w-full">
              <div
                className="flex flex-row items-center border-black py-1 px-2 mt-2 border-2 bg-white rounded-lg font-medium"
                style={{ fontFamily: "Silka" }}
              >
                {Currencies.map((i) => (
                  <button
                    type="button"
                    className={`text-black flex hover:text-white hover:bg-coalColor ${
                      selecteditem === i.value
                        ? "bg-coalColor text-white"
                        : "bg-white text-coalColor"
                    } focus:outline-none font-medium rounded-lg sm:text-xs md:text-sm sm:px-6 md:px-8 sm:py-3 md:py-4 text-center`}
                    onClick={() => {
                      setSelectedItem(i.value);
                      setSelectedSign(i?.sign);
                    }}
                  >
                    {i.title}
                  </button>
                ))}
              </div>
              <div
                className="flex flex-row items-center sm:w-3/5 md:w-auto md:justify-end border-black md:py-0 sm:py-1 sm:pl-4 md:pl-2 px-2 mt-2 border-2 rounded-lg font-medium"
                style={{ fontFamily: "Silka" }}
              >
                {type.map((i) => (
                  <button
                    type="button"
                    className={`text-black flex hover:text-white hover:bg-coalColor ${
                      selectedType === i.value
                        ? "bg-coalColor text-white"
                        : "bg-white text-black"
                    } focus:outline-none font-medium rounded-lg sm:text-xs md:text-sm sm:px-6 md:px-8 sm:py-3 md:py-4 text-center`}
                    onClick={() => setSelectedType(i.value)}
                  >
                    {i.title}
                  </button>
                ))}
              </div>
            </div>
            <div className="mt-5 grid sm:grid-cols-1 md:grid-cols-3 gap-3">
              <>
                {data?.data?.map((i) => (
                  <div
                    className={`border rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] loadingplan ${
                      i?.subscribeBy === null &&
                      "bg-white shadow-[0_3px_10px_rgb(0,0,0,0.2)]"
                    }`}
                  >
                    <div
                      className={`px-5 py-3 rounded-t-lg ${
                        i?.code == "free" && "bg-[#C0FF06]"
                      } ${i?.code == "pro" && "bg-[#FFB500]"} ${
                        i?.code == "Enterprise" && "bg-[#FF5812] text-white"
                      }`}
                    >
                      <div className="flex flex-col ">
                        <h1
                          className="text-2xl font-bold"
                          style={{ fontFamily: "Archia Bold" }}
                        >
                          {i?.name === "Free forever"
                            ? "Explore for free"
                            : i?.name}
                        </h1>
                        <div className="flex flex-row mr-auto">
                          <p
                            className="flex items-center"
                            style={{ fontFamily: "Archia Bold" }}
                          >
                            {selectedType === "year" ? (
                              <span
                                className={`xl:text-xl 2xl:text-2xl  font-bold tracking-tight ${
                                  i?.code == "Enterprise"
                                    ? "text-white"
                                    : "text-coalColor"
                                }`}
                              >
                                {selectedSign !== "SAR" && selectedSign}
                                {i?.prices[0]?.price
                                  ? Intl.NumberFormat("en-US", {
                                      style: "decimal",
                                      minimumFractionDigits: 0,
                                    }).format(
                                      Math.floor(i.prices[0]?.price / 12)
                                    )
                                  : 0}
                                {selectedSign === "SAR" && (
                                  <span className="ml-1">{selectedSign}</span>
                                )}
                              </span>
                            ) : (
                              <span
                                className={`xl:text-xl 2xl:text-2xl  font-bold tracking-tight ${
                                  i?.code == "Enterprise"
                                    ? "text-white"
                                    : "text-coalColor"
                                }`}
                              >
                                {selectedSign !== "SAR" && selectedSign}
                                {i?.prices[0]?.price
                                  ? Intl.NumberFormat("en-US", {
                                      style: "decimal",
                                      minimumFractionDigits: 0,
                                    }).format(Math.floor(i?.prices[0]?.price))
                                  : 0}
                                {selectedSign === "SAR" && (
                                  <span className="ml-1">{selectedSign}</span>
                                )}
                              </span>
                            )}
                          </p>
                          <span
                            className={`xl:text-xs 2xl:text-sm ml-3 my-auto text-coalColor ${
                              i?.code == "Enterprise" && "text-white"
                            }`}
                            style={{ fontFamily: "Silka" }}
                          >
                            {selectedType === "year" ? "upfront" : "per month"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="p-5">
                      <button
                        style={{ fontFamily: "Silka" }}
                        type="button"
                        className={`text-white hover:bg-primaryGreen hover:text-coalColor border border-coalColor w-full text-center py-3 justify-center rounded-md bg-coalColor flex mt-5 `}
                        onClick={() =>
                          navigate(
                            `/register?package_id=${i.id}&currency=${selecteditem}&interval=${selectedType}`
                          )
                        }
                      >
                        {i?.code == "free" ? "Explore for free" : "Purchase"}
                      </button>
                      <hr className="w-full mt-5 bg-gray-500 border-1" />
                    </div>
                  </div>
                ))}
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanModal;
