import React, { useState } from "react";
import Cropper from "react-easy-crop";
import { Box, Modal, Slider, Button } from "@mui/material";

const modalStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

function DpCropper({
  profileImage,
  onCropDoneForDp,
  onCropCancelForDP,
  profileModalOpen,
  setProfileModalOpen,
  setProfileAllow,
}) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(1);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onAspectRatioChange = (event) => {
    setAspectRatio(event.target.value);
  };

  return (
    <>
      {profileModalOpen ? (
        <Modal sx={modalStyle} open={profileModalOpen}>
          <div className={`cropper2 shadow-xl`}>
            <div>
              <Cropper
                image={profileImage}
                aspect={aspectRatio}
                crop={crop}
                zoom={zoom}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                cropShape="round"
                onCropComplete={onCropComplete}
                style={{
                  containerStyle: {
                    width: "100%",
                    height: "80%",
                    background: "gray",
                  },
                }}
              />
            </div>
            <div className="flex flex-col">
              <div className="controls">
                <input
                  type="range"
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e) => {
                    setZoom(e.target.value);
                  }}
                  className="zoom-range sm:w-[300px] md:w-[600px] sm:mt-12 md:mt-8"
                />
              </div>
              <div className="action-btns gap-5 flex flex-row">
                <button
                  className="bg-coalColor hover:bg-primaryGreen hover:text-coalColor border border-coalColor w-[7rem] text-white py-2 rounded-md"
                  style={{ fontFamily: "Silka" }}
                  onClick={() => setProfileModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-primaryGreen hover:bg-coalColor hover:text-white border font-bold border-coalColor w-[7rem] text-coalColor py-2 rounded-md"
                  style={{ fontFamily: "Silka" }}
                  onClick={() => {
                    setProfileAllow(true);
                    onCropDoneForDp(croppedArea);
                    setProfileModalOpen(false);
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
}

export default DpCropper;
