import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    setDone: false,
}

export const ExamDoneSlice = createSlice({
    name:"examdone",
    initialState,
    reducers:{
        setExamtoTrue :(state, action)=>{
            state.setDone = true
        },
        setExamToFalse :(state, action)=>{
            state.setDone = false
        },
    },
})

export const { setExamtoTrue, setExamToFalse } = ExamDoneSlice.actions;
export default ExamDoneSlice.reducer;