import React from "react";
import King from "../../Assets/preee.png";

const DropdownList = ({ onClick, title, user_package }) => {
  console.log(title, "a");
  return (
    <li
      className="flex justify-between items-center text-sm w-full text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer px-4 py-2"
      onClick={onClick}
    >
      <span className="font-medium">{title}</span>
      {user_package !== "Enterprise" &&
        (title === "Dexta candidate pool average" ||
          title === "Dexta best performing candidate") && (
          <img
            src={King}
            alt="Premium Icon"
            className="w-4 h-4 ml-2 justify-end"
          />
        )}
    </li>
  );
};

export default DropdownList;
