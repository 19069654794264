import React, { useEffect, useState } from "react";
import Right from "../../../Assets/rightarrow.png";
import { useNavigate, useLocation } from "react-router-dom";
import CookieConsent, { Cookies } from "react-cookie-consent";
import queryString from "query-string";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import "./student.css";
import { startEvaluation } from "../hooks/startEvaluation";
import * as moment from "moment";
import Loader from "react-loader-spinner";
import { GoArrowRight } from "react-icons/go";

const Information = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const parsed = queryString.parse(location.search);
  const currentTimeUnix = moment().unix();

  const section = {
    CurrentModule: 0,
    ModuleID: 0,
    time: 0,
    evaluation: 0,
    pageQuestion: 1,
    module_Name: "",
    isPractice: false,
  };
  const practice_section = {
    CurrentModule: 0,
    ModuleID: 0,
    time: 0,
    evaluation: 0,
    pageQuestion: 1,
    module_Name: "",
    isPractice: true,
  };
  const time = { timeLeft: 0, seconds: 0, time_consumed: 0 };
  const practice_time = { timeLeft: 0, seconds: 0 };
  const module_data = { current: 0, last: 0, lastModule: false };
  const practice_module_data = { current: 0, last: 0, lastModule: false };
  const questions = { current: 0, total: 0, solved: 0 };
  const practice_questions = { current: 0, total: 0, solved: 0 };
  const min_sec = { min: 0, sec: 0, secleft: 0, secBar: 0 };
  const practice_min_sec = { min: 0, sec: 0, secleft: 0 };
  const prevQuestion = [];
  const module_info = JSON?.parse(localStorage.getItem("module_info"));
  const theme = JSON?.parse(localStorage.getItem("theme"));
  const question_ID = 0;

  const { mutate, isLoading } = useMutation(startEvaluation, {
    onSuccess: (response) => {
      section.evaluation = response?.evaluation?.id;
      practice_section.evaluation = response?.evaluation?.id;
      queryClient.invalidateQueries("evaluation");
      localStorage.setItem("Current Module", JSON.stringify(section));
      localStorage.setItem("time", JSON.stringify(time));
      localStorage.setItem("practice_time", JSON.stringify(practice_time));
      localStorage.setItem("module", JSON.stringify(module_data));
      localStorage.setItem("questions", JSON.stringify(questions));
      localStorage.setItem("minsec", JSON.stringify(min_sec));
      localStorage.setItem(
        "practice_min_sec",
        JSON.stringify(practice_min_sec)
      );
      localStorage.setItem("prevQuestion", JSON.stringify(prevQuestion));
      localStorage.setItem("question_ID", JSON.stringify(question_ID));
      localStorage.setItem(
        "practice_section",
        JSON.stringify(practice_section)
      );
      localStorage.setItem(
        "practice_module_data",
        JSON.stringify(practice_module_data)
      );
      localStorage.setItem(
        "practice_questions",
        JSON.stringify(practice_questions)
      );
      navigate("/setup");
    },
    onError: () => {
      //error
    },
  });

  const handleStarted = () => {
    let data = JSON.stringify({
      user: parseInt(localStorage.getItem("CP-CANDIDATE-ID")),
      assessment: parseInt(localStorage.getItem("CANDIDATE-ASSESSMENT-ID")),
      start: currentTimeUnix,
      status: "invited",
    });
    try {
      mutate(data);
    } catch (err) {
      //catching error
    }
  };

  //#region showing navbar
  useEffect(() => {
    localStorage.removeItem("exam");
  }, []);
  //#endregion

  const [isHovered, setIsHovered] = useState(false);

  function darkenHexColor(hex, percent) {
    // Ensure the hex code is in the correct format
    hex = hex?.replace(/^#/, "");

    // Convert 3-digit hex to 6-digit hex
    if (hex?.length === 3) {
      hex = hex
        .split("")
        .map((char) => char + char)
        .join("");
    }

    // Convert hex to RGB
    let r = parseInt(hex?.substring(0, 2), 16);
    let g = parseInt(hex?.substring(2, 4), 16);
    let b = parseInt(hex?.substring(4, 6), 16);

    // Calculate the darkened color
    r = Math.floor(r * (1 - percent / 100));
    g = Math.floor(g * (1 - percent / 100));
    b = Math.floor(b * (1 - percent / 100));

    // Convert RGB back to hex
    const darkenedHex = `#${r.toString(16).padStart(2, "0")}${g
      .toString(16)
      .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

    return darkenedHex;
  }

  // Example usage:
  const originalColor = theme.color;
  const darkenedColor = darkenHexColor(originalColor, 15);

  const buttonStyle = {
    background: isHovered ? darkenedColor : theme.color,
    transition: "background-color 0.3s, transform 0.6s",
    transform: isHovered ? "scale(1.02)" : "scale(1)",
    fontFamily: "Archia Semibold",
    color: theme.sec_color,
    border: `1px solid ${theme.sec_color}`,
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  document.title = "Information | Dexta";

  return (
    <>
      <nav
        className={`xl:static 2xl:fixed w-full z-20 top-0 left-0 py-2 bg-white`}
      >
        <div className="w-3/4 px-2 items-center py-2 text-xl text-white mx-auto">
          <div className="grid grid-cols-3 container mx-auto">
            <img
              src={theme.logo}
              className=" object-contain"
              style={{ height: "70px" }}
            />
          </div>
        </div>
      </nav>
      <div className="bg-bodyColor">
        <div className="mx-auto lg:container sm:max-md:mt-10 mt-10 2xl:mt-0">
          <div className="flex justify-center h-screen">
            <div className="m-auto w-5/6 rounded-md bg-white">
              <div className="rounded-lg">
                <div className="lg:p-8">
                  <div className="grid lg:grid-cols-2">
                    <div className="pt-10 pb-10 lg:pl-12 col-span-1 sm:px-4 lg:px-[80px]">
                      <h1
                        className="text-xl"
                        style={{ fontFamily: "Archia Semibold" }}
                      >
                        Hello{" "}
                        <b style={{ fontFamily: "Archia Bold" }}>
                          {parsed.firstName} {parsed.lastName}
                        </b>
                      </h1>
                      <p
                        className="mt-5 text-[#767676]"
                        style={{ fontFamily: "Silka" }}
                      >
                        Thanks you for applying to{" "}
                        <b
                          style={{ fontFamily: "Silka Bold" }}
                          className="text-black"
                        >
                          {parsed.Company}
                        </b>{" "}
                        and welcome to our test.{" "}
                      </p>
                      <p
                        className="mt-2 text-[#767676]"
                        style={{ fontFamily: "Silka" }}
                      >
                        Completing it will give you a chance to show off your
                        skills and stand out from the crowd!
                      </p>
                      <p
                        className="mt-5 text-black"
                        style={{ fontFamily: "Silka" }}
                      >
                        Good luck!
                      </p>
                      <button
                        className="inline-flex items-center xl:w-1/2 2xl:w-1/3 justify-center px-4 mt-10 py-4 hover:text-white text-white text-sm font-medium rounded-md"
                        style={buttonStyle}
                        onMouseEnter={handleHover}
                        onMouseLeave={handleLeave}
                        onClick={() => {
                          handleStarted();
                        }}
                      >
                        {isLoading ? (
                          <span className="flex items-center justify-center">
                            <Loader
                              type="Oval"
                              color={theme.sec_color}
                              height={20}
                              width={20}
                            />
                            <span className="ml-2">Starting...</span>
                          </span>
                        ) : (
                          "Get Started"
                        )}
                        <GoArrowRight
                          alt="Add Transaction Icon"
                          className="w-5 h-5 ml-2 icon-image"
                        />
                      </button>
                    </div>
                    <div
                      className="pt-10 pb-10 pl-2 sm:px-4 lg:px-[50px]"
                      style={{ fontFamily: "Silka" }}
                    >
                      <p className="font-bold text-lg">
                        A few things before you start:
                      </p>
                      <ul className="list-disc pl-5 pt-2 text-[#767676]">
                        <li className="mt-2">
                          This test consists of{" "}
                          <b className="text-black">
                            {module_info.tests}{" "}
                            {module_info.tests === 1 ? "module" : "modules"}
                          </b>
                          . It will take approximately{" "}
                          <b className="text-black">
                            {module_info.time} minutes
                          </b>{" "}
                          to complete
                        </li>
                        <li className="mt-2">
                          The test is timed. A timer is shown as per module or
                          question
                        </li>
                        <li className="mt-2">
                          You are free to use a{" "}
                          <b className="text-black">
                            calculator, pen and paper
                          </b>
                          . Note this may only be required for some modules and
                          not all
                        </li>
                        <li className="mt-2">
                          Each test module will have a set of preview questions
                          which are shown before the official test. The preview
                          questions will give you a flavour of what the official
                          test is like
                        </li>
                        <li className="mt-2">
                          The official module which you will be assessed against
                          will start after the preview questions
                        </li>
                        <li className="mt-2">
                          Each test module will have its own prescribed time
                          limit. Be sure to monitor the timer as you are
                          completing the test
                        </li>
                        <li className="mt-2">
                          Please allow the use of your camera/webcam and do not
                          leave full-screen mode. Snapshots will be taken of you
                          periodically during the test. These measures are taken
                          to ensure fairness for everyone
                        </li>
                        <li className="mt-2">
                          Turn on your speakers or headphone (to play audio)
                        </li>
                        <li className="mt-2">
                          We recommend completing the test in one go
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Information;
