import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    accountType: "",
};

export const AccountTypeSlice = createSlice({
    name: "accountTypeDetails",
    initialState,
    reducers: {
        setAccountTypeofUser: (state, action) => {
            state.accountType = action.payload;
        },
    },
});

export const { setAccountTypeofUser } =
    AccountTypeSlice.actions;
export default AccountTypeSlice.reducer;
