export const highestEducation = [
  {
    title: "Primary Education",
  },
  {
    title: "Secondary Education (High School)",
  },
  {
    title: "Vocational/Technical Training",
  },
  {
    title: "College / University (No Degree)",
  },
  {
    title: "Associate Degree",
  },
  {
    title: "Bachelor's Degree",
  },
  {
    title: "Master's Degree",
  },
  {
    title: "Professional Degree (e.g., MD, JD)",
  },
  {
    title: "Doctorate (PhD)",
  },
  {
    title: "Other (Please specify)",
  },
];

export const gender = [
  {
    title: "Male",
  },
  {
    title: "Female",
  },
  {
    title: "Non-binary",
  },
  {
    title: "Prefer not to say",
  },
  {
    title: "Other (Please specify)",
  },
];

export const candidate_statuses = [
  {
    title: "Employed Full-Time",
  },
  {
    title: "Employed Part-Time",
  },
  {
    title: "Self-Employed",
  },
  {
    title: "Unemployed",
  },
  {
    title: "Student",
  },
  {
    title: "Internship",
  },
  {
    title: "Freelancer/Contractor",
  },
  {
    title: "Retired",
  },
  {
    title: "Homemaker",
  },
  {
    title: "Prefer not to say",
  },
  {
    title: "Other (Please specify)",
  },
];

export const ethnicity = [
  {
    title: "Arab",
  },
  {
    title: "Asian",
  },
  {
    title: "Black, Caribbean, or African",
  },
  {
    title: "Hispanic or Latin American",
  },
  {
    title: "Mixed or multiple ethnic groups",
  },
  {
    title: "Pacific Islander",
  },
  {
    title: "Other ethnicity",
  },
  {
    title: "White",
  },
  {
    title: "Prefer not to answer",
  },
];

export const language = [
  {
    title: "English",
  },
  {
    title: "Danish",
  },
  {
    title: "Dutch",
  },
  {
    title: "French",
  },
  {
    title: "Hindi",
  },
  {
    title: "Arabic",
  },
  {
    title: "Cantonese",
  },
  {
    title: "Mandarin",
  },
  {
    title: "German",
  },
  {
    title: "Italian",
  },
  {
    title: "Norwegian",
  },
  {
    title: "Polish",
  },
  {
    title: "Portuguese (Brazil)",
  },
  {
    title: "Spanish",
  },
  {
    title: "Swedish",
  },
  {
    title: "Other",
  },
  {
    title: "Prefer not to answer",
  },
];

export const experience_years = [
  {
    title: "1 year",
  },
  {
    title: "2 years",
  },
  {
    title: "3 years",
  },
  {
    title: "4 years",
  },
  {
    title: "5 years",
  },
  {
    title: "6 years",
  },
  {
    title: "7 years",
  },
  {
    title: "8 years",
  },
  {
    title: "9 years",
  },
  {
    title: "10 years",
  },
  {
    title: "11 years",
  },
  {
    title: "12 years",
  },
  {
    title: "13 years",
  },
  {
    title: "14 years",
  },
  {
    title: "15 years",
  },
  {
    title: "16 years",
  },
  {
    title: "17 years",
  },
  {
    title: "18 years",
  },
  {
    title: "19 years",
  },
  {
    title: "20 years",
  },
  {
    title: "21 years",
  },
  {
    title: "22 years",
  },
  {
    title: "23 years",
  },
  {
    title: "24 years",
  },
  {
    title: "25 years",
  },
  {
    title: "26 years",
  },
  {
    title: "27 years",
  },
  {
    title: "28 years",
  },
  {
    title: "29 years",
  },
  {
    title: "30 years",
  },
];

export const hiringPlan = [
  {
    value: "1-10",
  },
  {
    value: "10-30",
  },
  {
    value: "30-50",
  },
  {
    value: "50-100",
  },
  {
    value: "100+",
  },
];

export const TimeSlots = [
  {
    slot: "1-5",
  },
  {
    slot: "6-10",
  },
  {
    slot: "11-20",
  },
  {
    slot: "21-250",
  },
  {
    slot: "251-1000",
  },
  {
    slot: "1000+",
  },
];

export const workForce = [
  {
    slot: "1-50",
  },
  {
    slot: "51-150",
  },
  {
    slot: "151-300",
  },
  {
    slot: "301-500",
  },
  {
    slot: "501-750",
  },
  {
    slot: "750-1000",
  },
  {
    slot: "1001-1500",
  },
  {
    slot: "1500+",
  },
];
