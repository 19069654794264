import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  setReveal: false,
};

export const RevealSlice = createSlice({
  name: "reveal",
  initialState,
  reducers: {
    setRevealToTrue: (state, action) => {
      state.setReveal = true;
    },
    setRevealToFalse: (state, action) => {
      state.setReveal = false;
    },
  },
});

export const { setRevealToTrue, setRevealToFalse } = RevealSlice.actions;
export default RevealSlice.reducer;
