import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    setinvite: "",
};

export const InviteSlice = createSlice({
    name: "invite",
    initialState,
    reducers: {
        setInviteText: (state, action) => {
            state.setinvite = action.payload;
        },
    },
});

export const { setInviteText } =
    InviteSlice.actions;
export default InviteSlice.reducer;
