import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import baas from "../../../Assets/baas.png";
import Webcam from "react-webcam";
import { FaCamera } from "react-icons/fa";
import "./setup.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { GoArrowRight } from "react-icons/go";
import close from "../../../Dexta_assets/closeModal.png";
import { useQuery } from "@tanstack/react-query";
import { getEvaluation } from "../hooks/getEvaluation";
import { useDispatch } from "react-redux";
import { setResumeToTrue } from "../../../redux/reducers/ResumeTest/ResumeSlice";
import { setQuestionNumberResume } from "../../../redux/reducers/ResumedQuestion/ResumeQuestionNumberSlice";

const Setup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const videoRef = useRef({});
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const webcamRef = useRef(null);
  const [camblocked, setcamblocked] = useState(false);
  const theme = JSON?.parse(localStorage.getItem("theme"));
  const [fakeLoad, setFakeLoad] = useState(true);
  const [camaccess, setcamaccess] = useState(false);
  const [deniedModal, setDeniedModal] = useState(false);
  const [denied, setDenied] = useState(false);
  const currentSection = JSON?.parse(localStorage.getItem("Current Module"));
  const [evaluationData, setEvaluationData] = useState(null);
  //#region Fetch solved questions / modules data
  const { data, error, isLoading } = useQuery(
    ["evaluation", currentSection?.evaluation],
    () => getEvaluation(currentSection?.evaluation)
  );
  //#endregion

  console.log(camaccess, "access cam");

  //#region camera access settings
  const getUserMedia = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: false,
      });
      const videoTrack = stream.getVideoTracks()[0];
      videoTrack.onended = () => {
        setIsCameraOn(false);
      };

      videoRef.current.srcObject = stream;
      setcamaccess(true);
      setIsCameraOn(true);
      checkCameraPermission();
    } catch (err) {}
  };
  useEffect(() => {
    getUserMedia();
  }, [videoRef]);

  const checkCameraPermission = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({
        name: "camera",
      });

      if (permissionStatus.state === "denied") {
        console.log("Denied");
        setDenied(true);
      } else if (permissionStatus.state === "prompt") {
        // Handle prompt state if necessary
      } else if (permissionStatus.state === "granted") {
        setcamaccess(true);
        console.log("Granted");
      }
    } catch (error) {
      console.error("Error checking camera permission:", error);
    }
  };

  useEffect(() => {
    checkCameraPermission();
  }, [camaccess]);
  //#endregion

  //#region Fake load for better user experience
  useEffect(() => {
    setTimeout(() => {
      setFakeLoad(false);
    }, 2000);
  }, [fakeLoad]);
  //#endregion

  //#region handle full screen
  const enterFullscreen = () => {
    const elementToFullscreen = document.documentElement;
    if (elementToFullscreen.requestFullscreen) {
      elementToFullscreen.requestFullscreen();
    } else if (elementToFullscreen.mozRequestFullScreen) {
      elementToFullscreen.mozRequestFullScreen();
    } else if (elementToFullscreen.webkitRequestFullscreen) {
      elementToFullscreen.webkitRequestFullscreen();
    } else if (elementToFullscreen.msRequestFullscreen) {
      elementToFullscreen.msRequestFullscreen();
    }
  };
  useEffect(() => {
    function onFullscreenChange() {
      setIsFullscreen(Boolean(document.fullscreenElement));
      if (document.fullscreenElement) {
        console.log("Entered full-screen mode");
      } else {
        console.log("Exited full-screen mode");
      }
    }
    document.addEventListener("fullscreenchange", onFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", onFullscreenChange);
    };
  }, []);

  // Function to request full-screen mode
  const handleFullscreenClick = () => {
    enterFullscreen();
  };
  //#endregion

  //#region showing navbar
  useEffect(() => {
    localStorage.removeItem("exam");
  }, []);
  //#endregion

  //#region if user continuing without giving access or not
  const checkContinue = () => {
    localStorage.setItem("test_completed", "");
    localStorage.setItem("user_exists", "");
    handleFullscreenClick();
    if (data?.sessionInfo !== null) {
      UpdateUserQuestionModulesData();
    } else {
      const section = {
        CurrentModule: 0,
        ModuleID: 0,
        time: 0,
        evaluation: parseInt(currentSection?.evaluation),
        pageQuestion: 1,
        module_Name: "",
        isPractice: false,
      };

      const practice_section = {
        CurrentModule: 0,
        ModuleID: 0,
        time: 0,
        evaluation: parseInt(currentSection?.evaluation),
        pageQuestion: 1,
        module_Name: "",
        isPractice: true,
      };

      const time = { timeLeft: 0, seconds: 0, time_consumed: 0 };
      const practice_time = { timeLeft: 0, seconds: 0 };
      const module_data = { current: 0, last: 0, lastModule: false };
      const practice_module_data = { current: 0, last: 0, lastModule: false };
      const questions = { current: 0, total: 0, solved: 0 };
      const practice_questions = { current: 0, total: 0, solved: 0 };
      const min_sec = { min: 0, sec: 0, secleft: 0, secBar: 0 };
      const practice_min_sec = { min: 0, sec: 0, secleft: 0 };
      const prevQuestion = [];
      const question_ID = 0;

      // Saving to localStorage
      localStorage.setItem("Current Module", JSON.stringify(section));
      localStorage.setItem("time", JSON.stringify(time));
      localStorage.setItem("practice_time", JSON.stringify(practice_time));
      localStorage.setItem("module", JSON.stringify(module_data));
      localStorage.setItem("questions", JSON.stringify(questions));
      localStorage.setItem("minsec", JSON.stringify(min_sec));
      localStorage.setItem(
        "practice_min_sec",
        JSON.stringify(practice_min_sec)
      );
      localStorage.setItem("prevQuestion", JSON.stringify(prevQuestion));
      localStorage.setItem("question_ID", JSON.stringify(question_ID));
      localStorage.setItem(
        "practice_section",
        JSON.stringify(practice_section)
      );
      localStorage.setItem(
        "practice_module_data",
        JSON.stringify(practice_module_data)
      );
      localStorage.setItem(
        "practice_questions",
        JSON.stringify(practice_questions)
      );
      dispatch(setQuestionNumberResume(0));
      navigate("/practice-ready");
    }
  };
  //#endregion

  //#region Updating question and modules data
  const settingDataToStorage = () => {
    return new Promise((resolve) => {
      const section = {
        CurrentModule: evaluationData?.currentModuleNumber,
        ModuleID: evaluationData?.sectionId,
        evaluation: evaluationData?.evaluation,
        pageQuestion: evaluationData?.QuestionPage,
        isPractice: false,
        time: evaluationData?.totalModuleTime,
      };
      const practice_section = {
        CurrentModule: evaluationData?.PracticeSectionModule,
        ModuleID: evaluationData?.PracticeModuleID,
        time: evaluationData?.PracticeTime,
        evaluation: evaluationData?.evaluation,
        pageQuestion: evaluationData?.PracticePage,
        isPractice: true,
      };
      const time = {
        timeLeft: evaluationData?.timeWidth,
        seconds: evaluationData?.CompletionTime,
        time_consumed: evaluationData?.time_consumed,
      };
      const practice_time = {
        timeLeft: 0,
        seconds: 0,
      };
      const module_data = {
        current: evaluationData?.currentModule,
        last: evaluationData?.TotalModules,
        lastModule: evaluationData?.lastModule,
      };
      const min_sec = {
        min: 0,
        sec: 0,
        secleft: evaluationData?.seconds_left,
        secBar: evaluationData?.total_seconds,
      };
      const practice_min_sec = { min: 0, sec: 0, secleft: 5 * 60 };
      const practice_module_data = { current: 0, last: 0, lastModule: false };
      const practice_questions = { current: 0, total: 0, solved: 0 };

      localStorage.setItem("Current Module", JSON.stringify(section));
      localStorage.setItem("practice_time", JSON.stringify(practice_time));
      localStorage.setItem("time", JSON.stringify(time));
      localStorage.setItem("minsec", JSON.stringify(min_sec));
      localStorage.setItem("module", JSON.stringify(module_data));
      localStorage.setItem(
        "practice_section",
        JSON.stringify(practice_section)
      );
      localStorage.setItem(
        "practice_module_data",
        JSON.stringify(practice_module_data)
      );
      localStorage.setItem(
        "practice_min_sec",
        JSON.stringify(practice_min_sec)
      );
      localStorage.setItem(
        "practice_questions",
        JSON.stringify(practice_questions)
      );
      resolve();
    });
  };

  const UpdateUserQuestionModulesData = async () => {
    await settingDataToStorage();
    dispatch(setResumeToTrue(true));
    dispatch(setQuestionNumberResume(0));
    navigate("/exam");
  };
  //#endregion

  //#region Button styling
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  function darkenHexColor(hex, percent) {
    // Ensure the hex code is in the correct format
    hex = hex?.replace(/^#/, "");

    // Convert 3-digit hex to 6-digit hex
    if (hex?.length === 3) {
      hex = hex
        .split("")
        .map((char) => char + char)
        .join("");
    }

    // Convert hex to RGB
    let r = parseInt(hex?.substring(0, 2), 16);
    let g = parseInt(hex?.substring(2, 4), 16);
    let b = parseInt(hex?.substring(4, 6), 16);

    // Calculate the darkened color
    r = Math.floor(r * (1 - percent / 100));
    g = Math.floor(g * (1 - percent / 100));
    b = Math.floor(b * (1 - percent / 100));

    // Convert RGB back to hex
    const darkenedHex = `#${r.toString(16).padStart(2, "0")}${g
      .toString(16)
      .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

    return darkenedHex;
  }

  // Example usage:
  const originalColor = theme.color;
  const darkenedColor = darkenHexColor(originalColor, 15);

  const buttonStyle = {
    background: isHovered ? darkenedColor : theme.color,
    transition: "background-color 0.3s, transform 0.6s",
    transform: isHovered ? "scale(1.03)" : "scale(1)",
    fontFamily: "Archia Semibold",
    color: theme.sec_color,
    border: `1px solid ${theme.sec_color}`,
  };

  const buttonStyle3 = {
    background: isHovered ? `rgba(${theme.sec_color}, 1.9)` : theme.sec_color,
    transition: "background-color 0.3s, transform 0.6s",
    transform: isHovered ? "scale(1.03)" : "scale(1)",
    fontFamily: "Archia Semibold",
    color: theme.color,
    border: `1px solid ${theme.color}`,
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  const handleHover2 = () => {
    setIsHovered2(true);
  };

  const handleLeave2 = () => {
    setIsHovered2(false);
  };

  const buttonStyle2 = {
    background: isHovered2 ? darkenedColor : theme.color,
    transition: "background-color 0.3s, transform 0.6s",
    transform: isHovered2 ? "scale(1.03)" : "scale(1)",
    fontFamily: "Archia Semibold",
    color: theme.sec_color,
    border: `1px solid ${theme.sec_color}`,
  };
  //#endregion

  //#region evaluation data

  useEffect(() => {
    if (!isLoading && data?.sessionInfo !== null) {
      setEvaluationData(JSON.parse(data?.sessionInfo));
    }
  }, [data]);

  //#endregion
  document.title = "Setup | Dexta";
  return (
    <>
      <nav className={`w-full z-20 top-0 left-0 py-2 bg-white`}>
        <div className="w-3/4 px-2 items-center py-2 text-xl text-white mx-auto">
          <div className="grid grid-cols-3 container mx-auto">
            <img
              src={theme.logo}
              className=" object-contain"
              style={{ height: "70px" }}
            />
          </div>
        </div>
      </nav>
      <div className="bg-bodyColor">
        <div className="lg:container mt-8 pb-10">
          <div className="flex justify-center sm:max-md:px-8">
            <div className="lg:w-3/4 rounded-md bg-white">
              <div className="rounded-lg">
                <div className="lg:p-8 sm:max-md:pb-5">
                  <div className="grid lg:grid-cols-2">
                    <div className="pt-10 lg:pl-12 sm:max-md:px-4">
                      <h1
                        className="text-xl font-bold"
                        style={{ fontFamily: "Archia Bold" }}
                      >
                        Camera setup
                      </h1>
                      <hr className="lg:w-3/4 my-3 mt-8 border-[1px] border-coalColor" />
                      <div className="lg:w-3/4">
                        <p
                          className="mt-5 text-[#767676]"
                          style={{ fontFamily: "Silka" }}
                        >
                          We use camera images to ensure fairness for everyone.
                          Make sure that you are in front of your camera.
                        </p>
                      </div>
                      <div className="flex justify-between mt-5 w-1/2">
                        <div className="flex flex-row">
                          <FaCamera className="my-auto text-coalColor" />
                          <p
                            className="ml-2 my-auto text-sm"
                            style={{ fontFamily: "Silka Light" }}
                          >
                            Camera
                          </p>
                        </div>
                      </div>
                      <button
                        className={`inline-flex items-center sm:w-full lg:w-1/3 justify-center px-4 mt-10 py-4  hover:text-white text-white text-sm font-medium rounded-md`}
                        style={!camaccess ? buttonStyle2 : buttonStyle}
                        onMouseEnter={handleHover}
                        onMouseLeave={handleLeave}
                        onClick={() => {
                          if (denied || !camaccess) {
                            setDeniedModal(true);
                          } else {
                            checkContinue();
                          }
                        }}
                      >
                        Continue
                        <GoArrowRight
                          alt="Add Transaction Icon"
                          className="w-5 h-5 ml-2 icon-image"
                        />
                      </button>
                    </div>

                    <div className="lg:pr-5 sm:max-md:px-4">
                      <div className="pt-5 lg:pl-2 w-full ml-auto">
                        <>
                          {fakeLoad ? (
                            <div className="containerz">
                              <svg viewBox="25 25 50 50" className="svgs">
                                <circle
                                  r="20"
                                  cy="50"
                                  cx="50"
                                  className="ml-auto circles"
                                  style={{ stroke: "black" }}
                                ></circle>
                              </svg>
                            </div>
                          ) : (
                            <TransitionGroup>
                              <CSSTransition
                                key={fakeLoad}
                                timeout={450}
                                className="settingFade"
                              >
                                {isCameraOn ? (
                                  <div>
                                    <Webcam
                                      audio={false}
                                      ref={webcamRef}
                                      screenshotFormat="image/jpeg"
                                      className="rounded-lg"
                                    />
                                  </div>
                                ) : (
                                  <>
                                    <div className="p-5 bg-[#DBD9C0] shadow-xl rounded-lg">
                                      <div className="flex flex-col">
                                        <img
                                          src={baas}
                                          className="w-8 h-8 mx-auto"
                                        />
                                        <p
                                          className="text-[#767676] mt-4 text-sm"
                                          style={{ fontFamily: "Silka" }}
                                        >
                                          It seems you don’t have a camera
                                          connected to your computer or your
                                          camera is blocked. To enable the
                                          camera, click on the camera blocked
                                          icon in your browser’s address bar and
                                          reload the page. If you don’t enable a
                                          camera, you can still take the test,
                                          but then {theme.name} cannot verify
                                          fair play.
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </CSSTransition>
                            </TransitionGroup>
                          )}
                          <div
                            className="p-5 mt-3 shadow-xl rounded-lg bg-[#DBD9C0]"
                            style={{ fontFamily: "Silka" }}
                          >
                            <h1 className="font-bold">
                              Trouble with your webcam?
                            </h1>
                            <div className="text-[#767676] text-sm">
                              <p className="mt-2">
                                Ensure you have granted permission for your
                                browser to access your camera.
                              </p>
                              <p className="mt-2">
                                Ensure you are using a supported browser.
                              </p>
                              <p className="mt-2">
                                If you have multiple Camera devices, ensure you
                                have give your browser and our website
                                permission to use the right device.{" "}
                              </p>
                              <p className="mt-2">
                                Try launching the test in incognito mode or in a
                                private window.
                              </p>
                              <p className="mt-2">
                                Ensure your camera drivers and web browser are
                                up to date.
                              </p>
                              <p className="mt-2">
                                Restart your device and try accessing the test
                                again using the link in the invitation email.
                              </p>
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {deniedModal && (
        <div
          id="crypto-modal"
          tabindex="-1"
          aria-hidden="true"
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60"
          style={{ zIndex: 999 }}
        >
          <div class="relative p-4 w-full max-w-xl max-h-full">
            <div class="relative bg-white rounded-lg shadow ">
              <button
                type="button"
                class="absolute top-3 end-2.5 text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center  hover:bg-gray-200"
                data-modal-hide="popup-modal"
                onClick={() => setDeniedModal(false)}
              >
                <img src={close} className="w-5 h-5" />
                <span class="sr-only">Close modal</span>
              </button>
              <div class="px-6 py-4 pl-2 text-center">
                <h3
                  class="mb-5 mt-3 text-xl font-bold text-coalColor"
                  style={{ fontFamily: "Archia Bold" }}
                >
                  Are you sure you want to continue?
                </h3>
                <p
                  className="px-2 mt-6 text-coalColor text-sm"
                  style={{ fontFamily: "Silka" }}
                >
                  You can still take the test if your camera is switched off.{" "}
                  <span className="">
                    However, switching your camera off will not allow{" "}
                    <b className="italic">{theme.name}</b>
                  </span>{" "}
                  to verify that you have taken the test fairly.
                </p>
                <div
                  className="flex flex-row justify-end mt-8 gap-3"
                  style={{ fontFamily: "Archia Semibold" }}
                >
                  <button
                    className="inline-flex items-center justify-center px-4 py-2.5 my-auto text-white border border-black text-sm font-medium rounded-lg bg-coalColor"
                    onClick={() => setDeniedModal(false)}
                    style={buttonStyle3}
                  >
                    Cancel
                  </button>
                  <button
                    className={`inline-flex items-center justify-center  px-4 py-2.5 my-auto text-white text-base font-medium rounded-lg border border-${theme.sec_color}`}
                    style={buttonStyle2}
                    onMouseEnter={handleHover2}
                    onMouseLeave={handleLeave2}
                    onClick={checkContinue}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Setup;
