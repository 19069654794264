import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_Server,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("CP-CANDIDATE-TOKEN");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
      // config.headers["x-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
var count = 0;

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/auth/sign-in" && err?.response) {
      // Access Token was expired
      if (err?.response?.status === 401 && !originalConfig?._retry) {
        localStorage.clear();
        window.location = "/candidate/login";
        originalConfig._retry = true;
        if (count < 2) {
          count = count + 1;
          try {
            const rs = await instance.post("/auth/refresh-access");

            if (rs.data) {
              localStorage.setItem(
                "CP-CANDIDATE-TOKEN",
                rs?.data?.access?.token
              );
            }
            return instance(originalConfig);
          } catch (_error) {
            localStorage.clear();
            window.location = "/candidate/login";
            return Promise.reject(_error);
            count = count + 1;
          }
        } else {
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
