import React, { useCallback, useEffect, useRef } from "react";
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

Chart.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Tooltip,
  Legend,
  Filler
);

const BoxPlotChart = ({ xArray, myScore, firstName, lastName, avgScore }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const tooltipTimeoutRef = useRef(null);

  const handleMouseMove = useCallback(
    (event) => {
      const canvas = chartRef.current;
      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      const detectionThreshold = 5;
      const { left, right, top, bottom } = chartInstanceRef.current.chartArea;
      const { Q1, Q2, Q3 } = calculateQuartiles(
        [...xArray].sort((a, b) => a - b)
      );
      const min = Math.min(...xArray);
      const max = Math.max(...xArray);

      const q1X = left + ((Q1 - 0) / (100 - 0)) * (right - left);
      const q3X = left + ((Q3 - 0) / (100 - 0)) * (right - left);
      const minX = left + ((min - 0) / (100 - 0)) * (right - left);
      const maxX = left + ((max - 0) / (100 - 0)) * (right - left);
      const boxTop = top + 20;
      const boxBottom = top + 80;

      if (
        (x >= minX - detectionThreshold &&
          x <= maxX + detectionThreshold &&
          y >= boxTop - detectionThreshold &&
          y <= boxBottom + detectionThreshold) ||
        (Math.abs(x - minX) < detectionThreshold && y >= top && y <= bottom) ||
        (Math.abs(x - maxX) < detectionThreshold && y >= top && y <= bottom)
      ) {
        clearTimeout(tooltipTimeoutRef.current);
        tooltipTimeoutRef.current = setTimeout(() => {
          showCustomTooltip(
            event,
            chartInstanceRef.current,
            { Q1, Q2, Q3 },
            min,
            max
          );
        }, 0);
      } else {
        hideCustomTooltip();
      }
    },
    [xArray]
  );

  const handleMouseOut = useCallback(() => {
    hideCustomTooltip();
  }, []);

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    const sortedScores = [...xArray].sort((a, b) => a - b);
    const quartiles = calculateQuartiles(sortedScores);
    const rangeStart = 0;
    const rangeEnd = 100;
    const scorE =
      myScore === 100 ? 99 : myScore === 0 ? 1 : Math.floor(myScore);
    const minscore = sortedScores[0];
    const maxscore = sortedScores[sortedScores.length - 1];
    const labels = Array.from(
      { length: rangeEnd - rangeStart + 1 },
      (_, i) => rangeStart + i
    );
    const stepSize = 10;
    const customLabels = Array.from(
      { length: Math.ceil((rangeEnd - rangeStart) / stepSize) + 1 },
      (_, i) => rangeStart + i * stepSize
    );
    const data = {
      labels: labels,
      datasets: [
        ...generateGaussianData(
          minscore,
          maxscore,
          quartiles.Q1,
          quartiles.Q3,
          quartiles.Q2,
          true
        ),
        {
          label: `${firstName} ${lastName} score`,
          data: [{ x: scorE, y: 155 }],
          pointRadius: 6,
          pointBackgroundColor: "#FF5812",
          showLine: false,
          order: 1,
        },
        {
          label: `Average score`,
          data: [{ x: parseInt(avgScore), y: 155 }],
          pointRadius: 6,
          pointBackgroundColor: "#C0FF06",
          showLine: false,
          order: 2,
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        tooltip: {
          enabled: false,
          external: customTooltip,
        },
        legend: {
          display: true,
          position: "bottom",
          labels: {
            usePointStyle: true,
            pointStyle: "circle",
            radius: 10,
            borderWidth: 2,
            font: {
              size: 14,
              family: "Silka",
            },
            color: "black",
          },
          padding: 20,
          margin: {
            left: 20,
          },
        },
        title: {
          display: true,
          text: `Score Distribution Analysis`,
          font: {
            size: 18,
            family: "Archia Semibold",
          },
          color: "black",
          padding: { top: 10, bottom: 40 },
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: "Candidates total score ( percentage )",
            font: {
              size: 16,
              weight: "bold",
              family: "Silka",
            },
            color: "black",
          },
          min: rangeStart,
          max: rangeEnd,
          ticks: {
            callback: (value) => (customLabels.includes(value) ? value : ""),
            stepSize: stepSize,
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0,
          },
          grid: {
            drawOnChartArea: false,
          },
        },
        y: {
          display: false,
        },
      },
    };

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }
    chartInstanceRef.current = new Chart(ctx, {
      type: "line",
      data,
      options,
      plugins: [
        {
          id: "customBoxPlot",
          afterDraw: (chart) => {
            const {
              ctx,
              chartArea: { left, right, top, bottom },
              canvas,
            } = chart;

            // Define quartiles and score values
            const abc = {
              Q1: quartiles?.Q1,
              Q2: quartiles?.Q2,
              Q3: quartiles?.Q3,
            };
            const min = minscore;
            const max = maxscore;

            if (!ctx) return;

            // Draw the box plot lines and areas
            ctx.save();
            ctx.strokeStyle = "#252E3A";
            ctx.lineWidth = 3;

            // Calculate positions
            const q1X =
              left +
              ((quartiles.Q1 - rangeStart) / (rangeEnd - rangeStart)) *
                (right - left);
            const q3X =
              left +
              ((quartiles.Q3 - rangeStart) / (rangeEnd - rangeStart)) *
                (right - left);
            const medianX =
              left +
              ((quartiles.Q2 - rangeStart) / (rangeEnd - rangeStart)) *
                (right - left);
            const minX =
              left +
              ((minscore - rangeStart) / (rangeEnd - rangeStart)) *
                (right - left);
            const maxX =
              left +
              ((maxscore - rangeStart) / (rangeEnd - rangeStart)) *
                (right - left);
            const boxTop = top + 20;
            const boxBottom = top + 80;

            // Draw box and whiskers
            ctx.beginPath();
            ctx.moveTo(q1X, boxTop);
            ctx.lineTo(q3X, boxTop);
            ctx.lineTo(q3X, boxBottom);
            ctx.lineTo(q1X, boxBottom);
            ctx.lineTo(q1X, boxTop);
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(medianX, boxTop);
            ctx.lineTo(medianX, boxBottom);
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(minX, (boxTop + boxBottom) / 2);
            ctx.lineTo(q1X, (boxTop + boxBottom) / 2);
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(q3X, (boxTop + boxBottom) / 2);
            ctx.lineTo(maxX, (boxTop + boxBottom) / 2);
            ctx.stroke();

            // Dashed lines for min and max
            ctx.setLineDash([5, 5]);
            ctx.beginPath();
            ctx.moveTo(minX, top);
            ctx.lineTo(minX, bottom);
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(maxX, top);
            ctx.lineTo(maxX, bottom);
            ctx.stroke();

            // Tooltip handling
            canvas.addEventListener("mousemove", handleMouseMove);
            canvas.addEventListener("mouseout", handleMouseOut);

            ctx.restore();
          },
        },
      ],
    });

    return () => {
      if (chartInstanceRef.current) {
        const canvas = chartInstanceRef.current.canvas;
        if (canvas) {
          canvas.removeEventListener("mousemove", handleMouseMove);
          canvas.removeEventListener("mouseout", handleMouseOut);
        }
        chartInstanceRef.current.destroy();
        chartInstanceRef.current = null;
      }
    };
  }, [xArray, myScore, avgScore]);

  const calculateQuartiles = (arr) => {
    const n = arr.length;
    const Q1 = interpolate(arr, 0.25 * (n + 1));
    const Q2 = interpolate(arr, 0.5 * (n + 1));
    const Q3 = interpolate(arr, 0.75 * (n + 1));

    return { Q1, Q2, Q3 };
  };

  const showCustomTooltip = useCallback(
    (event, chart, quartiles, minscore, maxscore) => {
      let tooltipEl = document.getElementById("custom-tooltip");

      if (!tooltipEl) {
        tooltipEl = document.createElement("div");
        tooltipEl.id = "custom-tooltip";
        tooltipEl.innerHTML = "<table></table>";
        document.body.appendChild(tooltipEl);
      }

      const innerHtml = `
      <div><strong>Maximum:</strong> ${maxscore}</div>
      <div><strong>Q3:</strong> ${quartiles.Q3}</div>
      <div><strong>Median:</strong> ${quartiles.Q2}</div>
      <div><strong>Q1:</strong> ${quartiles.Q1}</div>
      <div><strong>Minimum:</strong> ${minscore}</div>
    `;

      const tableRoot = tooltipEl.querySelector("table");
      tableRoot.innerHTML = innerHtml;

      tooltipEl.style.opacity = 1;
      tooltipEl.style.position = "absolute";
      tooltipEl.style.left = event.clientX + "px";
      tooltipEl.style.top = event.clientY + window.scrollY + 15 + "px";
      tooltipEl.style.padding = "10px";
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.zIndex = "9999";
      tooltipEl.style.backgroundColor = "#FFF8E8";
      tooltipEl.style.color = "black";
      tooltipEl.style.border = "1px solid rgba(0, 0, 0, 0.1)";
      tooltipEl.style.borderRadius = "5px";
      tooltipEl.style.boxShadow = "0 0 10px rgba(0, 0, 0, 0.15)";
    },
    []
  );

  const hideCustomTooltip = useCallback(() => {
    clearTimeout(tooltipTimeoutRef.current);
    const tooltipEl = document.getElementById("custom-tooltip");
    if (tooltipEl) {
      tooltipEl.style.opacity = 0;
    }
  }, []);

  const interpolate = (arr, position) => {
    const index = Math.floor(position);
    const fraction = position - index;
    if (index < 1) {
      return arr[0];
    }
    if (index >= arr.length) {
      return arr[arr.length - 1];
    }
    return arr[index - 1] + fraction * (arr[index] - arr[index - 1]);
  };

  const generateGaussianData = (
    min,
    max,
    q1,
    q3,
    q2,
    disableTooltip = false
  ) => {
    const data = [];
    const interQuartileRange = q3 - q1;
    const closerToQ1 = Math.abs(q2 - q1) < Math.abs(q2 - q3);

    let midPoint;
    if (Math.abs(q2 - q1) === Math.abs(q2 - q3)) {
      midPoint = q2;
    } else {
      midPoint = closerToQ1 ? (q2 + q1) / 2 : (q2 + q3) / 2;
    }

    const mu = midPoint;
    const sigma = interQuartileRange / 4;

    // Generate y values from min to max
    for (let x = min; x <= max; x++) {
      const y = Math.exp(-((x - mu) ** 2) / (2 * sigma ** 2));
      data.push({ x, y });
    }

    // Normalize and scale the data
    const maxY = Math.max(...data.map((point) => point.y));
    const normalizedData = data.map((point) => ({
      x: point.x,
      y: (point.y / maxY) * 200, // Scale to a max height of 200
    }));

    // Smooth out the start of the graph for the left side
    if (normalizedData.length > 1) {
      normalizedData[0].y = 0; // Ensure start at y = 0
      normalizedData[1].y = normalizedData[2].y * 0.5; // Gradually increase from zero
    }
    normalizedData[normalizedData?.length - 1].y = 0; // Ensure end at y = 0

    const dataset = {
      type: "line",
      label: "Gaussian Distribution",
      data: normalizedData,
      borderColor: "#FFB500",
      backgroundColor: "rgba(255, 165, 0, 0.2)",
      pointHitRadius: 0,
      fill: true,
      tension: 0.4,
      order: 5,
      pointRadius: 0,
    };

    if (disableTooltip) {
      dataset.tooltip = { enabled: false };
    }

    return [dataset];
  };

  const customTooltip = (context) => {
    // Tooltip Element
    let tooltipEl = document.getElementById("chartjs-tooltip");
    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.id = "chartjs-tooltip";
      tooltipEl.innerHTML = "<table></table>";
      document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set caret Position
    tooltipEl.classList.remove("above", "below", "no-transform");
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add("no-transform");
    }

    // Set Text
    if (tooltipModel.body) {
      let innerHtml = "<thead>";

      if (tooltipModel?.dataPoints[0]?.dataset?.label === "Average score") {
        innerHtml += `<tr><td>Average score: ${Number(avgScore || 0).toFixed(
          0
        )}%</td></tr>`;
      } else {
        innerHtml += `<tr><td>${firstName} ${lastName} score:  ${Number(
          myScore || 0
        ).toFixed(0)}%</td></tr>`;
      }
      innerHtml += "</tbody>";

      const tableRoot = tooltipEl.querySelector("table");
      tableRoot.innerHTML = innerHtml;
    }

    const position = context.chart.canvas.getBoundingClientRect();
    tooltipEl.style.opacity = 1;
    tooltipEl.style.position = "absolute";
    tooltipEl.style.left =
      position.left + window.pageXOffset + tooltipModel.caretX + "px";
    tooltipEl.style.top =
      position.top + window.pageYOffset + tooltipModel.caretY + 15 + "px"; // Add 10px margin to the bottom
    tooltipEl.style.font = tooltipModel.options.bodyFont.string;
    tooltipEl.style.padding =
      tooltipModel.padding + "px " + tooltipModel.padding + "px";
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.zIndex = "9999"; // Ensure tooltip is above other elements
    tooltipEl.style.backgroundColor = "black"; // Set background color to black
    tooltipEl.style.color = "white"; // Set text color to white
  };

  return <canvas ref={chartRef} />;
};

export default BoxPlotChart;
