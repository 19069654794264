import React, { useEffect, useState } from "react";
import "./bar.css";

const VerticalBar = ({
  widthtooltip = null,
  heightOfCell = null,
  widthCustom = null,
  bottomCell = null,
  FilledCellColor = null,
  EmptyCellColor = null,
  FilledCellHeight = null,
  tooltip1Text = null,
  tooltip2Text = null,
  tooltip3Text = null,
  paddingTooltip = null,
  marginTopTooltip = null,
  tooltip1TextSize = null,
  tooltip2TextSize = null,
  tooltip3TextSize = null,
  filter = null,
  tooltip4Text = null,
  tooltip4TextSize = null,
  spaceY = null,
}) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(0);
  }, [FilledCellHeight]);

  useEffect(() => {
    if (count < FilledCellHeight) {
      const timer = setTimeout(() => {
        setCount(count + 1);
      }, 30);
      return () => clearTimeout(timer);
    }
  }, [FilledCellHeight, count]);

  return (
    <div
      className={`${heightOfCell && heightOfCell} ${
        widthCustom ? widthCustom : "w-16"
      } ${bottomCell && bottomCell} ${
        EmptyCellColor && EmptyCellColor
      } relative`}
    >
      <div
        className={`absolute bottom-0 ${FilledCellColor && FilledCellColor}`}
        style={{ height: `${count}%`, width: "100%" }}
      >
        <div
          className={`absolute ${widthtooltip && widthtooltip} top-0 ${
            marginTopTooltip && marginTopTooltip
          }  left-1/2 transform -translate-x-1/2`}
        >
          <div
            className={`bg-[#252E3A] shadow-[0_3px_10px_rgb(0,0,0,0.2)] border-[2px] border-[#DBD9C0] ${
              paddingTooltip && paddingTooltip
            } rounded-xl  text-sm relative`}
          >
            <div className={`${spaceY && spaceY}`}>
              {tooltip1Text && (
                <p
                  className={`text-white ${
                    tooltip1TextSize
                      ? tooltip1TextSize
                      : "text-[10px] 2xl:text-xs"
                  } text-center`}
                >
                  {tooltip1Text}
                </p>
              )}
              {tooltip2Text && (
                <p
                  className={`text-white text-center ${
                    tooltip1TextSize
                      ? tooltip1TextSize
                      : "text-[10px] 2xl:text-xs"
                  }`}
                >
                  {tooltip2Text}
                </p>
              )}
              {tooltip4Text && (
                <p
                  className={`text-white text-center ${
                    tooltip4TextSize
                      ? tooltip4TextSize
                      : "text-[10px] 2xl:text-xs"
                  }`}
                >
                  {tooltip4Text}
                </p>
              )}
            </div>
            {tooltip3Text && (
              <p
                className="text-lg text-center text-white"
                style={{ fontFamily: "Archia Bold" }}
              >
                {tooltip3Text}
              </p>
            )}
            <div className="tooltip-arrow" data-popper-arrow></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalBar;
