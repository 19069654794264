import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  setResumeQuestion: 0,
};

export const ResumeQuestionNumberSlice = createSlice({
  name: "resumeQuestionNumber",
  initialState,
  reducers: {
    setQuestionNumberResume: (state, action) => {
      state.setResumeQuestion = action.payload;
    },
  },
});

export const { setQuestionNumberResume } = ResumeQuestionNumberSlice.actions;
export default ResumeQuestionNumberSlice.reducer;
