import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    setCandidate: null,
};

export const CandidateDetailsSlice = createSlice({
    name: "candidateDetails",
    initialState,
    reducers: {
        setCandidateDetails: (state, action) => {
            state.setCandidate = action.payload;
        },
    },
});

export const { setCandidateDetails } =
    CandidateDetailsSlice.actions;
export default CandidateDetailsSlice.reducer;
