import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    startTime: 0,
    endTime: 0,
};

export const StartTimeSlice = createSlice({
    name: "start",
    initialState,
    reducers: {
        setStartTime: (state, action) => {
            state.startTime = action.payload;
            state.endTime = action.payload;
        },
    },
});

export const { setStartTime } =
    StartTimeSlice.actions;
export default StartTimeSlice.reducer;
