import React, { useRef, useEffect, useState } from "react";
import Premium from "../Modals/Premium";
import PremiumLogo from "../../Assets/preee.png";

function FileInput({
  onImageSelected,
  user_package_check,
  maindiv,
  setMainDiv,
}) {
  const inputRef = useRef();
  const [premiumOpen, setPremiumOpen] = useState(false);

  useEffect(() => {
    if (maindiv) {
      // If maindiv is true, automatically open file explorer
      inputRef.current.click();
      setMainDiv(false);
    }
  }, [maindiv]);

  const handleOnChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function (e) {
        onImageSelected(reader.result);
      };
    }
  };

  const onChooseImg = () => {
    inputRef.current.click();
  };

  return (
    <div>
      <Premium premiumOpen={premiumOpen} setPremiumOpen={setPremiumOpen} />
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        onChange={handleOnChange}
        style={{ display: "none" }}
      />
      <label
        className="text-sm xl:w-1/2 2xl:w-1/3 py-3 relative mt-5 mx-auto flex justify-center cursor-pointer font-medium"
        onClick={() => (user_package_check === "free" ? null : onChooseImg())}
      >
        <p
          className="text-center text-[#767676]"
          style={{ fontFamily: "Silka" }}
        >
          Upload Your Company Logo
        </p>
      </label>
    </div>
  );
}

export default FileInput;
