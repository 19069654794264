import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import closeIcon from "../../Dexta_assets/closeModal.png";
import VerticalBar from "../VerticalBar/VerticalBar";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getCandidatesforGraphs } from "../../Pages/Profile/MyAssessments/Assessments-main/hooks/getCandidatesforGraphs";
import MyBarChart from "../chart/BarChart";

export default function ScoresGraph({
  ScoreGraph,
  setScoreGraph,
  heading = null,
  moduleName = null,
  candidateData = null,
  sectionIndex = null,
  currentPage = null,
}) {
  const cancelButtonRef = useRef(null);
  const [sortDropdown, setSortDropdown] = useState(false);
  const sortRef = useRef(null);
  const { id } = useParams();
  const [soryBy, setSortBy] = useState("createdAt");
  const [order, setOrder] = useState("");
  const [sortvalue, setSortvalue] = useState("");

  const { data, isLoading, refetch } = useQuery(
    ["assessment", id, 1],
    () => getCandidatesforGraphs(id, 1),
    {
      onSuccess: (data) => {},
    }
  );

  useEffect(() => {
    function handleoutsideField(event) {
      if (
        sortRef.current &&
        !sortRef.current.contains(event.target) &&
        event.target.id !== "sort" &&
        !event.target.closest(".sortClass")
      ) {
        setSortDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleoutsideField);
    return () => {
      document.removeEventListener("mousedown", handleoutsideField);
    };
  }, [sortRef]);

  const sortValues = [
    {
      value: "l2h",
      title: "Average score (low to high)",
    },
    {
      value: "h2l",
      title: "Average score (high to low)",
    },
  ];

  const sortValues_modules = [
    {
      value: "l2h",
      title: "Score (low to high)",
    },
    {
      value: "h2l",
      title: "Score (high to low)",
    },
  ];

  const [filteredSections, setFilteredSections] = useState([]);
  const [filteredScore, setFilteredScore] = useState([]);

  useEffect(() => {
    if (moduleName !== "") {
      setFilteredSections([]);
      const dataMain = data?.data
        ?.map((item) => {
          const sections = item.sections?.filter(
            (_, index) => index === sectionIndex
          );
          return sections.length > 0
            ? { section: sections[0], candidate: item.candidates }
            : null;
        })
        .filter((item) => item !== null);

      let sortedSections = dataMain;
      if (order === "ASC" || order === "DESC") {
        sortedSections = null;
        sortedSections = dataMain?.sort((a, b) => {
          const scoreA = parseFloat(a?.section?.score);
          const scoreB = parseFloat(b?.section?.score);
          if (order === "ASC") {
            return scoreA - scoreB;
          } else if (order === "DESC") {
            return scoreB - scoreA;
          }
          return 0;
        });
      }
      sortedSections = sortedSections?.slice(0, 40);
      setFilteredSections(sortedSections);
    }
  }, [data, sectionIndex, order, moduleName]);

  useEffect(() => {
    if (moduleName === "") {
      setFilteredScore([]);
      const datamain = [...(data?.data || [])]; // Shallow copy to avoid mutating the original data
      let sortedScore = datamain;
      if (order === "ASC" || order === "DESC") {
        sortedScore = datamain?.sort((a, b) => {
          const scoreA = parseFloat(a?.score);
          const scoreB = parseFloat(b?.score);
          if (order === "ASC") {
            return scoreA - scoreB;
          } else if (order === "DESC") {
            return scoreB - scoreA;
          }
          return 0;
        });
      }
      sortedScore = sortedScore?.slice(0, 40);
      setFilteredScore(sortedScore);
    }
  }, [order, data, moduleName]);

  return (
    <Transition.Root show={ScoreGraph} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={() => {
          setScoreGraph(false);
          setOrder("");
          setSortvalue("");
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform rounded-lg bg-white text-left transition-all sm:my-8 sm:w-full sm:max-w-2xl md:h-[600px]"
                style={{ zIndex: 20 }}
              >
                <div className="bg-white rounded-lg">
                  <p
                    className="text-lg font-medium text-coalColor px-10 cursor-pointer hover:text-coalColor text-left pt-5"
                    style={{ fontFamily: "Archia Bold" }}
                  >
                    {heading}
                  </p>
                  <p
                    className="text-lg font-medium text-coalColor px-10 cursor-pointer hover:text-coalColor text-left"
                    style={{ fontFamily: "Archia Bold" }}
                  >
                    {moduleName !== "" && moduleName}
                  </p>
                  <img
                    src={closeIcon}
                    className="absolute top-3 right-5 z-20 w-6 h-6 cursor-pointer"
                    onClick={() => {
                      setScoreGraph(false);
                      setOrder("");
                      setSortvalue("");
                    }}
                  />
                  <div className="flex flex-row justify-end px-6 gap-3 mt-5">
                    <p
                      className="my-auto text-sm pl-2  sm:w-1/4 lg:w-auto"
                      style={{ fontFamily: "Silka Light" }}
                    >
                      Sort by
                    </p>
                    <div className="flex flex-col gap-3 sm:w-full lg:w-auto my-auto relative">
                      <button
                        id="sort"
                        className="text-coalColor border bg-white border-[#D3D5D8] focus:border-coalColor p-2.5 sm:w-full lg:w-[16rem] py-3.5 font-medium rounded-md text-sm text-left inline-flex items-center"
                        type="button"
                        onClick={() => setSortDropdown(!sortDropdown)}
                        style={{ fontFamily: "Silka" }}
                      >
                        {sortvalue != "" ? (
                          <span className="text-coalColor sortClass">
                            {sortvalue}
                          </span>
                        ) : (
                          <span className="text-[#7C8289] sortClass">
                            {moduleName === "" ? "Average score" : "Score"}
                          </span>
                        )}
                        <svg
                          className="w-2.5 h-2.5 ml-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 10 6"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 4 4 4-4"
                          />
                        </svg>
                      </button>
                      {sortDropdown && (
                        <div
                          id="education-drop"
                          className="sortClass absolute z-20 border border-coalColor right-0 top-full overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                        >
                          {moduleName === "" ? (
                            <>
                              {sortValues.map((i) => (
                                <ul
                                  key={i.title}
                                  className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                  ref={sortRef}
                                  onClick={() => {
                                    if (!isLoading) {
                                      setSortvalue(i?.title);
                                      setOrder(
                                        i?.value === "h2l" ? "DESC" : "ASC"
                                      );
                                      setSortDropdown(false);
                                      setSortBy("score");
                                    }
                                  }}
                                  style={{ fontFamily: "Silka" }}
                                >
                                  <li>
                                    <p className="block px-5 py-2 text-sm font-medium">
                                      {i.title}
                                    </p>
                                  </li>
                                </ul>
                              ))}
                            </>
                          ) : (
                            <>
                              {sortValues_modules.map((i) => (
                                <ul
                                  key={i.title}
                                  className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                  ref={sortRef}
                                  onClick={() => {
                                    if (!isLoading) {
                                      setSortvalue(i?.title);
                                      setOrder(
                                        i?.value === "h2l" ? "DESC" : "ASC"
                                      );
                                      setSortDropdown(false);
                                      setSortBy("score");
                                    }
                                  }}
                                  style={{ fontFamily: "Silka" }}
                                >
                                  <li>
                                    <p className="block px-5 py-2 text-sm font-medium">
                                      {i.title}
                                    </p>
                                  </li>
                                </ul>
                              ))}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="px-5 mt-20">
                    {moduleName === "" ? (
                      <MyBarChart data_students={filteredScore} />
                    ) : (
                      <MyBarChart
                        data_students={filteredSections}
                        sectionGraph={true}
                      />
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
