export const onCropDone = (
  imgCroppedArea,
  image,
  setblobdata,
  setImgAfterCrop,
  setCurrentPage
) => {
  const canvasEle = document.createElement("canvas");
  canvasEle.width = imgCroppedArea.width;
  canvasEle.height = imgCroppedArea.height;
  const context = canvasEle.getContext("2d");

  // Set a transparent background
  context.clearRect(0, 0, canvasEle.width, canvasEle.height);

  let imageObj1 = new Image();
  imageObj1.src = image;
  imageObj1.onload = function () {
    context.drawImage(
      imageObj1,
      imgCroppedArea.x,
      imgCroppedArea.y,
      imgCroppedArea.width,
      imgCroppedArea.height,
      0,
      0,
      imgCroppedArea.width,
      imgCroppedArea.height
    );

    // Convert the canvas data to a Blob
    canvasEle.toBlob((blob) => {
      // Set the blob data to a separate state variable
      setblobdata(blob);

      const dataURL = canvasEle.toDataURL("image/png");
      setImgAfterCrop(dataURL);
      setCurrentPage("img-cropped");
    }, "image/png");
  };
};

export const onCropCancel = (setCurrentPage, setImage) => {
  setCurrentPage("choose-img");
  setImage("");
};
