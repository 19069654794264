import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    setPlanDetail: "",
};

export const PlanDetailsSlice = createSlice({
    name: "planDetails",
    initialState,
    reducers: {
        setPlanDetails: (state, action) => {
            state.setPlanDetail = action.payload;
        },
    },
});

export const { setPlanDetails } =
    PlanDetailsSlice.actions;
export default PlanDetailsSlice.reducer;
