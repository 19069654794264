import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    endTime: null
};

export const EndTimeSlice = createSlice({
    name: "end",
    initialState,
    reducers: {
        setEndTime: (state, action) => {
            state.endTime = action.payload;
        },
    },
});

export const { setEndTime } =
    EndTimeSlice.actions;
export default EndTimeSlice.reducer;
