import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import closeIcon from "../../../../../Dexta_assets/closeModal.png";
import { ToastContainer, toast, Zoom } from "react-toastify";
import CustomButton from "../../../../../Components/CustomButton/CustomButton";
import { updateHiringStatus } from "../hooks/updateHiringStatus";
import queryString from "query-string";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import King from "../../../../../Assets/preee.png";
import Premium from "../../../../../Components/Modals/Premium";
import CustomizeHiringEmail from "../../../../../Components/CustomizeEmail/CustomizeHiringEmail";
import { updateAssessmentStatus } from "../hooks/updateStatus";
import { updateHiringStatuses } from "../hooks/updateHiringStatuses";

export default function HiringModal({
  hiringOpen,
  setHiringOpen,
  hiringStatus,
  hiringLabel,
  typeHiring,
  checkboxText,
  firstName,
  lastName,
  uID,
  noCustomization,
  HiringType,
  selectedCandidates,
  setSelectedRows,
  setSelectAll,
}) {
  const [isChecked, setIsChecked] = useState(false);
  const queryClient = useQueryClient();
  const { id } = useParams();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const [customizeCustomModal, setCustomizeCustomModal] = useState(false);
  const [emailContent, setEmailContent] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailType, setEmailType] = useState("");
  const user_package_check = useSelector(
    (state) => state.packageDetails.setPackage
  );
  const [premiumOpen, setPremiumOpen] = useState(false);

  //#region updating hiring status
  const { mutate: hiringMutate, isLoading: hiringLoading } = useMutation(
    updateHiringStatus,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("/assessment/candidate");
        toast.success("Hiring stage changed for candidate.", {
          toastId: "copy-success",
        });
        setHiringOpen(false);
        setIsChecked(false);
      },
      onError: () => {},
    }
  );
  const handlehiringChange = () => {
    let data = {
      IDS: {
        userID: uID ? uID : parsed.user_ID,
        Assessment: id,
      },
      postData: JSON.stringify({
        sendApprovalEmail: typeHiring === "PASSED" && isChecked,
        sendInviteEmail: typeHiring === "INVITED FOR INTERVIEW" && isChecked,
        status: typeHiring,
        sendOfferEmail: typeHiring === "OFFER SENT" && isChecked,
        sendRejectionEmail: typeHiring === "REJECTED" && isChecked,
        sendReminderToRespondToOfferEmail:
          typeHiring === "REMINDER TO RESPOND TO OFFER" && isChecked,
        sendHiredEmail: typeHiring === "HIRED" && isChecked,
        sendPositionWithdrawnEmail:
          typeHiring === "POSITION WITHDRAWN" && isChecked,
      }),
    };

    try {
      hiringMutate(data);
    } catch (err) {
      // react-query will handle error
    }
  };
  //#endregion

  //#region updating hiring statuses
  const { mutate: hiringMutateMultiple, isLoading: hiringLoadingMultiple } =
    useMutation(updateHiringStatuses, {
      onSuccess: (response) => {
        queryClient.invalidateQueries(
          "/assessment/multiple/candidate/update/hiring/status/assessment"
        );
        toast.success("Hiring stage changed for candidates.", {
          toastId: "copy-success",
        });
        setSelectedRows([]);
        setHiringOpen(false);
        setIsChecked(false);
        setSelectAll(false);
      },
      onError: () => {},
    });

  const handlehiringChangeofMultiple = () => {
    let data = {
      IDS: {
        Assessment: id,
      },
      postData: JSON.stringify({
        status: HiringType === "Accept" ? "OFFER SENT" : "REJECTED",
        sendOfferEmail: HiringType === "Accept" && isChecked,
        sendRejectionEmail: HiringType === "Reject" && isChecked,
        userIds: selectedCandidates,
      }),
    };

    try {
      hiringMutateMultiple(data);
    } catch (err) {
      // react-query will handle error
    }
  };
  //#endregion

  //#region opening rejection template
  const handleRejectEmailTemplate = () => {
    if (typeHiring === "INVITED FOR INTERVIEW") {
      setEmailContent(`
        <h1><strong style="font-size: 16px;">Dear {candidate_name}</strong></h1>
        <p>
        <span style="font-size: 16px;">We are pleased to inform you that you have been shortlisted for an interview.</span>
        <p><span style="font-size: 16px;">You will soon receive a detailed email from our team with information on the date, time, and location of the interview, as well as any additional instructions to help you prepare.</span></p>
        <p><span style="font-size: 16px;">We look forward to meeting you and discussing how you can contribute to our team!</span></p>
        <p><span style="font-size: 16px;">Kind regards,</span></p>
        <p><strong style="font-size: 16px;">{company_name}</strong></p>
      `);
      setEmailType("INVITED FOR INTERVIEW");
      setCustomizeCustomModal(true);
    } else if (typeHiring === "OFFER SENT") {
      setEmailContent(`
        <h1><strong style="font-size: 16px;">Dear {candidate_name}</strong></h1>
        <p>
        <span style="font-size: 16px;">We are thrilled to offer you the opportunity to join </span>
        <strong style="font-size: 16px;">{company_name}.</strong>
        <p><span style="font-size: 16px;">You will soon receive an official offer letter from our team, outlining all the important details about your role, salary, benefits, and more.</span></p>
        <p><span style="font-size: 16px;">We are excited to welcome you aboard and are confident that your skills and expertise will make a meaningful impact at <span><strong style="font-size: 16px;">{company_name}.</strong></span></span></p>
        <p><span style="font-size: 16px;">Please don't hesitate to reach out if you have any questions. We look forward to having you on the team!</span></p>
        <p><span style="font-size: 16px;">Kind regards,</span></p>
        <p><strong style="font-size: 16px;">{company_name}</strong></p>

      `);
      setEmailType("OFFER");
      setCustomizeCustomModal(true);
    } else if (typeHiring === "REJECTED") {
      setEmailContent(`
        <h1><strong style="font-size: 16px;">Dear {candidate_name}</strong></h1>
        <p>
        <span style="font-size: 16px;">Thank you for applying to the <strong>{role_name}</strong> role. We sincerely appreciate your interest in our company, and we are grateful for the time and energy you've invested in our test.</span>
        <p><span style="font-size: 16px;">Unfortunately, after careful consideration, we will not be able to invite you to the next phase of our selection process. We wish you success in your job search and all of your future endeavors.</span></p>
        <p><span style="font-size: 16px;">Once again, we thank you for your interest in working with us.</span></p>
        <p><span style="font-size: 16px;">Kind regards,</span></p>
        <p><strong style="font-size: 16px;">{company_name}</strong></p>
      `);
      setEmailType("REJECTION");
      setCustomizeCustomModal(true);
    } else if (typeHiring === "REMINDER TO RESPOND TO OFFER") {
      setEmailContent(`
        <h1><strong style="font-size: 16px;">Dear {candidate_name}</strong></h1>
        <p>
        <span style="font-size: 16px;">We hope this message finds you well. We wanted to follow up on the job offer we sent you from </span>
        <strong style="font-size: 16px;">{company_name}.</strong>
        <p><span style="font-size: 16px;">We are very excited about the prospect of you joining our team and would appreciate your response at your earliest convenience.</span></p>
        <p><span style="font-size: 16px;">If you have any questions or need further information to make your decision, please do not hesitate to reach out to us.</span></p>
        <p><span style="font-size: 16px;">We look forward to hearing from you soon and hope to welcome you to <span><strong style="font-size: 16px;">{company_name}.</strong></span></span></p>
        `);
      setEmailType("REMINDER TO RESPOND TO OFFER");
      setCustomizeCustomModal(true);
    } else if (typeHiring === "HIRED") {
      setEmailContent(`
        <h1><strong style="font-size: 16px;">Dear {candidate_name}</strong></h1>
        <p>
        <p><span style="font-size: 16px;">We are delighted to officially welcome you to the</span>
       <strong style="font-size: 16px;"> {company_name}</strong> team.</p>
        <p><span style="font-size: 16px;">Your skills, experience, and enthusiasm truly stood out during the interview process, and we're excited to have you join us. We're confident that you'll be a valuable addition to the team and look forward to seeing the impact you'll make on our projects and goals.</span></p>
        <p><span style="font-size: 16px;">If you have any questions or need further details to help with your transition, please don't hesitate to reach out.</span></p>
        <p><span style="font-size: 16px;">Once again, congratulations and welcome to the team!</span></p>
        <p><span style="font-size: 16px;">Kind regards,</span></p>
        <p><strong style="font-size: 16px;">{company_name}</strong></p>
        `);
      setEmailType("HIRED");
      setCustomizeCustomModal(true);
    } else if (typeHiring === "PASSED") {
      setEmailContent(`
        <h1><strong style="font-size: 16px;">Dear {candidate_name}</strong></h1>
        <p><span style="font-size: 16px;">We are excited to inform you that after a thorough review and evaluation by the recruitment team at <strong>{company_name}</strong>, you have successfully passed the evaluation process!.</span></p>
        <p><span style="font-size: 16px;">We will soon contact you with more details and the next steps in the hiring process, such as the offer letter and any additional information you may need.</span></p>
        <p><span style="font-size: 16px;">Congratulations once again! We look forward to welcoming you to the team and working together in the near future.</span></p>
        `);
      setEmailType("APPROVAL");
      setCustomizeCustomModal(true);
    } else if (typeHiring === "POSITION WITHDRAWN") {
      setEmailContent(`
        <h1><strong style="font-size: 16px;">Dear {candidate_name}</strong></h1>
        <p>
        <span style="font-size: 16px;">Thank you for your interest in the position at <strong>{company_name}</strong> and for taking the time to apply.</span>
        <p><span style="font-size: 16px;">We regret to inform you that the position you applied for has been withdrawn and is no longer available. We appreciate the effort you put into your application and the interest you have shown in our company.</span></p>
        <p><span style="font-size: 16px;">We encourage you to keep an eye on our careers page for future openings that may match your skills and experience.</span></p>
        <p><span style="font-size: 16px;">Thank you once again for considering <strong>{company_name}</strong> as a potential employer.</span></p>
        <p><span style="font-size: 16px;">Kind regards,</span></p>
        <p><strong style="font-size: 16px;">{company_name}</strong></p>
        `);
      setEmailType("POSITION WITHDRAWN");
      setCustomizeCustomModal(true);
    }
  };

  const handleRejectEmailTemplateMultiple = () => {
    if (HiringType === "Accept") {
      setEmailContent(`
   <h1><strong style="font-size: 16px;">Dear {candidate_name}</strong></h1>
        <p>
        <span style="font-size: 16px;">We are thrilled to offer you the opportunity to join </span>
        <strong style="font-size: 16px;">{company_name}.</strong>
        <p><span style="font-size: 16px;">You will soon receive an official offer letter from our team, outlining all the important details about your role, salary, benefits, and more.</span></p>
        <p><span style="font-size: 16px;">We are excited to welcome you aboard and are confident that your skills and expertise will make a meaningful impact at <span><strong style="font-size: 16px;">{company_name}.</strong></span></span></p>
        <p><span style="font-size: 16px;">Please don't hesitate to reach out if you have any questions. We look forward to having you on the team!</span></p>
        <p><span style="font-size: 16px;">Kind regards,</span></p>
        <p><strong style="font-size: 16px;">{company_name}</strong></p>
      `);
      setEmailType("OFFER");
      setCustomizeCustomModal(true);
    } else {
      setEmailContent(`
         <h1><strong style="font-size: 16px;">Dear {candidate_name}</strong></h1>
        <p>
        <span style="font-size: 16px;">Thank you for applying to the <strong>{role_name}</strong> role. We sincerely appreciate your interest in our company, and we are grateful for the time and energy you've invested in our test.</span>
        <p><span style="font-size: 16px;">Unfortunately, after careful consideration, we will not be able to invite you to the next phase of our selection process. We wish you success in your job search and all of your future endeavors.</span></p>
        <p><span style="font-size: 16px;">Once again, we thank you for your interest in working with us.</span></p>
        <p><span style="font-size: 16px;">Kind regards,</span></p>
        <p><strong style="font-size: 16px;">{company_name}</strong></p>
      `);
      setEmailType("REJECTION");
      setCustomizeCustomModal(true);
    }
  };
  //#endregion

  return (
    <Dialog
      open={hiringOpen}
      onClose={() => {
        setHiringOpen(false);
        setIsChecked(false);
      }}
      className="fixed inset-0 z-40 flex items-center justify-center overflow-y-auto"
    >
      <CustomizeHiringEmail
        customizeCustomModal={customizeCustomModal}
        setCustomizeCustomModal={setCustomizeCustomModal}
        emailContent={emailContent}
        emailSubject={emailSubject}
        emailType={emailType}
        passCheck={true}
        setHiringOpen={setHiringOpen}
        firstName={noCustomization ? "Candidate" : firstName}
        lastName={lastName}
      />
      <Premium premiumOpen={premiumOpen} setPremiumOpen={setPremiumOpen} />
      <div className="fixed inset-0 bg-black bg-opacity-75" />
      <Dialog.Panel
        className="relative bg-white rounded-lg overflow-hidden shadow-lg transform transition-all sm:max-w-lg sm:w-full"
        style={{
          height:
            typeHiring === "REMINDER TO RESPOND TO OFFER" ||
            typeHiring === "HIRED" ||
            typeHiring === "POSITION WITHDRAWN" ||
            typeHiring === "REJECTED"
              ? "275px"
              : noCustomization
              ? "265px"
              : "265px",
        }}
      >
        <div className="bg-white">
          <p
            className="text-lg font-medium text-coalColor pl-4 cursor-pointer hover:text-coalColor text-left pt-5"
            style={{ fontFamily: "Archia Bold" }}
          >
            {hiringStatus}
          </p>
          <img
            src={closeIcon}
            className="absolute top-3 right-5 z-20 w-6 h-6 cursor-pointer"
            onClick={() => {
              setHiringOpen(false);
              setIsChecked(false);
            }}
          />
          <p className="pl-4 mt-5" style={{ fontFamily: "Silka" }}>
            {hiringLabel}
          </p>
          <div className="ml-4 mt-5">
            <div className="flex flex-row">
              <label
                className="relative my-auto flex cursor-pointer items-center rounded-full"
                data-ripple-dark="true"
              >
                <input
                  id="ripple-on"
                  type="checkbox"
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                  className="peer relative h-4 w-4 cursor-pointer checked:bg-primaryGreen appearance-none rounded border-[1px] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8 border-gray-700 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-coalColor hover:before:opacity-50"
                />
                <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-coalColor opacity-0 transition-opacity peer-checked:opacity-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3.5 w-3.5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="1"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
              </label>
              <p
                className={`ml-2 ${
                  noCustomization ? "text-[13px]" : "text-sm"
                } my-auto`}
                style={{ fontFamily: "Silka Light" }}
              >
                {checkboxText
                  ? checkboxText
                  : "Send an email to this candidate as well"}
              </p>
            </div>
            <p className="flex flex-row mt-3" style={{ fontFamily: "Silka" }}>
              {user_package_check !== "Enterprise" && (
                <img src={King} className="w-4 h-4 my-auto mr-2" />
              )}
              You can customise your email to the candidates{" "}
              <p
                className="text-black font-bold ml-1 underline cursor-pointer"
                style={{ fontFamily: "Archia Bold" }}
                onClick={() => {
                  if (user_package_check !== "Enterprise") {
                    setPremiumOpen(true);
                  } else {
                    if (noCustomization) {
                      handleRejectEmailTemplateMultiple();
                    } else {
                      handleRejectEmailTemplate();
                    }
                  }
                }}
              >
                here
              </p>
              .
            </p>
            <div className="flex flex-row justify-end px-8 gap-5 mt-8">
              <CustomButton
                label="No"
                textSize="text-base"
                borderCustom="border border-coalColor text-white"
                bgColor="#252E3A"
                hoverBgColor="#C0FF06"
                hoverTextColor="#252E3A"
                widthButton="w-[5rem]"
                paddingY="0.3rem"
                textColor="black"
                onClickButton={() => {
                  setHiringOpen(false);
                  setIsChecked(false);
                }}
              />
              <CustomButton
                label="Yes"
                textSize="text-base"
                bgColor="#C0FF06"
                widthButton="w-[6rem]"
                textColor="black"
                borderCustom="border border-black text-coalColor"
                hoverBgColor="#252E3A"
                hoverTextColor="white"
                paddingY="0.3rem"
                disabledColor="#D3D5D8"
                disabledTextColor="#7C8289"
                loadingColor="black"
                LoadingBtn={hiringLoading || hiringLoadingMultiple}
                loadingText="Saving"
                onClickButton={() => {
                  if (noCustomization) {
                    handlehiringChangeofMultiple();
                  } else {
                    handlehiringChange();
                  }
                }}
              />
            </div>
          </div>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
}
