import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  setResume: false,
};

export const ResumeSlice = createSlice({
  name: "resumeExam",
  initialState,
  reducers: {
    setResumeToTrue: (state, action) => {
      state.setResume = true;
    },
    setResumeToFalse: (state, action) => {
      state.setResume = false;
    },
  },
});

export const { setResumeToTrue, setResumeToFalse } = ResumeSlice.actions;
export default ResumeSlice.reducer;
