import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    setNextModule: false,
}

export const NextModulesSlice = createSlice({
    name:"nextModule",
    initialState,
    reducers:{
        setNextModuleToTrue :(state, action)=>{
            state.setNextModule = true
        },
        setNextModuleToFalse :(state, action)=>{
            state.setNextModule = false
        },
    },
})

export const { setNextModuleToTrue, setNextModuleToFalse } = NextModulesSlice.actions;
export default NextModulesSlice.reducer;