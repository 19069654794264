import { combineReducers, configureStore } from "@reduxjs/toolkit";
import UpdateSlice from "../reducers/ButtonsUpdate/UpdateSlice";
import ToggleSlice from "../reducers/toggleTour/ToggleSlice";
import CandidateSlice from "../reducers/CandidateBack/CandidateSlice";
import PreviewSlice from "../reducers/PreviewBack/PreviewSlice";
import ExamDoneSlice from "../reducers/ExamDone/ExamDoneSlice";
import InviteSlice from "../reducers/Invite/InviteSlice";
import PreviewDataSlice from "../reducers/PreviewData/PreviewDataSlice";
import packageDataSlice from "../reducers/packageData/packageDataSlice";
import PlanDetailsSlice from "../reducers/PlanDetails/PlanDetailsSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import IndustriesSlice from "../reducers/Industries/IndustriesSlice";
import EntrySlice from "../reducers/EntryLevel/EntrySlice";
import StartTimeSlice from "../reducers/StartTime/StartTimeSlice";
import EndTimeSlice from "../reducers/EndTime/EndTimeSlice";
import ClearRowsSlice from "../reducers/ClearRows/ClearRowsSlice";
import NextGeneralSlice from "../reducers/NextGeneral/NextGeneralSlice";
import NextModulesSlice from "../reducers/NextModules/NextModulesSlice";
import NextQuestionSlice from "../reducers/NextQuestions/NextQuestionsSlice";
import NextCandidateSlice from "../reducers/NextCandidate/NextCandidateSlice";
import CandidateDetailsSlice from "../reducers/CandidateDetails/CandidateDetailsSlice";
import TourStepsSlice from "../reducers/TourSteps/TourStepsSlice";
import TourCompletedSlice from "../reducers/TourCompleted/TourCompletedSlice";
import JobsSlice from "../reducers/Jobs/JobsSlice";
import AccountTypeSlice from "../reducers/AccountType/AccountTypeSlice";
import CopySlice from "../reducers/CopyLink/CopySlice";
import RevealSlice from "../reducers/RevealAnswers/RevealSlice";
import QuestionsSlice from "../reducers/QuestionsTotal/QuestionsSlice";
import CurrentQuestionSlice from "../reducers/CurrentQuestion/CurrentQuestionSlice";
import GraphDataSlice from "../reducers/GraphData/GraphDataSlice";
import DepartmentsSlice from "../reducers/Departments/DepartmentsSlice";
import CandidateLoginDetailsSlice from "../reducers/CandidateDetails/CandidateLoginDetailsSlice";
import ResumeSlice from "../reducers/ResumeTest/ResumeSlice";
import ResumeQuestionNumberSlice from "../reducers/ResumedQuestion/ResumeQuestionNumberSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  buttonsupdate: UpdateSlice,
  tour: ToggleSlice,
  candidateBack: CandidateSlice,
  previewBack: PreviewSlice,
  examdone: ExamDoneSlice,
  invite: InviteSlice,
  preview: PreviewDataSlice,
  packageDetails: packageDataSlice,
  planDetails: PlanDetailsSlice,
  industries: IndustriesSlice,
  entry_level: EntrySlice,
  start: StartTimeSlice,
  end: EndTimeSlice,
  clearrow: ClearRowsSlice,
  nextGeneral: NextGeneralSlice,
  nextModule: NextModulesSlice,
  nextQuestion: NextQuestionSlice,
  nextCandidate: NextCandidateSlice,
  candidateDetails: CandidateDetailsSlice,
  tourStep: TourStepsSlice,
  tourCompleted: TourCompletedSlice,
  jobs: JobsSlice,
  accountTypeDetails: AccountTypeSlice,
  copy: CopySlice,
  reveal: RevealSlice,
  questionsTotal: QuestionsSlice,
  currentQuestion: CurrentQuestionSlice,
  graphData: GraphDataSlice,
  departments: DepartmentsSlice,
  candidateLoginDetails: CandidateLoginDetailsSlice,
  resumeExam: ResumeSlice,
  resumeQuestionNumber: ResumeQuestionNumberSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
