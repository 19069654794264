import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    setClearRow: false,
}

export const ClearRowsSlice = createSlice({
    name:"clearrow",
    initialState,
    reducers:{
        setClearToTrue :(state, action)=>{
            state.setClearRow = true
        },
        setClearToFalse :(state, action)=>{
            state.setClearRow = false
        },
    },
})

export const { setClearToTrue, setClearToFalse } = ClearRowsSlice.actions;
export default ClearRowsSlice.reducer;