import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    settourStep: 1,
};

export const TourStepsSlice = createSlice({
    name: "tourStep",
    initialState,
    reducers: {
        setTourStepData: (state, action) => {
            state.settourStep = action.payload;
        },
    },
});

export const { setTourStepData } =
    TourStepsSlice.actions;
export default TourStepsSlice.reducer;
