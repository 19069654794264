import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  setcandidate: false,
};

export const CandidateSlice = createSlice({
  name: "candidateBack",
  initialState,
  reducers: {
    setCandidateToTrue: (state, action) => {
      state.setcandidate = true;
    },
    setCandidateToFalse: (state, action) => {
      state.setcandidate = false;
    },
  },
});

export const { setCandidateToTrue, setCandidateToFalse } =
  CandidateSlice.actions;
export default CandidateSlice.reducer;
