export const Currencies = [
  {
    title: "USD",
    value: "usd",
    sign: "$",
  },
  {
    title: "EUR",
    value: "eur",
    sign: "€",
  },
  {
    title: "GBP",
    value: "gbp",
    sign: "£",
  },
  {
    title: "AUD",
    value: "aud",
    sign: "$",
  },
  {
    title: "SAR",
    value: "sar",
    sign: "SAR",
  },
];

export const type = [
  {
    title: "Monthly",
    value: "month",
  },
  {
    title: "Annually",
    value: "year",
  },
];

export const subscriptions = [
  {
    title: "Explore for free",
    subtitle: "Try out skills-based hiring",
    amount: 0,
    currency: "USD",
    type: "per month",
    commitment: "Free forever",
    plan: "Current plan",
  },
  {
    title: "Starter",
    subtitle: "Begin with skills-based hiring for all roles",
    amount: 75,
    save: 120,
    currency: "USD",
    type: "per month",
    commitment: "1-year commitment, pay $900 upfront.",
    plan: "Upgrade now",
  },
  {
    title: "Pro",
    subtitle: "Maximize your hiring with advanced features",
    amount: 115,
    save: 180,
    currency: "USD",
    type: "per month",
    commitment: "1-year commitment, pay $1,380 upfront.",
    plan: "Upgrade now",
  },
];

export const LoadingMap = [
  {
    index: 1,
  },
  {
    index: 2,
  },
  {
    index: 3,
  },
];
