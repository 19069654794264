import React from "react";
import Loader from "react-loader-spinner";
import TextField from "../Dexta/TextField/TextField";

const FeedbackModal = ({
  icon,
  header,
  setFeedbackModal,
  loading,
  buttonText,
  feedback,
  setFeedback,
  onClickButton,
}) => {
  return (
    <main className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="bg-black opacity-80 absolute inset-0"
        onClick={() => setFeedbackModal(false)}
      ></div>
      <div className="bg-white rounded-lg p-4 w-1/3 md:mx-auto relative">
        <div className="bg-white rounded-lg md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 mx-4 md:relative">
          <div className="md:flex items-center">
            <div className="rounded-full border border-gray-300 flex items-center justify-center w-16 h-16 mx-auto flex-shrink-0">
              <img src={icon} className="w-8 h-8" />
            </div>
            <div className="mt-4 md:mt-0 w-full md:ml-8 text-center md:text-left">
              <p className="font-bold" style={{ fontFamily: "Archia Bold" }}>
                {header}
              </p>
              <textarea
                style={{ fontFamily: "Silka" }}
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                className="resize-y rounded-lg border mt-3 h-[8rem] border-[#B6B6B6] sm:w-full flex mx-auto p-2"
                placeholder="Please tell us why you would like to unsubscribe. We would love to hear your feedback so we can continue to improve our offering."
              ></textarea>
            </div>
          </div>
          <div className="text-center md:text-right mt-8 md:flex md:justify-end">
            <button
              className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-primaryGreen text-coalColor hover:bg-coalColor hover:text-white border border-coalColor rounded-md text-sm md:ml-2 md:order-2"
              onClick={onClickButton}
              style={{ fontFamily: "Silka" }}
            >
              {loading ? (
                <span className="flex items-center justify-center">
                  <Loader type="Oval" color="black" height={20} width={20} />
                  <span className="ml-2">Deleting...</span>
                </span>
              ) : (
                <>{buttonText ? buttonText : "Delete Test"}</>
              )}
            </button>
            <button
              className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 hover:bg-primaryGreen hover:text-coalColor bg-coalColor border border-coalColor text-white rounded-md text-sm mt-4 md:mt-0 md:order-1"
              onClick={() => setFeedbackModal(false)}
              style={{ fontFamily: "Silka" }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default FeedbackModal;
