import React, { useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handlePath = () => {
    window.location = "https://dexta.io/";
  };

  return (
    <div className="bg-bodyColor md:py-10">
      <div className="max-w-3xl mx-auto bg-white shadow-md pb-20 md:h-[850px] overflow-hidden md:rounded-lg">
        <div className="bg-[#252E3A] p-8">
          <h2
            className="text-white text-center text-2xl"
            style={{ fontFamily: "Archia Bold" }}
          >
            Privacy Policy
          </h2>
          <p
            className="text-white text-[11px] text-center"
            style={{ fontFamily: "Silka" }}
          >
            Version v1 -June 2024
          </p>
        </div>
        <Scrollbars
          autoHeight
          autoHeightMin={0}
          autoHeightMax="calc(100vh - 200px)"
          style={{ width: "100%", overflowX: "hidden" }}
        >
          <div className="p-8">
            <p className="" style={{ fontFamily: "Archia Bold" }}>
              Introduction
            </p>
            <p className="mb-4 text-xs" style={{ fontFamily: "Silka" }}>
              Career Tech LTD, a UK private limited company registered with the
              Registrar of Companies for England and Wales under company number
              13346925 (“Dexta,” “we,” “us,” or “our”) is committed to
              protecting and respecting your privacy. This privacy policy
              (“Privacy Policy”) explains how we collect, use, share, and
              protect data that identifies or is associated with you (“personal
              data”) in relation to our website{" "}
              <span
                className="cursor-pointer text-blue-500"
                onClick={handlePath}
              >
                www.dexta.io
              </span>{" "}
              including the services offered on such website (the “Platform”),
              and your choices about the collection and use of your information.
              Dexta operates a testing platform specifically for the Banking and
              Financial Services industry, allowing employers and customers
              (“customers”) to instruct candidates (“Candidates”) to take
              aptitude, personality, and skills tests through the Platform. This
              Privacy Policy applies to you as an individual accessing and using
              our Platform to take these tests (“Candidate” or “User”). To use
              our Platform, Dexta needs to process certain personal data of the
              Users. This Privacy Policy applies to all acts of processing of
              personal data where Dexta acts as a controller in the sense of the
              General Data Protection Regulation (“GDPR”) or applicable UK
              privacy laws. Before accessing or using the Platform, please
              ensure that you have read and understood our Privacy Policy.
            </p>
            <p className=" mt-6 " style={{ fontFamily: "Archia Bold" }}>
              1. What kind of information do we collect?
            </p>
            <span>
              <p className=" mt-2  " style={{ fontFamily: "Archia Bold" }}>
                Information we collect when you use our platform:
              </p>
              <p className="mb-4  text-xs" style={{ fontFamily: "Silka" }}>
                Below are the types of personal data we may require from you and
                which we process to provide our services on the Platform:
              </p>
              <ul className="list-disc pl-5 pl-10 text-xs">
                <li style={{ fontFamily: "Silka" }} className=" text-xs">
                  Personal identification information (e.g., name, email
                  address, phone number)
                </li>
                <li style={{ fontFamily: "Silka" }} className=" text-xs">
                  Professional information (e.g., job title, employer, industry)
                </li>
                <li style={{ fontFamily: "Silka" }} className=" text-xs">
                  Test data and results
                </li>
                <li style={{ fontFamily: "Silka" }} className=" text-xs">
                  Usage data (e.g., IP address, browser type, access times)
                </li>
              </ul>
            </span>
            <p className="mt-6  " style={{ fontFamily: "Archia Bold" }}>
              2. For what purposes do we use your personal data?
            </p>
            <p className="mb-4  text-xs" style={{ fontFamily: "Silka" }}>
              <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>
                {" "}
                2.1 Service provision:
              </span>{" "}
              We use the information to carry out and administer assessment
              tests and share the results with future employers. Alternatively,
              we use the information to match your skills and interests with
              selected future employers.
            </p>
            <p className="mb-4  text-xs " style={{ fontFamily: "Silka" }}>
              <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>
                {" "}
                2.2 Communication:
              </span>{" "}
              Sending emails, newsletters, and other messages to keep you
              informed of our services and the Platform, including your test
              results. You may opt out of receiving any or all of these
              communications from us by following the unsubscribe link. We also
              use personal data to handle inquiries and complaints made by you
              regarding the Platform.
            </p>
            <p className="mb-4  text-xs" style={{ fontFamily: "Silka" }}>
              <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>
                2.3 Website monitoring:
              </span>{" "}
              To check the Platform and our other technology services are being
              used appropriately and to optimize their functionality.
            </p>
            <p className="mb-4  text-xs" style={{ fontFamily: "Silka" }}>
              <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>
                2.4 Platform optimization:
              </span>{" "}
              Improve, test, and monitor the effectiveness of our Platform and
              diagnose or fix technology problems.
            </p>
            <p className="mb-4  text-xs " style={{ fontFamily: "Silka" }}>
              <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>
                2.5 Managing suppliers:
              </span>{" "}
              Who deliver services to us.
            </p>
            <p className="mb-4  text-xs" style={{ fontFamily: "Silka" }}>
              <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>
                2.6 Easy access:
              </span>{" "}
              To help you efficiently access your information after you sign in
              and to remember information, so you will not have to re-enter it
              during your visit or the next time you visit the Platform.
            </p>
            <p className="mb-4  text-xs" style={{ fontFamily: "Silka" }}>
              <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>
                2.7 Statistics:{" "}
              </span>{" "}
              Monitor metrics such as total number of visitors, traffic,
              demographic patterns, and patterns in our test results (on an
              anonymized and aggregated basis).
            </p>
            <p className="mb-4  text-xs" style={{ fontFamily: "Silka" }}>
              <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>
                2.8 Development:{" "}
              </span>{" "}
              Develop and test new products and features and improve our tests
              and Platform.
            </p>
            <p className="mb-4  text-xs" style={{ fontFamily: "Silka" }}>
              <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>
                2.9 Benchmarks:{" "}
              </span>{" "}
              Use aggregated and anonymized test scores and aggregated
              demographics to provide benchmarks to our customers and improve
              our services.
            </p>
            <p className="mt-2  " style={{ fontFamily: "Archia Bold" }}>
              3. Our legal grounds for using your personal data
            </p>
            <p className="mb-4  text-xs" style={{ fontFamily: "Silka" }}>
              We will process your personal data for several reasons:
            </p>
            <p className="mb-4  text-xs " style={{ fontFamily: "Silka" }}>
              <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>
                3.1 Consent:
              </span>{" "}
              Where we are processing personal data with your consent, you can
              withdraw your consent at any time by following the instructions
              for doing so in our communications.
            </p>
            <p className="mb-4  text-xs" style={{ fontFamily: "Silka" }}>
              <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>
                3.2 Contract:
              </span>{" "}
              The processing is necessary for a contract we have with you, or
              because you have asked us to take specific steps before entering
              into a contract.
            </p>
            <p className="mb-4  text-xs" style={{ fontFamily: "Silka" }}>
              <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>
                3.3 Legal obligation:
              </span>{" "}
              The processing is necessary to comply with the law (not including
              contractual obligations).
            </p>
            <p className="mb-4  text-xs" style={{ fontFamily: "Silka" }}>
              <span className="mb-4 " style={{ fontFamily: "Archia Bold" }}>
                3.4 Legitimate interests:
              </span>{" "}
              The processing is necessary for our legitimate interests or the
              legitimate interests of a third party unless there is a good
              reason to protect your personal data which overrides those
              legitimate interests.
            </p>
            <p className="mt-2 " style={{ fontFamily: "Archia Bold" }}>
              4. Sharing and disclosure of your information
            </p>
            <p className="mb-4  text-xs" style={{ fontFamily: "Silka" }}>
              We may share your personal data with:
            </p>
            <ul className="list-disc pl-5 pl-10 text-xs">
              <li style={{ fontFamily: "Silka" }} className=" text-xs">
                Future employers – to the extent required to enable us to match
                your skills and interests with selected future employers and
                administer assessment tests and share the results.
              </li>
              <li style={{ fontFamily: "Silka" }} className=" text-xs">
                Service providers – who perform functions and provide services
                to us, including our clients (i.e., the employer or recruitment
                company), IT service providers, and other third parties who
                support the delivery of our products and services.
              </li>
              <li style={{ fontFamily: "Silka" }} className=" text-xs">
                Law enforcement – to the extent required by law, court order, or
                if we believe it is necessary to detect, prevent, or address
                fraud or other illegal activity or protect our rights or the
                rights of others.
              </li>
            </ul>
            <p className="mt-2  " style={{ fontFamily: "Archia Bold" }}>
              5. Your rights
            </p>
            <p className="mb-4  text-xs" style={{ fontFamily: "Silka" }}>
              Depending on the jurisdiction, you may have the following rights
              regarding your personal data:
            </p>
            <ul className="list-disc pl-5 pl-10 text-xs">
              <li style={{ fontFamily: "Silka" }} className=" text-xs">
                Right to access – the right to be informed about and request
                access to the personal data we process about you.
              </li>
              <li style={{ fontFamily: "Silka" }} className=" text-xs">
                Right to rectification – the right to request that we amend or
                update your personal data where it is inaccurate or incomplete.
              </li>
              <li style={{ fontFamily: "Silka" }} className=" text-xs">
                Right to erasure – the right to request that we delete your
                personal data.
              </li>
              <li style={{ fontFamily: "Silka" }} className=" text-xs">
                Right to restrict – the right to request that we temporarily or
                permanently stop processing all or some of your personal data.
              </li>
              <li style={{ fontFamily: "Silka" }} className=" text-xs">
                Right to object – the right, at any time, to object to us
                processing your personal data on grounds relating to your
                particular situation; the right to object to your personal data
                being processed for direct marketing purposes.
              </li>
              <li style={{ fontFamily: "Silka" }} className=" text-xs">
                Right to data portability – the right to request a copy of your
                personal data in electronic format and the right to transmit
                that personal data for use in another party’s service.
              </li>
              <li style={{ fontFamily: "Silka" }} className=" text-xs">
                Right not to be subject to automated decision-making – the right
                to not be subject to a decision based solely on automated
                decision-making, including profiling, where the decision would
                have a legal effect on you or produce a similarly significant
                effect.
              </li>
            </ul>
            <p className="mb-4  text-xs" style={{ fontFamily: "Silka" }}>
              Please note that these rights are not absolute, and we may be
              entitled to refuse requests where exceptions apply. To exercise
              your rights, please contact us using the details set out at the
              end of this Privacy Policy.
            </p>
            <p className="mt-2  " style={{ fontFamily: "Archia Bold" }}>
              6. International data transfers
            </p>
            <p className="mb-4  text-xs" style={{ fontFamily: "Silka" }}>
              We may transfer your personal data to, and process your personal
              data in, countries other than the country in which you are
              resident. We will protect your personal data in accordance with
              this Privacy Policy wherever it is processed and will take
              appropriate contractual or other steps to protect the relevant
              personal data in accordance with applicable laws.
            </p>
            <p className="mt-2  " style={{ fontFamily: "Archia Bold" }}>
              7. Security of your personal data
            </p>
            <p className="mb-4  text-xs" style={{ fontFamily: "Silka" }}>
              We implement appropriate technical and organizational measures to
              protect your personal data against accidental or unlawful
              destruction, loss, alteration, unauthorized disclosure of, or
              access to personal data transmitted, stored, or otherwise
              processed by us.
            </p>
            <p className="mt-2  " style={{ fontFamily: "Archia Bold" }}>
              8. Retention of your personal data
            </p>
            <p className="mb-4  text-xs" style={{ fontFamily: "Silka" }}>
              We will only retain your personal data for as long as necessary to
              fulfill the purposes we collected it for, including for the
              purposes of satisfying any legal, accounting, or reporting
              requirements. To determine the appropriate retention period for
              personal data, we consider the amount, nature, and sensitivity of
              the personal data, the potential risk of harm from unauthorized
              use or disclosure of your personal data, the purposes for which we
              process your personal data, and whether we can achieve those
              purposes through other means, and the applicable legal
              requirements.
            </p>
            <p className="mt-2  " style={{ fontFamily: "Archia Bold" }}>
              9. Changes to this Privacy Policy
            </p>
            <p className="mb-4  text-xs" style={{ fontFamily: "Silka" }}>
              We may update this Privacy Policy from time to time in response to
              changing legal, technical, or business developments. When we
              update our Privacy Policy, we will take appropriate measures to
              inform you, consistent with the significance of the changes we
              make. We will obtain your consent to any material Privacy Policy
              changes if and where this is required by applicable data
              protection laws. We encourage you to periodically review this page
              for the latest information on our privacy practices.
            </p>
            <p className="mt-2  " style={{ fontFamily: "Archia Bold" }}>
              10. Contact us
            </p>
            <p className="mb-4  text-xs" style={{ fontFamily: "Silka" }}>
              If you have any questions or concerns about our use of your
              personal data, please contact us at:
            </p>
            <p className="text-xs" style={{ fontFamily: "Silka" }}>
              Career Tech LTD
            </p>
            <p className="text-xs" style={{ fontFamily: "Silka" }}>
              Registered address: Unit 4, Vista Place, Coy Pond Business Park,
              Ingworth Road, Poole, Dorset, England, BH12 1JY
            </p>
            <p className="text-xs" style={{ fontFamily: "Silka" }}>
              Email: support@dexta.io
            </p>
            <p className="mt-2 text-xs" style={{ fontFamily: "Silka" }}>
              Date of last update: June 2024
            </p>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
