import React, { useEffect, useState, useRef } from "react";
import { FaStar } from "react-icons/fa";
import styles from "../../../Candidates/Feedback/styling.module.css";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { getAllJobs } from "../../MyAssessments/Assessments-main/hooks/getAlljobs";
import { useSelector } from "react-redux";
import { GoArrowRight } from "react-icons/go";
import NavbarPreviews from "./Navbar/Navbar";
import {
  gender,
  candidate_statuses,
  highestEducation,
  ethnicity,
  language,
} from "../../../../data/mapData";
import Scrollbars from "react-custom-scrollbars";
import http from "../../../../http";

const FeedbackScreen = () => {
  const previewData = useSelector((state) => state.preview.setPreview);
  const [clicked, setClicked] = useState([true, true, true, true, true]);
  const [feedback, setFeedback] = useState("");
  const educationref = useRef(null);
  const fieldRef = useRef(null);
  const genderRef = useRef(null);
  const countryRef = useRef(null);
  const ethRef = useRef(null);
  const langRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchCountry, setsearchCountry] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [jobs, setJobs] = useState([]);
  const candidateRef = useRef(null);
  const expRef = useRef(null);

  //#region useStates for dropdowns
  //education
  const [educationLevel, setEducationLevel] = useState("");
  const [educationDrop, setEducationDrop] = useState(false);

  //study
  const [study, setStudy] = useState("");

  //field
  const [field, setField] = useState("");
  const [fieldDrop, setFieldDrop] = useState(false);

  //experience years
  const [yearexp, setyearexp] = useState("");

  //gender
  const [gen, setGender] = useState("");
  const [gendrop, setGendrop] = useState(false);

  //born year
  const [born, setBorn] = useState("");

  //country Dropdown
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countryDrop, setCountryDrop] = useState(false);

  //ethnicity
  const [eth, setEth] = useState("");
  const [ethDrop, setEthDrop] = useState(false);

  //Language
  const [lang, setLang] = useState("");
  const [langDrop, setLangDrop] = useState(false);

  //candidate status
  const [candidate, setCandidate] = useState("");
  const [candidateDropdown, setCandidateDropdown] = useState(false);

  //year
  const [selectedYear, setSelectedYear] = useState("");
  const [yearDropdown, setYearDropdown] = useState(false);
  //#endregion

  //year
  const [selectedYearExp, setYearExp] = useState("");
  const [expDropdown, setExpDropdown] = useState(false);
  //#endregion

  //#region Fetching Countries from API
  useEffect(() => {
    const getCountry = async () => {
      try {
        const response = await http.get("/countries?limit=249");
        const countryNames = Object.values(response.data.data)
          .map((country) => country.country)
          .sort();
        setCountries(countryNames);
      } catch (error) {
        console.error("Error fetching countries: ", error);
      }
    };
    getCountry();
  }, []);
  //#endregion

  //#region Handling refs
  useEffect(() => {
    function handleoutsideeducation(event) {
      if (
        educationref.current &&
        !educationref.current.contains(event.target) &&
        event.target.id !== "education" &&
        event.target.id !== "education2" &&
        !event.target.closest(".edu")
      ) {
        setEducationDrop(false);
      }
    }
    document.addEventListener("mousedown", handleoutsideeducation);
    return () => {
      document.removeEventListener("mousedown", handleoutsideeducation);
    };
  }, [educationref]);

  useEffect(() => {
    function handleoutsideField(event) {
      if (
        fieldRef.current &&
        !fieldRef.current.contains(event.target) &&
        event.target.id !== "field" &&
        !event.target.closest(".fieldclass")
      ) {
        setFieldDrop(false);
      }
    }
    document.addEventListener("mousedown", handleoutsideField);
    return () => {
      document.removeEventListener("mousedown", handleoutsideField);
    };
  }, [fieldRef]);

  useEffect(() => {
    function handleOutsideGender(event) {
      if (
        genderRef.current &&
        !genderRef.current.contains(event.target) &&
        event.target.id !== "gender" &&
        !event.target.closest(".genderClass")
      ) {
        setGendrop(false);
      }
    }
    document.addEventListener("mousedown", handleOutsideGender);
    return () => {
      document.removeEventListener("mousedown", handleOutsideGender);
    };
  }, [genderRef]);

  useEffect(() => {
    function handleOutsideCountry(event) {
      if (
        countryRef.current &&
        !countryRef.current.contains(event.target) &&
        event.target.id !== "country" &&
        !event.target.closest(".countryClass")
      ) {
        setCountryDrop(false);
      }
    }
    document.addEventListener("mousedown", handleOutsideCountry);
    return () => {
      document.removeEventListener("mousedown", handleOutsideCountry);
    };
  }, [countryRef]);

  useEffect(() => {
    function handleOutsideEthnicity(event) {
      if (
        ethRef.current &&
        !ethRef.current.contains(event.target) &&
        event.target.id !== "ethnicity" &&
        !event.target.closest(".ethClass")
      ) {
        setEthDrop(false);
      }
    }
    document.addEventListener("mousedown", handleOutsideEthnicity);
    return () => {
      document.removeEventListener("mousedown", handleOutsideEthnicity);
    };
  }, [ethRef]);

  useEffect(() => {
    function handleOutsideLanguage(event) {
      if (
        candidateRef.current &&
        !candidateRef.current.contains(event.target) &&
        event.target.id !== "candidate" &&
        !event.target.closest(".candidateClass")
      ) {
        setCandidateDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleOutsideLanguage);
    return () => {
      document.removeEventListener("mousedown", handleOutsideLanguage);
    };
  }, [candidateRef]);

  useEffect(() => {
    function handleOutsideLanguage(event) {
      if (
        langRef.current &&
        !langRef.current.contains(event.target) &&
        event.target.id !== "language" &&
        !event.target.closest(".langClass")
      ) {
        setLangDrop(false);
      }
    }
    document.addEventListener("mousedown", handleOutsideLanguage);
    return () => {
      document.removeEventListener("mousedown", handleOutsideLanguage);
    };
  }, [langRef]);

  //#endregion

  //#region Handling stars
  const handleStarClick = (e, index) => {
    e.preventDefault();
    let clickStates = [...clicked];
    for (let i = 0; i < 5; i++) {
      if (i <= index) clickStates[i] = true;
      else clickStates[i] = false;
    }
    setClicked(clickStates);
  };
  //#endregion

  //#region handling button styling
  const buttonStyle = {
    background: isHovered ? previewData[1] : previewData[1],
    transition: "background-color 0.1s, transform 0.1s",
    transform: isHovered ? "scale(1.02)" : "scale(1)",
    color: previewData[2],
    border: `1px solid ${"#252E3A"}`,
    fontFamily: "Silka",
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };
  //#endregion

  const {
    data: jobData,
    isLoading: jobLoading,
    error: jobError,
  } = useQuery({
    queryKey: ["jobs", currentPage, field],
    queryFn: () => getAllJobs(currentPage, field),
    onSuccess: (data) => {
      setJobs((prevJobs) => {
        const newJobs = [...prevJobs, ...data?.data];
        const uniqueJobs = Array.from(
          new Set(newJobs.map((job) => job.id))
        ).map((id) => newJobs.find((job) => job.id === id));
        return uniqueJobs;
      });
    },
  });

  console.log(jobs);

  //#endregion

  //#region filtering experiences and countries
  const filteredExperiences = jobs?.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredCountries = countries.filter((item) =>
    item.toLowerCase().includes(searchCountry.toLowerCase())
  );
  //#endregion

  //#region closing tab on onClick
  const closeTab = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };
  //#endregion
  document.title = "Feedback | Dexta";

  return (
    <>
      <NavbarPreviews previewData={previewData} fixed={true} marginTop={true} />
      <div className="bg-bodyColor">
        <div className="mx-auto lg:container">
          <div className="flex justify-center mt-20 pb-20">
            <div className="w-5/6 mt-10 rounded-md bg-white">
              <div className="rounded-lg">
                <div className="lg:p-8 lg:px-12 sm:max-md:p-6">
                  <h1
                    className="text-3xl text-[#000000] text-center mt-4 font-bold"
                    style={{ fontFamily: "Archia Bold" }}
                  >
                    Thank You
                  </h1>
                  <p
                    className="text-[#767676] mt-2 text-sm text-center"
                    style={{ fontFamily: "Silka" }}
                  >
                    You’ve finished your test. How was your experience?
                  </p>
                  <div className="flex flex-row justify-center mt-5 pb-5">
                    <div className={styles.rating}>
                      <div className="flex flex-row gap-1">
                        <FaStar
                          size={40}
                          onClick={(e) => handleStarClick(e, 0)}
                          className={
                            clicked[0] ? styles.clickedstar : styles.notclicked
                          }
                        />
                        <FaStar
                          size={40}
                          onClick={(e) => handleStarClick(e, 1)}
                          className={
                            clicked[1] ? styles.clickedstar : styles.notclicked
                          }
                        />
                        <FaStar
                          size={40}
                          onClick={(e) => handleStarClick(e, 2)}
                          className={
                            clicked[2] ? styles.clickedstar : styles.notclicked
                          }
                        />
                        <FaStar
                          size={40}
                          onClick={(e) => handleStarClick(e, 3)}
                          className={
                            clicked[3] ? styles.clickedstar : styles.notclicked
                          }
                        />
                        <FaStar
                          size={40}
                          onClick={(e) => handleStarClick(e, 4)}
                          className={
                            clicked[4] ? styles.clickedstar : styles.notclicked
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <textarea
                    style={{ fontFamily: "Silka" }}
                    className="resize-y rounded-lg border border-[#B6B6B6] sm:w-full lg:w-3/4 flex mx-auto p-2"
                    placeholder="Please explain..."
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                  ></textarea>
                  <div className="sm:w-full lg:w-3/4 flex mx-auto">
                    <div className="py-8 sm:px-2 md:px-10 mt-3 shadow-xl rounded-lg bg-[#252E3A33]">
                      <div
                        className="text-coalColor text-sm text-center"
                        style={{ fontFamily: "Silka" }}
                      >
                        <span>
                          To help make sure that our test provide equal
                          opportunities for everyone. We statistically analyze
                          test results anonymously. By sharing this information
                          about your background, you help us improve our tests
                          for all candidates. This information will not be
                          shared with your potential employer. Sharing this
                          information with us is optional, and anything shared
                          will be held in compliance with our{" "}
                          <a
                            href={
                              process.env.REACT_APP_STATIC_SITE +
                              "/PrivacyPolicy"
                            }
                            style={{ fontFamily: "Archia Bold" }}
                            className="font-bold text-coalColor"
                          >
                            Privacy Policy
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* First two Dropdowns code*/}
                  <div className="w-full flex mx-auto mt-10 lg:px-12 relative">
                    <div className="w-full justify-center gap-5 flex sm:flex-col md:flex-row">
                      <div
                        className="flex flex-col sm:w-full lg:w-2/5 gap-3 relative"
                        style={{ fontFamily: "Silka" }}
                      >
                        <button
                          id="education"
                          data-dropdown-toggle="education-drop"
                          className={` border font-medium rounded-lg text-sm px-5 w-full py-2.5 text-center flex items-center`}
                          style={{
                            borderColor: educationDrop ? "#252E3A" : "#B6B6B6",
                            color: educationLevel != "" ? "#252E3A" : "#999999",
                            borderColor:
                              educationLevel != "" ? "#252E3A" : "#B6B6B6",
                          }}
                          type="button"
                          onClick={(e) => {
                            setEducationDrop(!educationDrop);
                          }}
                        >
                          {educationLevel != ""
                            ? educationLevel
                            : "Highest education level"}
                          <svg
                            className="w-2.5 h-2.5 ml-auto"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 10 6"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 1 4 4 4-4"
                            />
                          </svg>
                        </button>
                        {educationDrop && (
                          <div className="edu absolute z-40 border border-coalColor right-0 top-full h-[80 overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2">
                            {highestEducation?.map((i) => (
                              <ul
                                key={i.title}
                                className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                ref={educationref}
                                onClick={() => {
                                  setEducationLevel(i?.title);
                                  setEducationDrop(false);
                                }}
                              >
                                <li>
                                  {" "}
                                  <a className="block px-5 py-2 text-sm font-medium">
                                    {" "}
                                    {i.title}{" "}
                                  </a>
                                </li>
                              </ul>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col sm:w-full lg:w-2/5 gap-3 relative">
                        <input
                          className={` border border-[#B6B6B6] font-medium rounded-lg  text-sm px-5 w-full py-2.5 text-left flex items-center `}
                          style={{
                            color: study != "" ? "#252E3A" : "#999999",
                            borderColor: study != "" ? "#252E3A" : "#B6B6B6",
                            fontFamily: "Silka",
                          }}
                          placeholder="What did you study"
                          value={study}
                          onChange={(e) => setStudy(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  {/* 3rd and 4th Dropdown code*/}
                  <div className="w-full flex mx-auto sm:mt-5 md:mt-10 md:px-12 relative">
                    <div className="w-full justify-center gap-5 flex sm:flex-col md:flex-row">
                      <div className="flex flex-col sm:w-full md:w-2/5 gap-3 relative">
                        <input
                          type="text"
                          id="field"
                          className={`border font-medium rounded-lg text-sm px-5 w-full py-2.5 text-left flex items-center focus:border-[#4a9bb9da]`}
                          style={{
                            color: field != "" ? "#252E3A" : "#999999",
                            borderColor: field != "" ? "#252E3A" : "#B6B6B6",
                            fontFamily: "Silka",
                          }}
                          placeholder="Most relevant experience"
                          value={field}
                          onChange={(e) => {
                            setField(e.target.value);
                            setSearchTerm(e.target.value);
                          }}
                          onClick={() => setFieldDrop(!fieldDrop)}
                        />
                        {fieldDrop && (
                          <div
                            className="fieldclass absolute z-40 border border-coalColor right-0 top-full h-80 overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                            id="experienceDropdown"
                            style={{ fontFamily: "Silka" }}
                          >
                            {filteredExperiences?.map((item, index) => (
                              <ul
                                key={index}
                                className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                ref={fieldRef}
                                onClick={() => {
                                  setField(item?.name);
                                  setFieldDrop(false);
                                }}
                              >
                                <li>
                                  <a className="block px-5 py-2 text-sm font-medium">
                                    {item.name}
                                  </a>
                                </li>
                              </ul>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col sm:w-full md:w-2/5 gap-3 relative">
                        <input
                          className={` border font-medium rounded-lg text-sm px-5 w-full py-2.5 text-left flex items-center focus:border-[#4a9bb9da]`}
                          style={{
                            color: yearexp != "" ? "#252E3A" : "#999999",
                            borderColor: yearexp != "" ? "#252E3A" : "#B6B6B6",
                            fontFamily: "Silka",
                          }}
                          placeholder="Years of experience in role"
                          value={yearexp}
                          onChange={(e) => setyearexp(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  {/* 5th and 6th Dropdown code*/}
                  <div className="w-full flex mx-auto sm:mt-5 md:mt-10 md:px-12 relative">
                    <div className="w-full justify-center gap-5 flex sm:flex-col md:flex-row">
                      <div className="flex flex-col sm:w-full md:w-2/5 gap-3 relative">
                        <button
                          id="gender"
                          className={` border font-medium rounded-lg text-sm px-5 w-full py-2.5 text-center flex items-center`}
                          style={{
                            fontFamily: "Silka",
                            borderColor: gendrop ? "#252E3A" : "#B6B6B6",
                            color: gen != "" ? "#252E3A" : "#999999",
                            borderColor: gen != "" ? "#252E3A" : "#B6B6B6",
                          }}
                          type="button"
                          onClick={() => setGendrop(!gendrop)}
                        >
                          {gen != "" ? gen : "Gender"}
                          <svg
                            className="w-2.5 h-2.5 ml-auto"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 10 6"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 1 4 4 4-4"
                            />
                          </svg>
                        </button>
                        {gendrop && (
                          <div
                            id="education-drop"
                            className="genderClass absolute z-40 border border-coalColor right-0 top-full h-auto overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                            style={{ fontFamily: "Silka" }}
                          >
                            {gender?.map((i) => (
                              <ul
                                key={i.title}
                                className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                ref={genderRef}
                                onClick={() => {
                                  setGender(i?.title);
                                  setGendrop(false);
                                }}
                              >
                                <li>
                                  <a className="block px-5 py-2 text-sm font-medium">
                                    {i.title}
                                  </a>
                                </li>
                              </ul>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col sm:w-full md:w-2/5 gap-3 relative">
                        <input
                          className={`border border-[#B6B6B6] font-medium rounded-lg text-sm px-5 w-full py-2.5 text-left flex items-center focus:border-[#4a9bb9da]`}
                          style={{
                            fontFamily: "Silka",
                            color: born != "" ? "#252E3A" : "#999999",
                            borderColor: born != "" ? "#252E3A" : "#B6B6B6",
                          }}
                          placeholder="What year were you born?"
                          value={born}
                          type="number"
                          onChange={(e) => setBorn(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  {/* 6th and 7th Dropdown code*/}
                  <div className="w-full flex mx-auto sm:mt-5 md:mt-10 md:px-12 relative">
                    <div className="w-full justify-center gap-5 flex sm:flex-col md:flex-row">
                      <div className="flex flex-col sm:w-full md:w-2/5 gap-3 relative">
                        <input
                          type="text"
                          id="country"
                          className={`border font-medium rounded-lg text-sm px-5 w-full py-2.5 text-left flex items-center focus:border-[#4a9bb9da]`}
                          style={{
                            fontFamily: "Silka",
                            color:
                              selectedCountry != "" ? "#252E3A" : "#999999",
                            borderColor:
                              selectedCountry != "" ? "#252E3A" : "#B6B6B6",
                          }}
                          placeholder="Country/Region of Residence"
                          value={selectedCountry}
                          onChange={(e) => {
                            setSelectedCountry(e.target.value);
                            setsearchCountry(e.target.value);
                          }}
                          onClick={() => setCountryDrop(!countryDrop)}
                        />
                        {countryDrop && (
                          <div
                            id="education-drop"
                            className="countryClass absolute z-40 border border-coalColor right-0 bottom-full h-80 overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                            style={{ fontFamily: "Silka" }}
                          >
                            {filteredCountries.map((i, index) => (
                              <ul
                                key={index}
                                className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                ref={countryRef}
                                onClick={() => {
                                  setSelectedCountry(i);
                                  setCountryDrop(false);
                                }}
                              >
                                <li>
                                  <a className="block px-5 py-2 text-sm font-medium">
                                    {i}
                                  </a>
                                </li>
                              </ul>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col sm:w-full md:w-2/5 gap-3 relative">
                        <button
                          id="ethnicity"
                          className={` border border-[#B6B6B6] font-medium rounded-lg text-sm px-5 w-full py-2.5 text-center flex items-center`}
                          style={{
                            fontFamily: "Silka",
                            borderColor: ethDrop ? "#252E3A" : "#B6B6B6",
                            color: eth != "" ? "#252E3A" : "#999999",
                            borderColor: eth != "" ? "#252E3A" : "#B6B6B6",
                          }}
                          type="button"
                          onClick={() => setEthDrop(!ethDrop)}
                        >
                          {eth != "" ? eth : "Ethnicity"}
                          <svg
                            className="w-2.5 h-2.5 ml-auto"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 10 6"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 1 4 4 4-4"
                            />
                          </svg>
                        </button>
                        {ethDrop && (
                          <div
                            id="education-drop"
                            className="ethClass absolute z-40 border border-coalColor right-0 top-full h-[11rem] overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                            style={{ fontFamily: "Silka" }}
                          >
                            {ethnicity.map((i, index) => (
                              <ul
                                key={index}
                                className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                ref={ethRef}
                                onClick={() => {
                                  setEth(i.title);
                                  setEthDrop(false);
                                }}
                              >
                                <li>
                                  <a className="block px-5 py-2 text-sm font-medium">
                                    {i.title}
                                  </a>
                                </li>
                              </ul>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* 8th Dropdown code*/}
                  <div className="w-full flex mx-auto sm:mt-5 md:mt-10 md:px-12 relative">
                    <div className="w-full justify-center gap-5 flex sm:flex-col md:flex-row">
                      <div className="flex flex-col sm:w-full md:w-2/5 gap-3 relative">
                        <button
                          id="candidate"
                          className={` border border-[#B6B6B6] font-medium rounded-lg text-sm px-5 w-full py-2.5 text-center flex items-center`}
                          style={{
                            fontFamily: "Silka",
                            borderColor: candidateDropdown
                              ? "#252E3A"
                              : "#B6B6B6",
                            color: candidate != "" ? "#252E3A" : "#999999",
                            borderColor:
                              candidate != "" ? "#252E3A" : "#B6B6B6",
                          }}
                          type="button"
                          onClick={() =>
                            setCandidateDropdown(!candidateDropdown)
                          }
                        >
                          {candidate != "" ? candidate : "Candidate Status"}
                          <svg
                            className="w-2.5 h-2.5 ml-auto"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 10 6"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 1 4 4 4-4"
                            />
                          </svg>
                        </button>

                        {candidateDropdown && (
                          <div
                            id="education-drop"
                            className="candidateClass absolute z-40 border enable-scrollbar2 border-coalColor right-0 top-full h-[12rem] overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                            style={{ fontFamily: "Silka" }}
                          >
                            <Scrollbars
                              autoHide
                              style={{ width: "100%", height: "100%" }}
                            >
                              {candidate_statuses.map((i, index) => (
                                <ul
                                  key={index}
                                  className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                  ref={candidateRef}
                                  onClick={() => {
                                    setCandidate(i.title);
                                    setCandidateDropdown(false);
                                  }}
                                >
                                  <li>
                                    <a className="block px-5 py-2 text-sm font-medium">
                                      {i.title}
                                    </a>
                                  </li>
                                </ul>
                              ))}
                            </Scrollbars>
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col sm:w-full md:w-2/5 gap-3 relative">
                        <button
                          id="language"
                          className={` border border-[#B6B6B6] font-medium rounded-lg text-sm px-5 w-full py-2.5 text-center flex items-center`}
                          style={{
                            fontFamily: "Silka",
                            borderColor: langDrop ? "#252E3A" : "#B6B6B6",
                            color: lang != "" ? "#252E3A" : "#999999",
                            borderColor: lang != "" ? "#252E3A" : "#B6B6B6",
                          }}
                          type="button"
                          onClick={() => setLangDrop(!langDrop)}
                        >
                          {lang != "" ? lang : "What is your first language"}
                          <svg
                            className="w-2.5 h-2.5 ml-auto"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 10 6"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 1 4 4 4-4"
                            />
                          </svg>
                        </button>
                        {langDrop && (
                          <div
                            id="education-drop"
                            className="langClass absolute z-40 border border-coalColor right-0 top-full h-[8rem] overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                            style={{ fontFamily: "Silka" }}
                          >
                            {language
                              .sort((a, b) => a?.title.localeCompare(b.title))
                              .map((i) => (
                                <ul
                                  key={i.title}
                                  className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                  ref={langRef}
                                  onClick={() => {
                                    setLang(i.title);
                                    setLangDrop(false);
                                  }}
                                >
                                  <li>
                                    <a className="block px-5 py-2 text-sm font-medium">
                                      {i.title}
                                    </a>
                                  </li>
                                </ul>
                              ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center sm:full md:w-1/6 mx-auto relative">
                    <button
                      className="inline-flex items-center w-full justify-center px-4 mt-10 py-4  hover:text-white text-white text-sm font-medium rounded-md"
                      style={buttonStyle}
                      onMouseEnter={handleHover}
                      onMouseLeave={handleLeave}
                      onClick={closeTab}
                    >
                      Submit
                      <GoArrowRight
                        alt="Add Transaction Icon"
                        className="w-5 h-5 ml-2 icon-image"
                      />
                    </button>
                    <div
                      className="tooltip w-[8rem] font-medium text-center"
                      style={{ fontFamily: "Silka" }}
                    >
                      Primary color
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FeedbackScreen;
