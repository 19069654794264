import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  plugins,
} from "chart.js";
import useWindowSize from "../../Helpers/useWindowSize";

// Register necessary Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MyBarChart = ({ data_students, sectionGraph = null }) => {
  let firstName = null;
  let lastName = null;
  const size = useWindowSize();
  const isMobile = size.width <= 640;
  let score = null;

  if (!sectionGraph) {
    firstName = data_students?.map((i) => i?.candidates?.firstName);
    lastName = data_students?.map((i) => i?.candidates?.lastName);
    score = data_students?.map((i) => Number(i?.score).toFixed(0));
  } else {
    firstName = data_students?.map((i) => i?.candidate.firstName);
    lastName = data_students?.map((i) => i?.candidate.lastName);
    score = data_students?.map((i) => Number(i?.section?.score).toFixed(0));
  }

  let fullNames = null;
  if (isMobile) {
    fullNames = firstName.map((firstName, index) => {
      return `${firstName}`;
    });
  } else {
    fullNames = firstName.map((firstName, index) => {
      return `${firstName} ${lastName[index]}`;
    });
  }
  const labels = fullNames;
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Score",
        data: score,
        backgroundColor: ["#FF5812"],
        borderColor: ["#FF5812"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        ticks: {
          maxRotation: 90,
          minRotation: 90,
          align: "start",
          font: {
            family: "Silka",
            size: 13,
          },
        },
        grid: {
          display: false,
          borderWidth: 20, // Make x-axis line bold
        },
        border: {
          color: "black",
        },
      },
      y: {
        ticks: {
          callback: function (value, index, ticks) {
            if (value === 0 || value === 100) {
              return value;
            }
            return null;
          },
          font: {
            family: "Silka",
            size: 12,
          },
        },
        beginAtZero: true,
        max: 100,
        grid: {
          display: true,
          drawOnChartArea: false,
          tick: {
            length: 0,
          },
        },
        border: {
          color: "black",
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          radius: 10,
          borderWidth: 2,
          font: {
            size: 14,
            family: "Silka",
          },
          color: "black",
        },
        padding: 20,
        margin: {
          left: 20,
        },
      },
      tooltip: {
        callbacks: {
          title: (context) => {
            return "";
          },
          label: (tooltipItem, data) => {
            return (
              `${tooltipItem?.dataset?.label}:` +
              " " +
              `${tooltipItem?.formattedValue}%`
            );
          },
        },
        // displayColors: false,
        // label: false,
        // backgroundColor: "#FF5812",
      },
    },
  };

  return <Bar data={data} options={options} height={200} />;
};

export default MyBarChart;
