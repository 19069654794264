import React from "react";
import PropTypes from "prop-types";
import withRouter from "../Common/withRouter"
import Navbar from "../Navbar/Navbar"
const NonAuthLayout = (props) => {
  return <React.Fragment>
  <Navbar/>
  {props.children}
  </React.Fragment>;
};

NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
};

export default withRouter(NonAuthLayout);
