import React, { useState } from "react";
import { FiDownload } from "react-icons/fi";
import { GrDocumentUpload } from "react-icons/gr";
import King from "../../Assets/preee.png";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import PremiumGeneral from "../Modals/PremiumGeneral";
import PremiumModaloverModal from "../Modals/PremiumModaloverModal";
// import csv from 'csv-parser';

const InviteByBulk = (props) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [fileName, setFileName] = useState(null);
  const user_package_check = useSelector(
    (state) => state.packageDetails.setPackage
  );
  const [premiumGeneralOpen, setPremiumGeneral] = useState(false);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setFileName(file.name);
    props.setFile(file);
  };

  const premiumOpen = () => {
    setPremiumGeneral(true);
  };

  return (
    <>
      <PremiumGeneral
        premiumGeneralOpen={premiumGeneralOpen}
        setPremiumGeneral={setPremiumGeneral}
      />
      <div className="grid grid-cols-1 lg:grid-cols-3 md:h-[25rem] mt-5 gap-4 2xl:gap-8 ">
        <div className="lg:col-span-2 bg-[#B3B3B3] px-3 md:px-6 py-6 2xl:py-8 md:mt-0 sm:mt-3 rounded-lg">
          <p className="text-base 2xl:text-lg font-medium">Keep in mind....</p>
          <p className="xl:mt-2 2xl:mt-4 text-sm 2xl:text-base">
            Make sure you use three columns in your file in the following order:
            First Name, Last Name, Email. You can only add 1200 candidates per
            file.
          </p>
          <div className="pr-4 2xl:pr-14 mt-2 2xl:mt-4">
            <div className="p-4 2xl:p-5 rounded-lg bg-white">
              <p className="font-medium">Example</p>
              <div className="relative overflow-x-auto mt-3 2xl:mt-5">
                <table className="w-full text-xs 2xl:text-sm text-left rtl:text-right">
                  <thead className="text-xs bg-gray-200 uppercase text-center">
                    <tr className="font-normal">
                      <th
                        scope="col"
                        className="px-2 py-2 bg-white border-t-0 border-l-0 border border-gray-400"
                      ></th>
                      <th
                        scope="col"
                        className="px-6 py-2 border-t-0 border border-gray-400"
                      >
                        A
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-2 border-t-0 border border-gray-400"
                      >
                        B
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-2 border-t-0 border border-gray-400"
                      >
                        C
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-1 bg-white border-t-0 border-l-0 border border-gray-400"
                      >
                        1
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-1 font-medium border-t-0 border border-gray-400"
                      >
                        First name
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-1 font-medium border-t-0 border border-gray-400"
                      >
                        Last name
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-1 font-medium border-t-0 border border-gray-400"
                      >
                        Email
                      </th>
                    </tr>
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-1 bg-white border-t-0 border-l-0 border border-gray-400"
                      >
                        2
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-1 text-xs 2xl:text-sm font-normal border-t-0 border border-gray-400"
                      >
                        Rumman
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-1 text-xs 2xl:text-sm font-normal border-t-0 border border-gray-400"
                      >
                        Mohsin
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-1 text-xs 2xl:text-sm font-normal border-t-0 border border-gray-400"
                      >
                        rumman@code-freaks.com
                      </th>
                    </tr>
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-1 bg-white border-t-0 border-l-0 border border-gray-400"
                      >
                        3
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-1 text-xs font-normal border-t-0 border border-gray-400"
                      >
                        Saad
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-1 text-xs font-normal border-t-0 border border-gray-400"
                      >
                        Farooq
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-1 text-xs font-normal border-t-0 border border-gray-400"
                      >
                        saad@code-freaks.com
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="flex flex-row mt-5 2xl:mt-3">
            <p className="my-auto mr-2 text-xs 2xl:text-base">
              You can download one of these templates
            </p>
            <div className="gap-3 flex ml-auto">
              <a
                className="border border-black px-2 2xl:px-4 py-1 text-xs my-auto 2xl:text-lg rounded flex flex-row gap-2"
                href="https://cp-assessment.s3.eu-west-2.amazonaws.com/csv.csv"
              >
                <FiDownload className="my-auto" />
                CSV
              </a>
              <a
                className="border border-black px-2 2xl:px-4 py-1 rounded my-auto text-xs 2xl:text-lg flex flex-row gap-2"
                href="https://cp-assessment.s3.eu-west-2.amazonaws.com/xlsx.xlsx"
              >
                <FiDownload className="my-auto" />
                XLSX
              </a>
            </div>
          </div>
        </div>

        {/* Upload Area */}
        <div className="h-full">
          <div
            className="border-dotted border-[2px] rounded-lg border-[#888] h-full 2xl:h-1/2 bg-[#f5f6f6] relative"
            onClick={() => {
              if (user_package_check !== "Enterprise") {
                premiumOpen();
              }
            }}
          >
            <label
              htmlFor="fileInput"
              className="cursor-pointer h-full flex flex-col justify-center items-center"
            >
              <div className="2xl:px-5 3xl:px-6">
                <GrDocumentUpload className="m-auto mt-5 2xl:mt-10 h-7 w-7" />
                <p className="text-xs 2xl:text-sm mt-4 text-center">
                  Drag and drop or <b>browse</b> your files.
                </p>
                <div className="text-center mt-2 2xl:mt-6 text-xs 2xl:text-sm">
                  {fileName ? (
                    <p>Selected file: {fileName}</p>
                  ) : (
                    <>
                      <p>You can upload up to</p>
                      <p>1200 candidates per file.</p>
                    </>
                  )}
                </div>
              </div>
              <input
                type="file"
                id="fileInput"
                className="hidden"
                accept=".xlsx, .csv"
                onChange={handleFileUpload}
                disabled={user_package_check !== "Enterprise"}
              />
            </label>
            {user_package_check !== "Enterprise" && (
              <span className="absolute top-0 right-0 -mt-2 -mr-2">
                <img src={King} alt="Premium Icon" className="w-6 h-6" />
              </span>
            )}
          </div>
          <p className="text-xs 2xl:text-sm mt-3 2xl:mt-1">
            <b className="mr-1">Supported file types:</b>
            .csv .xls .xlsx
          </p>
          {props?.barLoading && (
            <div className="bg-[#4A9CB9] h-1 w-full mt-2">
              <div
                className="bg-green-500 h-1"
                style={{ width: `${props.progress}%` }}
              ></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default InviteByBulk;
