import http from "../../http";

export const getEmailContent = async (assessment_id, user_id, emailType) => {
  const response = await http.get(
    `/email-content?order=ASC&page=1&take=10&assessmentId=${assessment_id}&userId=${user_id}&type=${
      emailType ? emailType : "INVITE"
    }`
  );
  return response.data;
};
