import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    setPreview: false,
}

export const PreviewSlice = createSlice({
    name:"previewBack",
    initialState,
    reducers:{
        setPreviewToTrue :(state, action)=>{
            state.setPreview = true
        },
        setPreviewToFalse :(state, action)=>{
            state.setPreview = false
        },
    },
})

export const { setPreviewToTrue, setPreviewToFalse } = PreviewSlice.actions;
export default PreviewSlice.reducer;