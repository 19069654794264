import https from "../../../https";

export const getInvite = async (code, email) => {
  let url = `/assessment/invite/${code}`;

  // Attach email query parameter only if it exists and is not undefined
  if (email) {
    const encodedEmail = encodeURIComponent(email);
    url += `?email=${encodedEmail}`;
  }

  const response = await https.get(url);
  return response.data;
};
