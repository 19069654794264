import http from "../../../../../http";

export const createCustomSet = (data) => {
  //   console.log(data?.section?.id, "hehehe");
  const response = http.patch(
    `/section/customQuestionSet/${data?.section?.id}`,
    data?.data
  );
  return response;
};
