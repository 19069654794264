import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Right from "../../../Assets/rite.png"

const Completed = () => {
    const navigate = useNavigate();

    return (
        <div className='bg-[#F0F0F0]'>
            <div className='mx-auto container'>
                <div className='flex justify-center h-screen'>
                    <div className='m-auto w-1/2 rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] bg-white'>
                        <div className='rounded-lg'>
                            <div className='p-8 px-12'>
                                <h1 className='text-lg text-[#000000] text-center mt-4'>You completed the Module ABC.</h1>
                                <p className='text-[#767676] mt-1 text-sm text-center'>Take a moment to relax, before you continue.</p>
                                <div className='ml-auto my-auto  mr-auto w-1/3 mt-10 pb-5'>
                                    <button className="inline-flex items-center w-full justify-center px-6 py-3 my-auto bg-[#4A9CB9] hover:bg-[#4A9CB9]/90 text-white text-sm font-medium rounded-md"
                                        onClick={() => {
                                            localStorage.removeItem("exam")
                                            navigate("/feedback")
                                        }}>
                                        Next test
                                        <img src={Right} alt="Add Transaction Icon" className="w-5 h-5 ml-2 icon-image" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Completed