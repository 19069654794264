import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UpdateSteps } from "./hooks/updateSteps";
import { TimeSlots } from "../../../../data/mapData";
const Step1 = () => {
  const [selectedItem, setSelectedItem] = useState("");
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(UpdateSteps, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("/users");
    },
  });

  const handleClick = () => {
    let data = JSON.stringify({
      peoplePlanToHire: selectedItem,
    });
    try {
      mutate(data);
    } catch {
      //react-query will handle this
    }
  };
  return (
    <div className="mx-auto container">
      <div className="flex justify-center h-screen">
        <div className="m-auto">
          <div
            className="text-center px-2 text-2xl"
            style={{ fontFamily: "Archia Bold" }}
          >
            <h1>How many people do you plan on hiring</h1>
            <h1>in the next 12 months?</h1>
          </div>
          <div className="w-full border mt-5 border-coalColor rounded-lg">
            <div className="p-4">
              <div
                className="grid grid-cols-2 gap-4"
                style={{ fontFamily: "Silka" }}
              >
                {TimeSlots.map((i) => (
                  <button
                    type="button"
                    className={`text-black border border-coalColor font-bold ${
                      selectedItem === i.slot
                        ? "bg-coalColor text-white"
                        : "bg-primaryGreen"
                    } hover:bg-coalColor hover:text-white focus:outline-none rounded-lg text-sm w-full py-4 text-center mr-3 md:mr-0`}
                    style={{ fontFamily: "Inter" }}
                    onClick={() => setSelectedItem(i.slot)}
                  >
                    {i.slot}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <button
              type="button"
              className={`flex mt-4 focus:outline-none font-medium ${
                selectedItem === ""
                  ? "bg-[#D3D5D8] text-[#7C8289] cursor-not-allowed"
                  : "bg-coalColor text-white"
              } rounded-md text-sm px-8 py-2 text-center md:mr-0`}
              disabled={selectedItem === ""}
              onClick={() => {
                navigate("/step-2");
                handleClick();
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
