import React, { useState, useEffect } from 'react';
import "./s.css";

const TextFieldCustom = ({ name, id, isPasswordVisible, placeholder = null, type = "text", label, value, onChangeValue, onBlur = null, setIsPasswordVisible, passwordIcon = null, icon: Icon, onClick = null, additionalClasses, imgIcon = null }) => {
    const [focused, setFocused] = useState(false);
    const [autofilled, setAutofilled] = useState(false); // State to track autofill for this field

    const handleFocus = () => {
        setFocused(true);
    };

    const handleBlur = () => {
        setFocused(false);
    };

    const handleAutofill = (e) => {
        // Update the autofill state only for this field
        setAutofilled(true);
        onChangeValue(e); // Update value
    };

    useEffect(() => {
        // Reset autofill state when field value changes manually
        if (value !== "" && autofilled) {
            setAutofilled(false);
        }
    }, [value, autofilled]);

    return (
        <div className={`relative mb-1 ${focused || autofilled ? 'focused' : ''}`} data-te-input-wrapper-init style={{ fontFamily: "Silka" }}>
            <input
                name={name}
                type={type}
                className={`peer block min-h-[auto] ${additionalClasses && additionalClasses} w-full rounded border focus:border-coalColor bg-transparent px-3 py-[0.5rem] leading-[2.10] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 ${value ? 'border-coalColor' : 'border-neutral-300'}`}
                id={id}
                value={value}
                onChange={onChangeValue}
                onBlur={(e) => { handleBlur(); onBlur && onBlur(e); }}
                onFocus={handleFocus}
                onClick={onClick}
                onInput={handleAutofill} // Handle autofill event
            />
            <label
                htmlFor={id}
                className={`absolute left-3 pointer-events-none top-3  text-base transition-all duration-200 ease-out ${focused || value || autofilled ? '-translate-y-5 text-coalColor text-xs bg-white px-1' : 'text-neutral-500'}`}
            >
                {autofilled ? label : (value === "" ? placeholder : label)}
            </label>
            {passwordIcon && (
                <div
                    className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600 cursor-pointer"
                    onClick={() => { setIsPasswordVisible(!isPasswordVisible) }}
                >
                    {isPasswordVisible ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                        >
                            {/* Password visibility toggle SVG */}
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                        >
                            {/* Password visibility toggle SVG */}
                        </svg>
                    )}
                </div>
            )}
            {Icon && (
                <div
                    className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600 cursor-pointer">
                    <Icon className={`my-auto ml-auto w-5 h-5`} />
                </div>
            )}
            {imgIcon && (
                <div
                    className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600 cursor-pointer">
                    <img src={imgIcon} className={`my-auto ml-auto w-5 h-5`} />
                </div>
            )}
        </div>
    );
};

export default TextFieldCustom;
