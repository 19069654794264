import { useRef, useEffect, useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useSelector } from "react-redux";
import baas from "../../../../Assets/baas.png";
import Right from "../../../../Assets/rightarrow.png";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import NavbarPreviews from "./Navbar/Navbar";
import { FaCamera } from "react-icons/fa";
import Webcam from "react-webcam";
import { GoArrowRight } from "react-icons/go";

const WebcamScreen = () => {
  const previewData = useSelector((state) => state.preview.setPreview);
  const [isHovered, setIsHovered] = useState(false);
  const [camaccess, setcamaccess] = useState(false);
  const [deniedModal, setDeniedModal] = useState(false);
  const [denied, setDenied] = useState(false);
  const [fakeLoad, setFakeLoad] = useState(true);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const webcamRef = useRef(null);
  const videoRef = useRef({});
  const [stepNumber, setStepNumber] = useState(0);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    user_email: "",
  });

  //#region Handling input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };
  //#endregion

  //#region Styling of continue button
  const buttonStyle = {
    background: isHovered ? previewData[1] : previewData[1],
    transition: "background-color 0.1s, transform 0.1s",
    transform: isHovered ? "scale(1.02)" : "scale(1)",
    color: previewData[2],
    border: `1px solid ${previewData[2]}`,
    fontFamily: "Silka",
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };
  //#endregion

  //#region closing tab on onClick
  const closeTab = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };
  //#endregion

  //#region handling cam
  const getUserMedia = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: false,
      });
      const videoTrack = stream.getVideoTracks()[0];
      videoTrack.onended = () => {
        setIsCameraOn(false);
      };

      videoRef.current.srcObject = stream;
      setIsCameraOn(true);
    } catch (err) {}
  };
  useEffect(() => {
    getUserMedia();
  }, [videoRef]);

  useEffect(() => {
    setTimeout(() => {
      setFakeLoad(false);
    }, 2000);
  }, [fakeLoad]);

  const checkCameraPermission = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({
        name: "camera",
      });

      if (permissionStatus.state === "denied") {
        setcamaccess(true);
        setDenied(true);
      } else if (permissionStatus.state === "prompt") {
      } else if (permissionStatus.state === "granted") {
        setcamaccess(true);
      }
    } catch (error) {
      console.error("Error checking camera permission:", error);
    }
  };

  useEffect(() => {
    checkCameraPermission();
  }, [camaccess]);
  //#endregion
  return (
    <>
      <NavbarPreviews
        previewData={previewData}
        fixed={false}
        marginTop={false}
      />
      <div className="bg-bodyColor">
        <div className="lg:container mt-8 pb-10">
          <div className="flex justify-center sm:max-md:px-8">
            <div className="lg:w-3/4 rounded-md bg-white">
              <div className="rounded-lg">
                <div className="lg:p-8 sm:max-md:pb-5">
                  <div className="grid lg:grid-cols-2">
                    <div className="pt-10 lg:pl-12 sm:max-md:px-4">
                      <h1
                        className="text-xl font-bold"
                        style={{ fontFamily: "Silka" }}
                      >
                        Camera setup
                      </h1>
                      <hr className="lg:w-3/4 my-3 mt-8 border-[1px] border-coalColor" />
                      <div className="flex relative w-3/4">
                        <div
                          className="tooltip w-[9rem] font-medium text-center"
                          style={{ fontFamily: "Silka" }}
                        >
                          Secondary color
                        </div>
                      </div>
                      <div className="w-3/4">
                        <p
                          className="mt-5 text-[#767676]"
                          style={{ fontFamily: "Silka" }}
                        >
                          We use camera images to ensure fairness for everyone.
                          Make sure that you are in front of your camera.
                        </p>
                      </div>
                      <div className="flex justify-between mt-5 w-1/2">
                        <div className="flex flex-row">
                          <FaCamera className="my-auto text-coalColor" />
                          <p
                            className="ml-2 my-auto text-sm"
                            style={{ fontFamily: "Silka Light" }}
                          >
                            Camera
                          </p>
                        </div>
                      </div>
                      <div className="flex relative md:w-1/3">
                        <button
                          className="inline-flex items-center w-full justify-center px-4 mt-5 py-4  hover:text-white text-white text-sm font-medium rounded-md"
                          style={buttonStyle}
                          onMouseEnter={handleHover}
                          onMouseLeave={handleLeave}
                          type="submit"
                          onClick={closeTab}
                        >
                          Continue
                          <GoArrowRight
                            alt="Add Transaction Icon"
                            className="w-5 h-5 ml-2 icon-image"
                          />
                        </button>
                        <div
                          className="tooltip w-[8rem] font-medium text-center"
                          style={{ fontFamily: "Silka" }}
                        >
                          Primary color
                        </div>
                      </div>
                    </div>

                    <div className="lg:pr-5 sm:max-md:px-4">
                      <div className="pt-5 lg:pl-2 w-full ml-auto">
                        <>
                          {fakeLoad ? (
                            <div className="containerz">
                              <svg viewBox="25 25 50 50" className="svgs">
                                <circle
                                  r="20"
                                  cy="50"
                                  cx="50"
                                  className="ml-auto circles"
                                  style={{ stroke: previewData[2] }}
                                ></circle>
                              </svg>
                            </div>
                          ) : (
                            <TransitionGroup>
                              <CSSTransition
                                key={fakeLoad}
                                timeout={450}
                                className="settingFade"
                              >
                                {isCameraOn ? (
                                  <div>
                                    <Webcam
                                      audio={false}
                                      ref={webcamRef}
                                      screenshotFormat="image/jpeg"
                                      className="rounded-lg"
                                    />
                                  </div>
                                ) : (
                                  <>
                                    <div className="p-5 bg-[#DBD9C0] shadow-xl rounded-lg">
                                      <div className="flex flex-col">
                                        <img
                                          src={baas}
                                          className="w-8 h-8 mx-auto"
                                        />
                                        <p
                                          className="text-[#767676] mt-4 text-sm"
                                          style={{ fontFamily: "Silka" }}
                                        >
                                          It seems you don’t have a camera
                                          connected to your computer or your
                                          camera is blocked. To enable the
                                          camera, click on the camera blocked
                                          icon in your browser’s address bar and
                                          reload the page. If you don’t enable a
                                          camera, you can still take the test,
                                          but then Dexta cannot verify fair
                                          play.
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </CSSTransition>
                            </TransitionGroup>
                          )}
                          <div className="relative">
                            <div
                              className="p-5 mt-3 shadow-xl rounded-lg bg-[#DBD9C0]"
                              id="secondary"
                              style={{ fontFamily: "Silka" }}
                            >
                              <h1 className="font-bold">
                                Trouble with your webcam?
                              </h1>
                              <div className="text-[#767676] text-sm">
                                <p className="mt-2">
                                  Ensure you have granted permission for your
                                  browser to access your camera.
                                </p>
                                <p className="mt-2">
                                  Ensure you are using a supported browser.
                                </p>
                                <p className="mt-2">
                                  If you have multiple Camera devices, ensure
                                  you have give your browser and our website
                                  permission to use the right device.{" "}
                                </p>
                                <p className="mt-2">
                                  Try launching the test in incognito mode or in
                                  a private window.
                                </p>
                                <p className="mt-2">
                                  Ensure your camera drivers and web browser are
                                  up to date.
                                </p>
                                <p className="mt-2">
                                  Restart your device and try accessing the test
                                  again using the link in the invitation email.
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebcamScreen;
