import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Rightw from "../../../Assets/rightarrow.png";
import { useSelector } from "react-redux";
import { AiOutlineArrowRight } from "react-icons/ai";

const Finished = () => {
  const navigate = useNavigate();
  const theme = JSON?.parse(localStorage.getItem("theme"));
  const [time, setTime] = useState(10);
  const [isHovered, setIsHovered] = useState(false);
  const user_exists = localStorage.getItem("user_exists");

  const CandidateDetails = useSelector(
    (state) => state.candidateDetails.setCandidate
  );

  function darkenHexColor(hex, percent) {
    // Ensure the hex code is in the correct format
    hex = hex?.replace(/^#/, "");

    // Convert 3-digit hex to 6-digit hex
    if (hex?.length === 3) {
      hex = hex
        .split("")
        .map((char) => char + char)
        .join("");
    }

    // Convert hex to RGB
    let r = parseInt(hex?.substring(0, 2), 16);
    let g = parseInt(hex?.substring(2, 4), 16);
    let b = parseInt(hex?.substring(4, 6), 16);

    // Calculate the darkened color
    r = Math.floor(r * (1 - percent / 100));
    g = Math.floor(g * (1 - percent / 100));
    b = Math.floor(b * (1 - percent / 100));

    // Convert RGB back to hex
    const darkenedHex = `#${r.toString(16).padStart(2, "0")}${g
      .toString(16)
      .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

    return darkenedHex;
  }

  // Example usage:
  const originalColor = theme.color;
  const darkenedColor = darkenHexColor(originalColor, 15);

  const buttonStyle = {
    background: isHovered ? darkenedColor : theme.color,
    transition: "background-color 0.1s, transform 0.1s",
    transform: isHovered ? "scale(1.02)" : "scale(1)",
    color: theme.sec_color,
    border: `1px solid ${theme.sec_color}`,
    fontFamily: "Archia Semibold",
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);
    if (time === 0) {
      if (CandidateDetails === null) {
        localStorage.setItem("user_exists", "yes");
        navigate("/candidate/create-account");
      } else {
        localStorage.setItem("user_exists", "no");
        navigate("/candidate/dashboard");
      }
    }
    return () => {
      clearInterval(interval);
    };
  }, [time]);

  //#region check if user completed feedback
  useEffect(() => {
    if (user_exists === "yes") {
      navigate("/candidate/dashboard");
    } else if (user_exists === "no") {
      navigate("/candidate/login");
    }
  }, []);
  //#endregion
  return (
    <div className="bg-[#F0F0F0]">
      <div className="mx-auto lg:container">
        <div className="flex justify-center h-screen">
          <div className="m-auto w-5/6 md:w-2/5 rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] bg-white">
            <div className="rounded-lg">
              <div className="py-8 px-5 md:px-20 pb-12 flex mx-auto justify-center flex-col">
                <h1
                  className="text-xl text-[#000000] font-medium text-left mt-4"
                  style={{ fontFamily: "Archia Bold" }}
                >
                  Great job! You've completed your test
                </h1>
                <hr className="w-full my-3 mx-auto border-1 border-coalColor" />
                <p
                  className="text-left md:px-8"
                  style={{ fontFamily: "Silka" }}
                >
                  Would you like to see your results now? We will redirect you
                  to create your Dexta Profile and view your test outcome
                </p>
                <hr className="w-full my-3 mx-auto border-[1.5px] bg-gray-800" />
                <div
                  className="flex sm:flex-col md:flex-row md:flex-between gap-10"
                  style={{ fontFamily: "Silka" }}
                >
                  <h1 className="text-center my-auto">
                    Redirecting in <span style={{ color: theme.color }}> </span>
                    <b> {time} seconds</b>
                  </h1>
                  <button
                    className={`inline-flex items-center justify-center sm:w-full md:w-2/6 px-3 py-3 my-auto ml-auto text-white text-sm font-medium rounded-md`}
                    style={buttonStyle}
                    onMouseEnter={handleHover}
                    onMouseLeave={handleLeave}
                    onClick={() => {
                      if (CandidateDetails === null) {
                        localStorage.setItem("user_exists", "no");
                        navigate("/candidate/create-account");
                      } else {
                        localStorage.setItem("user_exists", "yes");
                        navigate("/candidate/dashboard");
                      }
                    }}
                  >
                    Go there now
                    <AiOutlineArrowRight className="w-5 h-5 ml-2 icon-image" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Finished;
