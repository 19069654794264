import React from "react";

const NavbarPreviews = ({ previewData, fixed, marginTop }) => {
  return (
    <div className="flex relative">
      <nav
        className={`w-full ${fixed && "fixed"} top-0 left-0 py-2`}
        style={{ background: "white" }}
      >
        <div className="w-3/4 px-2 items-center py-2 text-xl text-white mx-auto">
          <div className="grid grid-cols-3 container mx-auto">
            <img
              src={previewData[0]}
              className="my-auto object-contain"
              style={{ height: "70px" }}
            />
            <div className=" my-auto w-full">
              <div className="flex flex-col gap-2"></div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavbarPreviews;
