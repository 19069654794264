import React, { useState } from "react";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import https from "../../../https";
import TextFieldCustom from "../../../Components/TextField/TextFieldCustom";
import Loader from "react-loader-spinner";
import { toast, ToastContainer, Zoom } from "react-toastify";
import { updateUser } from "../hooks/updateUser";
import { publicLink } from "../hooks/publicLink";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { setInviteText } from "../../../redux/reducers/Invite/InviteSlice";
import GeneralModal from "../../../Components/Modals/GeneralModal";
import { setQuestionNumberResume } from "../../../redux/reducers/ResumedQuestion/ResumeQuestionNumberSlice";

const AccessDenied = () => {
  const location = useLocation();
  const module_info = { tests: 0, time: 0 };
  const parsed = queryString.parse(location.search);
  const [email, setEmail] = useState("");
  const queryClient = useQueryClient();
  const [company, setCompany] = useState("");
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [generalModal, setGeneralModal] = useState(false);
  const navigate = useNavigate();
  const checkEmail = () => {
    let encodedEmail;
    if (email) {
      encodedEmail = encodeURIComponent(email);
    }
    return https
      .get(`/assessment/invite/${parsed?.code}?email=${encodedEmail}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const errorResponseMessage = error?.response?.data?.message;
        const firstErrorMessage = Array.isArray(errorResponseMessage)
          ? errorResponseMessage[0]
          : errorResponseMessage;
        return Promise.reject(firstErrorMessage);
      });
  };

  const checkEmailandContinue = () => {
    checkEmail()
      .then((res) => {
        const theme = {
          logo: res?.data?.recruiter_company_detail?.companyAvatar,
          color: res?.data?.recruiter_company_detail?.color,
          name: res?.data?.assessments?.user?.companyName,
          sec_color: res?.data?.recruiter_company_detail?.sec_color,
        };
        localStorage.setItem("theme", JSON.stringify(theme));
        setFirstName(res?.data?.candidate?.firstName);
        setLastName(res?.data?.candidate?.lastName);
        localStorage.setItem(
          "CANDIDATE-ASSESSMENT-ID",
          res?.data?.assessments?.id
        );

        handleContinue(res);
      })
      .catch((errorMessage) => {
        toast.error("Invalid email", {
          toastId: "copy-success",
        });
        // Add your error handling logic here
      });
  };

  const { mutate: assessmentMutate, isLoading: assessmentLoading } =
    useMutation(publicLink, {
      onSuccess: (response) => {
        queryClient.invalidateQueries("assessment");
        localStorage.setItem("CP-CANDIDATE-TOKEN", response?.accessToken);
        localStorage.setItem("CP-CANDIDATE-ID", response?.user?.id);
        localStorage.setItem("CP-CANDIDATE-EMAIL", email);
        module_info.tests = response?.totalSection;
        module_info.time = response?.totalTime;
        localStorage.setItem("module_info", JSON.stringify(module_info));
        setCompany(response?.assessments?.user?.companyName);
        let data = JSON.stringify({
          firstName: response?.user?.firstName,
          lastName: response?.user?.lastName,
          email: response?.user?.email,
        });
        try {
          publicmutate(data);
        } catch {
          //don't need to catch error
        }
      },
      onError: (response) => {
        // if (response?.response?.data?.message[0].includes("Assessment link")) {
        //   navigate(`/expired?company=${data?.assessments?.user?.companyName}`)
        // }
        if (response?.response?.data?.message[0].includes("already")) {
          navigate(`/expired`);
          dispatch(setInviteText(response?.response?.data?.message));
        }
      },
    });

  const { mutate: publicmutate, isLoading: publicLoading } = useMutation(
    updateUser,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        localStorage.setItem("CP-CANDIDATE-EMAIL", email);
        localStorage.setItem("CP-CANDIDATE-FirstName", firstName);
        localStorage.setItem("CP-CANDIDATE-LastName", lastName);
        dispatch(setQuestionNumberResume(0));
        navigate(
          `/information?firstName=${firstName}&lastName=${lastName}&Company=${company}`
        );
      },
      onError: (response) => {
        const errorMessage = response?.response?.data?.message;

        if (Array.isArray(errorMessage)) {
          setTimeout(() => {
            toast.error(errorMessage[0], {
              toastId: "copy-success",
            });
          }, 500);
        } else if (typeof errorMessage === "string") {
          setTimeout(() => {
            toast.error(errorMessage, {
              toastId: "copy-success",
            });
          }, 500);
        } else {
          toast.error("An error occurred.", {
            toastId: "copy-success",
          });
        }
      },
    }
  );
  const handleContinue = (res) => {
    let mainData = {
      code: res?.data?.assessments?.code,
      data: JSON.stringify({
        firstName: res?.data?.candidate?.firstName,
        lastName: res?.data?.candidate?.lastName,
        email: email,
        attemptCode: parsed?.code,
      }),
    };
    try {
      assessmentMutate(mainData);
    } catch {
      //don't need to catch error
    }
  };
  return (
    <>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="bg-bodyColor">
        <div className="mx-auto lg:container">
          <div className="flex justify-center h-screen">
            <div className="m-auto sm:w-5/6 md:w-1/2 rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] bg-white">
              <div className="rounded-lg">
                <section className="bg-white">
                  <div className="flex items-center px-6 py-12 mx-auto">
                    <div className="flex flex-col items-center max-w-sm mx-auto text-center">
                      <p className="p-3 text-sm font-medium text-coalColor rounded-full bg-[#E6FF07]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                          />
                        </svg>
                      </p>
                      <h1
                        className="mt-3 text-2xl font-semibold text-gray-800  md:text-3xl"
                        style={{ fontFamily: "Archia Bold" }}
                      >
                        Enter email address
                      </h1>
                      <p className="mt-5">
                        If returning to a test you started previously, please
                        use the same email address to log back into the test
                      </p>
                      <p className="mt-3">
                        For support, please contact <b>support@dexta.io</b>
                      </p>
                      <div className="mt-5 w-full">
                        <TextFieldCustom
                          type="email"
                          name="email"
                          label="Email"
                          rounded="rounded-md"
                          border={`border border-[#D3D5D8] focus-within:border focus-within:border-coalColor `}
                          placeholder="Enter your email"
                          onChangeValue={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                      </div>
                      <div
                        className="sm:w-full ml-auto"
                        style={{
                          fontFamily: "Archia Semibold",
                        }}
                      >
                        <button
                          className="inline-flex items-center w-full justify-center px-4 mt-5 py-3 hover:bg-coalColor hover:text-white bg-primaryGreen border border-coalColor text-coalColor rounded-md"
                          type="submit"
                          onClick={checkEmailandContinue}
                        >
                          {publicLoading || assessmentLoading ? (
                            <span className="flex items-center justify-center">
                              <Loader
                                type="Oval"
                                color="white"
                                height={20}
                                width={20}
                              />
                              <span className="ml-2">Verifying email...</span>
                            </span>
                          ) : (
                            "Continue"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccessDenied;
