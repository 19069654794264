import React, { useEffect, useState } from "react";
import "../../../Components/Loading/Loading4.css";
import "react-clock/dist/Clock.css";
import Rightw from "../../../Assets/rightarrow.png";
import { submitmoduleFeedback } from "../hooks/submitmoduleFeedback";
import { useNavigate } from "react-router-dom";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useSelector, useDispatch } from "react-redux";
import { setExamToFalse } from "../../../redux/reducers/ExamDone/ExamDoneSlice";
import { GoArrowRight } from "react-icons/go";
import worker from "workerize-loader!./worker"; // eslint-disable-line import/no-webpack-loader-syntax

const ModuleFeedback = () => {
  const theme = JSON?.parse(localStorage.getItem("theme"));
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedOptionName, setSelectedOptionName] = useState([]);
  const [feedback, setFeedback] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const currentSection = JSON?.parse(localStorage.getItem("Current Module"));
  const moduleData = JSON?.parse(localStorage.getItem("module"));
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const examCheck = useSelector((state) => state.examdone.setDone);
  const dispatch = useDispatch();
  const completion_check = localStorage.getItem("test_completed");
  const user_exists = localStorage.getItem("user_exists");
  const questions = JSON?.parse(localStorage.getItem("prevQuestion"));
  const [response, setResponse] = useState(null);

  console.log(questions?.slice(-1)[0]);
  console.log(currentSection?.ModuleID);

  //#region functions to lighten or darken colors
  function LightenDarkenColor(col, amt) {
    var usePound = false;

    if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  }

  var NewColor = LightenDarkenColor(theme.color, 140);

  function DarkenColor(col, amt) {
    var usePound = false;

    if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) - amt;

    if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) - amt;

    if (b < 0) b = 0;

    var g = (num & 0x0000ff) - amt;

    if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  }

  var DarkenedColor = DarkenColor(theme.color, 50);
  //#endregion

  //#region options for feedback
  const data = [
    {
      id: 1,
      title: "Yes",
    },
    {
      id: 2,
      title: "Somewhat",
    },
    {
      id: 3,
      title: "No",
    },
  ];
  //#endregion

  //#region handle back browser
  useEffect(() => {
    const handlePopState = (event) => {
      window.history.pushState(null, null, window.location.href);
    };

    // Push the current state to the history stack initially
    window.history.pushState(null, null, window.location.href);

    // Listen for popstate events
    window.addEventListener("popstate", handlePopState);

    // Push the state whenever the user attempts to navigate back
    handlePopState();

    return () => {
      // Cleanup the event listener
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
  //#endregion

  //#region function to handle radios
  const handleRadios = (id, name) => {
    // setSelectedOption([])
    // setSelectedOption([...selectedOption, id]);
    setSelectedOption([id]);
    setSelectedOptionName([name]);
  };
  //#endregion

  //#region function to darken color
  function darkenHexColor(hex, percent) {
    // Ensure the hex code is in the correct format
    hex = hex?.replace(/^#/, "");

    // Convert 3-digit hex to 6-digit hex
    if (hex?.length === 3) {
      hex = hex
        .split("")
        .map((char) => char + char)
        .join("");
    }

    // Convert hex to RGB
    let r = parseInt(hex?.substring(0, 2), 16);
    let g = parseInt(hex?.substring(2, 4), 16);
    let b = parseInt(hex?.substring(4, 6), 16);

    // Calculate the darkened color
    r = Math.floor(r * (1 - percent / 100));
    g = Math.floor(g * (1 - percent / 100));
    b = Math.floor(b * (1 - percent / 100));

    // Convert RGB back to hex
    const darkenedHex = `#${r.toString(16).padStart(2, "0")}${g
      .toString(16)
      .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

    return darkenedHex;
  }
  //#endregion

  //#region normal and dark color variables
  const originalColor = theme.color;
  const darkenedColor = darkenHexColor(originalColor, 15);
  //#endregion

  //#region Handling hover
  const buttonStyle = {
    background: isHovered ? darkenedColor : theme.color,
    transition: "background-color 0.1s, transform 0.1s",
    transform: isHovered ? "scale(1.02)" : "scale(1)",
    fontFamily: "Archia Semibold",
    color: theme.sec_color,
    border: `1px solid ${theme.sec_color}`,
  };
  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  //#endregion

  //#region Submit module feedback
  const { mutate, isLoading } = useMutation(submitmoduleFeedback, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("/evaluationUsersFeedback");

      //actual test
      const currentSection = JSON?.parse(
        localStorage.getItem("Current Module")
      );
      currentSection.CurrentModule = currentSection.CurrentModule + 1;
      localStorage.setItem("Current Module", JSON.stringify(currentSection));

      //practice test
      const currentPracticeSection = JSON?.parse(
        localStorage.getItem("practice_section")
      );
      currentPracticeSection.CurrentModule =
        currentPracticeSection.CurrentModule + 1;
      localStorage.setItem(
        "practice_section",
        JSON.stringify(currentPracticeSection)
      );
      if (examCheck) {
        dispatch(setExamToFalse(false));
        localStorage.setItem("test_completed", "yes");
        window.location = "/feedback";
        // navigate("/feedback");
      } else {
        navigate("/practice-ready");
      }
    },
  });

  const submitFeedback = () => {
    let data = JSON.stringify({
      usersId: parseInt(localStorage.getItem("CP-CANDIDATE-ID")),
      assessmentId: parseInt(localStorage.getItem("CANDIDATE-ASSESSMENT-ID")),
      sectionId: parseInt(currentSection.ModuleID),
      evaluation: currentSection.evaluation,
      selectedOptions: selectedOptionName.toString(),
      feedback: feedback,
    });
    try {
      mutate(data);
    } catch (err) {}
  };
  //#endregion

  //#region check if user completed feedback
  useEffect(() => {
    if (completion_check === "yes") {
      navigate("/feedback");
    } else if (user_exists === "yes") {
      navigate("/candidate/dashboard");
    } else if (user_exists === "no") {
      navigate("/candidate/login");
    }
  }, []);
  //#endregion

  //#region inactivity solution
  const handleApiRequest = () => {
    let DataObj = {
      data: {
        usersId: parseInt(localStorage.getItem("CP-CANDIDATE-ID")),
        evaluation: currentSection.evaluation,
        assessmentId: parseInt(localStorage.getItem("CANDIDATE-ASSESSMENT-ID")),
        sectionId: parseInt(currentSection.ModuleID),
        questionId: parseInt(questions?.slice(-1)[0]),
      },
      requestOptions: {
        authorization: `Bearer ${localStorage.getItem("CP-CANDIDATE-TOKEN")}`,
      },
    };
    const workerInstance = worker();
    workerInstance.postToApi({ data: DataObj });
    workerInstance.addEventListener("message", (e) => {
      const { type, result } = e.data;
      if (type === "POST_RESPONSE") {
        setResponse(result);
        workerInstance.terminate();
      }
    });
  };

  useEffect(() => {
    const intervalId = setInterval(handleApiRequest, 1 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);
  //#endregion
  return (
    <>
      <nav
        className={`xl:static 2xl:fixed w-full z-20 top-0 left-0 py-2 bg-white`}
      >
        <div className="w-3/4 px-2 items-center py-2 text-xl text-white mx-auto">
          <div className="grid grid-cols-3 container mx-auto">
            <img
              src={theme.logo}
              className=" object-contain"
              style={{ height: "70px" }}
            />
          </div>
        </div>
      </nav>
      <div className="bg-bodyColor">
        <div className="mx-auto lg:container">
          <div className="flex justify-center h-screen">
            <div className="m-auto lg:max-xxl:w-2/3 2xl:w-1/2  w-5/6 rounded-md bg-white relative overflow-hidden">
              <div className="rounded-lg">
                {false ? (
                  <div className="cssload-container2">
                    <ul className="cssload-flex-container">
                      <li key="loading">
                        <span className="cssload-loading2 cssload-one"></span>
                        <span className="cssload-loading2 cssload-two"></span>
                        <span className="cssload-loading2-center"></span>
                      </li>
                    </ul>
                    <style>
                      {`:root {
                           --dynamicColor: ${DarkenedColor};
                           --dynamicColor2: ${theme.color};
                         }`}
                    </style>
                  </div>
                ) : (
                  <div className="md:p-8 sm:p-4 md:px-20">
                    <h1
                      className="text-xl font-bold mt-5"
                      style={{ fontFamily: "Archia Semibold" }}
                    >
                      You have now finished the{" "}
                      <span>
                        {parseInt(moduleData.current) !==
                        parseInt(moduleData.last) ? (
                          <>
                            {parseInt(moduleData.current) === 1 && "first "}
                            {parseInt(moduleData.current) === 2 && "second "}
                            {parseInt(moduleData.current) === 3 && "third "}
                            {parseInt(moduleData.current) === 4 && "fourth "}
                            {parseInt(moduleData.current) === 5 && "fifth "}
                          </>
                        ) : null}
                      </span>
                      module
                    </h1>
                    <hr className="w-full my-3 mx-auto border-1 border-[#252E3A]" />
                    <p className="mt-7" style={{ fontFamily: "Silka" }}>
                      Help us improve this module by providing anonymous
                      feedback. Your answer will be available to Dexta, not your
                      prospective employer.
                    </p>
                    <p className="mt-10" style={{ fontFamily: "Silka" }}>
                      In your opinion, did the module accurately measure your
                      skills in <b>{currentSection.module_Name}</b>
                    </p>
                    <div className="flex sm:flex-col sm:mt-3 md:mt-0 md:flex-row gap-2">
                      {data.map((j) => (
                        <div
                          key={j.id}
                          onClick={() => handleRadios(j.id, j.title)}
                          className={`p-5 mt-2 md:mt-5 cursor-pointer  ${
                            selectedOption.includes(j.id) ? "bg-black" : null
                          } hover:animate-[jiggle_1s_ease-in-out_infinite] w-full  rounded-xl border border-[#B6B6B6]`}
                          style={{
                            background: selectedOption.includes(j.id)
                              ? theme.sec_color
                              : "white",
                          }}
                        >
                          <div className="flex gap-10">
                            <div className="inline-flex items-center">
                              <label
                                className="relative flex cursor-pointer items-center rounded-full px-3"
                                htmlFor={`radio_${j.id}`}
                                data-ripple-dark="true"
                              >
                                <input
                                  id={`radio_${j.id}`}
                                  name="type"
                                  type="radio"
                                  className="peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-white transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:opacity-0 before:transition-opacity border-black checked:border-white checked:before:bg-black hover:before:opacity-10"
                                  onChange={() => handleRadios(j.id)}
                                  checked={selectedOption.includes(j.id)}
                                />
                                <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-3 w-3"
                                    viewBox="0 0 16 16"
                                    fill="currentColor"
                                  >
                                    <circle
                                      data-name="ellipse"
                                      cx="8"
                                      cy="8"
                                      r="8"
                                    ></circle>
                                  </svg>
                                </div>
                              </label>
                              <label
                                className={`mt-px cursor-pointer select-none text-sm font-bold`}
                                htmlFor={`radio_${j.id}`}
                                style={{
                                  color: selectedOption.includes(j.id)
                                    ? theme.color
                                    : "#252E3A",
                                  fontFamily: "Silka",
                                }}
                              >
                                {j.title}
                              </label>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <textarea
                      style={{ fontFamily: "Silka" }}
                      className="resize-y rounded-lg border border-[#B6B6B6] w-full flex mx-auto mt-6 p-2"
                      placeholder="Please explain..."
                      value={feedback}
                      onChange={(e) => setFeedback(e.target.value)}
                    ></textarea>
                    <hr className="w-full my-3 mt-8 bg-black border-1" />
                    <div className="flex justify-end">
                      <button
                        className={`inline-flex items-center w-full md:w-1/5 justify-center px-8 py-4 my-auto  text-white text-sm font-medium rounded-md`}
                        style={buttonStyle}
                        onMouseEnter={handleHover}
                        onMouseLeave={handleLeave}
                        onClick={submitFeedback}
                      >
                        Submit
                        <GoArrowRight
                          alt="Add Transaction Icon"
                          className="w-5 h-5 ml-2 icon-image"
                        />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModuleFeedback;
