import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  xArray: null,
  myScore: null,
  firstName: null,
  lastName: null,
};

export const GraphDataSlice = createSlice({
  name: "graphData",
  initialState,
  reducers: {
    setGraphData: (state, action) => {
      state.xArray = action.payload;
      state.myScore = action.payload;
      state.firstName = action.payload;
      state.lastName = action.payload;
    },
  },
});

export const { setGraphData } = GraphDataSlice.actions;
export default GraphDataSlice.reducer;
