import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  setCompleted: false,
};

export const TourCompletedSlice = createSlice({
  name: "tourCompleted",
  initialState,
  reducers: {
    setTourToCompleted: (state, action) => {
      state.setCompleted = true;
    },
    setTourToIncomplete: (state, action) => {
      state.setCompleted = false;
    },
  },
});

export const { setTourToCompleted, setTourToIncomplete } =
  TourCompletedSlice.actions;
export default TourCompletedSlice.reducer;
