import React from "react";
import Logo from "../../Dexta_assets/navbar-logo.png";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./navbar.css";
import "intro.js/introjs.css";
import CustomButton from "../CustomButton/CustomButton";
import { FaArrowRightLong } from "react-icons/fa6";

const NavbarPublic = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("home");
  const navigate = useNavigate();

  //#region Select item from navbar
  const handleSelectItem = (item) => {
    setSelectedItem(item);
  };
  //#endregion

  //#region Switching tabs
  useEffect(() => {
    if (window.location.pathname === "/dashboard") {
      setSelectedItem("home");
    } else if (window.location.pathname === "/settings") {
      setSelectedItem("settings");
    } else if (window.location.pathname === "/candidates") {
      setSelectedItem("candidates");
    } else if (window.location.pathname === "/tests") {
      setSelectedItem("tests");
    }
  }, [selectedItem, window.location.pathname]);
  //#endregion

  return (
    <>
      <nav className="bg-white w-full z-40 top-0 left-0 dark:border-gray-600 ">
        <div className="md:container flex items-center sm:px-3 md:px-[3rem] mx-auto py-4">
          <Link to="/dashboard" className="flex items-center">
            <img
              src={Logo}
              className="md:h-[50px] sm:h-10 sm:w-40 md:w-[180px] sm:object-contain md:object-cover"
              alt="CP Logo"
            />
          </Link>
          <div className="flex flex-shrink-0 ml-auto items-center px-4">
            <CustomButton
              label="Request a Demo"
              borderCustom="border border-black text-white"
              hoverBgColor="#C0FF06"
              hoverTextColor="#252E3A"
              paddingY="0.7rem"
              bgColor="#252E3A"
              iconR={FaArrowRightLong}
              noMarginIcon={false}
              textSize="sm:text-xs md:text-lg"
              textMarginBotton="ml-auto"
              autoLeftMargin="ml-4"
              onClickButton={() => navigate("/request-demo")}
            />
          </div>
        </div>
        <div
          className={`lg:hidden ${isMobileMenuOpen ? "block" : "hidden"}`}
          id="mobile-menu"
        >
          <div className="space-y-1 px-2 pb-3 pt-2">
            <a
              to="/dashboard"
              onClick={() => handleSelectItem("home")}
              className={`text-[#000000]  block rounded-md px-3 py-2 hover:text-[#4A9CB9] text-base font-medium`}
              aria-current="page"
            >
              My assessments
            </a>
            <a
              to="/crypto-education-software"
              onClick={() => handleSelectItem("product")}
              className={`text-[#000000]  block hover:text-[#4A9CB9] rounded-md px-3 py-2 text-base font-medium`}
            >
              My candidates
            </a>
            <a
              to="/plans-pricing"
              onClick={() => handleSelectItem("pricing")}
              className={`text-[#000000]  block rounded-md px-3 hover:text-[#4A9CB9] py-2 text-base font-medium`}
            >
              Settings
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavbarPublic;
