import http from "../../../../../http";

export const getJobs = async (categoryID, page) => {
  // Base URL
  let url = `/jobs?order=ASC&page=${page}&take=1000&orderBy=name&status=active&hasSections=true`;
  if (categoryID !== true) {
    url += `&categoryIds=${categoryID}`;
  }
  const response = await http.get(url);
  return response.data;
};
