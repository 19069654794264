import React, { useRef, useEffect } from "react";

function ImageInput({ onDpSelected, onChooseImg }) {
  const inputRef = useRef();

  useEffect(() => {
    if (onChooseImg) {
      onChooseImg(chooseImg);
    }
  }, [onChooseImg]);

  const handleOnChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function (e) {
        onDpSelected(reader.result);
      };
    }
  };

  const chooseImg = () => {
    inputRef.current.click();
  };

  return (
    <div>
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        onChange={handleOnChange}
        style={{ display: "none" }}
      />
    </div>
  );
}

export default ImageInput;
