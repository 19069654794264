import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  candidateData: {
    educationLevel: "",
    study: "",
    relevant_experience: "",
    years_experience: "",
    candidate_status: "",
    first_lang: "",
  },
};

export const CandidateLoginDetailsSlice = createSlice({
  name: "candidateLoginDetails",
  initialState,
  reducers: {
    updateCandidateData: (state, action) => {
      state.candidateData = { ...state.candidateData, ...action.payload };
    },
  },
});

export const { updateCandidateData } = CandidateLoginDetailsSlice.actions;
export default CandidateLoginDetailsSlice.reducer;
