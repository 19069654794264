import React from "react";

const DropdownListInterpret = ({ onClick, title, textsize = null }) => {
  console.log(title, "a");
  return (
    <li
      className={`flex justify-between items-center  w-full ${
        textsize ? textsize : "text-sm"
      } text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer px-4 py-2`}
      onClick={onClick}
    >
      <span className="font-medium">{title}</span>
    </li>
  );
};

export default DropdownListInterpret;
