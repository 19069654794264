import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import CloseModal from "../../../../../Dexta_assets/closeModal.png";
import styles from "./styling2.module.css";
import "./custom.css";
import ReactHtmlParser from "react-html-parser";
import Scrollbars from "react-custom-scrollbars";

export default function CustomQuestions({
  customOpen,
  setCustomOpen,
  customQuestionsList,
  assessmentLoading,
}) {
  const cancelButtonRef = useRef(null);
  const removeInlineStyles = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    // Remove style attributes
    const elementsWithStyle = doc.querySelectorAll("[style]");
    elementsWithStyle.forEach((element) => {
      element.removeAttribute("style");
    });

    // Remove strong tags
    const strongTags = doc.querySelectorAll("strong");
    strongTags.forEach((tag) => {
      const parent = tag.parentNode;
      while (tag.firstChild) {
        parent.insertBefore(tag.firstChild, tag);
      }
      parent.removeChild(tag);
    });

    return doc.documentElement.innerHTML;
  };

  return (
    <Transition.Root show={customOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={setCustomOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-40 w-screen overflow-y-auto sm:px-4 md:px-0">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-scroll rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl"
                style={{ height: "550px" }}
              >
                <div className="bg-white">
                  <p
                    className="text-lg font-medium text-coalColor cursor-pointer text-left pl-5 pt-5"
                    style={{ fontFamily: "Archia Bold" }}
                  >
                    Custom Questions
                  </p>
                  <img
                    src={CloseModal}
                    className="absolute top-4 right-5 z-20 w-6 h-6 cursor-pointer"
                    onClick={() => setCustomOpen(false)}
                  />
                  <hr className="w-full bg-[#D3D5D8] border-1 mt-6" />
                  <div
                    className="relative overflow-x-auto px-4 bg-white sm:rounded-lg mt-6"
                    id="assessment2"
                  >
                    <table className="w-full sm:text-xs md:text-sm sm:text-center md:text-left text-white ">
                      <thead
                        className="sm:text-base text-coalColor bg-white"
                        style={{ fontFamily: "Archia Semibold" }}
                      >
                        <tr>
                          <th
                            scope="col"
                            className="sm:px-3 w-[20%] md:px-6 sm:py-2"
                          >
                            ID
                          </th>
                          <th
                            scope="col"
                            className="sm:px-3 md:px-6 w-[60%] sm:py-2"
                          >
                            Questions
                          </th>
                          <th
                            scope="col"
                            className="sm:px-3 md:px-6 w-[20%] sm:py-2 "
                          >
                            Types
                          </th>
                        </tr>
                      </thead>
                      <tbody className="rounded-lg">
                        {assessmentLoading ? (
                          <tr>
                            <td colSpan="5" className="text-center">
                              <div className="bg-white">
                                <div class="loader-container-2">
                                  <div class="loader-2"></div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <>
                            {customQuestionsList?.questions?.map((i, index) => (
                              <tr
                                key={index}
                                className={`bg-white w-full odd:bg-[#F6F7F7] text-black cursor-pointer`}
                                style={{ fontFamily: "Silka" }}
                              >
                                <td className="md:px-6 sm:px-2 sm:py-2 clickable">
                                  {i?.id}
                                </td>

                                <td className="md:px-6 sm:px-2 sm:py-2 clickable">
                                  <div
                                    style={{
                                      fontFamily: "Silka",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: 1,
                                      WebkitBoxOrient: "vertical",
                                    }}
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: removeInlineStyles(
                                          i?.description
                                        ),
                                      }}
                                    />
                                  </div>
                                </td>

                                <td className="md:px-6 sm:px-2 sm:py-2 clickable">
                                  {i?.type}
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </tbody>
                    </table>
                    {customQuestionsList?.questions?.length < 1 && (
                      <div className="px-6 mt-3">
                        <div className="border border-[#FF5812] py-4 rounded">
                          <p
                            className="text-[#252E3A] text-center"
                            style={{ fontFamily: "Silka" }}
                          >
                            No question added yet!
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
