import React from "react";
import PropTypes from "prop-types";
import withRouter from "../Common/withRouter"
import StudentsNavbar from "../Navbar/StudentsNavbar";
const NonAuthStudentLayout = (props) => {
  return <React.Fragment>
    {props.children}
  </React.Fragment>;
};

NonAuthStudentLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
};


export default withRouter(NonAuthStudentLayout);
