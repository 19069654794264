import React, { useState } from "react";
import Logo from "../../../../Assets/mainLOGO.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import ".././auth.css";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../../Auth/hooks/loginUser";
import { useMutation } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { ToastContainer, Zoom } from "react-toastify";
import TextField from "../../../../Components/Dexta/TextField/TextField";
import Loader from "react-loader-spinner";
import { forgetEmail } from "../../hooks/forgetEmail";

const ForgetPasswordCandidate = () => {
  const [error, setError] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  //#region validation with formik
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Email Required"),
    }),
    onSubmit: (values) => {
      let data = JSON.stringify({
        email: values?.email,
      });
      try {
        mutate(data);
      } catch (error) {
        //onError will automatically detect
      }
    },
  });
  //#endregion

  //#region login with candidate
  const { mutate, isLoading } = useMutation(forgetEmail, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("/auth/forgot-password");
      navigate(
        `/candidate/forget-password-email-sent/?email=${validation?.values?.email}`
      );
    },
    onError: (error) => {
      const errorMessage = error?.response?.data?.message;
      if (Array.isArray(errorMessage)) {
        setTimeout(() => {
          toast.error(errorMessage[0], {
            toastId: "copy-success",
          });
        }, 500);
      } else if (typeof errorMessage === "string") {
        setTimeout(() => {
          toast.error(errorMessage, {
            toastId: "copy-success",
          });
        }, 500);
      } else {
        toast.error("An error occurred.", {
          toastId: "copy-success",
        });
      }
    },
  });
  //#endregion

  return (
    <>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="flex justify-center px-2 lg:px-0 h-screen">
        <div className="m-auto w-full lg:w-2/5 rounded-md bg-white">
          <div className="rounded-lg">
            <div className="sm:p-5 lg:p-14">
              <img src={Logo} className="h-11 w-40" alt="CP Logo" />
              <h1
                className="pt-10 text-xl font-bold"
                style={{ fontFamily: "Archia Bold" }}
              >
                Forgot Password?
              </h1>
              <p
                className="sm:text-xs md:text-sm mt-5 text-black"
                style={{ fontFamily: "Silka" }}
              >
                No, worries, we’ll send you reset instructions.
              </p>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                autoComplete="off"
              >
                <TextField
                  type="email"
                  name="email"
                  label="Enter your email"
                  rounded="rounded-md"
                  border={`border mt-3 border-[#D3D5D8] focus-within:border focus-within:border-coalColor ${
                    validation.values.firstName && "border-coalColor"
                  }`}
                  onChange={(e) => {
                    validation.handleChange(e);
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  placeholder="Enter your email"
                />

                <div className="flex justify-between">
                  <div
                    className="flex flex-row"
                    style={{ fontFamily: "Silka" }}
                  ></div>
                </div>
                <div className="mb-3 mt-6 flex justify-center items-center flex-col">
                  <button
                    type="submit"
                    name="Sign up"
                    style={{ fontFamily: "Silka" }}
                    className="bg-primaryGreen text-coalColor w-full h-12 rounded-lg border border-coalColor font-medium sm:text-sm md:text-lg relative"
                  >
                    {isLoading ? (
                      <span className="flex items-center justify-center">
                        <Loader
                          type="Oval"
                          color="black"
                          height={20}
                          width={20}
                        />
                        <span className="ml-2">Resetting...</span>
                      </span>
                    ) : (
                      "Reset Password"
                    )}
                  </button>
                </div>
                <span
                  className="text-coalColor text-sm text-center p-2"
                  style={{ fontFamily: "Silka" }}
                >
                  Don't want to reset your password?{" "}
                  <span
                    className="text-coalColor font-medium cursor-pointer"
                    style={{ fontFamily: "Silka Bold" }}
                    onClick={() => navigate("/candidate/login")}
                  >
                    Return to Login
                  </span>
                </span>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPasswordCandidate;
