import { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { FiTrash2, FiUpload, FiMove, FiCamera } from "react-icons/fi"; // Using consistent icons
import ImageInput from "../../../../../Components/FileInput/ImageInput";
import CustomCropper from "../../../../../Components/ImageCropper/CustomCropper";
import { aspectRatios } from "./data";
import { onCropCancel, onCropDone } from "../../../../../Helpers/CropFunctions";
import http from "../../../../../http";
import "./modal.css";
import { MdOutlineFileUpload } from "react-icons/md";
import Loader from "react-loader-spinner";

const DraggableElement = ({
  element,
  index,
  moveElement,
  elements,
  setElements,
  handleCorrectOption,
  handleTextEdit,
  setDeletedID,
  toggleOn,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [image, setImage] = useState("");
  const [currentPage, setCurrentPage] = useState("choose-img");
  const [modalOpen, setModalOpen] = useState(false);
  const chooseImgFunctionRef = useRef();
  const [imgAfterCrop, setImgAfterCrop] = useState("");
  const [blobdata, setblobdata] = useState(null);
  const [allow, setAllow] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  //#region Drag and drop functions
  const [, drag] = useDrag({
    type: "ELEMENT",
    item: { index },
    canDrag: !isDragging,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "ELEMENT",
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveElement(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });
  //#endregion

  //#region Saving image to bucket and updating states
  const handlePostImage = async (blobx) => {
    setUploadLoading(true);
    const formData = new FormData();
    formData.append("file", blobx);
    formData.append("fileType", "user");
    try {
      const response = await http.post(
        "/questions/uploadQuestionImage",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setImageForElement(index, response.data.link);
      setUploadLoading(false);
    } catch (error) {
      console.error(error);
      setUploadLoading(false);
    }
  };

  const setImageForElement = (index, newImage) => {
    console.log(index, newImage, "hellooooo");
    setElements((prevElements) =>
      prevElements.map((element, idx) =>
        idx === index ? { ...element, imageUrl: newImage } : element
      )
    );
  };

  //#endregion

  //#region Opening cropper
  const onDpSelected = (selectedImg) => {
    setImage(selectedImg);
    setCurrentPage("crop-img");
    setModalOpen(true);
  };

  useEffect(() => {
    if (blobdata) {
      handlePostImage(blobdata);
    }
  }, [blobdata]);

  const handleCropDone = (croppedArea) => {
    onCropDone(
      croppedArea,
      image,
      setblobdata,
      setImgAfterCrop,
      setCurrentPage
    );
  };
  //#endregion

  //#region canceling crop
  const handleCropCancel = () => {
    onCropCancel(setCurrentPage, setImage);
  };
  //#endregion

  return (
    <>
      <CustomCropper
        image={image}
        aspectRatios={aspectRatios}
        onCropDone={handleCropDone}
        onCropCancel={handleCropCancel}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        setAllow={setAllow}
      />
      <div
        ref={(node) => drop(drag(node))}
        className="flex items-center px-4 h-[4rem] mb-2 bg-white shadow rounded-lg"
      >
        {/* Checkbox */}
        <input
          id={`checkbox-${element?.id}`}
          type="checkbox"
          checked={element?.isCorrectOption}
          className="w-4 h-4 text-teal-600 bg-gray-100 cursor-pointer mr-2 accent-primaryGreen border-gray-300 rounded focus:ring-teal-500"
          onChange={(e) => handleCorrectOption(index, e.target.checked)}
        />
        <input
          type="text"
          x1
          value={element?.title}
          placeholder="Insert answer"
          className="w-full pr-12 overflow-hidden max-h-16 ml-0 text-sm tracking-wide"
          onChange={(e) => handleTextEdit(index, e.target.value)}
          onMouseDown={() => setIsDragging(true)}
          onMouseUp={() => setIsDragging(false)}
          style={{ fontFamily: "Silka" }}
        />
        {!element?.imageUrl && (
          <>
            <label htmlFor={`file-upload-${index}`} className="cursor-pointer">
              <FiUpload
                className="icon-standard mr-3"
                onClick={() => chooseImgFunctionRef.current()}
              />
            </label>

            <ImageInput
              setImage={setImage}
              onDpSelected={onDpSelected}
              onChooseImg={(chooseImg) =>
                (chooseImgFunctionRef.current = chooseImg)
              }
            />
          </>
        )}
        {element?.imageUrl && (
          <>
            <label htmlFor={`file-upload-${index}`} className="cursor-pointer">
              <img
                src={element?.imageUrl}
                alt="Uploaded preview"
                className="w-8 h-8 object-cover rounded mr-3"
                onClick={() => chooseImgFunctionRef.current()}
              />
            </label>
            <ImageInput
              setImage={setImage}
              onDpSelected={onDpSelected}
              onChooseImg={(chooseImg) =>
                (chooseImgFunctionRef.current = chooseImg)
              }
            />
          </>
        )}
        <FiTrash2
          className="icon-standard hover:text-red-600 mr-3"
          onClick={() => {
            setDeletedID(element.id);
            const newElements = [...elements];
            newElements.splice(index, 1);
            setElements(newElements);
          }}
        />
        <FiMove className="icon-standard" />
      </div>
    </>
  );
};

export default DraggableElement;
