//#region Steps related to navbar
export const steps = [
  {
    title: "Welcome to Dexta",
    intro: (
      <div style={{ fontFamily: "Silka" }}>
        <p>
          Our platform allows you to create high-quality tests tailored for your
          industry and hiring role in a few simple steps
        </p>
      </div>
    ),
  },
  {
    title: "My Tests",
    element: "#tab1",
    intro: (
      <p>
        Clicking ‘My Tests’ will allow you to easily return to the ‘My Tests’
        page. From there, as well as creating a new test, you will also be able
        to access your created tests and view completion progress
      </p>
    ),
  },
];

export const steps_candidate = [
  {
    title: "My Candidates",
    element: "#tab2",
    intro: (
      <div>
        <p>
          Clicking ‘My Candidates’ will allow you to view all the tests that
          have been sent to candidates. From here you can also:
        </p>
        <ul className="list-disc px-4">
          <li>See the name of the test sent to each candidate</li>
          <li>
            See the status of each candidate’s test. For example, you can see
            whether they have been invited to take the test, are in progress of
            a test or have completed a test
          </li>
          <li>See the test score attained by each candidate</li>
        </ul>
      </div>
    ),
  },
];

export const steps_settings = [
  {
    title: "Settings",
    element: "#tab3",
    intro: (
      <div>
        <p>Clicking 'Settings' will allow you to:</p>
        <ul className="list-disc px-4">
          <li>
            Edit your personal information, such as your name, profile picture,
            phone number, and email address
          </li>
          <li>
            Adjust your notification settings for when you receive alerts of
            completed candidate tests
          </li>
          <li>Customise your test with your company's logo and color scheme</li>
          <li>View your current plan and billing information</li>
        </ul>
      </div>
    ),
  },
];

export const steps_tests = [
  {
    title: "Modules",
    element: "#tab4",
    intro: (
      <div>
        <p>
          Clicking on the "Modules" section will take you to a page where you
          can explore various modules categorized by industries
        </p>
        <p>Here's what you can do:</p>
        <ul className="list-disc px-4">
          <li>
            Use the filters to refine the modules based on industries and other
            criteria
          </li>
          <li>
            Scroll through the list of modules to find ones that match your
            requirements
          </li>
          <li>Click on a module to view its details and possibly a preview</li>
          <li>
            If you're ready to create a new test using selected modules, click
            the 'Create Test' button
          </li>
        </ul>
      </div>
    ),
  },
];
//#endregion
