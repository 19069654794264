import React, { useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import http from "../../http";
import Loader from "react-loader-spinner";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { attachCard } from "../hooks/attachCard";
import Cardimg from "../../Assets/blackcard.png";
import calendar from "../../Assets/calend.png";
import cvc from "../../Assets/cvc.png";
import cardPic from "../../Dexta_assets/cardpic.png";
import visa from "../../Dexta_assets/visa-logo.png";

//#region styling of card input fields
const inputStyle = {
  iconColor: "#c4f0ff",
  backgroundColor: "#F6F7F7",
  color: "#000",
  fontSize: "16px",
  fontSmoothing: "antialiased",
  ":-webkit-autofill": {
    color: "#000",
  },
  "::placeholder": {
    color: "#6B6B6B",
  },
};
//#endregion

const CheckoutForm = ({ setHome }) => {
  const [isLoading, setIsloading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();
  const userID = localStorage.getItem("CP-USER-ID");
  const [errors, setErrors] = useState({
    cardNumber: "",
    expiry: "",
    cvc: "",
    general: "",
  });

  //#region Adding Card
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsloading(true);
      if (elements == null) {
        return;
      }

      // Validate card details before creating payment method
      const cardElement = elements.getElement(CardNumberElement);
      if (cardElement._complete === false) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          cardNumber: "Please enter a valid card number.",
        }));
        setIsloading(false);
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, cardNumber: "" }));
      }

      const expiryElement = elements.getElement(CardExpiryElement);
      if (expiryElement._complete === false) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          expiry: "Invalid expiry date.",
        }));
        setIsloading(false);
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, expiry: "" }));
      }

      const cvcElement = elements.getElement(CardCvcElement);
      if (cvcElement._complete === false) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          cvc: "Invalid CVV.",
        }));
        setIsloading(false);
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, cvc: "" }));
      }

      // Clear previous error messages
      setErrors((prevErrors) => ({ ...prevErrors, general: "" }));

      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      // If payment method creation is successful, proceed to attaching card
      let data = {
        id: userID,
        methodID: paymentMethod?.paymentMethod?.id,
      };

      mutate(data);
    } catch (error) {
      // Handle error, show error message to the user
      console.error(error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        general: "An error occurred. Please try again.",
      }));
      setIsloading(false);
    }
  };
  //#endregion

  //#region calling api to store card creds
  const { mutate, isLoading: mutateLoading } = useMutation(attachCard, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("/subscriptions/attachPaymentMethod");
      setIsloading(false);
      setHome("home");
    },
    onError: (error) => {
      // Handle error if needed
    },
  });
  //#endregion

  return (
    <form onSubmit={handleSubmit}>
      <div className="h-[200px]">
        <div
          className="flex flex-row sm:w-1/2 md:w-1/3 bg-primaryGreen px-2 py-2"
          style={{ fontFamily: "Silka" }}
        >
          <img src={cardPic} className="w-4 h-4 my-auto mr-2" />
          Credit card
        </div>
        <div className="transform mt-5 bg-[#F6F7F7] h-10 transition border border-[#999999] duration-500 focus:scale-105 rounded w-full py-3 px-3 text-coalColor leading-tight">
          <div style={{ position: "relative" }}>
            <CardNumberElement
              options={{
                style: {
                  base: inputStyle,
                },
              }}
            />
            <img
              src={visa}
              alt="Card Image"
              className="w-6 h-3"
              style={{
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
              }}
            />
          </div>
        </div>
        {errors.cardNumber && (
          <div className="ml-1">
            <p className="text-rose-500 sm:text-xs md:text-sm fade-in-image">
              {errors.cardNumber}
            </p>
          </div>
        )}
        <div className="w-full flex flex-row gap-3 h-10">
          <div className="w-1/2">
            <div className="transform mt-5 bg-[#F6F7F7] transition border border-[#999999] duration-500 focus:scale-105 rounded w-full py-3 px-3 text-coalColor leading-tight">
              <div style={{ position: "relative" }}>
                <CardExpiryElement
                  options={{
                    style: {
                      base: inputStyle,
                    },
                  }}
                />
              </div>
            </div>
            {errors.expiry && (
              <div className="ml-1">
                <p className="text-rose-500 sm:text-xs md:text-sm fade-in-image">
                  {errors.expiry}
                </p>
              </div>
            )}
          </div>
          <div className="w-1/2">
            <div className="transform mt-5 bg-[#F6F7F7] transition border border-[#999999] duration-500 focus:scale-105 rounded w-full py-3 px-3 text-coalColor leading-tight">
              <div style={{ position: "relative" }}>
                <CardCvcElement
                  options={{
                    style: {
                      base: inputStyle,
                    },
                  }}
                />
                <img
                  src={cvc}
                  alt="Card Image"
                  className="w-6 h-6"
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                  }}
                />
              </div>
            </div>
            {errors.cvc && (
              <div className="ml-1">
                <p className="text-rose-500 sm:text-xs md:text-sm fade-in-image">
                  {errors.cvc}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {errors.general && (
        <div className="ml-1">
          <p className="text-rose-500 sm:text-xs md:text-sm fade-in-image">
            {errors.general}
          </p>
        </div>
      )}
      <p
        className="px-2 mt-3 text-xs text-coalColor"
        style={{ fontFamily: "Silka" }}
      >
        I authorize Dexta BV to save this payment method and automatically
        charge this payment method whenever a subscription is associated with
        it.
      </p>

      <div className="w-1/2 mt-[3rem] mx-auto">
        <button
          className="inline-flex items-center w-full justify-center px-4 mt-5 py-3 bg-primaryGreen hover:bg-primaryGreen/90 border border-coalColor text-coalColor text-sm font-medium rounded-md"
          type="submit"
          disabled={!stripe || !elements}
          style={{ fontFamily: "Silka" }}
        >
          {isLoading ? (
            <span className="flex items-center justify-center">
              <Loader type="Oval" color="black" height={20} width={20} />
              <span className="ml-2">Adding...</span>
            </span>
          ) : (
            "Add Card"
          )}
        </button>
      </div>
    </form>
  );
};

export default CheckoutForm;
