import React from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { GrCircleInformation } from "react-icons/gr";
import { useSelector } from "react-redux";
const AlreadyDone = () => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const invite = useSelector((state) => state.invite.setinvite);
  console.log(invite);
  return (
    <div className="bg-bodyColor">
      <div className="mx-auto container">
        <div className="flex justify-center h-screen">
          <div className="m-auto w-1/2 rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] bg-white">
            <div className="rounded-lg">
              <section className="bg-white">
                <div className="flex items-center px-6 py-12 mx-auto">
                  <div className="flex flex-col items-center max-w-sm mx-auto text-center">
                    <p className="p-3 text-sm font-medium text-coalColor rounded-full bg-[#E6FF07]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                        />
                      </svg>
                    </p>
                    <h1
                      className="mt-3 text-2xl font-semibold text-gray-800  md:text-3xl"
                      style={{ fontFamily: "Archia Bold" }}
                    >
                      Invite Expired
                    </h1>
                    <p
                      className="mt-4 text-gray-500"
                      style={{ fontFamily: "Silka" }}
                    >
                      {invite}
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlreadyDone;
