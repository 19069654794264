import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import "react-loading-skeleton/dist/skeleton.css";
import { IoClose } from "react-icons/io5";
import { ToastContainer, Zoom, toast } from "react-toastify";
import CustomButton from "../CustomButton/CustomButton";
import TextFieldCustom from "../TextField/TextFieldCustom";
import { IoIosArrowDown } from "react-icons/io";
import { FiPlusCircle } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { roles } from "./data";
import { Menu, MenuItem } from "@mui/material";
import { AddTeam } from "../hooks/addTeamMember";
import { getTeamMembers } from "../hooks/getTeamMemebers";
import AlertModal from "./Alert";
import GeneralModal from "../Modals/GeneralModal";
import { useSelector } from "react-redux";

//#region functions to check if the input mail is personal mail or work mail
const personalEmailDomains = [
  "gmail.com",
  "yahoo.com",
  "outlook.com",
  "hotmail.com",
  "aol.com",
  "icloud.com",
  "mail.com",
];

const isWorkEmail = (email) => {
  const domain = email.split("@")[1];
  return !personalEmailDomains.includes(domain);
};

const isEmailValid = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.(com|io|net|co|ac\.uk)$/;
  return emailRegex.test(email);
};

//#endregion

export default function TeamMember({
  teamMemberModal,
  setTeamMemberModal,
  TeamAllowed = null,
}) {
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState(null);
  const cancelButtonRef = useRef(null);
  const rolesRef = useRef(null);
  const [teamMembers, setTeamMembers] = useState([
    {
      email: "",
      accountType: "admin",
      error: "",
      rolesDropdown: false,
      sucess: false,
    },
  ]);
  const [indexA, setIndex] = useState(null);
  const [alertModal, setAlertModal] = useState(false);
  const [errorC, setErrorCount] = useState(0);
  const [sucessC, setSuccessCount] = useState(0);
  const [modalCount, setModalCount] = useState(0);
  const [personalEmail, setPersonalEmail] = useState(false);
  const [toastMessages, setToastMessages] = useState(new Set());
  const user_package_check = useSelector(
    (state) => state.packageDetails.setPackage
  );
  const [generalModal, setGeneralModal] = useState(false);
  const [heading, setHeading] = useState("");
  const [description, setdescription] = useState("");
  const [failedEmails, setFailedEmails] = useState([]);
  const handleAddMore = () => {
    setTeamMembers([
      ...teamMembers,
      { email: "", accountType: "admin", error: "", rolesDropdown: false },
    ]);
  };

  const handleInputChange = (index, key, value) => {
    const newTeamMembers = [...teamMembers];
    newTeamMembers[index][key] = value;

    if (key === "email") {
      if (!isEmailValid(value)) {
        newTeamMembers[index].error = "Incorrect email format";
        setPersonalEmail(true);
      } else if (!isWorkEmail(value)) {
        newTeamMembers[index].error = "We don't accept personal emails";
        setPersonalEmail(true);
      } else {
        newTeamMembers[index].error = "";
        setPersonalEmail(false);
      }
    }
    setTeamMembers(newTeamMembers);
  };

  const handleAccountTypeChange = (index, key, value) => {
    const newTeamMembers = [...teamMembers];
    newTeamMembers[index][key] = value;
    setTeamMembers(newTeamMembers);
  };

  const handleRolesDropdown = (event, index) => {
    setAnchorEl(event.currentTarget);
    const newTeamMembers = [...teamMembers];
    newTeamMembers[index].rolesDropdown = !newTeamMembers[index].rolesDropdown;
    setTeamMembers(newTeamMembers);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { mutate, isLoading: mutateLoading } = useMutation(AddTeam, {
    onSuccess: (res) => {
      queryClient.invalidateQueries("/users/invite-team-member");
      setSuccessCount((prevSuccessCount) => prevSuccessCount + 1);
      setModalCount((prevModaCount) => prevModaCount + 1);
      setTeamMembers((prevMembers) => {
        return prevMembers.map((member) => {
          if (member.email === res?.email) {
            return { ...member, error: "", sucess: true };
          }
          return member;
        });
      });
    },
    onError: (error) => {
      setErrorCount((prevErrorCount) => prevErrorCount + 1);
      try {
        const errorResponseMessage = error?.response?.data?.message;

        // Check for the first meaningful (non-empty) error message
        const firstErrorMessage = Array.isArray(errorResponseMessage)
          ? errorResponseMessage.find((msg) => msg && msg.trim()) // Find the first non-empty message
          : errorResponseMessage;

        // Only show toast if there’s a valid, non-empty error message
        if (
          firstErrorMessage.includes("already") &&
          firstErrorMessage.includes("use")
        ) {
          setFailedEmails((prevFailedEmails) => [
            ...prevFailedEmails,
            JSON.parse(error?.config?.data)?.email,
          ]);
        }

        // Parsing error for member updates
        let parsedError;
        try {
          parsedError = firstErrorMessage && JSON.parse(firstErrorMessage);
        } catch (jsonParseError) {
          console.error("Error parsing error message:", jsonParseError);
        }

        if (Array.isArray(parsedError) && parsedError.length >= 2) {
          setTeamMembers((prevMembers) => {
            return prevMembers.map((member) => {
              if (member.email === parsedError[0]) {
                return { ...member, error: parsedError[1] };
              }
              return member;
            });
          });
        }

        console.log(failedEmails, "lmao"); // Note: This will still log the old state
      } catch (parseError) {
        console.error("Error handling error message:", parseError);
      }

      setModalCount((prevModalCount) => prevModalCount + 1);
    },
  });

  const filterTeamMembers = () => {
    setTeamMembers((prevTeamMembers) =>
      prevTeamMembers.filter(
        (teamMember) => teamMember.email && teamMember.email.trim() !== ""
      )
    );
  };

  const HandleSaveTeam = () => {
    teamMembers.forEach((teamMember) => {
      const { email, accountType } = teamMember;
      const data = JSON.stringify({ email, accountType });
      try {
        mutate(data);
      } catch (error) {
        console.error("Failed to mutate data:", error);
      }
    });
  };

  const handleRemoveMember = (index) => {
    const newTeamMembers = [...teamMembers];
    newTeamMembers.splice(index, 1);
    setTeamMembers(newTeamMembers);
  };

  useEffect(() => {
    if (errorC > 0 && modalCount === teamMembers.length) {
      setAlertModal(true);
    } else if (
      errorC === 0 &&
      modalCount === teamMembers.length &&
      teamMembers.length != 0
    ) {
      toast.success("Member(s) added to team", {
        toastId: "copy-success",
      });
      setModalCount(0);
      setSuccessCount(0);
      setTeamMemberModal(false);
    }
  }, [errorC, modalCount, sucessC]);

  const AddTeamMemberCheck = () => {
    if (
      user_package_check === "pro" &&
      TeamAllowed === 1 &&
      teamMembers?.length > 3
    ) {
      return true;
    }
    if (
      user_package_check === "pro" &&
      TeamAllowed === 2 &&
      teamMembers?.length > 2
    ) {
      return true;
    }
    if (
      user_package_check === "pro" &&
      TeamAllowed === 3 &&
      teamMembers?.length > 1
    ) {
      return true;
    }
    if (
      user_package_check === "pro" &&
      TeamAllowed === 4 &&
      teamMembers?.length > 0
    ) {
      return true;
    }
    if (
      user_package_check === "pro" &&
      TeamAllowed === 5 &&
      teamMembers?.length > 0
    ) {
      return true;
    }

    return false;
  };

  return (
    <Transition.Root show={teamMemberModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={setTeamMemberModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <AlertModal
          alertModal={alertModal}
          setAlertModal={setAlertModal}
          setErrorCount={setErrorCount}
          errorC={errorC}
          sucessC={sucessC}
          setSuccessCount={setSuccessCount}
          modalCount={modalCount}
          setModalCount={setModalCount}
          failedEmails={failedEmails}
          setFailedEmails={setFailedEmails}
        />
        <GeneralModal
          generalModal={generalModal}
          setGeneralModal={setGeneralModal}
          heading={heading}
          description={description}
        />
        <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center sm:px-4 md:px-0 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left transition-all sm:my-8 sm:w-full md:w-1/3 sm:h-[39rem] md:h-[37rem]">
                <div className="bg-white">
                  <p
                    className="text-xl font-bold text-black cursor-pointer text-left px-8 pt-5"
                    style={{ fontFamily: "Archia Bold" }}
                  >
                    Add new team members
                  </p>
                  <p
                    className="px-8 text-[#000000de] mt-3"
                    style={{ fontFamily: "Silka" }}
                  >
                    You can always add team members in the settings.
                  </p>
                  <IoClose
                    className="absolute top-3 right-5 z-20 w-5 h-5 cursor-pointer"
                    onClick={() => setTeamMemberModal(false)}
                  />
                </div>
                <div className="h-[23.5rem] overflow-auto">
                  {teamMembers.map((teamMember, index) => (
                    <div className="h-[4rem]">
                      <div
                        key={index}
                        className="mt-8 px-8 flex flex-row w-full"
                      >
                        <div
                          className={`grid grid-cols-3 border-[2px] w-full border-gray-300 rounded-lg ${
                            teamMember?.sucess === true
                              ? "bg-gray-100"
                              : "focus-within:border-coalColor hover:border-coalColor"
                          }`}
                        >
                          <div className="col-span-2">
                            <input
                              className="block w-full px-4 py-3 text-base text-gray-900 rounded-lg dark:placeholder-gray-400"
                              placeholder="Teammate's work email"
                              required
                              value={teamMember.email}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "email",
                                  e.target.value
                                )
                              }
                              disabled={teamMember?.sucess === true}
                              style={{ fontFamily: "Silka" }}
                            />
                          </div>
                          <div className="col-span-1">
                            <div className="relative h-full">
                              <button
                                type="button"
                                style={{ fontFamily: "Silka" }}
                                className="h-full border-l flex flex-row items-center justify-center hover:bg-gray-100 hover:rounded-tr-lg hover:rounded-br-lg border-gray-300 w-full font-medium text-sm px-4"
                                onClick={(event) => {
                                  handleRolesDropdown(event, index);
                                  setIndex(index);
                                }}
                                disabled={teamMember?.sucess === true}
                              >
                                <span className="mr-2">
                                  {teamMember.accountType ===
                                    "hiring-manager" && "Viewer"}
                                  {teamMember.accountType === "recruiter" &&
                                    "Editor"}
                                  {teamMember.accountType === "admin" &&
                                    "Admin"}
                                </span>
                                <IoIosArrowDown className="text-base ml-auto sm:hidden md:block" />
                              </button>
                              {teamMember.rolesDropdown && (
                                <Menu
                                  anchorEl={anchorEl}
                                  open={Boolean(anchorEl)}
                                  onClose={(event) =>
                                    handleRolesDropdown(event, index)
                                  }
                                  PaperProps={{
                                    style: {
                                      boxShadow: "0 3px 5px rgba(0,0,0,0.1)",
                                      width: "450px",
                                      marginTop: "5px",
                                      fontFamily: "Silka",
                                    },
                                  }}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                  autoFocus={false}
                                >
                                  {roles.map((role, roleIndex) => (
                                    <MenuItem
                                      key={roleIndex}
                                      onClick={() => {
                                        handleAccountTypeChange(
                                          indexA,
                                          "accountType",
                                          role?.value
                                        );
                                        handleClose();
                                      }}
                                    >
                                      <div
                                        className={`p-1 rounded-full my-auto`}
                                        style={{ fontFamily: "Silka" }}
                                      >
                                        <p
                                          className="block px-1 py-2 text-sm font-bold"
                                          style={{
                                            fontFamily: "Archia Semibold",
                                          }}
                                        >
                                          {role?.title}
                                        </p>
                                        <p className="px-1">{role?.access}</p>
                                      </div>
                                    </MenuItem>
                                  ))}
                                </Menu>
                              )}
                            </div>
                          </div>
                        </div>
                        <IoMdClose
                          className="my-auto ml-5 cursor-pointer"
                          onClick={() => handleRemoveMember(index)}
                        />
                      </div>
                      <p className="text-rose-500 text-xs px-8 ml-2 fade-in-text-candidate">
                        {teamMember?.error}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="px-8 mt-2 2xl:mt-5 w-full">
                  <CustomButton
                    label="Add more"
                    iconWidth={5}
                    iconHeight={5}
                    textSize="font-medium"
                    bgColor="bg-white"
                    widthButton="w-auto"
                    icon={FiPlusCircle}
                    hoverButton="hover:bg-coalColor text-[#252E3A] hover:text-primaryGreen"
                    onClickButton={() => {
                      if (AddTeamMemberCheck()) {
                        setHeading("You’ve reached your limit!");
                        setdescription(
                          user_package_check === "free"
                            ? "Explore For Free subscription allows 1 Active Test, either change the status of your Active Test to ‘Draft’ or upgrade your subscription package"
                            : "To add more than 5 team members, upgrade your subscription package to Enterprise"
                        );
                        setGeneralModal(true);
                      } else {
                        handleAddMore();
                      }
                    }}
                  />
                </div>
                <div className="flex flex-row justify-end px-8 gap-5">
                  <CustomButton
                    label="Cancel"
                    iconWidth={5}
                    iconHeight={5}
                    textSize="text-base"
                    bgColor="#EDEDED"
                    widthButton="w-[5rem]"
                    textColor="black"
                  />
                  <CustomButton
                    label="Invite"
                    iconWidth={5}
                    iconHeight={5}
                    textSize="text-base"
                    bgColor="#C0FF06"
                    widthButton="w-[5rem]"
                    textColor="black"
                    borderCustom="border border-black text-coalColor"
                    hoverBgColor="#252E3A"
                    hoverTextColor="#C0FF06"
                    onClickButton={() => {
                      if (
                        teamMembers?.length > 4 &&
                        user_package_check === "pro"
                      ) {
                        setHeading("You’ve reached your limit!");
                        setdescription(
                          user_package_check === "free"
                            ? "Explore For Free subscription allows 1 Active Test, either change the status of your Active Test to ‘Draft’ or upgrade your subscription package"
                            : "To add more than 5 team members, upgrade your subscription package to Enterprise"
                        );
                        setGeneralModal(true);
                      } else {
                        HandleSaveTeam();
                      }
                    }}
                    disableField={
                      personalEmail || teamMembers[0]?.email === ""
                        ? true
                        : false
                    }
                    disabledCheck={
                      personalEmail || teamMembers[0]?.email === ""
                        ? true
                        : false
                    }
                    disabledColor="#D3D5D8"
                    disabledTextColor="#7C8289"
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
