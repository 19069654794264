import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    setEntry: "",
};

export const EntrySlice = createSlice({
    name: "entry_level",
    initialState,
    reducers: {
        setEntryLevel: (state, action) => {
            state.setEntry = action.payload;
        },
    },
});

export const { setEntryLevel } =
    EntrySlice.actions;
export default EntrySlice.reducer;
