//#region slider tabs
export const tabs_sidebar = [
  {
    title: "General",
  },
  {
    title: "Education",
  },
  {
    title: "Work experience",
  },
];
//#endregion

export const location_preferences = [
  {
    title: "Remote",
  },
  {
    title: "On/Site",
  },
  {
    title: "Hybrid",
  },
];

export const currency = [
  {
    title: "USD",
  },
  {
    title: "EUR",
  },
  {
    title: "GBP",
  },
  {
    title: "AUD",
  },
  {
    title: "SAR",
  },
];
