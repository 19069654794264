import React, { useMemo, useState } from "react";
import Modules from "./Modules";
import General from "./General";
import Candidates from "./Candidates";
import { getAssessmentByID } from "./hooks/getAssessmentByID";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useEffect } from "react";
import "intro.js/introjs.css";
import { useNavigate } from "react-router-dom";
import "./invite.css";
import "./tooltip.css";
import {
  setPreviewToTrue,
  setPreviewToFalse,
} from "../../../../redux/reducers/PreviewBack/PreviewSlice";
import { useDispatch } from "react-redux";
import Questions from "./Questions";
import CustomButton from "../../../../Components/CustomButton/CustomButton";
import { setNextGeneralToTrue } from "../../../../redux/reducers/NextGeneral/NextGeneralSlice";
import { setNextModuleToTrue } from "../../../../redux/reducers/NextModules/NextModulesSlice";
import { setNextQuestionsToTrue } from "../../../../redux/reducers/NextQuestions/NextQuestionsSlice";
import { setNextCandidateToTrue } from "../../../../redux/reducers/NextCandidate/NextCandidateSlice";
import createIcon from "../../../../Dexta_assets/createIcon.png";
import time from "../../../../Dexta_assets/time.png";
import add from "../../../../Dexta_assets/add.png";
import tick from "../../../../Dexta_assets/tick.png";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaLessThan } from "react-icons/fa6";
import tick2 from "../../../../Dexta_assets/tickCoal.png";
import SetupCompleted from "./setupComplete";
import eye from "../../../../Dexta_assets/eye.png";
import { MdOutlineEmail } from "react-icons/md";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
const CreateAssessment = () => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [data_assessment, setDataAssessment] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const assessment_id = localStorage.getItem("assessment_ID");
  const dispatch = useDispatch();
  const [generalLoading, setGeneralLoading] = useState(false);
  const [modulesLoading, setModulesLoading] = useState(false);
  const [questionLoading, setQuestionLoading] = useState(false);
  const [candidatesLoading, setCandidatesLoading] = useState(false);
  const copied = useSelector((state) => state.copy.setCopy);

  useEffect(() => {
    if (
      assessment_id !== "null" &&
      (!parsed || parsed.assessment_id === undefined)
    ) {
      setIsLoading(true);
      getAssessmentByID(parseInt(assessment_id))
        .then((result) => {
          setDataAssessment(result);
          setIsLoading(false);
        })
        .catch((error) => {
          setError(error);
          setIsLoading(false);
        });
    } else if (parsed && parsed.assessment_id !== undefined) {
      setIsLoading(true);
      getAssessmentByID(parsed.assessment_id)
        .then((result) => {
          console.log("🚀 ~ .then ~ result:", result);
          setDataAssessment(result);
          setIsLoading(false);
        })
        .catch((error) => {
          setError(error);
          setIsLoading(false);
        });
    }
  }, [parsed.assessment_id, assessment_id]);

  //#region Fetch assessments data
  const {
    data: datanew,
    error: dataerrpr,
    isLoading: dataLoading,
  } = useQuery(
    [
      "assessment",
      parsed && parsed.assessment_id !== undefined
        ? parsed.assessment_id
        : assessment_id,
    ],
    () =>
      getAssessmentByID(
        parsed && parsed.assessment_id !== undefined
          ? parsed.assessment_id
          : assessment_id
      )
  );
  //#endregion

  const timeCalculation = data_assessment?.section?.reduce((accumulator, i) => {
    if (i.time !== "") {
      const timeValue = parseInt(i?.time);
      if (!isNaN(timeValue)) {
        return accumulator + timeValue;
      }
    }
    return accumulator;
  }, 0);

  const assessmentData = {
    name: data_assessment?.name || "",
    job: data_assessment?.jobs?.map((i) => i.id).filter(Boolean) || [],
    job_name: data_assessment?.jobs?.map((i) => i.name).filter(Boolean) || [],
    job_title: data_assessment?.jobRole || "",
    work_arrangement: data_assessment?.work_arrangement || "",
    jobLocation: data_assessment?.job_location.split(",") || [],
    experience: data_assessment?.experience || "",
    expName:
      data_assessment?.experience === "entry"
        ? "Entry Level"
        : "" || data_assessment?.experience === "mid"
        ? "Mid Level"
        : "" || data_assessment?.experience === "senior"
        ? "Senior"
        : "" || data_assessment?.experience === "all"
        ? "All Levels"
        : "" || "",
    category:
      data_assessment?.category?.map((i) => i.categoryName).filter(Boolean) ||
      [],
    categoryID:
      data_assessment?.category?.map((i) => i.id).filter(Boolean) || [],
    AssessmentID: 0,
    task1: data_assessment?.setpOne || "",
    modulesID: data_assessment?.section?.map((i) => i.id).filter(Boolean) || [],
    customQuestion: data_assessment?.customQuestionsList?.id || null,
    customQuestionDetail: data_assessment?.customQuestionsList || null,
    moduleNames:
      data_assessment?.section?.map((i) => i.name).filter(Boolean) || [],
    showRemove: false,
    task2: data_assessment?.setpTwo || "",
    task4: datanew?.setpThree || "",
    fileBulk: null,
    tags: [],
    tagleng:
      data_assessment?.candidates
        ?.map((candidate) => candidate.email)
        .filter(Boolean).length || [],
    task3: data_assessment?.customQuestionsList?.id
      ? "completed"
      : "" || data_assessment?.setpThree === "completed"
      ? "completed"
      : "",
    timeMod:
      timeCalculation && timeCalculation !== undefined ? timeCalculation : 0,
  };
  const [data, setData] = useState(assessmentData);
  const [selecteditem, setselecteditem] = useState("");
  const itemName = localStorage.getItem("current_module");

  console.log(datanew?.isFinished, "keeping track");

  useEffect(() => {
    setselecteditem(itemName);
  }, [selecteditem]);

  useMemo(() => {
    setData(assessmentData);
  }, [isLoading]);

  document.title = "Create New | Dexta";

  //#region Handle next and back on general and modules
  const handlingNext = () => {
    dispatch(setNextGeneralToTrue(true));
  };

  const handlingNextModule = () => {
    dispatch(setNextModuleToTrue(true));
  };

  const handleBackModule = () => {
    setselecteditem("general");
    localStorage.setItem("current_module", "general");
  };

  const handlingNextQuestions = () => {
    dispatch(setNextQuestionsToTrue(true));
  };
  //#endregion

  return (
    <>
      <div className="bg-bodyColor">
        <div className="lg:container mx-auto">
          <div className="pt-[7rem] sm:px-4 lg:px-2 mb-10">
            <div className="flex justify-between sm:flex-col lg:flex-row">
              <div className="flex sm:flex-col lg:flex-row sm:gap-2 lg:gap-8">
                <div className="flex flex-row">
                  <img
                    src={createIcon}
                    className="w-4 h-5 mr-2 icon-image my-auto"
                  />
                  <p
                    className="sm:text-xl lg:text-2xl my-auto"
                    style={{ fontFamily: "Archia Semibold" }}
                  >
                    Create new test
                  </p>
                </div>
                {data?.task1 === "completed" && (
                  <p
                    className="text-xs my-auto sm:ml-6 lg:ml-0"
                    style={{ fontFamily: "Silka Light" }}
                  >
                    {data?.name}
                  </p>
                )}
                <div className="flex flex-row my-auto">
                  <img src={add} className="w-4 h-4 my-auto mr-2" />
                  <p className="text-xs" style={{ fontFamily: "Silka Light" }}>
                    Added modules ({data?.modulesID?.length})
                  </p>
                </div>
                <div className="flex flex-row my-auto">
                  <img src={time} className="w-4 h-4 my-auto mr-2" />
                  <p className="text-xs" style={{ fontFamily: "Silka Light" }}>
                    {data?.timeMod} mins
                  </p>
                </div>
                <div className="flex flex-row">
                  {(selecteditem === "questions" ||
                    selecteditem === "candidates") && (
                    <>
                      {data?.task2 === "completed" &&
                      data?.moduleNames.length != 0 ? (
                        <>
                          {parsed && parsed.assessment_id !== undefined ? (
                            <a
                              className="my-auto justify-center border py-1 border-coalColor rounded-md flex px-4 flex-row gap-2 cursor-pointer"
                              href={`/preview/${parsed.assessment_id}`}
                              onClick={() => dispatch(setPreviewToTrue(true))}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={eye}
                                className="w-5 h-3 my-auto"
                                color="#4A9CB9"
                              />
                              <p
                                className="text-xs"
                                style={{ fontFamily: "Silka Light" }}
                              >
                                Preview modules
                              </p>
                            </a>
                          ) : (
                            <a
                              className="my-auto justify-center border py-1 border-coalColor rounded-md flex px-4 flex-row gap-2 cursor-pointer"
                              href={`/preview/${assessment_id}`}
                              onClick={() => dispatch(setPreviewToTrue(true))}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={eye}
                                className="w-5 h-3 my-auto"
                                color="#4A9CB9"
                              />
                              <p
                                className="text-xs"
                                style={{ fontFamily: "Silka Light" }}
                              >
                                Preview modules
                              </p>
                            </a>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="flex relative">
                            <div className="my-auto justify-center border py-1 border-coalColor rounded-md flex px-4 flex-row gap-2 cursor-not-allowed">
                              <img
                                src={eye}
                                className="w-5 h-3 my-auto"
                                color="#4A9CB9"
                              />
                              <p
                                className="text-xs"
                                style={{ fontFamily: "Silka Light" }}
                              >
                                Preview modules
                              </p>
                            </div>
                            <div className="tooltip2 w-80 text-center">
                              You have to complete at-least two steps to preview
                              your modules
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-row my-auto">
                <div className="underline my-auto sm:w-full lg:w-auto lg:ml-0">
                  {selecteditem !== "general" &&
                    selecteditem !== "finished" &&
                    datanew?.isFinished !== true && (
                      <div
                        className="mr-2 flex flex-row underline cursor-pointer"
                        onClick={() => {
                          if (selecteditem === "modules") {
                            handleBackModule();
                          } else if (selecteditem === "questions") {
                            setselecteditem("modules");
                            localStorage.setItem("current_module", "modules");
                          } else if (selecteditem === "candidates") {
                            setselecteditem("questions");
                            localStorage.setItem("current_module", "questions");
                          }
                        }}
                      >
                        <FaLessThan className="mr-2 w-3 h-3 my-auto" />
                        <span style={{ fontFamily: "Silka" }}>Go back</span>
                      </div>
                    )}
                </div>
                <div
                  className={`${
                    selecteditem === "finished"
                      ? "sm:w-[30rem] lg:w-[18rem]"
                      : "sm:w-full lg:w-[11rem]"
                  } lg:ml-4`}
                >
                  <CustomButton
                    label={
                      selecteditem === "questions"
                        ? "Publish Test"
                        : selecteditem === "candidates"
                        ? "Send invites"
                        : selecteditem === "finished"
                        ? "Back to My Tests"
                        : "Next step"
                    }
                    bgColor="#C0FF06"
                    textColor="#252E3A"
                    paddingY="0.5rem"
                    textSize="text-base"
                    disabledColor="#D3D5D8"
                    disabledTextColor="#7C8289"
                    hoverBgColor="#252E3A"
                    hoverTextColor="#C0FF06"
                    iconR={
                      selecteditem === "candidates"
                        ? MdOutlineEmail
                        : FaArrowRightLong
                    }
                    loadingColor="black"
                    onClickButton={() => {
                      if (selecteditem === "general") {
                        handlingNext();
                      } else if (selecteditem === "modules") {
                        handlingNextModule();
                        setData({ ...data, task2: "completed" });
                      } else if (selecteditem === "questions") {
                        handlingNextQuestions();
                        setData({ ...data, task3: "completed" });
                      } else if (selecteditem === "candidates") {
                        dispatch(setNextCandidateToTrue(true));
                        setData({ ...data, task4: "completed" });
                      } else if (selecteditem === "finished") {
                        navigate("/dashboard");
                        localStorage.removeItem("current_module");
                      }
                    }}
                    disableField={
                      (selecteditem === "general" &&
                        (data?.name === "" ||
                          data?.job_name.length === 0 ||
                          data?.experience === "" ||
                          data?.category?.length === 0)) ||
                      (selecteditem === "modules" &&
                        data?.modulesID.length === 0) ||
                      (selecteditem === "candidates" &&
                        data?.tags.length === 0 &&
                        copied === false &&
                        data?.fileBulk === null)
                    }
                    borderCustom="border border-black"
                    disabledCheck={
                      (selecteditem === "general" &&
                        (data.name === "" ||
                          data.job_name.length === 0 ||
                          data.experience === "" ||
                          data.category?.length === 0)) ||
                      (selecteditem === "modules" &&
                        data.modulesID.length === 0) ||
                      (selecteditem === "candidates" &&
                        data.tags.length === 0 &&
                        copied === false &&
                        data?.fileBulk === null)
                    }
                    LoadingBtn={
                      (selecteditem === "general" && generalLoading) ||
                      (selecteditem === "modules" && modulesLoading) ||
                      (selecteditem === "questions" && questionLoading) ||
                      (selecteditem === "candidates" && candidatesLoading)
                    }
                    loadingText="Processing"
                  />
                </div>
              </div>
            </div>
            <div
              className={`grid sm:grid-cols-2 lg:grid-cols-4 mt-7 sm:text-[10px] lg:text-xs  lg:bg-disabledColor rounded-2xl`}
              style={{ fontFamily: "Silka" }}
            >
              <div
                className={`border-r ${
                  selecteditem === "general" && "font-bold"
                } ${
                  data.task1 === "completed" &&
                  "bg-[#FF5812]  lg:rounded-l-2xl text-white border-none"
                } py-1 pl-4 border-white ${
                  datanew?.isFinished === true
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                onClick={() => {
                  if (datanew?.isFinished !== true) {
                    if (
                      data?.task1 === "completed" &&
                      selecteditem != "general"
                    ) {
                      setselecteditem("general");
                      localStorage.setItem("current_module", "general");
                    }
                  }
                }}
              >
                {data?.task1 === "completed" ? (
                  <div className="flex flex-row">
                    <img src={tick} className="w-2 h-2 my-auto mr-2" />
                    Add test details
                  </div>
                ) : (
                  "Add test details"
                )}
              </div>
              <div
                className={`border-r ${
                  selecteditem === "modules" && "font-bold"
                } ${
                  data.task2 === "completed" &&
                  "bg-[#FFB500] text-coalColor border-none"
                } py-1 pl-4 border-white ${
                  datanew?.isFinished === true
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                onClick={() => {
                  if (datanew?.isFinished !== true) {
                    if (
                      data?.task1 === "completed" &&
                      data?.task2 === "completed" &&
                      selecteditem != "modules"
                    ) {
                      setselecteditem("modules");
                      localStorage.setItem("current_module", "modules");
                    }
                  }
                }}
              >
                {data?.task2 === "completed" ? (
                  <div className="flex flex-row">
                    <img src={tick2} className="w-2 h-2 my-auto mr-2" />
                    Add modules
                  </div>
                ) : (
                  "Add modules"
                )}
              </div>
              <div
                className={`border-r py-1 pl-4 border-white ${
                  datanew?.isFinished === true
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                } ${selecteditem === "questions" && "font-bold"} ${
                  data.task3 === "completed" &&
                  "bg-[#C0FF06] text-coalColor border-none"
                } `}
                onClick={() => {
                  if (datanew?.isFinished !== true) {
                    if (
                      data?.task1 === "completed" &&
                      data?.task2 === "completed" &&
                      data?.task3 === "completed" &&
                      selecteditem != "questions"
                    ) {
                      setselecteditem("questions");
                      localStorage.setItem("current_module", "questions");
                    }
                  }
                }}
              >
                {data?.task3 === "completed" ? (
                  <div className="flex flex-row">
                    <img src={tick2} className="w-2 h-2 my-auto mr-2" />
                    Add custom questions
                  </div>
                ) : (
                  "Add custom questions"
                )}
              </div>
              <div
                className={`border-r py-1 pl-4 ${
                  datanew?.isFinished === true
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                } border-white ${
                  selecteditem === "candidates" && "font-bold"
                } ${
                  data.task4 === "completed" &&
                  "bg-[#0B5B23] lg:rounded-r-full text-white border-none"
                }`}
                onClick={() => {
                  if (datanew?.isFinished !== true) {
                    if (
                      data?.task1 === "completed" &&
                      data?.task2 === "completed" &&
                      data?.task3 === "completed" &&
                      data?.task4 === "completed" &&
                      selecteditem != "candidates"
                    ) {
                      setselecteditem("candidates");
                      localStorage.setItem("current_module", "candidates");
                    }
                  }
                }}
              >
                {data?.task4 === "completed" ? (
                  <div className="flex flex-row">
                    <img src={tick} className="w-2 h-2 my-auto mr-2" />
                    Select candidates
                  </div>
                ) : (
                  "Select candidates"
                )}
              </div>
            </div>
          </div>
          {!isLoading && (
            <React.Fragment>
              {selecteditem === "general" && (
                <General
                  selecteditem={selecteditem}
                  data={data}
                  setselecteditem={(value) => setselecteditem(value)}
                  setData={(value) => setData(value)}
                  setGeneralLoading={setGeneralLoading}
                />
              )}
            </React.Fragment>
          )}
          {selecteditem === "modules" && (
            <Modules
              selecteditem={selecteditem}
              data={data}
              setselecteditem={(value) => setselecteditem(value)}
              setData={(value) => setData(value)}
              setModulesLoading={setModulesLoading}
            />
          )}
          {selecteditem === "questions" && (
            <Questions
              selecteditem={selecteditem}
              data={data}
              setselecteditem={(value) => setselecteditem(value)}
              setData={(value) => setData(value)}
              setQuestionLoading={setQuestionLoading}
            />
          )}
          {selecteditem === "candidates" && (
            <Candidates
              data={data}
              setselecteditem={(value) => setselecteditem(value)}
              setData={(value) => setData(value)}
              setCandidatesLoading={setCandidatesLoading}
            />
          )}
          {selecteditem === "finished" && (
            <SetupCompleted
              data={data}
              setselecteditem={(value) => setselecteditem(value)}
              setData={(value) => setData(value)}
              setCandidatesLoading={setCandidatesLoading}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default CreateAssessment;
