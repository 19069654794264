export const stepsCandidates = [
  {
    title: "Candidates",
    element: "#cad2",
    intro: (
      <div>
        <p>
          Now, use this dashboard to view details for each candidate who
          participated in any test. You can:
        </p>
        <ul className="list-disc px-4">
          <li>See the candidate's name and which test they took</li>
          <li>
            View stats for each candidate, such as the status of their test and
            their score
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: "Filter Candidates",
    element: "#cad3",
    intro: (
      <div>
        <p>
          Use these filters to search for candidates who have taken the tests:
        </p>
        <ul className="list-disc px-4">
          <li>
            <b>Invited:</b> Candidates who have been invited to take the test
            but haven't started yet
          </li>
          <li>
            <b>In Progress:</b> Candidates who are currently taking the test but
            haven't completed it yet
          </li>
          <li>
            <b>Completed:</b> Candidates who have completed the test
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: "Search Candidates",
    element: "#cad4",
    intro: (
      <div>
        <p>
          Utilize the search functionality to quickly find specific candidates
          by their names
        </p>
        <p>
          Simply enter the candidate name in the search bar and the dashboard
          will filter candidates matching your criteria
        </p>
      </div>
    ),
  },
];

export const assessment_statuses = [
  {
    name: "All",
    value: "",
  },
  {
    name: "Invited",
    value: "invited",
  },
  {
    name: "In Progress",
    value: "InProgress",
  },
  {
    name: "Completed",
    value: "completed",
  },
];
