import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  setTotal: "",
};

export const QuestionsSlice = createSlice({
  name: "questionsTotal",
  initialState,
  reducers: {
    setQuestionsTotal: (state, action) => {
      state.setTotal = action.payload;
    },
  },
});

export const { setQuestionsTotal } = QuestionsSlice.actions;
export default QuestionsSlice.reducer;
