import React from "react";
import { Navigate } from "react-router-dom";
import { isRecruiterAuthenticated } from "../Helpers/Auth";

const Authmiddleware = (props) => {
  const isAuthenticated = isRecruiterAuthenticated();

  if (!isAuthenticated) {
    return <Navigate to={{ pathname: "/login" }} />;
  }


  return <React.Fragment>{props.children}</React.Fragment>;
};

export default Authmiddleware;
