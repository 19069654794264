import React, { useEffect, useState } from "react";
import Logo from "../../Assets/mainLOGO.png";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { CgProfile } from "react-icons/cg";
import { AiOutlineLogout } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { getCandidateDetails } from "../../Pages/Candidates/hooks/getCandidateDetails";
import dropdown from "../../Dexta_assets/dropdown.png";

const NavbarCandidate = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const userID = localStorage.getItem("CP-CANDIDATE-ID");
  const [selectedItem, setSelectedItem] = useState("");
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //#region Fetch users data
  const { data, isLoading, error } = useQuery(["users", userID], () =>
    getCandidateDetails(userID)
  );
  //#endregion

  //#region Select item from navbar
  const handleSelectItem = (item) => {
    setSelectedItem(item);
  };
  useEffect(() => {
    if (
      window.location.pathname === "/candidate/dashboard" ||
      window.location.pathname.includes("/assessment")
    ) {
      setSelectedItem("home");
    }
  }, [selectedItem, window.location.pathname]);
  //#endregion

  //#region toggle navbar for mobile
  const toggleMobileMenu = () => {
    setMobileMenuOpen(true);
    if (isMobileMenuOpen) {
      setMobileMenuOpen(false);
    }
  };
  //#endregion

  return (
    <nav className="bg-white">
      <div className="lg:container mx-auto">
        <div className="py-3">
          <div className="lg:pl-9 lg:pr-10 md:pl-5 md:pr-5 ">
            <div className="md:grid md:grid-cols-12 sm:flex sm:flex-row sm:justify-between">
              <div className="col-span-3 sm:pl-3 sm:pr-3 md:pl-0 md:pr-0">
                <Link
                  to="/candidate/dashboard"
                  className="flex items-center my-auto"
                >
                  <img
                    src={Logo}
                    className="h-[50px] w-[180px] object-cover "
                    alt="CP Logo"
                  />
                </Link>
              </div>
              <div
                className="sm:col-span-4 md:col-span-6 flex sm:hidden md:block border-black mr-auto"
                style={{ fontFamily: "Silka" }}
              >
                <div
                  className={`flex flex-row px-4 gap-2 my-auto ${
                    selectedItem === "home" ? "bg-coalColor rounded-md" : null
                  }`}
                  id="tab1"
                >
                  <Link
                    to="/candidate/dashboard"
                    onClick={() => handleSelectItem("home")}
                    className={`block py-2 text-coalColor ${
                      selectedItem === "home" ? "text-primaryGreen" : null
                    }`}
                    aria-current="page"
                  >
                    My results
                  </Link>
                </div>
              </div>
              <div className="sm:col-span-5  sm:hidden md:block md:col-span-3 ml-auto my-auto">
                <React.Fragment>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <div
                      onClick={(e) => {
                        handleClick(e);
                      }}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      className="flex flex-row cursor-pointer"
                    >
                      <div
                        className="flex flex-row gap-2 my-auto"
                        style={{ fontFamily: "Archia Semibold" }}
                      >
                        <div className="font-medium sm:text-xs md:text-[17px]">
                          {data?.firstName} {data?.lastName}
                        </div>
                        <img
                          src={dropdown}
                          className="w-3.5 ml-2 h-2 my-auto"
                        />
                      </div>
                    </div>
                  </Box>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        navigate("/candidate/profile");
                      }}
                    >
                      <ListItemIcon>
                        <CgProfile className="w-5 h-5 hover:animate-pulse cursor-pointer" />
                      </ListItemIcon>
                      <span style={{ fontFamily: "Silka" }}>
                        Edit profile info
                      </span>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        localStorage.removeItem("CP-CANDIDATE-TOKEN");
                        localStorage.removeItem("CP-CANDIDATE-ID");
                        navigate("/candidate/login");
                      }}
                    >
                      <ListItemIcon>
                        <AiOutlineLogout className="w-5 h-4 hover:animate-pulse cursor-pointer" />
                      </ListItemIcon>
                      <span style={{ fontFamily: "Silka" }}>Logout</span>
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              </div>
              <div className="my-auto sm:pl-3 sm:pr-3 md:pl-0 md:pr-0">
                <button
                  type="button"
                  onClick={toggleMobileMenu}
                  className="flex items-center ml-20 rounded-md p-2 md:hidden text-coalColor focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primaryGreen"
                  aria-controls="mobile-menu"
                  aria-expanded={isMobileMenuOpen}
                >
                  <span className="sr-only">Open main menu</span>
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 17 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 1h15M1 7h15M1 13h15"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div
              className={`lg:hidden absolute bg-white w-full ${
                isMobileMenuOpen ? "block" : "hidden"
              }`}
              id="mobile-menu"
              style={{ fontFamily: "Silka" }}
            >
              <div className="pb-3 pt-2">
                <Link
                  to="/candidate/dashboard"
                  className={`text-[#000000]  block rounded-md px-3 py-2 hover:text-coalColor  text-base font-medium`}
                  aria-current="page"
                >
                  My Tests
                </Link>
                <Link
                  to="/candidate/profile"
                  className={`text-[#000000]  block hover:text-coalColor  rounded-md px-3 py-2 text-base font-medium`}
                >
                  My Profile
                </Link>
                <Link
                  onClick={() => {
                    handleClose();
                    localStorage.removeItem("CP-CANDIDATE-TOKEN");
                    localStorage.removeItem("CP-CANDIDATE-ID");
                    navigate("/candidate/login");
                  }}
                  className={`text-[#000000]  block rounded-md px-3 hover:text-coalColor  py-2 text-base font-medium`}
                >
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavbarCandidate;
