import https from "../../../https";

export const publicLink = async (data) => {
  const response = await https.post(
    `/assessment/invite_public_link/${data.code}`,
    data.data
  );
  response.data["old_data"] = data?.data;
  return response.data;
};
