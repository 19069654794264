import React, { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastContainer, Zoom } from "react-toastify";
import TextField from "../../../../Components/Dexta/TextField/TextField";
import CustomButton from "../../../../Components/CustomButton/CustomButton";
import MainLogo from "../../../../Dexta_assets/signup_girl.png";
import { FaArrowRightLong } from "react-icons/fa6";
import { loginUser } from "../../../Auth/hooks/loginUser";

const LoginCandidate = () => {
  const queryClient = useQueryClient();
  const email = localStorage.getItem("CP-CANDIDATE-EMAIL");
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);

  //#region password hide and unhide
  function togglePasswordVisibility() {
    setIsPasswordVisible((prevState) => !prevState);
  }
  //#endregion

  //#region validation with formik
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Email Required"),
      password: Yup.string().required("Password Required"),
    }),

    onSubmit: (values) => {
      let data = JSON.stringify({
        emailOrUsername: values?.email,
        password: values?.password,
      });
      try {
        mutate(data);
      } catch (error) {
        //onError will automatically detect
      }
    },
  });

  const { mutate, isLoading } = useMutation(loginUser, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("/auth/sign-in");
      localStorage.clear();
      if (response.accessToken) {
        localStorage.setItem("CP-CANDIDATE-TOKEN", response.accessToken);
      }
      if (response.user.id) {
        localStorage.setItem("CP-CANDIDATE-ID", response.user.id);
      }
      if (response?.user?.isUserOnboard === false) {
        navigate("/candidate/profile");
      } else if (response?.user?.isUserOnboard === true) {
        navigate("/candidate/dashboard");
      }
    },
    onError: (error) => {
      setError(true);
      setErrorMessage(error.response.data.message[0]);
      toast.success(error.response.data.message[0], {
        toastId: "copy-success",
      });
    },
  });
  //#endregion

  //#region taking user to top of screen
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //#endregion

  document.title = "Register | Dexta";
  return (
    <React.Fragment>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="sm:grid-cols-1 lg:grid-cols-2 grid bg-bgAuth h-screen">
        <div className="bg-black sm:hidden lg:block">
          <img src={MainLogo} className="w-full lg:h-full  lg:object-cover" />
        </div>
        <div className="bg-bgAuth flex justify-center flex-col md:pb-40">
          <div className="sm:w-5/6 lg:w-2/3 mx-auto">
            <h1
              className="text-2xl text-left"
              style={{ fontFamily: "Archia Semibold" }}
            >
              Log in to your Dexta Profile
            </h1>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <div className="h-[4.7rem] mt-6">
                <TextField
                  name="email"
                  id="email"
                  type="email"
                  placeholder="Enter your email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  marginTop="mt-2"
                  value={validation.values.email || ""}
                />
                {validation.touched.email && validation.errors.email ? (
                  <div className="ml-1">
                    <p className="text-rose-500 fade-in-text-validations sm:text-xs md:text-sm">
                      {validation.errors.email}
                    </p>
                  </div>
                ) : null}
              </div>
              <div className="h-[4.7rem]">
                <TextField
                  name="password"
                  id="password"
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder="Password"
                  onChange={(e) => {
                    validation.handleChange(e);
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ""}
                  marginTop="mt-2"
                  onClick={togglePasswordVisibility}
                  passwordIcon={true}
                  isPasswordVisible={isPasswordVisible}
                />
                {validation.touched.password && validation.errors.password ? (
                  <div className="ml-1">
                    <p className="text-rose-500 fade-in-text-validations sm:text-xs md:text-sm">
                      {validation.errors.password}
                    </p>
                  </div>
                ) : null}
              </div>
              <div className="flex justify-between mt-1">
                <div
                  className="flex flex-row p-2"
                  style={{ fontFamily: "Silka" }}
                >
                  <input
                    type="checkbox"
                    className="default:ring-2 ... accent-coalColor border-2 my-auto border-black w-4 h-4"
                  />
                  <p className="ml-2 sm:text-xs md:text-sm text-black">
                    Keep me logged in
                  </p>
                </div>
                <p
                  className="ml-2 p-2 sm:text-xs md:text-sm text-black cursor-pointer"
                  style={{ fontFamily: "Silka" }}
                  onClick={() => navigate("/candidate/forget-password")}
                >
                  Forgot password?
                </p>
              </div>
              <CustomButton
                label="Log in"
                borderCustom="border border-black text-white"
                paddingY="0.7rem"
                hoverBgColor="#C0FF06"
                hoverTextColor="#252E3A"
                marginTop="mt-4"
                bgColor="#252E3A"
                iconR={FaArrowRightLong}
                noMarginIcon={false}
                autoLeftMargin="ml-auto"
                textMarginBotton="ml-auto"
                LoadingBtn={isLoading}
                loadingText="Logging in"
              />
            </form>
            <span
              className="text-coalColor text-sm text-center mt-8"
              style={{ fontFamily: "Silka" }}
            >
              Don't have an account?{" "}
              <span
                style={{ fontFamily: "Silka Bold" }}
                className="text-coalColor font-medium cursor-pointer"
                onClick={() => navigate("/candidate/create-account")}
              >
                Create one here
              </span>
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginCandidate;
