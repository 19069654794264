import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getInvoiceList } from "../hooks/getInvoiceList";
import "react-loading-skeleton/dist/skeleton.css";
import { IoClose } from "react-icons/io5";
import { invite_tabs } from "./data";
import { ToastContainer, Zoom, toast } from "react-toastify";
import CustomButton from "../CustomButton/CustomButton";
import { useParams } from "react-router-dom";
import { updateStep } from "../../Pages/Profile/MyAssessments/Assessments-main/hooks/updateStep";
import InviteByBulk from "./InviteByBulk";
import http from "../../http";
import axios from "axios";
import InviteByLink from "./InviteByLink";
import CustomizeEmail from "../CustomizeEmail/CustomizeEmail";
import { setClearToTrue } from "../../redux/reducers/ClearRows/ClearRowsSlice";
import { useDispatch, useSelector } from "react-redux";
import PremiumModaloverModal from "../Modals/PremiumModaloverModal";
import King from "../../Assets/preee.png";
import InviteByEmailv2 from "./InviteByEmail_v2";
import InviteByEmail from "./InviteByEmail";
export default function ({
  inviteModal,
  setInviteModal,
  modulesData = [],
  candidatesAllowed = null,
  assessmentCode,
  setCopyCount,
  copyCount,
}) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const cancelButtonRef = useRef(null);
  const queryClient = useQueryClient();
  const [selectedTab, setSelectedTab] = useState("Invite by email");
  const [error_, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [inviteData, setInviteData] = useState([]);
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [barLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("CP-USER-TOKEN");
  const [customizeModal, setCustomizeModal] = useState(false);
  const user_package_check = useSelector(
    (state) => state.packageDetails.setPackage
  );
  const [premiumGeneralOpen, setPremiumGeneral] = useState(false);
  const [disableAdd, setDisableAdd] = useState(false);
  const [disableInput, setDisableInput] = useState(false);

  //#region submitting one by one
  const handleSubmit = () => {
    let data = {
      content: {
        candidates: inviteData,
        isFinished: true,
      },
      categoryID: id,
    };

    try {
      mutate(data);
    } catch (err) {
      //
    }
  };

  const handleSubmitStatus = () => {
    let data = {
      content: {
        isFinished: true,
      },
      categoryID: id,
    };

    try {
      mutateStatus(data);
    } catch (err) {
      //
    }
  };
  //#endregion

  //#region submitting in bulk
  const handleBulkSubmit = async () => {
    setIsLoading(true);
    if (!file) {
      setError("Please select a file.");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_Server}/assessment/${id}/bulk-invite/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );
              setProgress(percentCompleted);
            },
          }
        )
        .then((res) => {
          toast.success("Invitations sent", {
            toastId: "copy-success",
          });
          handleSubmitStatus();
          setInviteModal(false);
        });
      if (!response.ok) {
        throw new Error("Failed to upload file");
      }
    } catch (error) {
      setError("Failed to upload file");
      toast.success(error?.response?.data?.message, {
        style: { width: "400px" },
      });
    } finally {
      setIsLoading(false);
      setProgress(0);
      setFile(null);
      queryClient.invalidateQueries("assessment");
      // toast.success("Invitations sent")
    }
  };
  //#endregion

  //#region reach-query function to send invites
  const { mutate, isLoading: mutateLoading } = useMutation(updateStep, {
    onSuccess: () => {
      queryClient.invalidateQueries("assessment");
      if (inviteData?.length > 1) {
        toast.success("Invites has been sent", {
          toastId: "copy-success",
        });
      } else {
        toast.success("Invite has been sent", {
          toastId: "copy-success",
        });
      }
      dispatch(setClearToTrue(true));
      setInviteModal(false);
    },
    onError: (error) => {
      const errorResponseMessage = error?.response?.data?.message;
      const firstErrorMessage = Array.isArray(errorResponseMessage)
        ? errorResponseMessage[0]
        : errorResponseMessage;

      if (firstErrorMessage) {
        if (firstErrorMessage.includes(".com")) {
          toast.error(
            `This candidate ${error.response.data.message[0]} has already been invited`,
            {
              toastId: "copy-success",
              style: { width: "450px" },
            }
          );
        } else {
          toast.error(firstErrorMessage, {
            toastId: "copy-success",
            style: { width: "450px" },
          });
        }
      } else {
        toast.error("An error occurred.");
      }

      setError(true);
      setErrorMessage(firstErrorMessage || "An error occurred.");
    },
  });
  //#endregion

  const { mutate: mutateStatus } = useMutation(updateStep, {
    onSuccess: () => {
      queryClient.invalidateQueries("assessment");
    },
    onError: (error) => {
      const errorResponseMessage = error?.response?.data?.message;
      const firstErrorMessage = Array.isArray(errorResponseMessage)
        ? errorResponseMessage[0]
        : errorResponseMessage;

      if (firstErrorMessage) {
        if (firstErrorMessage.includes(".com")) {
          toast.error(
            `This candidate ${error.response.data.message[0]} has already been invited`,
            {
              toastId: "copy-success",
              style: { width: "450px" },
            }
          );
        } else {
          toast.error(firstErrorMessage, {
            style: {
              toastId: "copy-success",
              width: "450px",
            },
          });
        }
      } else {
        toast.error("An error occurred.");
      }

      setError(true);
      setErrorMessage(firstErrorMessage || "An error occurred.");
    },
  });

  //#region Handle item from tab
  const handleTabClick = (name) => {
    setSelectedTab(name);
  };
  //#endregion

  //#region Clear all candidates list
  const handleClear = () => {
    dispatch(setClearToTrue(true));
  };
  //#endregion

  //#region Disabling Add button and Input field
  useEffect(() => {
    if (
      user_package_check === "free" &&
      candidatesAllowed === 1 &&
      inviteData?.length > 4
    ) {
      setDisableAdd(true);
    }
    if (
      user_package_check === "free" &&
      candidatesAllowed === 2 &&
      inviteData?.length > 3
    ) {
      setDisableAdd(true);
    }
    if (
      user_package_check === "free" &&
      candidatesAllowed === 3 &&
      inviteData?.length > 2
    ) {
      setDisableAdd(true);
    }
    if (
      user_package_check === "free" &&
      candidatesAllowed === 4 &&
      inviteData?.length > 1
    ) {
      setDisableAdd(true);
    }
    if (
      user_package_check === "free" &&
      candidatesAllowed === 5 &&
      inviteData?.length > 0
    ) {
      setDisableAdd(true);
    }
  }, [disableAdd, candidatesAllowed, inviteData, user_package_check]);

  useEffect(() => {
    if (
      user_package_check === "free" &&
      candidatesAllowed === 1 &&
      inviteData?.length === 4
    ) {
      setDisableInput(true);
    }
    if (
      user_package_check === "free" &&
      candidatesAllowed === 2 &&
      inviteData?.length === 3
    ) {
      setDisableInput(true);
    }
    if (
      user_package_check === "free" &&
      candidatesAllowed === 3 &&
      inviteData?.length === 2
    ) {
      setDisableInput(true);
    }
    if (
      user_package_check === "free" &&
      candidatesAllowed === 4 &&
      inviteData?.length === 1
    ) {
      setDisableInput(true);
    }
    if (
      user_package_check === "free" &&
      candidatesAllowed === 5 &&
      inviteData?.length === 0
    ) {
      setDisableInput(true);
    }
  }, [disableInput, candidatesAllowed, inviteData, user_package_check]);
  //#endregion

  //#region clearing row
  useEffect(() => {
    if (selectedTab !== "Invite by email") {
      dispatch(setClearToTrue(true));
    }
  }, [selectedTab]);
  //#endregion
  return (
    <Transition.Root show={inviteModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={setInviteModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <ToastContainer
          position="top-center"
          transition={Zoom}
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          enableMultiContainer={false}
          limit={1}
        />

        <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0 sm:px-4 md:px-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className=" transform overflow-hidden rounded-lg overflow-y-auto bg-white text-left transition-all sm:my-8 sm:max-md:w-full  w-3/4 2xl:w-2/4 h-[46rem]">
                <div className="bg-white">
                  <p className="text-xl font-bold text-black cursor-pointer text-left px-8 pt-5">
                    Invite candidates
                  </p>
                  <IoClose
                    className="absolute top-3 right-5 z-20 w-5 h-5 cursor-pointer"
                    onClick={() => setInviteModal(false)}
                  />
                </div>
                <div className="flex flex-col sm:flex-row lg:flex-row md:justify-between mt-5 px-3 md:px-10 mb-7">
                  <div className="border-b border-gray-300 w-full overflow-x-auto">
                    <div className="md:text-lg font-medium text-center text-gray-500 dark:text-gray-400">
                      <ul className="flex md:flex-nowrap -mb-px whitespace-nowrap">
                        {invite_tabs?.map((i, index) => (
                          <li
                            key={index}
                            className="mr-2 cursor-pointer relative"
                            onClick={() => handleTabClick(i.title)}
                          >
                            <a
                              className={`inline-block p-2 md:p-4 ${
                                selectedTab === i.title
                                  ? "text-coalColor border-b-2 border-coalColor rounded-t-lg active"
                                  : "border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300"
                              }`}
                              aria-current="page"
                            >
                              {i.title}
                            </a>
                            {i?.title === "Invite in bulk" &&
                              user_package_check !== "Enterprise" && (
                                <span className="absolute top-2 right-0 -mr-2">
                                  <img
                                    src={King}
                                    alt="Premium Icon"
                                    className="w-5 h-5"
                                  />
                                </span>
                              )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                {selectedTab === "Invite by email" && (
                  <React.Fragment>
                    <div className="md:px-8 sm:px-3">
                      <p className="text-sm md:h-5 text-[#000000de]">
                        Invite multiple candidates by entering their first name,
                        last name and email below
                      </p>
                      <InviteByEmail
                        setInviteData={setInviteData}
                        disableAdd={disableAdd}
                        disableInput={disableInput}
                        candidatesAllowed={candidatesAllowed}
                      />
                    </div>
                  </React.Fragment>
                )}
                {selectedTab === "Invite in bulk" && (
                  <React.Fragment>
                    <div className="md:px-8 sm:px-3">
                      <p className="text-sm md:h-5 text-[#000000de]">
                        Invite multiple candidates at once through a CSV or XLSX
                        file upload.
                      </p>
                      <InviteByBulk
                        setFile={setFile}
                        barLoading={barLoading}
                        progress={progress}
                      />
                    </div>
                  </React.Fragment>
                )}
                {selectedTab === "Share link" && (
                  <React.Fragment>
                    <div className="md:px-8 sm:px-3">
                      <InviteByLink
                        code={assessmentCode}
                        setCopyCount={setCopyCount}
                        copyCount={copyCount}
                        handleSubmitStatus={handleSubmitStatus}
                      />
                    </div>
                  </React.Fragment>
                )}
                <hr className="w-full mt-8 md:block sm:hidden bg-gray-500 border-1" />
                <div className="px-8 flex justify-between sm:flex-col md:flex-row w-full sm:mt-8 md:mt-5">
                  <div className="text-[#cb7b7a] my-auto cursor-pointer md:text-left sm:text-center hover:text-[#a14d4b]">
                    {selectedTab === "Invite by email" &&
                      inviteData.length > 0 && (
                        <span onClick={handleClear}>Clear list</span>
                      )}
                  </div>
                  <div className="flex items-center md:flex-row sm:flex-col">
                    {(selectedTab === "Invite by email" ||
                      selectedTab === "Invite in bulk") && (
                      <>
                        <PremiumModaloverModal
                          premiumGeneralOpen={premiumGeneralOpen}
                          setPremiumGeneral={setPremiumGeneral}
                        />
                        <div className="relative">
                          <span
                            className="text-black underline md:mr-4 md:w-[15rem] flex flex-row cursor-pointer"
                            onClick={() =>
                              user_package_check === "Enterprise"
                                ? setCustomizeModal(true)
                                : setPremiumGeneral(true)
                            }
                          >
                            {user_package_check !== "Enterprise" && (
                              <span className="mr-2 my-auto">
                                <img
                                  src={King}
                                  alt="Premium Icon"
                                  className="w-4 h-4"
                                />
                              </span>
                            )}
                            Customize invitation email
                          </span>
                        </div>
                        <div className="md:w-[10rem] sm:mt-2 md:mt-0 relative group">
                          <CustomButton
                            label="Invite Candidate"
                            bgColor="#C0FF06"
                            borderCustom="border border-coalColor"
                            hoverBgColor="#252E3A"
                            hoverTextColor="#FFFFFF"
                            onClickButton={
                              selectedTab === "Invite by email"
                                ? handleSubmit
                                : handleBulkSubmit
                            }
                            disableField={
                              (selectedTab === "Invite by email" &&
                                inviteData.length === 0) ||
                              (selectedTab === "Invite in bulk" &&
                                file === null) ||
                              modulesData[0]?.status === "draft" ||
                              modulesData[0]?.status === "archived"
                            }
                            disabledCheck={
                              (selectedTab === "Invite by email" &&
                                inviteData.length === 0) ||
                              (selectedTab === "Invite in bulk" &&
                                file === null) ||
                              modulesData[0]?.status === "draft" ||
                              modulesData[0]?.status === "archived"
                            }
                            disabledColor="#D3D5D8"
                            LoadingBtn={mutateLoading ? true : false}
                            loadingText="Inviting"
                            loadingColor="black"
                          />
                          {modulesData[0]?.status === "draft" ||
                          modulesData[0]?.status === "archived" ? (
                            <div className="tooltip2 w-[12rem] text-center font-medium hidden group-hover:block absolute bottom-full left-1/2 transform -translate-x-1/2 mt-2 bg-white border border-gray-300 p-2 rounded shadow-lg">
                              {modulesData[0]?.status === "draft" ||
                              modulesData[0]?.status === "archived"
                                ? `This action is not available for ${
                                    modulesData[0]?.status === "draft"
                                      ? "draft"
                                      : "archived"
                                  } tests.`
                                : null}
                            </div>
                          ) : null}{" "}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
        {customizeModal && (
          <CustomizeEmail
            customizeModal={customizeModal}
            setCustomizeModal={setCustomizeModal}
            modulesData={modulesData}
          />
        )}
      </Dialog>
    </Transition.Root>
  );
}
