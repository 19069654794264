import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NavbarPreviews from "./Navbar/Navbar";
import { FaCheck, FaCircle } from "react-icons/fa";
import Clock from "react-clock";

const TimerScreen = () => {
  const previewData = useSelector((state) => state.preview.setPreview);
  const [isHovered, setIsHovered] = useState(false);
  const [value, setValue] = useState(new Date());
  const [time, setTime] = useState(10);
  const [isIntervalRunning, setIsIntervalRunning] = useState(true);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    user_email: "",
  });

  //#region Timer for test
  useEffect(() => {
    let interval;
    if (isIntervalRunning) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    if (time === 0) {
      window.opener = null;
      window.open("", "_self");
      window.close();
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [time, isIntervalRunning]);

  //#endregion

  //#region useEffect for clock
  useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  //#endregion

  //#region Styling of continue button
  const buttonStyle = {
    background: isHovered ? previewData[1] : previewData[1],
    transition: "background-color 0.1s, transform 0.1s",
    transform: isHovered ? "scale(1.02)" : "scale(1)",
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };
  //#endregion

  //#region closing tab on onClick
  const closeTab = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };
  //#endregion

  return (
    <>
      <NavbarPreviews previewData={previewData} fixed={true} marginTop={true} />
      <div className="bg-bodyColor">
        <div className="mx-auto lg:container">
          <div className="flex justify-center h-screen sm:max-md:px-4">
            <div className="m-auto sm:max-md:w-full w-2/3 2xl:w-1/2 rounded-md bg-white relative overflow-hidden">
              <div className="rounded-lg">
                <div className="lg:p-8 lg:px-12 sm:max-md:p-6">
                  <p
                    className="text-[#767676] gap-1 flex flex-row"
                    style={{ fontFamily: "Silka" }}
                  >
                    {Array.from({ length: 3 }, (_, index) => (
                      <FaCheck key={index} className="text-green-500 w-4 h-4" />
                    ))}
                    {Array.from({ length: 5 - 3 }, (_, index) => (
                      <FaCircle key={index} className="text-gray-400" />
                    ))}
                  </p>
                  <b
                    className="text-[#767676] font-bold mt-1"
                    style={{ fontFamily: "Silka" }}
                  >
                    Module Number:
                  </b>{" "}
                  <b
                    className="text-[#767676] font-bold mt-1"
                    style={{ fontFamily: "Silka" }}
                  >
                    3
                  </b>{" "}
                  <span style={{ fontFamily: "Silka" }}>out of</span>{" "}
                  <b
                    className="text-[#767676] font-bold mt-1"
                    style={{ fontFamily: "Silka" }}
                  >
                    5
                  </b>
                  <div className="flex relative cursor-pointer">
                    <h1 className="text-lg mt-7">
                      <span
                        className="py-0.5 px-8 rounded-xl bg-[#252E3A1A]"
                        style={{
                          fontFamily: "Silka",
                        }}
                      >
                        Mobile Home Park Investment Analysis and Strategy
                      </span>
                    </h1>
                  </div>
                  <p
                    className="text-[#767676] mt-1"
                    style={{ fontFamily: "Silka" }}
                  >
                    The actual test starts now.
                  </p>
                  <p
                    className="text-[#767676] font-bold mt-1"
                    style={{ fontFamily: "Silka" }}
                  >
                    Good Luck!
                  </p>
                  <Clock value={value} className="mx-auto mt-3 " />
                  <div>
                    {isIntervalRunning && (
                      <h1
                        className="text-lg mx-auto justify-center font-medium flex flex-row text-center mt-5"
                        style={{ fontFamily: "silka" }}
                      >
                        The test will start in{" "}
                        <div className="relative cursor-pointer">
                          <div className="ml-1 font-bold">
                            <span style={{ color: previewData[2] }}>
                              {time}{" "}
                            </span>
                            <span style={{ color: previewData[2] }}>
                              seconds
                            </span>
                          </div>
                          <div
                            className={`tooltip w-[10rem] text-center font-medium`}
                          >
                            Secondary color
                          </div>
                        </div>
                      </h1>
                    )}
                  </div>
                  <p
                    className="text-[#767676] mt-8"
                    style={{ fontFamily: "Silka" }}
                  >
                    Please stay on this screen. The timer for your next test has
                    started, and it cannot be paused.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimerScreen;
