import React, { useEffect, useState } from "react";
import TextFieldSmall from "../TextFieldSmall/TextFieldSmall";
import { FiPlusCircle } from "react-icons/fi";
import CustomButton from "../CustomButton/CustomButton";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { setClearToFalse } from "../../redux/reducers/ClearRows/ClearRowsSlice";
import GeneralModal from "../Modals/GeneralModal";

const InviteByEmail = (props) => {
  const dispatch = useDispatch();
  const clearRows = useSelector((state) => state.clearrow.setClearRow);

  const [rows, setRows] = useState([
    { id: 1, firstName: "", lastName: "", email: "" },
  ]);
  const [addedRowIds, setAddedRowIds] = useState([0]);
  const [disableField, setDisableField] = useState([0]);
  const [nextId, setNextId] = useState(2);
  const [addRequested, setAddRequested] = useState(false);
  const [generalModal, setGeneralModal] = useState(false);
  const [heading, setHeading] = useState("");
  const [description, setdescription] = useState("");
  const user_package_check = useSelector(
    (state) => state.packageDetails.setPackage
  );
  useEffect(() => {
    if (
      props?.inviteData &&
      props?.inviteData.length > 0 &&
      addRequested === false
    ) {
      const newRows = props.inviteData.map((item, index) => ({
        id: index + 1,
        firstName: item.firstName || "",
        lastName: item.lastName || "",
        email: item.email || "",
      }));
      setRows([
        ...newRows,
        { id: newRows.length + 1, firstName: "", lastName: "", email: "" },
      ]);
      setNextId(newRows.length + 2);
      setAddedRowIds(newRows.map((row) => row.id));
      setDisableField(newRows.map((row) => row.id));
      setAddRequested(false);
    }
  }, [props.inviteData, addRequested]);

  const handleAddRow = () => {
    const newId = nextId - 1;
    const newRowEmail = rows.find((row) => row.id === newId)?.email || "";
    const isEmailAlreadyAdded = rows
      .slice(0, rows.length - 1)
      .some((row) => row.email === newRowEmail);
    if (!isEmailAlreadyAdded) {
      const updatedRows = [
        ...rows,
        { id: nextId, firstName: "", lastName: "", email: "" },
      ];
      setRows(updatedRows);
      setNextId((prevId) => prevId + 1);
      setAddedRowIds((prevIds) => [...prevIds, newId]);
      setDisableField((prevIds) => [...prevIds, newId]);
      const filteredRows = updatedRows.filter((row) => row.email !== "");
      props.setInviteData(filteredRows);
    } else {
      toast.error("Email already exists", {
        toastId: "copy-success",
      });
    }
  };

  const handleFirstNameChange = (id, value) => {
    setRows((prevRows) => {
      const updatedRows = prevRows.map((row) => {
        if (row.id === id) {
          return { ...row, firstName: value };
        }
        return row;
      });
      const filteredRows = updatedRows.filter((row) => row.email !== "");
      props.setInviteData(filteredRows); // Update parent component
      setAddRequested(true);
      return updatedRows;
    });
  };

  const handleLastNameChange = (id, value) => {
    setRows((prevRows) => {
      const updatedRows = prevRows.map((row) => {
        if (row.id === id) {
          return { ...row, lastName: value };
        }
        return row;
      });
      const filteredRows = updatedRows.filter((row) => row.email !== "");
      props.setInviteData(filteredRows); // Update parent component
      setAddRequested(true);
      return updatedRows;
    });
  };

  const handleEmailChange = (id, value) => {
    setRows((prevRows) => {
      const updatedRows = prevRows.map((row) => {
        if (row.id === id) {
          return { ...row, email: value };
        }
        return row;
      });
      const filteredRows = updatedRows.filter((row) => row.email !== "");
      props.setInviteData(filteredRows); // Update parent component
      setAddRequested(true);
      return updatedRows;
    });
  };

  const handleClearRow = (id) => {
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
    const filteredRows = updatedRows.filter((row) => row.email !== "");
    props.setInviteData(filteredRows);
  };

  useEffect(() => {
    if (clearRows) {
      setRows([{ id: 1, firstName: "", lastName: "", email: "" }]);
      props.setInviteData([]);
      setDisableField([]);
      setAddedRowIds([]);
      setNextId(2);
      dispatch(setClearToFalse(false));
    }
  }, [clearRows]);

  const checkifcandidatesAllowed = () => {
    if (
      props?.disableInput &&
      props?.candidatesAllowed === 1 &&
      rows?.length === 5
    ) {
      return true;
    } else if (
      props?.disableInput &&
      props?.candidatesAllowed === 2 &&
      rows?.length === 4
    ) {
      return true;
    } else if (
      props?.disableInput &&
      props?.candidatesAllowed === 3 &&
      rows?.length === 3
    ) {
      return true;
    } else if (
      props?.disableInput &&
      props?.candidatesAllowed === 4 &&
      rows?.length === 2
    ) {
      return true;
    } else if (
      props?.disableInput &&
      props?.candidatesAllowed === 5 &&
      rows?.length === 1
    ) {
      return true;
    }
    return false;
  };

  const checkifcandidatesAllowedSignup = () => {
    if (rows?.length === 6 && user_package_check === "free") {
      return true;
    }
    return false;
  };
  return (
    <>
      <GeneralModal
        generalModal={generalModal}
        setGeneralModal={setGeneralModal}
        heading={"You’ve reached your limit!"}
        description={
          "To add more than 5 candidates, upgrade your subscription package to Pro or Enterprise"
        }
      />
      <div className="relative overflow-x-auto mt-5 rounded-md h-[25rem]">
        <table className="w-full text-sm text-left rtl:text-right min-w-[600px] md:min-w-full">
          <thead className="text-xs bg-gray-200 uppercase">
            <tr>
              <th scope="col" className="px-2 py-4 md:px-6">
                FIRST NAME
              </th>
              <th scope="col" className="px-2 py-4 md:px-6">
                LAST NAME
              </th>
              <th scope="col" className="px-2 py-4 md:px-6">
                EMAIL
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row.id} className="bg-white border-b">
                <td className="px-2 py-4 md:px-6">
                  <TextFieldSmall
                    id={`firstName-${row.id}`}
                    type="text"
                    name={`firstName-${row.id}`}
                    label="First name"
                    value={row.firstName}
                    mobileFont="16px"
                    onChangeValue={(e) => {
                      if (
                        checkifcandidatesAllowed() ||
                        checkifcandidatesAllowedSignup()
                      ) {
                        setGeneralModal(true);
                      } else {
                        handleFirstNameChange(row.id, e.target.value);
                      }
                    }}
                  />
                </td>
                <td className="px-2 py-4 md:px-6">
                  <TextFieldSmall
                    id={`lastName-${row.id}`}
                    type="text"
                    name={`lastName-${row.id}`}
                    label="Last name"
                    mobileFont="16px"
                    value={row.lastName}
                    onChangeValue={(e) => {
                      if (
                        checkifcandidatesAllowed() ||
                        checkifcandidatesAllowedSignup()
                      ) {
                        setGeneralModal(true);
                      } else {
                        handleLastNameChange(row.id, e.target.value);
                      }
                    }}
                  />
                </td>
                <td className="px-2 py-4 md:px-6">
                  <TextFieldSmall
                    id={`email-${row.id}`}
                    type="text"
                    name={`email-${row.id}`}
                    label="Email"
                    value={row.email}
                    mobileFont="16px"
                    onChangeValue={(e) => {
                      if (
                        checkifcandidatesAllowed() ||
                        checkifcandidatesAllowedSignup()
                      ) {
                        setGeneralModal(true);
                      } else {
                        handleEmailChange(row.id, e.target.value);
                      }
                    }}
                  />
                </td>
                <td
                  className={`px-2 py-4 md:px-6 ${
                    props?.buttonSize && props?.buttonSize
                  }`}
                >
                  {row.firstName &&
                  row.lastName &&
                  row.email &&
                  addedRowIds.includes(row.id) ? (
                    <RxCross2
                      className="cursor-pointer w-4 h-4 mx-auto"
                      onClick={() => handleClearRow(row.id)}
                    />
                  ) : (
                    <CustomButton
                      label="Add"
                      icon={FiPlusCircle}
                      bgColor="#252E3A"
                      textColor="#C0FF06"
                      onClickButton={() => {
                        if (props?.disableAdd) {
                          setHeading("You’ve reached your limit!");
                          setdescription(
                            "To add more than 5 candidates, upgrade your subscription package to Pro or Enterprise"
                          );
                          setGeneralModal(true);
                        } else {
                          handleAddRow();
                        }
                      }}
                      disabledCheck={
                        row.firstName === "" ||
                        row.lastName === "" ||
                        row.email === ""
                      }
                      disableField={
                        row.firstName === "" ||
                        row.lastName === "" ||
                        row.email === ""
                      }
                      disabledColor="#D3D5D8"
                      disabledTextColor="#7C8289"
                      hoverBgColor="#C0FF06"
                      hoverTextColor="#252E3A"
                      borderCustom="border border-coalColor text-white"
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default InviteByEmail;
