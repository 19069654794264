import React, { useState } from "react";
import Cropper from "react-easy-crop";
import { Box, Modal } from "@mui/material";
import "./styling.css";
import useWindowSize from "../../Helpers/useWindowSize";

const modalStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

function ImageCropper({
  image,
  onCropDone,
  onCropCancel,
  modalOpen,
  setModalOpen,
  setAllow,
  aspectRatios,
  defaultAspectRatio,
}) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(defaultAspectRatio);
  const size = useWindowSize();
  const isMobile = size.width <= 640;

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const handleAspectRatioChange = (event) => {
    setAspectRatio(Number(event.target.value));
    event.stopPropagation(); // Stop event propagation to avoid interference
  };

  return (
    <>
      {modalOpen ? (
        <Modal
          sx={modalStyle}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        >
          <Box className="cropper-container">
            <div className="cropper shadow-xl pb-2">
              <Cropper
                image={image}
                aspect={aspectRatio}
                crop={crop}
                zoom={zoom}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
                style={{
                  containerStyle: {
                    width: "100%",
                    height: "80%",
                    background: "gray",
                    marginBottom: "10%",
                  },
                }}
              />
              <Box className="controls">
                <input
                  id="slider"
                  type="range"
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e) => {
                    setZoom(e.target.value);
                  }}
                  className="zoom-range sm:w-[300px] md:w-[600px]"
                />
              </Box>
              <div className="flex flex-col mt-10 sm:text-xs md:text-sm">
                <div className="flex flex-row justify-center relative">
                  {aspectRatios.map((ratio) => (
                    <label
                      key={ratio.value}
                      className="flex items-center space-x-2 relative group mr-4"
                    >
                      <input
                        type="radio"
                        name="aspect-ratio"
                        value={ratio.value}
                        checked={aspectRatio === ratio.value}
                        onChange={handleAspectRatioChange}
                        className="form-radio h-[1.5rem] w-[1.5rem] relative"
                      />
                      <span
                        className="text-coalColor"
                        style={{ fontFamily: "Silka" }}
                      >
                        {ratio.label}
                      </span>
                      {!isMobile ? (
                        <div className="tooltip2 sm:w-[10rem] md:w-[15rem] text-center font-medium hidden group-hover:block absolute bottom-full left-1/2 transform -translate-x-1/2 mt-2 p-2 rounded shadow-lg">
                          Select an aspect ratio to crop your image. Save your
                          changes to preview the cropped picture using the
                          available options
                        </div>
                      ) : (
                        <>
                          {ratio.label !== "16:9" && ratio.label !== "3:1" && (
                            <div className="tooltip2 sm:w-[10rem] md:w-[15rem] text-center font-medium hidden group-hover:block absolute bottom-full left-1/2 transform -translate-x-1/2 mt-2 p-2 rounded shadow-lg">
                              Select an aspect ratio to crop your image. Save
                              your changes to preview the cropped picture using
                              the available options
                            </div>
                          )}
                        </>
                      )}
                    </label>
                  ))}
                </div>

                <div className="gap-5 mt-5 flex flex-row justify-center">
                  <button
                    className="bg-coalColor hover:bg-primaryGreen hover:text-coalColor border border-coalColor w-[7rem] text-white py-2 rounded-md"
                    style={{ fontFamily: "Silka" }}
                    onClick={() => setModalOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-primaryGreen border hover:bg-coalColor hover:text-white  font-bold border-coalColor w-[7rem] text-coalColor py-2 rounded-md"
                    style={{ fontFamily: "Silka" }}
                    onClick={() => {
                      setAllow(true);
                      onCropDone(croppedArea);
                      setModalOpen(false);
                    }}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      ) : null}
    </>
  );
}

export default ImageCropper;
