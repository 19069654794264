import React from "react"
import withRouter from "../Common/withRouter";
import NavbarUser from "../Navbar/NavbarUser";


const Layout = (props) => {


    return (
        <React.Fragment>
            <div id="preloader">
                <NavbarUser />
                <div className="main-content">{props.children}</div>
            </div>
        </React.Fragment >
    );
};

Layout.propTypes = {
};

export default withRouter(Layout);
