import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    setNextGeneral: false,
}

export const NextGeneralSlice = createSlice({
    name:"nextGeneral",
    initialState,
    reducers:{
        setNextGeneralToTrue :(state, action)=>{
            state.setNextGeneral = true
        },
        setNextGeneralToFalse :(state, action)=>{
            state.setNextGeneral = false
        },
    },
})

export const { setNextGeneralToTrue, setNextGeneralToFalse } = NextGeneralSlice.actions;
export default NextGeneralSlice.reducer;