import React, { useRef, useState, useEffect } from "react";
import { BiUpload } from "react-icons/bi";
import ImageInput from "../../../Components/FileInput/ImageInput";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { getCandidateDetails } from "../hooks/getCandidateDetails";
import * as Yup from "yup";
import { useFormik } from "formik";
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import axios, { all } from "axios";
import { FiUpload } from "react-icons/fi";
import { getCandidateMetaData } from "../hooks/getCandidateMetaData";
import DpCropper from "../../../Components/ImageCropper/DpCropper";
import https from "../../../https";
import { ToastContainer, toast, Zoom } from "react-toastify";
import { updateCandy } from "../../Profile/Settings/hooks/updateCandy";
import "./styling.css";
import { useNavigate } from "react-router-dom";
import { updateUser } from "../hooks/updateUser";
import Loader from "react-loader-spinner";
import TextField from "../../../Components/Dexta/TextField/TextField";
import Scrollbars from "react-custom-scrollbars";
import { getAllJobs } from "../../Profile/MyAssessments/Assessments-main/hooks/getAlljobs";
import { updateCandidate } from "../hooks/updateCandidate";
import { IoAddCircle } from "react-icons/io5";
import ChangePassword from "../../../Components/Modals/ChangePassword";
import ChangeEmail from "../../../Components/Modals/ChangeEmail";

const Profile = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [passwordOpen, setPasswordOpen] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [currentPage, setCurrentPage] = useState("choose-img");
  const [imageLoad, setImageLoad] = useState(false);
  const chooseImgFunctionRef = useRef();
  const queryClient = useQueryClient();
  const userID = localStorage.getItem("CP-CANDIDATE-ID");
  const [focusedField, setFocusedField] = useState(null);
  const [selectedPronoun, setSelectedPronoun] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [pronounModal, setPronounModal] = useState(false);
  const [countryModal, setCountryModal] = useState(false);
  const [countries, setCountries] = useState([]);
  const [searchCountry, setsearchCountry] = useState("");
  const [selectedProficiency, setSelectedProficiency] = useState("");
  const [proficiencyModal, setProficiencyModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [locationModal, setLocationModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [roleModal, setRoleModal] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [currencyModal, setCurrencyModal] = useState(false);
  const pronounRef = useRef(null);
  const countryRef = useRef(null);
  const englishRef = useRef(null);
  const locationRef = useRef(null);
  const roleRef = useRef(null);
  const [profileAllow, setProfileAllow] = useState(false);
  const [dpAfterCrop, setDpAfterCrop] = useState("");
  const [blobdataDp, setblobdataDp] = useState(null);
  const [errorstate, setError] = useState(false);
  const currencyRef = useRef(null);
  const [perror, setpError] = useState(false);
  const [cerror, setcError] = useState(false);
  const [englishError, setEnglishError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [primaryError, setPrimaryError] = useState(false);
  const [currencyError, setCurrencyError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const fname = localStorage.getItem("CP-CANDIDATE-FirstName");
  const lname = localStorage.getItem("CP-CANDIDATE-LastName");
  const navigate = useNavigate();
  const listInnerRef = useRef(null);
  const [paginationInfo, setPaginationInfo] = useState({
    currentTake: 1,
    hasNextPage: true,
    pageSize: 10, // Define pageSize here
  });

  const [allData, setAllData] = useState([]);

  //#region handle functions
  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const handleBiUploadClick = () => {
    if (chooseImgFunctionRef.current) {
      chooseImgFunctionRef.current();
    }
  };

  const onDpSelected = (selectedImg) => {
    setProfileImage(selectedImg);
    setCurrentPage("crop-img");
    setProfileModalOpen(true);
  };

  const handleLocationClick = (selectedLocationItem) => {
    // Check if the selected location is already in the array
    const isLocationSelected = selectedLocation?.includes(selectedLocationItem);

    // If it's not in the array, add it; otherwise, remove it
    setSelectedLocation((prevSelectedLocation) =>
      isLocationSelected
        ? prevSelectedLocation.filter(
            (location) => location !== selectedLocationItem
          )
        : [...prevSelectedLocation, selectedLocationItem]
    );
  };

  //#endregion

  //#region Handle Focus and Blur
  const handleFocus = (fieldName) => {
    setFocusedField(fieldName);
  };

  const handleBlur = () => {
    setFocusedField(null);
  };
  //#endregion

  //#region Fetch users data
  const { data, isLoading, error } = useQuery(["users", userID], () =>
    getCandidateDetails(userID)
  );
  //#endregion

  //#region Fetch candidate Meta Data
  const {
    data: metaData,
    isLoading: metaLoading,
    error: metaError,
  } = useQuery({
    queryKey: ["candidate-meta", userID],
    queryFn: () => getCandidateMetaData(userID),
  });
  //#endregion

  //#region Validations
  const validation = useFormik({
    enableReinitialize: true,
    initialValues:
      metaError || metaData === undefined
        ? {
            firstName: fname ? fname : "",
            lastName: lname ? lname : "",
            yearsxp: 0,
            amount: 0,
          }
        : {
            firstName:
              data?.firstName !== "" && data?.firstName !== " "
                ? data?.firstName
                : "",
            lastName: data?.lastName || "",
            yearsxp: metaData?.experience_years || 0,
            amount: metaData?.desired_annual_salary || 0,
          },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .min(3, "Too Short!")
        .max(50, "Too Long!")
        .required("First Name Required"),
      lastName: Yup.string()
        .min(3, "Too Short!")
        .max(50, "Too Long!")
        .required("Last Name Required"),
      amount: Yup.number().required("Amount Required"),
      yearsxp: Yup.number().required("Years of experience Required"),
    }),
    onSubmit: (values) => {
      let data = JSON.stringify({
        firstName: values?.firstName,
        lastName: values?.lastName,
        candidateMeta: {
          pronunciation: "",
          country: selectedCountry,
          english_proficiency: selectedProficiency,
          location_preferences: selectedLocation?.toString(),
          experience_in: selectedRole,
          experience_years: values?.yearsxp,
          desired_annual_salary: values?.amount,
          desired_currency: selectedCurrency,
        },
      });
      try {
        updateMutate(data);
      } catch (error) {
        //onError will automatically detect
      }
    },
  });
  //#endregion

  //#region mutate to update data
  const { mutate: updateMutate, isLoading: mutateLoadings } = useMutation(
    updateUser,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        handleOnBoard();
        setTimeout(() => {
          navigate("/candidate/dashboard");
        }, 500);
        toast.success("Information Saved", {
          toastId: "copy-success",
        });
      },
      onError: (error) => {
        setTimeout(() => {
          toast.error(error?.response?.data?.message[0], {
            toastId: "copy-success",
          });
        }, 500);
      },
    }
  );
  //#endregion

  //#region variables
  const proficiency = [
    {
      title: "None",
    },
    {
      title: "Beginner",
    },
    {
      title: "Intermediate",
    },
    {
      title: "Fluent",
    },
    {
      title: "Native Speaker",
    },
  ];

  const location = [
    {
      title: "Remote",
    },
    {
      title: "On/Site",
    },
    {
      title: "Hybrid",
    },
  ];

  const currency = [
    {
      title: "USD",
    },
    {
      title: "EUR",
    },
    {
      title: "GBP",
    },
    {
      title: "AUD",
    },
    {
      title: "SAR",
    },
  ];
  //#endregion

  //#region Fetching Countries from API
  useEffect(() => {
    const getCountry = async () => {
      try {
        const response = await https.get("/countries?limit=249");

        let countriesData = Object.values(response.data.data).map((country) => {
          // Keep "Western Sahara" as is, otherwise remove parenthetical content
          if (country.country === "Western Sahara*") {
            return "Western Sahara";
          } else {
            return country.country.replace(/\s*\(.*?\)\s*/g, "");
          }
        });

        // Manually add the desired countries at the top of the list
        const specialCountries = [
          "Global",
          "Saudi Arabia",
          "United Kingdom",
          "United States of America",
        ];

        // Remove the added countries from the original list
        countriesData = countriesData.filter(
          (country) => !specialCountries.includes(country)
        );

        // Add the countries in the desired order
        const finalCountries = [
          ...specialCountries,
          ...countriesData.sort((a, b) => a.localeCompare(b)),
        ];

        // Set the state with the modified list
        setCountries(finalCountries);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    getCountry();
  }, []);

  const filteredCountries = countries.filter((item) =>
    item.toLowerCase().includes(searchCountry.toLowerCase())
  );

  const calculateHeightForFeild = () => {
    const itemHeight = 40;
    const maxVisibleItems = 8;

    // Number of items in allData
    const itemsCount =
      filteredCountries.length > 0 ? filteredCountries.length : 1; // Ensure there's height for the "No results" message

    // Determine the height based on the items count
    const height =
      itemsCount > maxVisibleItems
        ? itemHeight * maxVisibleItems
        : itemHeight * itemsCount;

    return height;
  };
  //#endregion

  //#region useEffect functions to handle outside click
  useEffect(() => {
    function handleOutsidePronoun(event) {
      if (
        pronounRef.current &&
        !pronounRef.current.contains(event.target) &&
        event.target.id !== "pronounID" &&
        event.target.id !== "pronounID2" &&
        !event.target.closest(".pronounClass")
      ) {
        setPronounModal(false);
      }
    }
    document.addEventListener("mousedown", handleOutsidePronoun);
    return () => {
      document.removeEventListener("mousedown", handleOutsidePronoun);
    };
  }, [pronounRef]);

  useEffect(() => {
    function handleOutsideCountry(event) {
      if (
        countryRef.current &&
        !countryRef.current.contains(event.target) &&
        event.target.id !== "country" &&
        event.target.id !== "country2" &&
        !event.target.closest(".countryClass")
      ) {
        setCountryModal(false);
      }
    }
    document.addEventListener("mousedown", handleOutsideCountry);
    return () => {
      document.removeEventListener("mousedown", handleOutsideCountry);
    };
  }, [countryRef]);

  useEffect(() => {
    function handleOutsideEnglishProficiency(event) {
      if (
        englishRef.current &&
        !englishRef.current.contains(event.target) &&
        event.target.id !== "english" &&
        event.target.id !== "english2" &&
        !event.target.closest(".englishClass")
      ) {
        setProficiencyModal(false);
      }
    }
    document.addEventListener("mousedown", handleOutsideEnglishProficiency);
    return () => {
      document.removeEventListener(
        "mousedown",
        handleOutsideEnglishProficiency
      );
    };
  }, [englishRef]);

  useEffect(() => {
    function handleOutsideLocationRef(event) {
      if (
        locationRef.current &&
        !locationRef.current.contains(event.target) &&
        event.target.id !== "location" &&
        event.target.id !== "location2" &&
        !event.target.closest(".locationClass")
      ) {
        setLocationModal(false);
      }
    }
    document.addEventListener("mousedown", handleOutsideLocationRef);
    return () => {
      document.removeEventListener("mousedown", handleOutsideLocationRef);
    };
  }, [locationRef]);

  useEffect(() => {
    function handleOutsideRole(event) {
      if (
        roleRef.current &&
        !roleRef.current.contains(event.target) &&
        event.target.id !== "role" &&
        !event.target.closest(".roleClass")
      ) {
        setRoleModal(false);
      }
    }
    document.addEventListener("mousedown", handleOutsideRole);
    return () => {
      document.removeEventListener("mousedown", handleOutsideRole);
    };
  }, [roleRef]);

  useEffect(() => {
    function handleOutsideCurrency(event) {
      if (
        currencyRef.current &&
        !currencyRef.current.contains(event.target) &&
        event.target.id !== "currency" &&
        !event.target.closest(".currencyClass")
      ) {
        setCurrencyModal(false);
      }
    }
    document.addEventListener("mousedown", handleOutsideCurrency);
    return () => {
      document.removeEventListener("mousedown", handleOutsideCurrency);
    };
  }, [currencyRef]);
  //#endregion

  //#region crop functions
  const onCropDoneForDp = (imgCropped) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = imgCropped.width;
    canvasEle.height = imgCropped.height;
    const context = canvasEle.getContext("2d");

    // Set a transparent background
    context.clearRect(0, 0, canvasEle.width, canvasEle.height);

    let imageObj1 = new Image();
    imageObj1.src = profileImage;
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCropped.x,
        imgCropped.y,
        imgCropped.width,
        imgCropped.height,
        0,
        0,
        imgCropped.width,
        imgCropped.height
      );

      // Convert the canvas data to a Blob
      canvasEle.toBlob((blob) => {
        // Set the blob data to a separate state variable
        setblobdataDp(blob);
        const dataURL = canvasEle.toDataURL("image/png");
        setDpAfterCrop(dataURL);
        setCurrentPage("img-cropped");
        handlePostImage(blob);
      }, "image/png");
    };
  };

  // Handle Cancel Button Click
  const onCropCancelForDP = () => {
    setCurrentPage("choose-img");
    setProfileImage("");
  };
  //#endregion

  //#region Handle post image
  const handlePostImage = async (blobx) => {
    setImageLoad(true);
    const formData = new FormData();
    formData.append("file", blobx);
    formData.append("fileType", "user");
    try {
      const response = await https.post(
        "/questions/uploadQuestionImage",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      let data = {
        profilePhoto: response.data.link,
      };
      try {
        mutate(data);
      } catch (error) {}
      setImageLoad(false);
      setProfileAllow(false);
      resetImageState();
    } catch (error) {
      console.error(error);
      setImageLoad(false);
      setProfileAllow(false);
    }
  };

  const { mutate, isLoading: mutateLoading } = useMutation(updateCandy, {
    onSuccess: () => {
      queryClient.invalidateQueries("/users");
      setImageLoad(false);
      toast.success("Changes Saved", {
        toastId: "copy-success",
      });
      resetImageState();
    },
  });

  const resetImageState = () => {
    // Reset all the relevant states to allow a new upload
    setProfileImage("");
    setblobdataDp(null);
    setDpAfterCrop(null);
    setCurrentPage("choose-img"); // Go back to the initial page for image selection
  };
  //#endregion

  //#region upload user Image
  // useEffect(() => {
  //   const sendImageToApi = async () => {
  //     if (dpAfterCrop && profileAllow === true) {
  //       setImageLoad(true);
  //       const formData = new FormData();
  //       formData.append("file", blobdataDp);
  //       formData.append("fileType", "user");
  //       try {
  //         const response = await https.post(
  //           "/questions/uploadQuestionImage",
  //           formData,
  //           {
  //             headers: {
  //               "Content-Type": "multipart/form-data",
  //             },
  //           }
  //         );
  //         let data = {
  //           profilePhoto: response.data.link,
  //         };
  //         try {
  //           mutate(data);
  //           // if (!mutateLoading) {
  //           //   toast.success("Changes Saved");
  //           // }
  //         } catch (error) {}
  //         setImageLoad(false);
  //         setProfileAllow(false);
  //       } catch (error) {
  //         console.error(error);
  //         setImageLoad(false);
  //         setProfileAllow(false);
  //       }
  //     }
  //   };
  //   sendImageToApi();
  // }, [dpAfterCrop, profileAllow]);
  // //#endregion

  // //#region updating profile
  // const { mutate, isLoading: mutateLoading } = useMutation(updateCandy, {
  //   onSuccess: () => {
  //     queryClient.invalidateQueries("/users");
  //     setImageLoad(false);
  //     setTimeout(() => {
  //       toast.success("Changes Saved");
  //     }, 500);
  //   },
  // });
  //#endregion

  //#region clearing text errors
  useEffect(() => {
    if (selectedPronoun !== "") {
      setpError(false);
    }
    if (selectedCountry !== "") {
      setcError(false);
    }
    if (selectedProficiency !== "") {
      setEnglishError(false);
    }
    if (selectedLocation?.length != 0) {
      setLocationError(false);
    }
    if (selectedRole != "") {
      setPrimaryError(false);
    }
    if (selectedCurrency != "") {
      setCurrencyError(false);
    }
  }, [
    selectedPronoun,
    selectedCountry,
    selectedProficiency,
    selectedLocation,
    selectedRole,
    selectedCurrency,
  ]);
  //#endregion

  //#region updating data in states from api
  useEffect(() => {
    if (!metaError) {
      if (metaData != undefined) {
        setSelectedPronoun(
          metaData?.pronunciation != "" && metaData?.pronunciation
        );
        setSelectedCountry(
          metaData?.country != "" &&
            metaData?.country &&
            metaData?.country === false
            ? ""
            : metaData?.country
        );
        setSelectedProficiency(
          metaData?.english_proficiency != "" &&
            metaData?.english_proficiency != null &&
            metaData?.english_proficiency
        );
        setSelectedLocation(
          metaData?.location_preferences != "" &&
            metaData?.location_preferences != null
            ? metaData?.location_preferences?.split(",")
            : []
        );
        setSelectedRole(
          metaData?.experience_in != "" &&
            metaData?.experience_in != null &&
            metaData?.experience_in === false
            ? ""
            : metaData?.experience_in
        );
        setSelectedCurrency(
          metaData?.desired_currency !== "" &&
            metaData?.desired_currency !== null &&
            metaData?.desired_currency === false
            ? ""
            : metaData?.desired_currency
        );
      }
    }
  }, [metaData, metaError]);
  //#endregion

  //#region taking user to top of screen
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //#endregion

  //#region handling input change
  const handleInputChange = (e) => {
    setSelectedRole(e.target.value);
    setRoleModal(true);
    setPrimaryError(false);
  };
  //#endregion

  //#region handle pagination of jobs
  const { data: jobsData, isLoading: jobsLoading } = useQuery(
    ["jobs", paginationInfo.currentTake, selectedRole],
    () => getAllJobs(paginationInfo.currentTake, selectedRole),
    {
      keepPreviousData: true,
    }
  );

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight && !jobsLoading) {
        if (
          jobsData?.meta?.hasNextPage !== false &&
          allData.length >= paginationInfo.currentTake * paginationInfo.pageSize
        ) {
          setPaginationInfo((prevPaginationInfo) => ({
            ...prevPaginationInfo,
            currentTake: prevPaginationInfo.currentTake + 1,
          }));
        }
      }
    }
  };
  const handleScrollFrame = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      onScroll();
    }
  };

  useEffect(() => {
    if (!jobsLoading && jobsData && paginationInfo.currentTake === 1) {
      setAllData(jobsData?.data);
    } else if (!jobsLoading && jobsData && paginationInfo.currentTake > 1) {
      setAllData((prevData) => {
        const newData = jobsData?.data || [];
        const filteredData = newData.filter(
          (newItem) => !prevData.some((prevItem) => prevItem.id === newItem.id)
        );
        const updatedData = [...prevData, ...filteredData];

        // Update paginationInfo.hasNextPage based on whether all data is fetched
        if (filteredData.length < paginationInfo.pageSize) {
          setPaginationInfo((prevPaginationInfo) => ({
            ...prevPaginationInfo,
            hasNextPage: false,
          }));
        }

        return updatedData;
      });
    }
  }, [jobsData, jobsLoading, paginationInfo.currentTake]);

  //#endregion

  //#region Updating Tour Completed
  const handleOnBoard = () => {
    let data = JSON.stringify({
      isUserOnboard: true,
    });
    try {
      updateCandidateUserMutate(data);
    } catch (err) {
      console.log(err.message);
    }
  };
  //#endregion

  //#region updating tour status
  const { mutate: updateCandidateUserMutate, isLoading: updateLoading } =
    useMutation(updateCandidate, {
      onSuccess: (response) => {
        queryClient.invalidateQueries("/users");
      },
    });
  //#endregion

  //#region Calculate height based on the number of items
  const calculateHeight = () => {
    const itemHeight = 40;
    const maxVisibleItems = 8;

    // Number of items in allData
    const itemsCount = allData.length > 0 ? allData.length : 1; // Ensure there's height for the "No results" message

    // Determine the height based on the items count
    const height =
      itemsCount > maxVisibleItems
        ? itemHeight * maxVisibleItems
        : itemHeight * itemsCount;

    return height;
  };
  //#endregion

  return (
    <>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        enableMultiContainer={false}
        limit={1}
      />
      <ChangePassword
        passwordOpen={passwordOpen}
        setPasswordOpen={setPasswordOpen}
        candidateChange={true}
      />
      <ChangeEmail
        emailOpen={emailOpen}
        setEmailOpen={setEmailOpen}
        candidateChange={true}
      />

      <div className="md:mx-auto md:container sm:px-3 md:px-0">
        <div className="sm:py-5 md:py-0 md:p-10">
          <div className="sm:w-full md:w-4/6 mt-8 mx-auto">
            <div className="p-8 border-[1px] rounded-lg border-gray-300 bg-white">
              {isLoading ? (
                <div class="loader-container-1 col-span-6">
                  <div class="loader-1"></div>
                </div>
              ) : (
                <div>
                  <div className="flex flex-col">
                    <h1
                      className="text-2xl font-medium"
                      style={{ fontFamily: "Archia Semibold" }}
                    >
                      {metaError || metaData === undefined
                        ? "Create your Dexta profile"
                        : "Edit your Dexta profile"}
                    </h1>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <DpCropper
                        profileImage={profileImage}
                        onCropDoneForDp={onCropDoneForDp}
                        onCropCancelForDP={onCropCancelForDP}
                        profileModalOpen={profileModalOpen}
                        setProfileModalOpen={setProfileModalOpen}
                        setProfileAllow={setProfileAllow}
                      />
                      <div className="p-5">
                        <div className="flex justify-center">
                          <div
                            className={`relative w-40 h-40 flex items-center justify-center rounded-full overflow-hidden ${
                              isHovered ? "bg-black/40" : "bg-white"
                            }`}
                            onMouseEnter={handleHover}
                            onMouseLeave={handleHover}
                            onClick={handleBiUploadClick}
                          >
                            {imageLoad ? (
                              <div className="w-full h-full flex items-center justify-center">
                                <div class="three-body">
                                  <div class="three-body__dot"></div>
                                  <div class="three-body__dot"></div>
                                  <div class="three-body__dot"></div>
                                </div>
                              </div>
                            ) : (
                              <>
                                <img
                                  src={data?.profilePhoto}
                                  className="h-full w-full object-cover"
                                />

                                {!data?.profilePhoto && (
                                  <div className="w-full h-full flex items-center justify-center">
                                    <BiUpload className="h-6 w-6 text-white" />
                                  </div>
                                )}
                                <label
                                  htmlFor="profile-picture-upload"
                                  className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full flex items-center justify-center cursor-pointer ${
                                    isHovered || !data?.profilePhoto
                                      ? "block"
                                      : "hidden"
                                  }`}
                                >
                                  <div className="w-full h-full flex items-center justify-center bg-black/20 rounded-full transition-colors duration-300">
                                    <BiUpload className="h-6 w-6 text-white" />
                                  </div>
                                </label>
                              </>
                            )}
                            <ImageInput
                              setProfileImage={setProfileImage}
                              onDpSelected={onDpSelected}
                              onChooseImg={(chooseImg) =>
                                (chooseImgFunctionRef.current = chooseImg)
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <div className="grid md:grid-cols-2 gap-5 mt-8 md:h-16">
                            <div>
                              <TextField
                                type="text"
                                name="firstName"
                                id="firstName"
                                value={validation.values.firstName || ""}
                                rounded="rounded-md"
                                border={`border border-[#D3D5D8] focus-within:border focus-within:border-coalColor ${
                                  validation.values.email && "border-coalColor"
                                }`}
                                onChange={validation.handleChange}
                                onFocus={() => handleFocus("firstName")}
                                onBlur={(event) => {
                                  validation.handleBlur &&
                                    validation.handleBlur(event);
                                  handleBlur(event);
                                }}
                                placeholder="Enter your first name"
                              />
                              {validation.touched.firstName &&
                              validation.errors.firstName ? (
                                <p
                                  className="text-rose-500 text-xs ml-2 fade-in-text-candidate"
                                  style={{ fontFamily: "Silka" }}
                                >
                                  {validation.errors.firstName}
                                </p>
                              ) : null}
                            </div>
                            <div>
                              <TextField
                                type="text"
                                name="lastName"
                                id="lastName"
                                rounded="rounded-md"
                                border={`border border-[#D3D5D8] focus-within:border focus-within:border-coalColor ${
                                  validation.values.email && "border-coalColor"
                                }`}
                                onChange={validation.handleChange}
                                onFocus={() => handleFocus("lastName")}
                                onBlur={(event) => {
                                  validation.handleBlur &&
                                    validation.handleBlur(event);
                                  handleBlur(event);
                                }}
                                value={validation.values.lastName || ""}
                                placeholder="Enter your last name"
                              />
                              {validation.touched.lastName &&
                              validation.errors.lastName ? (
                                <p
                                  className="text-rose-500 text-xs ml-2 fade-in-text-candidate"
                                  style={{ fontFamily: "Silka" }}
                                >
                                  {validation.errors.lastName}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div
                            className="grid md:grid-cols-2 gap-5 mt-3 md:h-16"
                            style={{ fontFamily: "Silka" }}
                          >
                            <div className="relative">
                              <input
                                id="country"
                                className={`border border-[#D3D5D8] text-coalColor focus-within:border focus-within:border-coalColor rounded-md px-3 text-left w-full py-4`}
                                value={selectedCountry}
                                onChange={(e) => {
                                  setSelectedCountry(e.target.value);
                                  setsearchCountry(e.target.value);
                                  setCountryModal(true);
                                }}
                                onClick={() => setCountryModal(!countryModal)}
                                placeholder="Country"
                              />
                              <div className="absolute top-1/2 right-3 transform -translate-y-1/2 flex flex-row ">
                                <IoMdClose
                                  onClick={() => {
                                    setSelectedCountry("");
                                  }}
                                  className="cursor-pointer"
                                />
                                <IoIosArrowDown />
                              </div>

                              {countryModal && (
                                <div
                                  id="education-drop"
                                  className="countryClass absolute z-40 border enable-scrollbar2  border-coalColor right-0 top-full h-[15rem] overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full"
                                  style={{
                                    fontFamily: "Silka",
                                    height: calculateHeightForFeild(),
                                  }}
                                >
                                  <Scrollbars
                                    autoHide
                                    style={{ width: "100%", height: "100%" }}
                                  >
                                    {filteredCountries.map((i, index) => (
                                      <ul
                                        key={index}
                                        className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                        ref={countryRef}
                                        onClick={() => {
                                          setSelectedCountry(i);
                                          setCountryModal(false);
                                        }}
                                      >
                                        <li>
                                          <a className="block px-5 py-2 text-sm font-medium">
                                            {i}
                                          </a>
                                        </li>
                                      </ul>
                                    ))}
                                    <>
                                      {selectedCountry !== "" &&
                                        !filteredCountries.some(
                                          (item) => item === selectedCountry
                                        ) && (
                                          <ul
                                            className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                            ref={countryRef}
                                            onClick={() => {
                                              setCountryModal(false);
                                            }}
                                          >
                                            <li className="flex flex-row">
                                              <a className="block px-5 py-2 text-sm font-medium">
                                                Add "{selectedCountry}"
                                              </a>
                                              <IoAddCircle className="w-5 h-5 ml-auto my-auto" />
                                            </li>
                                          </ul>
                                        )}
                                    </>
                                  </Scrollbars>
                                </div>
                              )}
                              {/* {cerror ? (
                                <p
                                  className="text-rose-500 text-xs ml-2 fade-in-text-candidate"
                                  style={{ fontFamily: "Silka" }}
                                >
                                  Country Required
                                </p>
                              ) : null} */}
                            </div>
                            <div className="relative">
                              <button
                                id="english"
                                className={`border px-3 border-[#D3D5D8] focus-within:border focus-within:border-coalColor rounded-md text-left w-full py-4`}
                                type="button"
                                onFocus={() => handleFocus("proficiency")}
                                onBlur={(event) => {
                                  handleFocus("");
                                  if (selectedProficiency === "") {
                                    setEnglishError(true);
                                  }
                                }}
                                onClick={() =>
                                  setProficiencyModal(!proficiencyModal)
                                }
                              >
                                <div
                                  id="english"
                                  className={`flex justify-between ${
                                    selectedProficiency != ""
                                      ? "text-black"
                                      : "text-gray-400"
                                  }`}
                                >
                                  {selectedProficiency != ""
                                    ? selectedProficiency
                                    : "English Proficiency"}
                                  <div className="ml-auto flex flex-row py-1">
                                    <IoMdClose
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedProficiency("");
                                      }}
                                    />
                                    <IoIosArrowDown />
                                  </div>
                                </div>
                              </button>
                              {proficiencyModal && (
                                <div
                                  id="education-drop"
                                  className="englishClass absolute z-40 border enable-scrollbar2 border-coalColor right-0 top-full h-[8rem] overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full"
                                >
                                  <Scrollbars
                                    autoHide
                                    style={{ width: "100%", height: "100%" }}
                                  >
                                    {proficiency.map((i) => (
                                      <ul
                                        key={i.title}
                                        className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                        ref={englishRef}
                                        onClick={() => {
                                          setSelectedProficiency(i?.title);
                                          setProficiencyModal(false);
                                        }}
                                      >
                                        <li>
                                          <a className="block px-5 py-2 text-sm font-medium">
                                            {i.title}
                                          </a>
                                        </li>
                                      </ul>
                                    ))}
                                  </Scrollbars>
                                </div>
                              )}
                              {englishError ? (
                                <p
                                  className="text-rose-500 text-xs ml-2 fade-in-text-candidate"
                                  style={{ fontFamily: "Silka" }}
                                >
                                  English Proficiency Required
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div
                            className="grid md:grid-cols-2 gap-5 md:h-16 mt-3"
                            style={{ fontFamily: "Silka" }}
                          >
                            <div className="relative">
                              <button
                                id="location"
                                className={`border px-3 border-[#D3D5D8] focus-within:border focus-within:border-coalColor rounded-md text-left w-full py-4`}
                                type="button"
                                onFocus={() => handleFocus("location")}
                                onBlur={(event) => {
                                  handleFocus("");
                                  if (selectedLocation?.length === 0) {
                                    setLocationError(true);
                                  }
                                }}
                                onClick={() => setLocationModal(!locationModal)}
                              >
                                <div
                                  id="location"
                                  className={`flex justify-between ${
                                    selectedLocation != ""
                                      ? "text-black"
                                      : "text-gray-400"
                                  }`}
                                >
                                  {selectedLocation?.length === 0 && (
                                    <p className="text-gray-400">
                                      Location preferences
                                    </p>
                                  )}
                                  {selectedLocation?.map((i, index) => (
                                    <div className="flex flex-row my-auto gap-2 mr-2 bg-coalColor py-0.5 px-2 rounded-sm text-white">
                                      <IoMdClose
                                        className="my-auto hover:text-red-800 cursor-pointer"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          const updatedLocations = [
                                            ...selectedLocation,
                                          ];
                                          updatedLocations.splice(index, 1);
                                          setSelectedLocation(updatedLocations);
                                        }}
                                      />
                                      <p>{i}</p>
                                    </div>
                                  ))}
                                  <div className="ml-auto flex flex-row py-1">
                                    <IoMdClose
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedLocation([]);
                                      }}
                                    />
                                    <IoIosArrowDown />
                                  </div>
                                </div>
                              </button>

                              {locationModal && (
                                <div
                                  id="education-drop"
                                  className="locationClass absolute z-40 border border-coalColor right-0 top-full h-auto overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full"
                                >
                                  {location.map((i, index) => (
                                    <ul
                                      key={index}
                                      className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                      ref={locationRef}
                                      onClick={() => {
                                        handleLocationClick(i.title);
                                        setLocationModal(false);
                                      }}
                                    >
                                      <li>
                                        <a className="block px-5 py-2 text-sm font-medium">
                                          {i.title}
                                        </a>
                                      </li>
                                    </ul>
                                  ))}
                                </div>
                              )}
                              {locationError ? (
                                <p
                                  className="text-rose-500 text-xs ml-2 fade-in-text-candidate"
                                  style={{ fontFamily: "Silka" }}
                                >
                                  Location Preference Required
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <h1
                            className="font-medium text-lg mt-5"
                            style={{ fontFamily: "Archia Semibold" }}
                          >
                            Experience
                          </h1>
                          <div className="grid md:grid-cols-2 gap-5 mt-3 md:h-16">
                            <div className="relative">
                              <div
                                className={`border px-3 flex border-[#D3D5D8] focus-within:border focus-within:border-coalColor rounded-md text-left w-full py-4`}
                              >
                                <input
                                  id="role"
                                  className={`w-full bg-transparent border-none outline-none ${
                                    selectedRole !== ""
                                      ? "text-black"
                                      : "text-gray-400"
                                  }`}
                                  placeholder="Primary Role"
                                  style={{ fontFamily: "Silka" }}
                                  value={selectedRole}
                                  onFocus={() => handleFocus("role")}
                                  onBlur={(event) => {
                                    handleFocus("");
                                    if (selectedRole === "") {
                                      setPrimaryError(true);
                                    }
                                    // Delay closing to allow clicks on the dropdown
                                    setTimeout(() => {
                                      setRoleModal(false);
                                    }, 200);
                                  }}
                                  onClick={() => setRoleModal(true)}
                                  onChange={handleInputChange}
                                />
                                <div className="ml-auto flex flex-row py-1">
                                  {selectedRole && (
                                    <IoMdClose
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedRole("");
                                        setRoleModal(false);
                                      }}
                                    />
                                  )}
                                  <IoIosArrowDown
                                    onClick={() => setRoleModal(!roleModal)}
                                  />
                                </div>
                              </div>
                              {roleModal && (
                                <div
                                  className="roleClass absolute z-40 border enable-scrollbar2 border-coalColor right-0 top-full h-[15rem] overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                                  id="experienceDropdown"
                                  ref={listInnerRef}
                                  style={{
                                    fontFamily: "Silka",
                                    height: calculateHeight(),
                                  }}
                                >
                                  <Scrollbars
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      overflowX: "hidden",
                                    }}
                                    onScrollFrame={handleScrollFrame}
                                  >
                                    {allData?.map((item, index) => (
                                      <ul
                                        key={index}
                                        className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                        ref={roleRef}
                                        onClick={() => {
                                          setSelectedRole(item?.name);
                                          setRoleModal(false);
                                        }}
                                      >
                                        <li>
                                          <a className="block px-5 py-2 text-sm font-medium">
                                            {item.name}
                                          </a>
                                        </li>
                                      </ul>
                                    ))}
                                    <>
                                      {selectedRole !== "" &&
                                        !allData.some(
                                          (item) => item.name === selectedRole
                                        ) && (
                                          <ul
                                            className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                            ref={roleRef}
                                            onClick={() => {
                                              setRoleModal(false);
                                            }}
                                          >
                                            <li className="flex flex-row">
                                              <a className="block px-5 py-2 text-sm font-medium">
                                                Add "{selectedRole}"
                                              </a>
                                              <IoAddCircle className="w-5 h-5 ml-auto my-auto" />
                                            </li>
                                          </ul>
                                        )}
                                    </>
                                  </Scrollbars>
                                </div>
                              )}
                              {primaryError ? (
                                <p
                                  className="text-rose-500 text-xs ml-2 fade-in-text-candidate"
                                  style={{ fontFamily: "Silka" }}
                                >
                                  Primary role Required
                                </p>
                              ) : null}
                            </div>
                            <div>
                              <TextField
                                type="number"
                                name="yearsxp"
                                rounded="rounded-md"
                                border={`border border-[#D3D5D8] focus-within:border focus-within:border-coalColor ${
                                  validation.values.email && "border-coalColor"
                                }`}
                                onChange={validation.handleChange}
                                onFocus={() => handleFocus("yearsxp")}
                                placeholder="Years of experience"
                                onBlur={(event) => {
                                  validation.handleBlur &&
                                    validation.handleBlur(event);
                                  handleBlur(event);
                                }}
                                value={validation.values.yearsxp || ""}
                              />
                              {validation.touched.yearsxp &&
                              validation.errors.yearsxp ? (
                                <p
                                  className="text-rose-500 text-xs ml-2 fade-in-text-candidate"
                                  style={{ fontFamily: "Silka" }}
                                >
                                  {validation.errors.yearsxp}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <h1
                          className="font-medium text-lg mt-5"
                          style={{ fontFamily: "Archia Semibold" }}
                        >
                          Desired gross annual salary
                        </h1>
                        <div className="grid md:grid-cols-2 gap-5 mt-3 md:h-16">
                          <div className="flex flex-row gap-3">
                            <div className="sm:w-full md:w-1/3 relative">
                              <button
                                id="currency"
                                className={`border px-3 border-[#D3D5D8] focus-within:border focus-within:border-coalColor rounded-md text-left w-full py-4`}
                                type="button"
                                onFocus={() => handleFocus("currency")}
                                onBlur={(event) => {
                                  handleFocus("");
                                  if (selectedCurrency === "") {
                                    setCurrencyError(true);
                                  }
                                }}
                                onClick={() => setCurrencyModal(!currencyModal)}
                              >
                                <div
                                  id="currency"
                                  className={`flex justify-between ${
                                    selectedCurrency != "" &&
                                    selectedCurrency != null
                                      ? "text-black"
                                      : "text-gray-400"
                                  }`}
                                >
                                  {selectedCurrency != "" &&
                                  selectedCurrency != null
                                    ? selectedCurrency
                                    : "Currency"}
                                  <div className="ml-auto flex flex-row py-1">
                                    <IoMdClose
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedCurrency("");
                                      }}
                                    />
                                    <IoIosArrowDown />
                                  </div>
                                </div>
                              </button>
                              {currencyModal && (
                                <div
                                  id="education-drop"
                                  className="currencyClass absolute enable-scrollbar2 z-40 border border-coalColor right-0 top-full h-[8rem] overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                                >
                                  <Scrollbars
                                    autoHide
                                    style={{ width: "100%", height: "100%" }}
                                  >
                                    {currency.map((i) => (
                                      <ul
                                        key={i.title}
                                        className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                        ref={currencyRef}
                                        onClick={() => {
                                          setSelectedCurrency(i?.title);
                                          setCurrencyModal(false);
                                        }}
                                      >
                                        <li>
                                          <a className="block px-5 py-2 text-sm font-medium">
                                            {i.title}
                                          </a>
                                        </li>
                                      </ul>
                                    ))}
                                  </Scrollbars>
                                </div>
                              )}
                              {currencyError ? (
                                <p
                                  className="text-rose-500 text-xs ml-2 fade-in-text-candidate"
                                  style={{ fontFamily: "Silka" }}
                                >
                                  Currency Required
                                </p>
                              ) : null}
                            </div>
                            <div className="sm:w-full md:w-2/3 relative">
                              <TextField
                                type="number"
                                name="amount"
                                rounded="rounded-md"
                                border={`border border-[#D3D5D8] focus-within:border focus-within:border-coalColor ${
                                  validation.values.email && "border-coalColor"
                                }`}
                                onChange={validation.handleChange}
                                onFocus={() => handleFocus("amount")}
                                placeholder="Amount/yr"
                                onBlur={(event) => {
                                  validation.handleBlur &&
                                    validation.handleBlur(event);
                                  handleBlur(event);
                                }}
                                value={validation.values.amount || ""}
                              />
                              {validation.touched.amount &&
                              validation.errors.amount ? (
                                <p
                                  className="text-rose-500 text-xs ml-2 fade-in-text-candidate"
                                  style={{ fontFamily: "Silka" }}
                                >
                                  {validation.errors.amount}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div></div>
                        </div>
                        <div className="mb-3 mt-6 items-center flex justify-between px-1">
                          <div className="flex flex-row w-1/2 gap-5">
                            <div
                              name="Sign up"
                              className="bg-coalColor hover:bg-primaryGreen align-center hover:text-coalColor sm:w-full py-3 text-center rounded-md text-white border border-coalColor font-medium text-md relative"
                              style={{ fontFamily: "Archia Semibold" }}
                              onClick={() => setPasswordOpen(!passwordOpen)}
                            >
                              Change password
                            </div>
                            <div
                              name="Sign up"
                              className="bg-coalColor hover:bg-primaryGreen hover:text-coalColor sm:w-full py-3 text-center rounded-md text-white border border-coalColor font-medium text-md relative"
                              style={{ fontFamily: "Archia Semibold" }}
                              onClick={() => setEmailOpen(!emailOpen)}
                            >
                              Change email
                            </div>
                          </div>
                          <button
                            type="submit"
                            name="Sign up"
                            className="bg-primaryGreen hover:bg-coalColor hover:text-white sm:w-full md:w-1/6 h-12 rounded-md text-coalColor border border-coalColor font-medium text-md relative"
                            style={{ fontFamily: "Archia Semibold" }}
                          >
                            {mutateLoadings ? (
                              <span className="flex items-center justify-center">
                                <Loader
                                  type="Oval"
                                  color="black"
                                  height={20}
                                  width={20}
                                />
                                <span className="ml-2">Saving...</span>
                              </span>
                            ) : (
                              "Save"
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
