import React, { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import http from "../../http";
import queryString from "query-string";
import { ToastContainer, Zoom, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { patchPassword } from "./hooks/patchPassword";
import { patchInfo } from "./hooks/patchInfo";
import CustomButton from "../../Components/CustomButton/CustomButton";
import MainLogo from "../../Dexta_assets/SignupLogov2.png";
import { FaArrowRightLong } from "react-icons/fa6";
import TextField from "../../Components/Dexta/TextField/TextField";
import { setAccountTypeofUser } from "../../redux/reducers/AccountType/AccountTypeSlice";
import { setPackageData } from "../../redux/reducers/packageData/packageDataSlice";

const TeamSignup = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [verify, setVerify] = useState(false);
  const [load, setLoad] = useState(true);
  const navigate = useNavigate();
  const [packageNSelected, setPackageNSelected] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [packageDetail, setPackageDetail] = useState({});
  const { id } = useParams();
  const [inviteResponse, setInviteResponse] = useState(null);
  const [errorMessagePassword, setErrorMessagePassword] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);

  //#region Get access token
  const getAccessToken = async () => {
    let data = JSON.stringify({
      token: id,
    });
    try {
      const response = await http.post(
        "/auth/verify-invited-team-member",
        data
      );
      setInviteResponse(response?.data);
      localStorage.setItem("CP-USER-TOKEN", response?.data?.accessToken);
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    getAccessToken();
  }, []);
  //#endregion

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      email: inviteResponse?.user?.email,
      company: inviteResponse?.user?.companyName,
      password: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name Required"),
      lastName: Yup.string().required("Last Name Required"),
      email: Yup.string().required("Email Required"),
      password: Yup.string().required("Password Required"),
    }),
    onSubmit: (values) => {
      let data = JSON.stringify({
        firstName: values?.firstName,
        lastName: values?.lastName,
        isUserOnboard: true,
        isWebTourCompleted: true,
      });
      let passwordData = JSON.stringify({
        password1: values?.password,
        password2: values?.password,
      });
      try {
        InfoMutate(data);
        setTimeout(() => {
          mutate(passwordData);
        }, 500);
      } catch (error) {
        //onError will automatically detect
      }
    },
  });
  //#endregion

  function togglePasswordVisibility() {
    setIsPasswordVisible((prevState) => !prevState);
  }

  //#region saving password and basic info
  const { mutate, isLoading: mutateLoading } = useMutation(patchPassword, {
    onSuccess: () => {
      queryClient.invalidateQueries("/auth/setup-invited-member-password");
      setErrorPassword(false);
      localStorage.setItem("CP-USER-ID", inviteResponse?.user?.id);
      dispatch(setAccountTypeofUser(inviteResponse?.user?.accountType));
      getPackageUser(inviteResponse?.user?.id);
      // navigate("/dashboard");
    },
    onError: (error) => {
      setErrorPassword(true);
      setErrorMessagePassword(error?.response?.data?.message[0]);
      toast.success(error?.response?.data?.message[0], {
        toastId: "copy-success",
      });
    },
  });

  //#region Fetching user package
  const getPackageUser = async (pID) => {
    try {
      const response = await http.get(
        `/subscriptions/subscriptionsByUserId/${pID}`
      );
      dispatch(setPackageData(response?.data?.package?.code));
      navigate("/dashboard");
    } catch (err) {
      console.log(err.message);
    }
  };
  //#endregion

  const { mutate: InfoMutate, isLoading: InfoLoading } = useMutation(
    patchInfo,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("/users");
        setError(false);
      },
      onError: (error) => {
        setError(true);
        setErrorMessage(error?.response?.data?.message[0]);
        toast.success(error?.response?.data?.message[0], {
          toastId: "copy-success",
        });
      },
    }
  );
  //#endregion
  document.title = "Register | Dexta";

  return (
    <React.Fragment>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        enableMultiContainer={false}
        limit={1}
      />
      <div className="sm:grid-cols-1 lg:grid-cols-2 grid bg-bgAuth h-screen">
        <div className="bg-black sm:hidden lg:block">
          <img
            src={MainLogo}
            className="w-full lg:h-full xl:h-fit lg:object-cover"
          />
        </div>
        <div className="bg-bgAuth flex justify-center flex-col md:pb-40">
          <div className="sm:w-5/6 lg:w-1/2 mx-auto">
            <h1
              className="text-2xl text-left"
              style={{ fontFamily: "Archia Semibold" }}
            >
              {" "}
              Sign up{" "}
            </h1>
            <div style={{ fontFamily: "Silka" }} className="text-sm mt-6">
              <p>
                Fill the form below and join{" "}
                <b>{inviteResponse?.user?.companyName}</b>
              </p>
              {/* <span>Already a member? <span className='underline cursor-pointer' onClick={() => navigate("/login")}>Login</span></span> */}
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <div className="mt-6">
                <div className="grid grid-cols-2 gap-3 h-[4rem]">
                  <div className="h-[4rem]">
                    <TextField
                      name="firstName"
                      id="firstName"
                      type="name"
                      placeholder="First Name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.firstName || ""}
                    />
                    {validation.touched.firstName &&
                    validation.errors.firstName ? (
                      <div className="ml-1">
                        <p className="text-rose-500 fade-in-text-validations sm:text-xs md:text-sm">
                          {validation.errors.firstName}
                        </p>
                      </div>
                    ) : null}
                  </div>
                  <div className="h-[4rem]">
                    <TextField
                      name="lastName"
                      id="lastName"
                      type="name"
                      placeholder="Last Name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.lastName || ""}
                    />
                    {validation.touched.lastName &&
                    validation.errors.lastName ? (
                      <div className="ml-w-1/2 ml-1">
                        <p className="text-rose-500 fade-in-text-validations sm:text-xs md:text-sm">
                          {validation.errors.lastName}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="h-[4rem]">
                <TextField
                  name="email"
                  id="email"
                  type="email"
                  placeholder="Work email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  marginTop="mt-2"
                  value={validation.values.email || ""}
                  disabled={true}
                />
                {validation.touched.email && validation.errors.email ? (
                  <div className="ml-1">
                    <p className="text-rose-500 fade-in-text-validations sm:text-xs md:text-sm">
                      {validation.errors.email}
                    </p>
                  </div>
                ) : null}
              </div>
              <div className="h-[4rem]">
                <TextField
                  name="company"
                  id="company"
                  type="name"
                  placeholder="Company Name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  marginTop="mt-2"
                  value={validation.values.company || ""}
                  disabled={true}
                />
              </div>
              <div className="h-[4rem]">
                <TextField
                  name="password"
                  id="password"
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder="Password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ""}
                  marginTop="mt-2"
                  onClick={togglePasswordVisibility}
                  passwordIcon={true}
                  isPasswordVisible={isPasswordVisible}
                />
                {validation.touched.password && validation.errors.password ? (
                  <div className="ml-1">
                    <p className="text-rose-500 fade-in-text-validations sm:text-xs md:text-sm">
                      {validation.errors.password}
                    </p>
                  </div>
                ) : null}
              </div>
              <CustomButton
                label="Submit"
                borderCustom="border border-black text-white"
                hoverBgColor="#C0FF06"
                hoverTextColor="#252E3A"
                paddingY="0.7rem"
                marginTop="mt-8"
                bgColor="#252E3A"
                textColor="white"
                iconR={FaArrowRightLong}
                noMarginIcon={false}
                autoLeftMargin="ml-auto"
                textMarginBotton="ml-auto"
                LoadingBtn={mutateLoading || InfoLoading}
                loadingText="SUBMITTING"
              />
            </form>
            <p className="text-xs mt-5" style={{ fontFamily: "Silka" }}>
              By creating an account you confirm that you have read and agree to
              Dexta's term of use and Privacy policy
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TeamSignup;
