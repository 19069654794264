import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  setCurrent: "",
};

export const CurrentQuestionSlice = createSlice({
  name: "currentQuestion",
  initialState,
  reducers: {
    setCurrentQuestion: (state, action) => {
      state.setCurrent = action.payload;
    },
  },
});

export const { setCurrentQuestion } = CurrentQuestionSlice.actions;
export default CurrentQuestionSlice.reducer;
