import { useEffect } from "react";

const useOutsideClick = (refs, callback) => {
  useEffect(() => {
    function handleClickOutside(event) {
      refs.forEach(({ ref, excludeClasses, excludeIds }) => {
        const element = ref.current;
        if (
          element &&
          !element.contains(event.target) &&
          !excludeIds.includes(event.target.id) &&
          !excludeClasses?.some((cls) => event.target.closest(cls))
        ) {
          callback(ref);
        }
      });
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refs, callback]);
};

export default useOutsideClick;
