import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    setNextQuestions: false,
}

export const NextQuestionSlice = createSlice({
    name:"nextQuestion",
    initialState,
    reducers:{
        setNextQuestionsToTrue :(state, action)=>{
            state.setNextQuestions = true
        },
        setNextQuestionsToFalse :(state, action)=>{
            state.setNextQuestions = false
        },
    },
})

export const { setNextQuestionsToTrue, setNextQuestionsToFalse } = NextQuestionSlice.actions;
export default NextQuestionSlice.reducer;