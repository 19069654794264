export const TimeData = [
  {
    title: "5 mins",
    value: "5",
  },
  {
    title: "10 mins",
    value: "10",
  },
  {
    title: "15 mins",
    value: "15",
  },
  {
    title: "20 mins",
    value: "20",
  },
  {
    title: "25 mins",
    value: "25",
  },
  {
    title: "30 mins",
    value: "30",
  },
];

export const aspectRatios = [
  { label: "Original (1400:750)", value: 1400 / 750 },
  { label: "16:9", value: 16 / 9 },
  { label: "4:3", value: 4 / 3 },
  { label: "3:2", value: 3 / 2 },
  { label: "2:1", value: 2 / 1 },
];
