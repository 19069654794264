import React from "react";
import BoxPlotChart from "../../../../../Components/chart/demo";
import { useSelector } from "react-redux";
import "../invite.css";

const Graph1 = () => {
  const { xArray, myScore, firstName, lastName } = useSelector(
    (state) => state.graphData
  );

  console.log(xArray, myScore, firstName, lastName);
  return (
    <div className="mt-2 px-3 py-2 rotate-container bg-[#DBD9C0] rounded-lg">
      <BoxPlotChart
        xArray={xArray?.xArray}
        myScore={myScore?.myScore}
        firstName={firstName?.firstName}
        lastName={lastName?.lastName}
      />
    </div>
  );
};

export default Graph1;
